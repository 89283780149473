import React, { useEffect, useState } from 'react'
import DashboardNav from './dashboardNav'
import { Link, useHref, useNavigate } from 'react-router-dom'
import Chart from './Chart';
import { collection, doc, onSnapshot, orderBy, query,where,getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { UseAuth } from '../context/contex';



export default function Profile() {
  const [uid,setuid] = useState("")
  const [totalEarnings,settotalEarnings] = useState("")
  const [newAboutMe,setNewAboutMe] = useState("")
  const [newAboutMeError,setNewAboutMeError] = useState("")
  const [newFullName,setNewFullName] = useState("")
  const [newFullNameError,setNewFullNameError] = useState("")
  const [progress,setprogress] = useState("")
  const [region,setregion] = useState("")
  const [regionError,setregionError] = useState("")


  const [pop,setpop] = useState(false)
  const [notificationPop,setnotificationPop] = useState(false)
  const [fullNameEditor,setfullNameEditor] = useState(false)
  const [bioEditor,setbioEditor] = useState(false)
  const [photoEditor,setphotoEditor] = useState(false)
  const [photoHover,setphotoHover] = useState(false)
  const [imagePop,setimagePop] = useState(false)
  const [profileDeleteMessage,setprofileDeleteMessage] = useState(false)
  const [profileUploadedMessage,setprofileUploadedMessage] = useState(false)
  const [fullNameUpdatedMessage,setfullNameUpdatedMessage] = useState(false)
  const [aboutMeEditor,setaboutMeEditor] = useState(false)
  const [aboutMeMessage,setaboutMeMessage] = useState(false)
  const [regionEditor,setregionEditor] = useState(false)
  const [regionMessage,setregionMessage] = useState(false)









  


  const [notification,setnotification] = useState([])



  const [profilePicture,setprofilePicture] = useState("")
  const [fileMesssage,setfileMesssage] = useState("")






  const [averageMark,setaverageMark] = useState("")
  const [totalStudents,settotalStudents] = useState("")
  const [copied,setcopied] = useState(false)
  const [course,setCourse] = useState([])
  const [spinner,setspinner] =  useState(true)
  const [profileDelete,setprofileDelete] =  useState(false)

  const navigate = useNavigate()



    const [courses,setcourses] = useState([])
    const [teacher,setTeacher] = useState([])
    const [user,setuser] = useState([])
    const {accountType} = UseAuth()




function newAboutMeSet(e){
  setNewAboutMe(e.target.value)
  if(e.target.value.length == 0){
    setNewAboutMeError('error')
  }
  else{
    setNewAboutMeError('')
  }

}

function fullNameSet(e){
  setNewFullName(e.target.value)
  if(e.target.value.length == 0){
    setNewFullNameError('error')
  }
  else{
    setNewFullNameError('')
  }

}

function regionSet(e){
  setregion(e.target.value)
  if(e.target.value.length == 0){
    setregionError('error')
  }
  else{
    setregionError('')
  }

}

function profilePhotoHoverEnterHandler(){
  setphotoHover(true)
}

function profilePhotoHoverLeaveHandler(){
  setphotoHover(false)
}




function dropHandler(event) {
  setprofilePicture(event.dataTransfer.files);
  document.getElementById('overlay').classList.remove('dragged');
}
function uploadImg(){


  const storage = getStorage()


   


  const reff = ref(storage, `resume/${profilePicture.name}`)
  const uploadImage= uploadBytesResumable(reff,profilePicture)
  uploadImage.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setprogress(progress)

  }, 
  (error) => {
    console.log(error)
  }, 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  

  getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
    const documentRef2 = doc(firestore,"TotalUsers",uid)
    const documentRef = doc(firestore,accountType + 's',uid)
      
      const data = {
         photo:downloadURL


        };
      
         setDoc(documentRef, data, { merge: true });
         setDoc(documentRef2, data, { merge: true });
         setprofileUploadedMessage(true)
         window.scrollTo(0, 0);
    
  })
     
    
      
  }

  // )}
 
)


 

 
  

}
function cancelUploadImageDesktop(){
  setimagePop(false)
    setprogress(0)
    setprofilePicture([])
}

function removeFile() {
  setprofilePicture([])
  }

  const minimizeUploadImg = ()=>{
    setimagePop(false)

    setprofilePicture([])
  }

  function backImageDesktop (){
   setimagePop(false)
    setprofilePicture([])

  }

  function openImageDesktop (){
    setimagePop(true)

  
  }

  function openImageDeleteDesktop (){
    setprofileDelete(true)

  
  }

  function closeImageDeleteDesktop (){
    setprofileDelete(false)

  
  }
function dragLeaveHandler(event) {
  event.preventDefault();
  document.getElementById('overlay').classList.remove('dragged');
}

function handleImgSelect(event) {
  const file = event.target.files[0]
    const maxSize = 10 * 1024 * 1024; // 10MB
    // Check file type and size
    if (file.type.startsWith('image/')) {
      if (file.size > maxSize) {
        setfileMesssage(`File ${file.name} exceeds the size limit of 10MB.`);
        return false;
      } else {
        setfileMesssage('');
        setprofilePicture(file);
      }
    } else {
      setfileMesssage(`File ${file.name} is not a valid image file.`);
      return false;
    }


  // Clear the file input value to allow selecting the same file again
  event.target.value = '';
}
function mobileNavPop(){
  setpop(true)




}



function removeProfilePicture(){
  const documentRef2 = doc(firestore,"TotalUsers",uid)
  const documentRef = doc(firestore,accountType ,uid)
   const lectureData = {
     photo:''
    }
    setDoc(documentRef,lectureData,{merge:true})
    setDoc(documentRef2,lectureData,{merge:true})
    setprofileDelete(false)
    setprofileDeleteMessage(true)
    window.scrollTo(0, 0);

}
   function minimizeDeleteMessage(){
    setprofileDeleteMessage(false)

   } 

  // Profile Photo Click Handler
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}

  useEffect(()=>{

    onSnapshot(collection(firestore,"Courses"),snapshot=>{
      let temp = []
      snapshot.docs.forEach(doc=>{
        temp.push({...doc.data(),id:doc.id})
        setCourse(temp)
        
  
      })
    })
  
    },[])

 
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       

    const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

    const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

    onSnapshot(notificationRef,snapshot=>{
      let main =[]
      
  
  
  
      snapshot.docs.forEach(doc=>{
        main.push({...doc.data(),id:doc.id})
       
      setnotification(main)
      })
  
      
      
    
  
    })
    

  const coursesRef = collection(firestore,"AccountDetails",uid,"CoursesCreated");
  const teacherRef = doc(firestore,"TotalUsers",uid);
  const userRef = doc(firestore,"TotalUsers",uid);


  
  onSnapshot(coursesRef,snapshot=>{
    let main =[]
    let earning = 0;
    let students = 0;
    let markAverage = 0;



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
      const {totalEarnings,totalStudents,averageMark}= doc.data()
      earning += totalEarnings;
      students += totalStudents ;
      markAverage += averageMark ;

      markAverage = (markAverage/ main.length)
      settotalEarnings(earning)
      setaverageMark(markAverage)

      settotalStudents(students)
      setcourses(main)
    })

    
    
  

  })


       
  
  onSnapshot(teacherRef,snapshot=>{
    let main =[]
    let earning = 0;
    let students = 0;
    let markAverage = 0;



      main.push({...snapshot.data(),id:snapshot.id})
      
  setTeacher(main)

    
    
  

  })


  onSnapshot(userRef,snapshot=>{
   
    let main =[]



      main.push({...snapshot.data(),id:snapshot.id})
      
  setuser(main)

    
    
  

  })
        

           
 
//    })
  
            
  
  
  
  
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
      
  
        
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, []);

  function fullNameUpdate(){
    
    
    
    const documentRef2 = doc(firestore,"TotalUsers",uid)
    const documentRef = doc(firestore,accountType ,uid)
     const lectureData = {
       fullName:newFullName
      }
   if(newFullName.length != 0){
    setDoc(documentRef, lectureData, { merge: true });
    setDoc(documentRef2, lectureData, { merge: true }).then(
  setfullNameEditor(false),
setfullNameUpdatedMessage(true),
  window.scrollTo(0, 0)

    )
   }
   else{
    setNewFullNameError('error')
   }



  }

  function editInputOpenerDesktop(){
    setfullNameEditor(true)

  
  
  
    
  }
  // Edit Input Opener
  function editFullNameEditor(){
setfullNameEditor(false)
setNewFullNameError('')
  
  
  
    
  }

  function aboutMeTextAreaOpen(){
setaboutMeEditor(true)
  
  
    
  }
  // Edit Input Opener
  function aboutMeTextAreaMinimize(){
  setaboutMeEditor(false)
  
  
  
    
  }

  
  function regionTextAreaOpen(){
    setregionEditor(true)
      
      
        
      }
      // Edit Input Opener
      function regionTextAreaMinimize(){
      setregionEditor(false)
      
      
      
        
      }
  function aboutMeMessageMinimize(){
    setaboutMeMessage(false)
    
    
    
      
    }

    function regionMessageMinimize(){
      setregionMessage(false)
      
      
      
        
      }


      const convertTimestampToDateNavigation = (timestamp) => {
        const date = timestamp.toDate();
        const currentDate = new Date();
        const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
      
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;
        const secondsInMonth = 2592000;
        const secondsInYear = 31536000;
      
        let relativeTime = '';
      
        if (timeDifferenceInSeconds < secondsInMinute) {
          relativeTime = `${timeDifferenceInSeconds}s ago`;
        } else if (timeDifferenceInSeconds < secondsInHour) {
          const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
          relativeTime = `${minutes}m ago`;
        } else if (timeDifferenceInSeconds < secondsInDay) {
          const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
          relativeTime = `${hours}h ago`;
        } else if (timeDifferenceInSeconds < secondsInWeek) {
          const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
          relativeTime = `${days}d ago`;
        } else if (timeDifferenceInSeconds < secondsInMonth) {
          const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
          relativeTime = `${weeks}w ago`;
        } else if (timeDifferenceInSeconds < secondsInYear) {
          const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
          relativeTime = `${months}mo ago`;
        } else {
          const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
          relativeTime = `${years}y ago`;
        }
      
        return relativeTime;
      };
  const textRef = React.useRef();
  React.useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [newAboutMe]);


  
  const convertTimestampToDate = (timestamp) => {
 if(timestamp){
  const date = timestamp.toDate();
  return date.toLocaleDateString();
 }
  };
  function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    setDoc(notificationRef,{
      seen:true
    },{merge:true})
    .then(() => {
      
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
    });


   }
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

  function desktopSpinnerHandler(){
    setspinner(false)
   }
   function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
   const handleCopyUrl = () => {
    const url = `https://chekay.com/user/${uid}`;
  
    // Create a temporary input element
    const input = document.createElement('input');
    input.setAttribute('value', url);
    document.body.appendChild(input);
  
    // Select the input value
    input.select();
    input.setSelectionRange(0, 99999);
  
    // Execute the copy command
    document.execCommand('copy');
  
    // Remove the temporary input element
    document.body.removeChild(input);
  
    // Optionally, provide some visual feedback to the user
    setcopied(true)
  };
  
  function closeMessage6Desktop(){
    setcopied(false)

  }
  
  function saveRegionData(){
   
    const documentRef2 = doc(firestore,"TotalUsers",uid)
    const documentRef = doc(firestore,accountType , uid)
    const data = {
      region:region
    }


   if(region.length !=0){
    
    setDoc(documentRef,data,{merge:true})
    setDoc(documentRef2,data,{merge:true}).then(
      setregionEditor(false),
      setregionMessage(true),
      window.scrollTo(0, 0)

    )

   }
   else{
    setregionError('error')
   }

  }
  function saveUserData(){
   
    const documentRef2 = doc(firestore,"TotalUsers",uid)
    const documentRef = doc(firestore,accountType , uid)
    const data = {
      aboutMe:newAboutMe
    }

    if(newAboutMe.length !=0){
      
   
    setDoc(documentRef,data,{merge:true})
    setDoc(documentRef2,data,{merge:true}).then(
      setaboutMeEditor(false),
      setaboutMeMessage(true),
      window.scrollTo(0, 0)

    )
    }
    else{
      setNewAboutMeError('error')
    }

  }
  function profilePictureMessageClose(){
    setprofileUploadedMessage(false)
  }

  function fullNameMessageClose(){
    setfullNameUpdatedMessage(false)
  }
  
  return (
   <div>
   <div >

      
 {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
    {
      user.map(i=>{
        const{accountType,plan,fullName,photo,aboutMe,billingDate,region,email} = i
        
        return(
          accountType == "Student" ?
          <div>
       <div id='mobile'>
        {
          imagePop == true &&
          <div id='uploadImageDesktop' className="uploadFileDesktop h-screen w-screen " style={{zIndex: 100}}>



          {
            progress != 100 & progress == 0 ?
            <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>
          
            {/* file upload modal */}
            <article
              aria-label="File Upload Modal"
              className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

            >
          <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"5vw"}}>Upload profile picture</p>
          
              {/* overlay */}
              <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
          
              <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
                  <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
                  <button
                  style={{fontSize:"3vw"}}
                    className="fjalla text-white mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                    onClick={() => {
                      document.getElementById('hidden-input-img').click();
                    }}
                  >
                    Upload a file
                  </button>
                  <p className='Mooli text-gray-500' style={{fontSize:"3vw"}}>Supported file types include .png .jpg .jpeg</p>
          
                </header>
                    <p className='Mooli text-red-800 capitalize' style={{fontSize:"3vw"}}>{fileMesssage}</p>
                    {profilePicture.name}
          
          
                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900" style={{fontSize:"5vw"}}>To Upload</h1>
          
                <ul className="flex justify-evenley  -m-1">
                  {profilePicture.length === 0 ? (
                    <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                 
                      <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>No files selected</span>
                    </li>
                  ) : 
                      <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center">
                        <img
                        style={{maxHeight:"150px"}}
                          className="mx-auto w-32"
                          src={URL.createObjectURL(profilePicture)}
                          alt={profilePicture.name}
                          onClick={() => removeFile()}
                        />
                        <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>{profilePicture.name}</span>
                      </li>
                  }
                </ul>
              </section>
          
            
              <div className="flex justify-evenly mt-10 mb-10">
                <button
                  type="submit"
                  onClick={backImageDesktop}
                  style={{ width: '20%',fontSize:"3vw" }}
                  className="bg-red-800 m-auto w-fullflex fjalla justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Back
                </button>
                <button
                  onClick={uploadImg}
                  type="submit"
                  style={{ backgroundColor: '#2141ce', width: '20%',fontSize:"3vw" }}
                  className="m-auto w-full flex justify-center items-center pt-4 pr-5 fjalla pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Upload
                </button>
              </div>
            </article>
          </main>
          
          :
          progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                 <div class="">
                 <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                 <p className='anton  text-center' style={{fontSize:"5vw"}}>Profile Picture Successfully Updated</p>
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
             </div>
             
                   </div>
                   :
                   <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                   <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
                 <div class="relative mt-5">
                 <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 <p className='fjalla mt-4 text-center'>Uploading</p>
             </div>
             
                   </div>}
          
          
          </div>
          
        }
 
            <div>
         <DashboardNav/>
      
 
       
    
            </div>
            <div class="">
            {
  profileDeleteMessage == true &&

   <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
   <div className='flex justify-center items-center'>
 <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div className='Mooli' style={{fontSize:'3vw'}}>
 your Profile Picture has been successfully removed 

 </div>
 </div>
 <svg  onClick={minimizeDeleteMessage} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </div> 
}
{
  profileUploadedMessage &&
 
 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your Profile Picture has been successfully updated 


</div>
</div>
<svg  onClick={profilePictureMessageClose} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}

{
  aboutMeMessage &&
 
 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your  Bio has been successfully updated 


</div>
</div>
<svg  onClick={aboutMeMessageMinimize} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}

{
  regionMessage &&

 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your Region has been successfully updated 


</div>
</div>
<svg  onClick={regionMessageMinimize} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}
{
  fullNameUpdatedMessage &&

 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your Full Name has been successfully updated 


</div>
</div>
<svg  onClick={fullNameMessageClose} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}


            {
      copied == true &&

<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
successfully Copied User Profile


</div>
</div>
<svg  onClick={closeMessage6Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
    }
     {
  profileDelete == true && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeImageDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"4vw"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  style={{fontSize:"3vw"}}class="fjalla w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeImageDeleteDesktop}  style={{fontSize:"3vw"}} class="fjalla w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
    <div class=" mx-auto py-8">
        <div class="flex justify-evenly flex-col">
     <div class="mx-auto" style={{width:'95%'}}>

                <div class="bg-white shadow rounded-lg py-6 relative">
  
                    <div class="flex flex-col items-center">
                      {
                        photo.length != 0 ?
                         <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                         {photoHover && (
                           <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
                           </div>
                         )}
                        
                        <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                        :
                        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                        {photoHover && (
                          <div onClick={openImageDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                            </svg>
                          </div>
                        )}
                        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative',width:'30vw',height:'30vw' }}>
                          <p className=" capitalize text-white fjalla" style={{ fontSize: '6vw' }}>
                            {fullName && fullName[0]}
                          </p>
                        </div>
                      </div>

                      }
                        <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between items-center items-center  mb-2'>
                   <div>
{
  fullNameEditor == false &&
  <h1 className='fjalla  text-gray-900  mx-2' style={{fontSize:"5vw",marginTop:'2vw'}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>
}     

                   </div>
                   {
                    fullNameEditor == false &&
              
                                <svg onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:'4vw'}} fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                              </svg>
                   }
                   {
                    fullNameEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editFullNameEditor} id='cancelInputDesktop' style={{fontSize:'3vw'}}>Cancel</p>

                   }
                    </div>
                  {
                    fullNameEditor == true &&
                    <div className="relative mx-5 " id='editInputDesktop'>
                    <input  style={{fontSize:'3vw',height:'12vw',paddingLeft:'3vw'}} defaultValue={fullName}   onChange={e=>fullNameSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                        focus:border-black w-full  mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                        border-gray-300 rounded-md borel" />
         <div className={`${newFullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'2.5vw'}}>
      Full Name can't be empty
    </div>
    </div>
    
         </div>
                        <button style={{fontSize:'3vw'}}  onClick={fullNameUpdate} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white bg-blue-800 hover:bg-blue-900 focus:text-blue-700 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
                  </div>
                  }
                    <div>
                
                    </div>
                            
                  </div>
                        <p class="text-gray-700 borel" style={{fontSize:'3vw'}}>{email}</p>
                        <button onClick={handleCopyUrl} style={{fontSize:'3vw'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                      Copy Profile URL
                  </button>
                    </div>
                
                </div>
                <div class="bg-white shadow rounded-lg p-6 relative mt-5">
                <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"5vw"}}>Membership
 </h1>
<div className='flex justify-between items-center'>
<p className='borel text-gray-500 block capitalize' style={{fontSize:'3vw'}}> {plan}</p>
 <Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize 'style={{fontSize:'3vw'}}>Change</p></Link>

</div>
{
  plan != 'Free' &&
<div className='flex justify-between items-center mt-5'>
<h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"4vw"}}>Billing Date
 </h1>
<p className='borel text-gray-500 block capitalize' style={{fontSize:'3vw'}}>{ (billingDate)}</p>

</div>
}
                
                </div>
            </div>
     <div class="col-span-4 sm:col-span-9 mx-auto" style={{width:'95%',marginTop:'5vw',marginBottom:'5vw'}}>

                <div class="bg-white shadow border rounded-lg py-3">
      
                 
                    <div className='rounded m-auto' >
                    
                    <div className='flex justify-between items-center   w-full' style={{padding:'5vw'}}>
     <div className='w-full'>

                   <h1 className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"6vw"}}>About Me
                   {
              aboutMeEditor == false &&
              <svg onClick={aboutMeTextAreaOpen}xmlns="http://www.w3.org/2000/svg" style={{width:'4vw'}} fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
            </svg>
            }
  
  </h1>
{
              aboutMeEditor == false &&

     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>

}     
     
                   </div>
                
          
            {
              aboutMeEditor == true &&
              <p className='fjalla  cursor-pointer hover:text-red-800' onClick={aboutMeTextAreaMinimize} id='cancelDescripionInputDesktop'style={{fontSize:'3vw'}}>Cancel</p>

            }
                    </div>
                    {
              aboutMeEditor == true &&
              <div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
              <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>newAboutMeSet(e)}  type="text" class="border  focus:outline-none
                           focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                           border-gray-300 rounded-md" maxLength="2000"></textarea>
                           <div className={`${newAboutMeError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'2.5vw'}}>
               Bio Can't be empty
              </div>
              </div>
              </div>
          {newAboutMe.length != 0 && 
              <p className='Mooli text-gray-500' style={{fontSize:'2.5vw'}}>{newAboutMe && newAboutMe.length}/2,000</p>
            }   
             <button onClick={saveUserData} style={{fontSize:'3vw'}} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                    
                    Save
                  </button>    
              </div>
                    }
          
   
                    <div>
                
                    </div>
                            
                  </div>

               
               


                  
              
                 
                </div>

                <div class="bg-white shadow rounded-lg py-3 mt-6">
      
                 
      <div className='rounded m-auto' >
      
      <div className='flex justify-between items-center  px-5 py-5 w-full'>
     <div className='w-full'>
     <div className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"6vw"}}>Region
     {
regionEditor == false &&
<svg onClick={regionTextAreaOpen}xmlns="http://www.w3.org/2000/svg" style={{width:'4vw'}} fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
<path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
</svg>
}

</div>
{
regionEditor == false &&
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>

}


     </div>
  

{
regionEditor == true &&
<p className='fjalla  cursor-pointer hover:text-red-800'style={{fontSize:'3vw'}} onClick={regionTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

}
      </div>
      {
regionEditor == true &&
<div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>

<select defaultValue={region} onChange={e=> regionSet(e)} style={{fontSize:'3vw',color:'black'}} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 borel mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option' value="" >Select Region</option>
    
    <option className='skill__input-option' value="Addis Ababa">Addis Ababa</option>
    <option className='skill__input-option' value="Afar">Afar</option>

    <option className='skill__input-option' value="Benshangul-Gumz">Benshangul-Gumz</option>     
        <option className='skill__input-option' value="Dire Dawa" >Dire Dawa</option>
        <option className='skill__input-option' value="Gambela">Gambela</option>
        <option className='skill__input-option' value="Harari">Harari</option>
        <option className='skill__input-option' value="Oromia">Oromia</option>
        <option className='skill__input-option' value="Somali">Somali</option>
        <option className='skill__input-option capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
        <option className='skill__input-option' value="Tigray">Tigray</option>


</select> 
             <div className={`${regionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'2.5vw'}}>
 Region Can't be empty
</div>
</div>
</div>

<button onClick={saveRegionData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
      
      Save
    </button>    
</div>
      }


      <div>
  
      </div>
              
    </div>

 
 


    

   
  </div>
            </div>
        </div>
    </div>
</div>
      
          </div>
        
      <div id='desktop'>
        {
          imagePop == true &&
          <div id='uploadImageDesktop' className="uploadFileDesktop h-screen w-screen " style={{zIndex: 100}}>



          {
            progress != 100 & progress == 0 ?
            <main className="container mx-auto  my-5 " style={{width:"90%",height:"95%"}}>
          
            {/* file upload modal */}
            <article
              aria-label="File Upload Modal"
              className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

            >
          <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload profile picture</p>
          
              {/* overlay */}
              <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
          
              <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
                  <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
                  <button
                    className="fjalla text-white mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                    onClick={() => {
                      document.getElementById('hidden-input-img').click();
                    }}
                  >
                    Upload a file
                  </button>
                  <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>
          
                </header>
                    <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
                    {profilePicture.name}
          
          
                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
          
                <ul className="flex justify-evenley  -m-1">
                  {profilePicture.length === 0 ? (
                    <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                 
                      <span className="text-small text-gray-500">No files selected</span>
                    </li>
                  ) : 
                      <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center">
                        <img
                        style={{maxHeight:"150px"}}
                          className="mx-auto w-32"
                          src={URL.createObjectURL(profilePicture)}
                          alt={profilePicture.name}
                          onClick={() => removeFile()}
                        />
                        <span className="text-small text-gray-500">{profilePicture.name}</span>
                      </li>
                  }
                </ul>
              </section>
          
              {/* sticky footer */}
              <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
              <div className="flex justify-evenly mt-10 mb-10">
                <button
                  type="submit"
                  onClick={backImageDesktop}
                  style={{ width: '20%' }}
                  className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Back
                </button>
                <button
                  onClick={uploadImg}
                  type="submit"
                  style={{ backgroundColor: '#2141ce', width: '20%' }}
                  className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Upload
                </button>
              </div>
            </article>
          </main>
          
          :
          progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                 <div class="">
                 <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                 <p className='anton  text-center' style={{fontSize:"35px"}}>Profile Picture Successfully Updated</p>
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
             </div>
             
                   </div>
                   :
                   <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                   <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
                 <div class="relative mt-5">
                 <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 <p className='fjalla mt-4 text-center'>Uploading</p>
             </div>
             
                   </div>}
          
          
          </div>
          
        }
 
            <div>
            <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>

      <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/enrolledcourses' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >Enrolled Courses</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/achievements" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Achievements</li>
      
                    </Link>
      
           
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
      
 
       
    
            </div>
            <div class="">
            {
  profileDeleteMessage == true &&
  <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
  your Profile Picture has been successfully removed 
  </div>
  <button onClick={minimizeDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  profileUploadedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Profile Picture has been successfully updated 
  </div>
  <button onClick={profilePictureMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  aboutMeMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your  Bio has been successfully updated 
  </div>
  <button onClick={aboutMeMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  regionMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Region has been successfully updated 
  </div>
  <button onClick={regionMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  fullNameUpdatedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Full Name has been successfully updated 
  </div>
  <button onClick={fullNameMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}


            {
      copied == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     successfully Copied User Profile
    </div>
    <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
     {
  profileDelete == true && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeImageDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeImageDeleteDesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
    <div class=" mx-auto py-8">
        <div class="grid grid-cols-3 sm:grid-cols-12 gap-6 px-4">
            <div class="col-span-4 sm:col-span-3">
                <div class="bg-white shadow border rounded-lg p-6 relative">
  
                    <div class="flex flex-col items-center">
                      {
                        photo.length != 0 ?
                         <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                         {photoHover && (
                           <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
                           </div>
                         )}
                        
                        <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                        :
                        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                        {photoHover && (
                          <div onClick={openImageDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                            </svg>
                          </div>
                        )}
                        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative' }}>
                          <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
                            {fullName && fullName[0]}
                          </p>
                        </div>
                      </div>

                      }
                        <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between items-center items-center  mb-2'>
                   <div>
{
  fullNameEditor == false &&
  <h1 className='fjalla  text-gray-900  mx-2' style={{fontSize:"25px"}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>
}     

                   </div>
                   {
                    fullNameEditor == false &&
              
                                <svg onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                              </svg>
                   }
                   {
                    fullNameEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editFullNameEditor} id='cancelInputDesktop'>Cancel</p>

                   }
                    </div>
                  {
                    fullNameEditor == true &&
                    <div className="relative mx-5 " id='editInputDesktop'>
                    <input  style={{fontSize:'12px'}} defaultValue={fullName}   onChange={e=>fullNameSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                        focus:border-black w-full pt-3 pr-4 pb-3 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                        border-gray-300 rounded-md borel" />
         <div className={`${newFullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'11px'}}>
      Full Name can't be empty
    </div>
    </div>
    
         </div>
                        <button style={{fontSize:'12px'}}  onClick={fullNameUpdate} class="inline-flex px-5 py-3 mr-5 mt-5 text-white bg-blue-800 hover:bg-blue-900 focus:text-blue-700 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
                  </div>
                  }
                    <div>
                
                    </div>
                            
                  </div>
                        <p class="text-gray-700 borel" style={{fontSize:'11px'}}>{email}</p>
                        <button onClick={handleCopyUrl} style={{fontSize:'11px'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                      Copy Profile URL
                  </button>
                    </div>
                
                </div>
                <div class="bg-white shadow border rounded-lg p-6 relative mt-5">
                <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"30px"}}>Membership
 </h1>
<div className='flex justify-between items-center'>
<p className='borel text-gray-500 block capitalize'>{plan}</p>
 <Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize '>Change</p></Link>

</div>
{
  plan != 'Free' &&
<div className='flex justify-between items-center mt-5'>
<h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"20px"}}>Billing Date
 </h1>
<p className='borel text-gray-500 block capitalize'>{ (billingDate)}</p>

</div>
}
                
                </div>
            </div>
            <div class="col-span-4 sm:col-span-9">
                <div class="bg-white shadow rounded-lg p-6 border">
      
                 
                    <div className='rounded m-auto' >
                    
                    <div className='flex justify-between items-center px-5 py-5 w-full'>
     <div className='w-full'>

                   <h1 className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"30px"}}>About Me
                   {
              aboutMeEditor == false &&
              <svg onClick={aboutMeTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
            </svg>
            }
  
  </h1>
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"12px",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>
     
     
                   </div>
                
          
            {
              aboutMeEditor == true &&
              <p className='fjalla  cursor-pointer hover:text-red-800' onClick={aboutMeTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

            }
                    </div>
                    {
              aboutMeEditor == true &&
              <div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
              <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>newAboutMeSet(e)}  type="text" class="border  focus:outline-none
                           focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                           border-gray-300 rounded-md" maxLength="2000"></textarea>
                           <div className={`${newAboutMeError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               Bio Can't be empty
              </div>
              </div>
              </div>
          {newAboutMe.length != 0 && 
              <p className='Mooli text-gray-500'>{newAboutMe && newAboutMe.length}/2,000</p>
            }   
             <button onClick={saveUserData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                    
                    Save
                  </button>    
              </div>
                    }
          
   
                    <div>
                
                    </div>
                            
                  </div>

               
               


                  
              
                 
                </div>

                <div class="bg-white shadow rounded-lg p-6 mt-6 border">
      
                 
      <div className='rounded m-auto' >
      
      <div className='flex justify-between items-center px-5 py-5 w-full'>
     <div className='w-full'>
     <div className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"30px"}}>Region
     {
regionEditor == false &&
<svg onClick={regionTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
<path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
</svg>
}

</div>
{
regionEditor == false &&
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>

}


     </div>
  

{
regionEditor == true &&
<p className='fjalla  cursor-pointer hover:text-red-800' onClick={regionTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

}
      </div>
      {
regionEditor == true &&
<div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>

<select defaultValue={region} onChange={e=> regionSet(e)} style={{fontSize:'11px'}} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 borel mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option' value="" >Select Region</option>
    
    <option className='skill__input-option' value="Addis Ababa">Addis Ababa</option>
    <option className='skill__input-option' value="Afar">Afar</option>

    <option className='skill__input-option' value="Benshangul-Gumz">Benshangul-Gumz</option>     
        <option className='skill__input-option' value="Dire Dawa" >Dire Dawa</option>
        <option className='skill__input-option' value="Gambela">Gambela</option>
        <option className='skill__input-option' value="Harari">Harari</option>
        <option className='skill__input-option' value="Oromia">Oromia</option>
        <option className='skill__input-option' value="Somali">Somali</option>
        <option className='skill__input-option capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
        <option className='skill__input-option' value="Tigray">Tigray</option>


</select> 
             <div className={`${regionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
 Region Can't be empty
</div>
</div>
</div>

<button onClick={saveRegionData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
      
      Save
    </button>    
</div>
      }


      <div>
  
      </div>
              
    </div>

 
 


    

   
  </div>
            </div>
        </div>
    </div>
</div>
      
          </div>
          <div id='tablet'>
        {
          imagePop == true &&
          <div id='uploadImageDesktop' className="uploadFileDesktop h-screen w-screen " style={{zIndex: 100}}>



          {
            progress != 100 & progress == 0 ?
            <main className="container mx-auto  my-5 " style={{width:"90%",height:"95%"}}>
          
            {/* file upload modal */}
            <article
              aria-label="File Upload Modal"
              className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

            >
          <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload profile picture</p>
          
              {/* overlay */}
              <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
          
              <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
                  <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
                  <button
                    className="fjalla text-white mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                    onClick={() => {
                      document.getElementById('hidden-input-img').click();
                    }}
                  >
                    Upload a file
                  </button>
                  <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>
          
                </header>
                    <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
                    {profilePicture.name}
          
          
                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
          
                <ul className="flex justify-evenley  -m-1">
                  {profilePicture.length === 0 ? (
                    <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                 
                      <span className="text-small text-gray-500">No files selected</span>
                    </li>
                  ) : 
                      <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center">
                        <img
                        style={{maxHeight:"150px"}}
                          className="mx-auto w-32"
                          src={URL.createObjectURL(profilePicture)}
                          alt={profilePicture.name}
                          onClick={() => removeFile()}
                        />
                        <span className="text-small text-gray-500">{profilePicture.name}</span>
                      </li>
                  }
                </ul>
              </section>
          
              {/* sticky footer */}
              <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
              <div className="flex justify-evenly mt-10 mb-10">
                <button
                  type="submit"
                  onClick={backImageDesktop}
                  style={{ width: '20%' }}
                  className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Back
                </button>
                <button
                  onClick={uploadImg}
                  type="submit"
                  style={{ backgroundColor: '#2141ce', width: '20%' }}
                  className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Upload
                </button>
              </div>
            </article>
          </main>
          
          :
          progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                 <div class="">
                 <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                 <p className='anton  text-center' style={{fontSize:"35px"}}>Profile Picture Successfully Updated</p>
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
             </div>
             
                   </div>
                   :
                   <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                   <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
                 <div class="relative mt-5">
                 <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 <p className='fjalla mt-4 text-center'>Uploading</p>
             </div>
             
                   </div>}
          
          
          </div>
          
        }
 
            <div>
         <DashboardNav/>
      
 
       
    
            </div>
            <div class="">
            {
  profileDeleteMessage == true &&
  <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
  your Profile Picture has been successfully removed 
  </div>
  <button onClick={minimizeDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  profileUploadedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Profile Picture has been successfully updated 
  </div>
  <button onClick={profilePictureMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  aboutMeMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your  Bio has been successfully updated 
  </div>
  <button onClick={aboutMeMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  regionMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Region has been successfully updated 
  </div>
  <button onClick={regionMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  fullNameUpdatedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Full Name has been successfully updated 
  </div>
  <button onClick={fullNameMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}


            {
      copied == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     successfully Copied User Profile
    </div>
    <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
     {
  profileDelete == true && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeImageDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeImageDeleteDesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
    <div class=" mx-auto py-8">
        <div class="flex justify-evenly">
     <div class="" style={{width:'25%'}}>

                <div class="bg-white shadow rounded-lg py-6 relative">
  
                    <div class="flex flex-col items-center">
                      {
                        photo.length != 0 ?
                         <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                         {photoHover && (
                           <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
                           </div>
                         )}
                        
                        <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                        :
                        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                        {photoHover && (
                          <div onClick={openImageDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                            </svg>
                          </div>
                        )}
                        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative' }}>
                          <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
                            {fullName && fullName[0]}
                          </p>
                        </div>
                      </div>

                      }
                        <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between items-center items-center  mb-2'>
                   <div>
{
  fullNameEditor == false &&
  <h1 className='fjalla  text-gray-900  mx-2' style={{fontSize:"3vw"}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>
}     

                   </div>
                   {
                    fullNameEditor == false &&
              
                                <svg onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                              </svg>
                   }
                   {
                    fullNameEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editFullNameEditor} id='cancelInputDesktop'>Cancel</p>

                   }
                    </div>
                  {
                    fullNameEditor == true &&
                    <div className="relative mx-5 " id='editInputDesktop'>
                    <input  style={{fontSize:'12px'}} defaultValue={fullName}   onChange={e=>fullNameSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                        focus:border-black w-full pt-3 pr-4 pb-3 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                        border-gray-300 rounded-md borel" />
         <div className={`${newFullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'11px'}}>
      Full Name can't be empty
    </div>
    </div>
    
         </div>
                        <button style={{fontSize:'12px'}}  onClick={fullNameUpdate} class="inline-flex px-5 py-3 mr-5 mt-5 text-white bg-blue-800 hover:bg-blue-900 focus:text-blue-700 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
                  </div>
                  }
                    <div>
                
                    </div>
                            
                  </div>
                        <p class="text-gray-700 borel" style={{fontSize:'11px'}}>{email}</p>
                        <button onClick={handleCopyUrl} style={{fontSize:'11px'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                      Copy Profile URL
                  </button>
                    </div>
                
                </div>
                <div class="bg-white shadow rounded-lg p-6 relative mt-5">
                <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"3.5vw"}}>Membership
 </h1>
<div className='flex justify-between items-center'>
<p className='borel text-gray-500 block capitalize'>{plan}</p>
 <Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize '>Change</p></Link>

</div>
{
  plan != 'Free' &&
<div className='flex justify-between items-center mt-5'>
<h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"20px"}}>Billing Date
 </h1>
<p className='borel text-gray-500 block capitalize'>{ (billingDate)}</p>

</div>
}
                
                </div>
            </div>
     <div class="col-span-4 sm:col-span-9" style={{width:'65%'}}>

                <div class="bg-white shadow border rounded-lg py-6">
      
                 
                    <div className='rounded m-auto' >
                    
                    <div className='flex justify-between items-center   w-full' style={{padding:'5vw'}}>
     <div className='w-full'>

                   <h1 className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"3.8vw"}}>About Me
                   {
              aboutMeEditor == false &&
              <svg onClick={aboutMeTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
            </svg>
            }
  
  </h1>
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"11px",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>
     
     
                   </div>
                
          
            {
              aboutMeEditor == true &&
              <p className='fjalla  cursor-pointer hover:text-red-800' onClick={aboutMeTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

            }
                    </div>
                    {
              aboutMeEditor == true &&
              <div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
              <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>newAboutMeSet(e)}  type="text" class="border  focus:outline-none
                           focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                           border-gray-300 rounded-md" maxLength="2000"></textarea>
                           <div className={`${newAboutMeError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               Bio Can't be empty
              </div>
              </div>
              </div>
          {newAboutMe.length != 0 && 
              <p className='Mooli text-gray-500'>{newAboutMe && newAboutMe.length}/2,000</p>
            }   
             <button onClick={saveUserData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                    
                    Save
                  </button>    
              </div>
                    }
          
   
                    <div>
                
                    </div>
                            
                  </div>

               
               


                  
              
                 
                </div>

                <div class="bg-white shadow rounded-lg p-6 mt-6">
      
                 
      <div className='rounded m-auto' >
      
      <div className='flex justify-between items-center px-5 py-5 w-full'>
     <div className='w-full'>
     <div className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"3.8vw"}}>Region
     {
regionEditor == false &&
<svg onClick={regionTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
<path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
</svg>
}

</div>
{
regionEditor == false &&
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>

}


     </div>
  

{
regionEditor == true &&
<p className='fjalla  cursor-pointer hover:text-red-800' onClick={regionTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

}
      </div>
      {
regionEditor == true &&
<div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>

<select defaultValue={region} onChange={e=> regionSet(e)} style={{fontSize:'11px'}} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 borel mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option' value="" >Select Region</option>
    
    <option className='skill__input-option' value="Addis Ababa">Addis Ababa</option>
    <option className='skill__input-option' value="Afar">Afar</option>

    <option className='skill__input-option' value="Benshangul-Gumz">Benshangul-Gumz</option>     
        <option className='skill__input-option' value="Dire Dawa" >Dire Dawa</option>
        <option className='skill__input-option' value="Gambela">Gambela</option>
        <option className='skill__input-option' value="Harari">Harari</option>
        <option className='skill__input-option' value="Oromia">Oromia</option>
        <option className='skill__input-option' value="Somali">Somali</option>
        <option className='skill__input-option capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
        <option className='skill__input-option' value="Tigray">Tigray</option>


</select> 
             <div className={`${regionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
 Region Can't be empty
</div>
</div>
</div>

<button onClick={saveRegionData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
      
      Save
    </button>    
</div>
      }


      <div>
  
      </div>
              
    </div>

 
 


    

   
  </div>
            </div>
        </div>
    </div>
</div>
      
          </div>
              
      
      
          </div>
          :
          <div>
       <div id='mobile'>
        {
          imagePop == true &&
          <div id='uploadImageDesktop' className="uploadFileDesktop h-screen w-screen " style={{zIndex: 100}}>



          {
            progress != 100 & progress == 0 ?
            <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>
          
            {/* file upload modal */}
            <article
              aria-label="File Upload Modal"
              className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

            >
          <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"5vw"}}>Upload profile picture</p>
          
              {/* overlay */}
              <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
          
              <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
                  <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
                  <button
                  style={{fontSize:"3vw"}}
                    className="fjalla text-white mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                    onClick={() => {
                      document.getElementById('hidden-input-img').click();
                    }}
                  >
                    Upload a file
                  </button>
                  <p className='Mooli text-gray-500' style={{fontSize:"3vw"}}>Supported file types include .png .jpg .jpeg</p>
          
                </header>
                    <p className='Mooli text-red-800 capitalize' style={{fontSize:"3vw"}}>{fileMesssage}</p>
                    {profilePicture.name}
          
          
                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900" style={{fontSize:"5vw"}}>To Upload</h1>
          
                <ul className="flex justify-evenley  -m-1">
                  {profilePicture.length === 0 ? (
                    <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                 
                      <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>No files selected</span>
                    </li>
                  ) : 
                      <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center">
                        <img
                        style={{maxHeight:"150px"}}
                          className="mx-auto w-32"
                          src={URL.createObjectURL(profilePicture)}
                          alt={profilePicture.name}
                          onClick={() => removeFile()}
                        />
                        <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>{profilePicture.name}</span>
                      </li>
                  }
                </ul>
              </section>
          
            
              <div className="flex justify-evenly mt-10 mb-10">
                <button
                  type="submit"
                  onClick={backImageDesktop}
                  style={{ width: '20%',fontSize:"3vw" }}
                  className="bg-red-800 m-auto w-fullflex fjalla justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Back
                </button>
                <button
                  onClick={uploadImg}
                  type="submit"
                  style={{ backgroundColor: '#2141ce', width: '20%',fontSize:"3vw" }}
                  className="m-auto w-full flex justify-center items-center pt-4 pr-5 fjalla pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Upload
                </button>
              </div>
            </article>
          </main>
          
          :
          progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                 <div class="">
                 <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                 <p className='anton  text-center' style={{fontSize:"5vw"}}>Profile Picture Successfully Updated</p>
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
             </div>
             
                   </div>
                   :
                   <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                   <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
                 <div class="relative mt-5">
                 <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 <p className='fjalla mt-4 text-center'>Uploading</p>
             </div>
             
                   </div>}
          
          
          </div>
          
        }
 
            <div>
         <DashboardNav/>
      
 
       
    
            </div>
            <div class="">
            {
  profileDeleteMessage == true &&

   <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
   <div className='flex justify-center items-center'>
 <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div className='Mooli' style={{fontSize:'3vw'}}>
 your Profile Picture has been successfully removed 

 </div>
 </div>
 <svg  onClick={minimizeDeleteMessage} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </div> 
}
{
  profileUploadedMessage &&
 
 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your Profile Picture has been successfully updated 


</div>
</div>
<svg  onClick={profilePictureMessageClose} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}

{
  aboutMeMessage &&
 
 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your  Bio has been successfully updated 


</div>
</div>
<svg  onClick={aboutMeMessageMinimize} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}

{
  regionMessage &&

 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your Region has been successfully updated 


</div>
</div>
<svg  onClick={regionMessageMinimize} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}
{
  fullNameUpdatedMessage &&

 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Your Full Name has been successfully updated 


</div>
</div>
<svg  onClick={fullNameMessageClose} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
}


            {
      copied == true &&

<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
successfully Copied User Profile


</div>
</div>
<svg  onClick={closeMessage6Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
    }
     {
  profileDelete == true && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeImageDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"4vw"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  style={{fontSize:"3vw"}}class="fjalla w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeImageDeleteDesktop}  style={{fontSize:"3vw"}} class="fjalla w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
    <div class=" mx-auto py-8">
        <div class="flex justify-evenly flex-col">
     <div class="mx-auto" style={{width:'95%'}}>

                <div class="bg-white shadow rounded-lg py-6 relative">
  
                    <div class="flex flex-col items-center">
                      {
                        photo.length != 0 ?
                         <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                         {photoHover && (
                           <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
                           </div>
                         )}
                        
                        <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                        :
                        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                        {photoHover && (
                          <div onClick={openImageDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                            </svg>
                          </div>
                        )}
                        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative',width:'30vw',height:'30vw' }}>
                          <p className=" capitalize text-white fjalla" style={{ fontSize: '6vw' }}>
                            {fullName && fullName[0]}
                          </p>
                        </div>
                      </div>

                      }
                        <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between items-center items-center  mb-2'>
                   <div>
{
  fullNameEditor == false &&
  <h1 className='fjalla  text-gray-900  mx-2' style={{fontSize:"5vw",marginTop:'2vw'}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>
}     

                   </div>
                   {
                    fullNameEditor == false &&
              
                                <svg onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:'4vw'}} fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                              </svg>
                   }
                   {
                    fullNameEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editFullNameEditor} id='cancelInputDesktop' style={{fontSize:'3vw'}}>Cancel</p>

                   }
                    </div>
                  {
                    fullNameEditor == true &&
                    <div className="relative mx-5 " id='editInputDesktop'>
                    <input  style={{fontSize:'3vw',height:'12vw',paddingLeft:'3vw'}} defaultValue={fullName}   onChange={e=>fullNameSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                        focus:border-black w-full  mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                        border-gray-300 rounded-md borel" />
         <div className={`${newFullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'2.5vw'}}>
      Full Name can't be empty
    </div>
    </div>
    
         </div>
                        <button style={{fontSize:'3vw'}}  onClick={fullNameUpdate} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white bg-blue-800 hover:bg-blue-900 focus:text-blue-700 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
                  </div>
                  }
                    <div>
                
                    </div>
                            
                  </div>
                        <p class="text-gray-700 borel" style={{fontSize:'3vw'}}>{email}</p>
                        <button onClick={handleCopyUrl} style={{fontSize:'3vw'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                      Copy Profile URL
                  </button>
                    </div>
                
                </div>
                <div class="bg-white shadow rounded-lg p-6 relative mt-5">
                <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"5vw"}}>Membership
 </h1>
<div className='flex justify-between items-center'>
<p className='borel text-gray-500 block capitalize' style={{fontSize:'3vw'}}> {plan}</p>
 <Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize 'style={{fontSize:'3vw'}}>Change</p></Link>

</div>
{
  plan != 'Free' &&
<div className='flex justify-between items-center mt-5'>
<h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"4vw"}}>Billing Date
 </h1>
<p className='borel text-gray-500 block capitalize' style={{fontSize:'3vw'}}>{ (billingDate)}</p>

</div>
}
                
                </div>
            </div>
     <div class="col-span-4 sm:col-span-9 mx-auto" style={{width:'95%',marginTop:'5vw',marginBottom:'5vw'}}>

                <div class="bg-white shadow border rounded-lg py-3">
      
                 
                    <div className='rounded m-auto' >
                    
                    <div className='flex justify-between items-center   w-full' style={{padding:'5vw'}}>
     <div className='w-full'>

                   <h1 className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"6vw"}}>About Me
                   {
              aboutMeEditor == false &&
              <svg onClick={aboutMeTextAreaOpen}xmlns="http://www.w3.org/2000/svg" style={{width:'4vw'}} fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
            </svg>
            }
  
  </h1>
{
              aboutMeEditor == false &&

     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>

}     
     
                   </div>
                
          
            {
              aboutMeEditor == true &&
              <p className='fjalla  cursor-pointer hover:text-red-800' onClick={aboutMeTextAreaMinimize} id='cancelDescripionInputDesktop'style={{fontSize:'3vw'}}>Cancel</p>

            }
                    </div>
                    {
              aboutMeEditor == true &&
              <div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
              <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>newAboutMeSet(e)}  type="text" class="border  focus:outline-none
                           focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                           border-gray-300 rounded-md" maxLength="2000"></textarea>
                           <div className={`${newAboutMeError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'2.5vw'}}>
               Bio Can't be empty
              </div>
              </div>
              </div>
          {newAboutMe.length != 0 && 
              <p className='Mooli text-gray-500' style={{fontSize:'2.5vw'}}>{newAboutMe && newAboutMe.length}/2,000</p>
            }   
             <button onClick={saveUserData} style={{fontSize:'3vw'}} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                    
                    Save
                  </button>    
              </div>
                    }
          
   
                    <div>
                
                    </div>
                            
                  </div>

               
               


                  
              
                 
                </div>

                <div class="bg-white shadow rounded-lg py-3 mt-6">
      
                 
      <div className='rounded m-auto' >
      
      <div className='flex justify-between items-center  px-5 py-5 w-full'>
     <div className='w-full'>
     <div className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"6vw"}}>Region
     {
regionEditor == false &&
<svg onClick={regionTextAreaOpen}xmlns="http://www.w3.org/2000/svg" style={{width:'4vw'}} fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
<path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
</svg>
}

</div>
{
regionEditor == false &&
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>

}


     </div>
  

{
regionEditor == true &&
<p className='fjalla  cursor-pointer hover:text-red-800'style={{fontSize:'3vw'}} onClick={regionTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

}
      </div>
      {
regionEditor == true &&
<div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>

<select defaultValue={region} onChange={e=> regionSet(e)} style={{fontSize:'3vw',color:'black'}} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 borel mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option' value="" >Select Region</option>
    
    <option className='skill__input-option' value="Addis Ababa">Addis Ababa</option>
    <option className='skill__input-option' value="Afar">Afar</option>

    <option className='skill__input-option' value="Benshangul-Gumz">Benshangul-Gumz</option>     
        <option className='skill__input-option' value="Dire Dawa" >Dire Dawa</option>
        <option className='skill__input-option' value="Gambela">Gambela</option>
        <option className='skill__input-option' value="Harari">Harari</option>
        <option className='skill__input-option' value="Oromia">Oromia</option>
        <option className='skill__input-option' value="Somali">Somali</option>
        <option className='skill__input-option capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
        <option className='skill__input-option' value="Tigray">Tigray</option>


</select> 
             <div className={`${regionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'2.5vw'}}>
 Region Can't be empty
</div>
</div>
</div>

<button onClick={saveRegionData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
      
      Save
    </button>    
</div>
      }


      <div>
  
      </div>
              
    </div>

 
 


    

   
  </div>
            </div>
        </div>
    </div>
</div>
      
          </div>
        
      <div id='desktop'>
        {
          imagePop == true &&
          <div id='uploadImageDesktop' className="uploadFileDesktop h-screen w-screen " style={{zIndex: 100}}>



          {
            progress != 100 & progress == 0 ?
            <main className="container mx-auto  my-5 " style={{width:"90%",height:"95%"}}>
          
            {/* file upload modal */}
            <article
              aria-label="File Upload Modal"
              className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

            >
          <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload profile picture</p>
          
              {/* overlay */}
              <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
          
              <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
                  <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
                  <button
                    className="fjalla text-white mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                    onClick={() => {
                      document.getElementById('hidden-input-img').click();
                    }}
                  >
                    Upload a file
                  </button>
                  <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>
          
                </header>
                    <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
                    {profilePicture.name}
          
          
                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
          
                <ul className="flex justify-evenley  -m-1">
                  {profilePicture.length === 0 ? (
                    <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                 
                      <span className="text-small text-gray-500">No files selected</span>
                    </li>
                  ) : 
                      <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center">
                        <img
                        style={{maxHeight:"150px"}}
                          className="mx-auto w-32"
                          src={URL.createObjectURL(profilePicture)}
                          alt={profilePicture.name}
                          onClick={() => removeFile()}
                        />
                        <span className="text-small text-gray-500">{profilePicture.name}</span>
                      </li>
                  }
                </ul>
              </section>
          
              {/* sticky footer */}
              <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
              <div className="flex justify-evenly mt-10 mb-10">
                <button
                  type="submit"
                  onClick={backImageDesktop}
                  style={{ width: '20%' }}
                  className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Back
                </button>
                <button
                  onClick={uploadImg}
                  type="submit"
                  style={{ backgroundColor: '#2141ce', width: '20%' }}
                  className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Upload
                </button>
              </div>
            </article>
          </main>
          
          :
          progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                 <div class="">
                 <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                 <p className='anton  text-center' style={{fontSize:"35px"}}>Profile Picture Successfully Updated</p>
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
             </div>
             
                   </div>
                   :
                   <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                   <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
                 <div class="relative mt-5">
                 <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 <p className='fjalla mt-4 text-center'>Uploading</p>
             </div>
             
                   </div>}
          
          
          </div>
          
        }
 
            <div>
            <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDateNavigation(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
      
 
       
    
            </div>
            <div class="">
            {
  profileDeleteMessage == true &&
  <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
  your Profile Picture has been successfully removed 
  </div>
  <button onClick={minimizeDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  profileUploadedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Profile Picture has been successfully updated 
  </div>
  <button onClick={profilePictureMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  aboutMeMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your  Bio has been successfully updated 
  </div>
  <button onClick={aboutMeMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  regionMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Region has been successfully updated 
  </div>
  <button onClick={regionMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  fullNameUpdatedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Full Name has been successfully updated 
  </div>
  <button onClick={fullNameMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}


            {
      copied == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     successfully Copied User Profile
    </div>
    <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
     {
  profileDelete == true && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeImageDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeImageDeleteDesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
    <div class=" mx-auto py-8">
        <div class="grid grid-cols-3 sm:grid-cols-12 gap-6 px-4">
            <div class="col-span-4 sm:col-span-3">
                <div class="bg-white shadow border rounded-lg p-6 relative">
  
                    <div class="flex flex-col items-center">
                      {
                        photo.length != 0 ?
                         <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                         {photoHover && (
                           <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
                           </div>
                         )}
                        
                        <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                        :
                        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                        {photoHover && (
                          <div onClick={openImageDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                            </svg>
                          </div>
                        )}
                        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative' }}>
                          <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
                            {fullName && fullName[0]}
                          </p>
                        </div>
                      </div>

                      }
                        <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between items-center items-center  mb-2'>
                   <div>
{
  fullNameEditor == false &&
  <h1 className='fjalla  text-gray-900  mx-2' style={{fontSize:"25px"}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>
}     

                   </div>
                   {
                    fullNameEditor == false &&
              
                                <svg onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                              </svg>
                   }
                   {
                    fullNameEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editFullNameEditor} id='cancelInputDesktop'>Cancel</p>

                   }
                    </div>
                  {
                    fullNameEditor == true &&
                    <div className="relative mx-5 " id='editInputDesktop'>
                    <input  style={{fontSize:'12px'}} defaultValue={fullName}   onChange={e=>fullNameSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                        focus:border-black w-full pt-3 pr-4 pb-3 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                        border-gray-300 rounded-md borel" />
         <div className={`${newFullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'11px'}}>
      Full Name can't be empty
    </div>
    </div>
    
         </div>
                        <button style={{fontSize:'12px'}}  onClick={fullNameUpdate} class="inline-flex px-5 py-3 mr-5 mt-5 text-white bg-blue-800 hover:bg-blue-900 focus:text-blue-700 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
                  </div>
                  }
                    <div>
                
                    </div>
                            
                  </div>
                        <p class="text-gray-700 borel" style={{fontSize:'11px'}}>{email}</p>
                        <button onClick={handleCopyUrl} style={{fontSize:'11px'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                      Copy Profile URL
                  </button>
                    </div>
                
                </div>
                <div class="bg-white shadow border rounded-lg p-3 relative mt-5">
                <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"30px"}}>Membership
 </h1>
<div className='flex justify-between items-center'>
<p className='borel text-gray-500 block capitalize'>{plan}</p>
 <Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize '>Change</p></Link>

</div>
{
  plan != 'Free' &&
<div className='flex justify-between items-center mt-5'>
<h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"20px"}}>Billing Date
 </h1>
<p className='borel text-gray-500 block capitalize'>{ (billingDate)}</p>

</div>
}
                
                </div>
            </div>
            <div class="col-span-4 sm:col-span-9">
                <div class="bg-white shadow rounded-lg p-6 border">
      
                 
                    <div className='rounded m-auto' >
                    
                    <div className='flex justify-between items-center px-5 py-5 w-full'>
     <div className='w-full'>

                   <h1 className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"30px"}}>About Me
                   {
              aboutMeEditor == false &&
              <svg onClick={aboutMeTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
            </svg>
            }
  
  </h1>
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"12px",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>
     
     
                   </div>
                
          
            {
              aboutMeEditor == true &&
              <p className='fjalla  cursor-pointer hover:text-red-800' onClick={aboutMeTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

            }
                    </div>
                    {
              aboutMeEditor == true &&
              <div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
              <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>newAboutMeSet(e)}  type="text" class="border  focus:outline-none
                           focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                           border-gray-300 rounded-md" maxLength="2000"></textarea>
                           <div className={`${newAboutMeError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               Bio Can't be empty
              </div>
              </div>
              </div>
          {newAboutMe.length != 0 && 
              <p className='Mooli text-gray-500'>{newAboutMe && newAboutMe.length}/2,000</p>
            }   
             <button onClick={saveUserData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                    
                    Save
                  </button>    
              </div>
                    }
          
   
                    <div>
                
                    </div>
                            
                  </div>

               
               


                  
              
                 
                </div>

                <div class="bg-white shadow rounded-lg p-6 mt-6 border">
      
                 
      <div className='rounded m-auto' >
      
      <div className='flex justify-between items-center px-5 py-5 w-full'>
     <div className='w-full'>
     <div className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"30px"}}>Region
     {
regionEditor == false &&
<svg onClick={regionTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
<path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
</svg>
}

</div>
{
regionEditor == false &&
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>

}


     </div>
  

{
regionEditor == true &&
<p className='fjalla  cursor-pointer hover:text-red-800' onClick={regionTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

}
      </div>
      {
regionEditor == true &&
<div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>

<select defaultValue={region} onChange={e=> regionSet(e)} style={{fontSize:'11px'}} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 borel mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option' value="" >Select Region</option>
    
    <option className='skill__input-option' value="Addis Ababa">Addis Ababa</option>
    <option className='skill__input-option' value="Afar">Afar</option>

    <option className='skill__input-option' value="Benshangul-Gumz">Benshangul-Gumz</option>     
        <option className='skill__input-option' value="Dire Dawa" >Dire Dawa</option>
        <option className='skill__input-option' value="Gambela">Gambela</option>
        <option className='skill__input-option' value="Harari">Harari</option>
        <option className='skill__input-option' value="Oromia">Oromia</option>
        <option className='skill__input-option' value="Somali">Somali</option>
        <option className='skill__input-option capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
        <option className='skill__input-option' value="Tigray">Tigray</option>


</select> 
             <div className={`${regionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
 Region Can't be empty
</div>
</div>
</div>

<button onClick={saveRegionData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
      
      Save
    </button>    
</div>
      }


      <div>
  
      </div>
              
    </div>

 
 


    

   
  </div>
            </div>
        </div>
    </div>
</div>
      
          </div>
          <div id='tablet'>
        {
          imagePop == true &&
          <div id='uploadImageDesktop' className="uploadFileDesktop h-screen w-screen " style={{zIndex: 100}}>



          {
            progress != 100 & progress == 0 ?
            <main className="container mx-auto  my-5 " style={{width:"90%",height:"95%"}}>
          
            {/* file upload modal */}
            <article
              aria-label="File Upload Modal"
              className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

            >
          <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload profile picture</p>
          
              {/* overlay */}
              <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
          
              <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
                  <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
                  <button
                    className="fjalla text-white mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                    onClick={() => {
                      document.getElementById('hidden-input-img').click();
                    }}
                  >
                    Upload a file
                  </button>
                  <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>
          
                </header>
                    <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
                    {profilePicture.name}
          
          
                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
          
                <ul className="flex justify-evenley  -m-1">
                  {profilePicture.length === 0 ? (
                    <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                 
                      <span className="text-small text-gray-500">No files selected</span>
                    </li>
                  ) : 
                      <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center">
                        <img
                        style={{maxHeight:"150px"}}
                          className="mx-auto w-32"
                          src={URL.createObjectURL(profilePicture)}
                          alt={profilePicture.name}
                          onClick={() => removeFile()}
                        />
                        <span className="text-small text-gray-500">{profilePicture.name}</span>
                      </li>
                  }
                </ul>
              </section>
          
              {/* sticky footer */}
              <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
              <div className="flex justify-evenly mt-10 mb-10">
                <button
                  type="submit"
                  onClick={backImageDesktop}
                  style={{ width: '20%' }}
                  className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Back
                </button>
                <button
                  onClick={uploadImg}
                  type="submit"
                  style={{ backgroundColor: '#2141ce', width: '20%' }}
                  className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
                >
                  Upload
                </button>
              </div>
            </article>
          </main>
          
          :
          progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                 <div class="">
                 <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                 <p className='anton  text-center' style={{fontSize:"35px"}}>Profile Picture Successfully Updated</p>
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
             </div>
             
                   </div>
                   :
                   <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
                   <div class="w-full bg-neutral-200">
                <div
                  class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
                  style={{ width: progress + '%' }}
                >
                  {Math.round(progress)} %
                </div>
              </div>
                 <div class="relative mt-5">
                 <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 <p className='fjalla mt-4 text-center'>Uploading</p>
             </div>
             
                   </div>}
          
          
          </div>
          
        }
 
            <div>
         <DashboardNav/>
      
 
       
    
            </div>
            <div class="">
            {
  profileDeleteMessage == true &&
  <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
  your Profile Picture has been successfully removed 
  </div>
  <button onClick={minimizeDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  profileUploadedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Profile Picture has been successfully updated 
  </div>
  <button onClick={profilePictureMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  aboutMeMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your  Bio has been successfully updated 
  </div>
  <button onClick={aboutMeMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  regionMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Region has been successfully updated 
  </div>
  <button onClick={regionMessageMinimize}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  fullNameUpdatedMessage &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your Full Name has been successfully updated 
  </div>
  <button onClick={fullNameMessageClose}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}


            {
      copied == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     successfully Copied User Profile
    </div>
    <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
     {
  profileDelete == true && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeImageDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeImageDeleteDesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
    <div class=" mx-auto py-8">
        <div class="flex justify-evenly">
     <div class="" style={{width:'25%'}}>

                <div class="bg-white shadow rounded-lg py-6 relative">
  
                    <div class="flex flex-col items-center">
                      {
                        photo.length != 0 ?
                         <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                         {photoHover && (
                           <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
                           </div>
                         )}
                        
                        <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                        :
                        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
                        {photoHover && (
                          <div onClick={openImageDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                            </svg>
                          </div>
                        )}
                        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative' }}>
                          <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
                            {fullName && fullName[0]}
                          </p>
                        </div>
                      </div>

                      }
                        <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between items-center items-center  mb-2'>
                   <div>
{
  fullNameEditor == false &&
  <h1 className='fjalla  text-gray-900  mx-2' style={{fontSize:"3vw"}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>
}     

                   </div>
                   {
                    fullNameEditor == false &&
              
                                <svg onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                              </svg>
                   }
                   {
                    fullNameEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editFullNameEditor} id='cancelInputDesktop'>Cancel</p>

                   }
                    </div>
                  {
                    fullNameEditor == true &&
                    <div className="relative mx-5 " id='editInputDesktop'>
                    <input  style={{fontSize:'12px'}} defaultValue={fullName}   onChange={e=>fullNameSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                        focus:border-black w-full pt-3 pr-4 pb-3 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                        border-gray-300 rounded-md borel" />
         <div className={`${newFullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'11px'}}>
      Full Name can't be empty
    </div>
    </div>
    
         </div>
                        <button style={{fontSize:'12px'}}  onClick={fullNameUpdate} class="inline-flex px-5 py-3 mr-5 mt-5 text-white bg-blue-800 hover:bg-blue-900 focus:text-blue-700 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
                  </div>
                  }
                    <div>
                
                    </div>
                            
                  </div>
                        <p class="text-gray-700 borel" style={{fontSize:'11px'}}>{email}</p>
                        <button onClick={handleCopyUrl} style={{fontSize:'11px'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                      Copy Profile URL
                  </button>
                    </div>
                
                </div>
                <div class="bg-white shadow rounded-lg p-6 relative mt-5">
                <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"3.5vw"}}>Membership
 </h1>
<div className='flex justify-between items-center'>
<p className='borel text-gray-500 block capitalize'>{plan}</p>
 <Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize '>Change</p></Link>

</div>
{
  plan != 'Free' &&
<div className='flex justify-between items-center mt-5'>
<h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"20px"}}>Billing Date
 </h1>
<p className='borel text-gray-500 block capitalize'>{ (billingDate)}</p>

</div>
}
                
                </div>
            </div>
     <div class="col-span-4 sm:col-span-9" style={{width:'65%'}}>

                <div class="bg-white shadow border rounded-lg py-6">
      
                 
                    <div className='rounded m-auto' >
                    
                    <div className='flex justify-between items-center   w-full' style={{padding:'5vw'}}>
     <div className='w-full'>

                   <h1 className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"3.8vw"}}>About Me
                   {
              aboutMeEditor == false &&
              <svg onClick={aboutMeTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
            </svg>
            }
  
  </h1>
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"11px",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>
     
     
                   </div>
                
          
            {
              aboutMeEditor == true &&
              <p className='fjalla  cursor-pointer hover:text-red-800' onClick={aboutMeTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

            }
                    </div>
                    {
              aboutMeEditor == true &&
              <div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
              <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>newAboutMeSet(e)}  type="text" class="border  focus:outline-none
                           focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                           border-gray-300 rounded-md" maxLength="2000"></textarea>
                           <div className={`${newAboutMeError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               Bio Can't be empty
              </div>
              </div>
              </div>
          {newAboutMe.length != 0 && 
              <p className='Mooli text-gray-500'>{newAboutMe && newAboutMe.length}/2,000</p>
            }   
             <button onClick={saveUserData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                    
                    Save
                  </button>    
              </div>
                    }
          
   
                    <div>
                
                    </div>
                            
                  </div>

               
               


                  
              
                 
                </div>

                <div class="bg-white shadow rounded-lg p-6 mt-6">
      
                 
      <div className='rounded m-auto' >
      
      <div className='flex justify-between items-center px-5 py-5 w-full'>
     <div className='w-full'>
     <div className='fjalla  text-gray-900 mb-1 flex justify-between items-center w-full' style={{fontSize:"3.8vw"}}>Region
     {
regionEditor == false &&
<svg onClick={regionTextAreaOpen}xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-pen-fill text-green-600 hover:text-green-800" viewBox="0 0 16 16">
<path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
</svg>
}

</div>
{
regionEditor == false &&
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>

}


     </div>
  

{
regionEditor == true &&
<p className='fjalla  cursor-pointer hover:text-red-800' onClick={regionTextAreaMinimize} id='cancelDescripionInputDesktop'>Cancel</p>

}
      </div>
      {
regionEditor == true &&
<div id='editDescripionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>

<select defaultValue={region} onChange={e=> regionSet(e)} style={{fontSize:'11px'}} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 borel mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option' value="" >Select Region</option>
    
    <option className='skill__input-option' value="Addis Ababa">Addis Ababa</option>
    <option className='skill__input-option' value="Afar">Afar</option>

    <option className='skill__input-option' value="Benshangul-Gumz">Benshangul-Gumz</option>     
        <option className='skill__input-option' value="Dire Dawa" >Dire Dawa</option>
        <option className='skill__input-option' value="Gambela">Gambela</option>
        <option className='skill__input-option' value="Harari">Harari</option>
        <option className='skill__input-option' value="Oromia">Oromia</option>
        <option className='skill__input-option' value="Somali">Somali</option>
        <option className='skill__input-option capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
        <option className='skill__input-option' value="Tigray">Tigray</option>


</select> 
             <div className={`${regionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
 Region Can't be empty
</div>
</div>
</div>

<button onClick={saveRegionData} style={{fontSize:'12px'}} class="inline-flex px-5 py-3 mr-5 mt-5 bg-blue-800 hover:bg-blue-900 focus:text-blue-700 text-white fjalla focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
      
      Save
    </button>    
</div>
      }


      <div>
  
      </div>
              
    </div>

 
 


    

   
  </div>
            </div>
        </div>
    </div>
</div>
      
          </div>
              
      
      
          </div>
        )
      })
    }
   </div>
 
   </div>
  )
}
