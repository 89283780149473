import React, { useEffect, useState } from 'react'
import DashboardNav from './dashboardNav'
import { Link, useNavigate } from 'react-router-dom'
import { addDoc, collection, deleteDoc, doc, onSnapshot, serverTimestamp, setDoc,Timestamp,orderBy,query } from 'firebase/firestore'
import {uploadBytesResumable,ref,getDownloadURL, getStorage} from "firebase/storage"
import { auth, firestore } from '../firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { v4 } from 'uuid'
import TagsInput from 'react-tagsinput'

export default function CourseCreate() {
    const [subject,setSubject] = useState("")
    const [courseTitle,setcourseTitle] = useState("")
    const [myCourse,setmyCourse] = useState([])
    const [user,setuser] = useState([])
    const [notification,setnotification] = useState([])
  const [CourseDescriptionOpen,setCourseDescriptionOpen] = useState(false)
    const [spinner,setspinner] = useState(true)
    const [loading,setLoading] = useState(false)
    const [success,setSuccess] = useState(false)

    const [pop,setpop] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)


    const [plan,setplan] = useState('')
    const [planError,setplanError] = useState('')

    


    const [instructorName,setinstructorName] = useState('')
    const navigate = useNavigate()



    const [completionTime,setcompletionTime] = useState("")
    const [completionTimeError,setcompletionTimeError] = useState("")
    const [intendedAgeGroup,setintendedAgeGroup] = useState("")
    const [intendedAgeGroupError,setintendedAgeGroupError] = useState("")
    const [courseLevelGroup,setcourseLevelGroup] = useState("")
    const [courseLevelGroupError,setcourseLevelGroupError] = useState("")

    const [courseModel,setcourseModel] = useState("")
    const [isThereAnyQuiz,setisThereAnyQuiz] = useState("")
    const [isThereAnyQuizError,setisThereAnyQuizError] = useState("")

    
    const [courseModelError,setcourseModelError] = useState("")



    const [progress,setprogress] = useState("")
    const [courseId,setcourseId] = useState("")


    const [subjectError,setSubjectError] = useState("")
    const [courseTitleError,setcourseTitleError] = useState("")

    const [courseObjectiveError,setcourseObjectiveError] = useState("")
    const [courseObjective,setcourseObjective] = useState("")
    const [courseSkillsError,setcourseSkillsError] = useState("")
    const [courseSkills,setcourseSkills] = useState([])


    const [courseDescription,setcourseDescription] = useState("")
    const [courseDescriptionError,setcourseDescriptionError] = useState("")
    const [uid,setuid] = useState("")
    const [courseVideo,setcourseVideo] = useState([])
    const uniqueKey = courseTitle[0] + v4().slice(0,5) + courseTitle[courseTitle.length - 1]


    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
           
      
      
    //   fetch the courses data from Account Details

    const snapRef = collection(firestore, 'AccountDetails',uid,"CoursesCreated")
    onSnapshot(snapRef,(snapshot)=>{
        let temp = []
            snapshot.docs.forEach(doc=>{
                temp.push({...doc.data(),id:doc.id})
            })
      
       setmyCourse(temp)


          

  });
  const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

  const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

  onSnapshot(notificationRef,snapshot=>{
    let main =[]
    



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
     
    setnotification(main)
    })

    
    
  

  })


  const userRef = doc(firestore, 'TotalUsers',uid)
    onSnapshot(userRef,(snapshot)=>{
        let temp = []
                temp.push({...snapshot.data(),id:doc.id})
           
      setuser(temp)
      setinstructorName(snapshot.data().fullName)
      setplan(snapshot.data().plan)


          

  });
      
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);
 
// close course objective desktop
function removeCourseObjectiveDesktop(){
    const courseObjective = document.getElementById("courseObjectiveDesktop")
        courseObjective.style.display="none"
}

function mobileNavPop(){
  setpop(true)




}

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)
}





// Open course objective desktop
function openCourseObjectiveDesktop(){
    const courseObjective = document.getElementById("courseObjectiveDesktop")
        courseObjective.style.display="block"
}


// close course Description desktop
function removeCourseDescriptionDesktop(){
    setCourseDescriptionOpen(false)

}


// Open course Description desktop
function openCourseDescriptionDesktop(){
    setCourseDescriptionOpen(true)
}

// course Description height extender
const textRefDescription = React.useRef();
React.useEffect(() => {
  if (textRefDescription && textRefDescription.current) {
    textRefDescription.current.style.height = "0px";
    const taHeight = textRefDescription.current.scrollHeight;
    textRefDescription.current.style.height = taHeight + "px";
  }
}, [courseDescription]);

// course objective height extender
    const textRef = React.useRef();
    React.useEffect(() => {
      if (textRef && textRef.current) {
        textRef.current.style.height = "0px";
        const taHeight = textRef.current.scrollHeight;
        textRef.current.style.height = taHeight + "px";
      }
    }, [courseObjective]);





      


      //first page next click
function handleFirstDesktop(e){

    e.preventDefault()
    window.scrollTo(0, 0);

    if(plan == 'Free'){
      if(myCourse.length >= 5){
        setplanError('For unrestricted course creation, we recommend upgrading to a higher plan. The current plan allows up to five courses')
      }
   
      else{
            
  if(subject.length == 0){
    setSubjectError("error")
  }
  else{
    if(courseTitle.length == 0){
      setcourseTitleError("error")
    }

    else{
      if(intendedAgeGroup.length == 0){
        setintendedAgeGroupError("error")
      }
      else{
        if(completionTime.length == 0){
          setcompletionTimeError("error")
        }
        else{
         if(courseSkills.length == 0){
          setcourseSkillsError("error")

         }
         else{
          if(completionTime.length == 0){
            setcompletionTimeError('error')
          }
          else{
            if(courseDescription.length == 0){
              setcourseDescriptionError("error")
              
            }
            
            else{
              setLoading(true)
              setDoc(doc(firestore, "Courses",uniqueKey),
              {
                courseSubject:subject,
                courseLevel:courseLevelGroup,
                courseTitle:courseTitle,
                totalStudents:0,
                averageMark:0,
                underPerformingStudents:0,
                totalEarnings:0,
                courseDescription:courseDescription,
                completionTime:completionTime,
                courseThumbnail:"",
                createdTime:serverTimestamp(),
                status:"unpublished",
                courseTrailer:'',

          isTest:false,
                intendedAgeGroup:intendedAgeGroup,
                courseSkills:courseSkills,
                      certificateId:"",
                      certificate:false,
        certificateStudentName:"",
        certificateDescription:"",
        certificateInstructorName:"",
        certificateInstructorSignature:"",
                courseId:uniqueKey,
                totalLectures:0,
                instructorId:uid,
                
                coursePrice:0,
                
          
          
          
            }
              ).then(
                  setDoc(doc(firestore, 'AccountDetails',uid,"CoursesCreated",uniqueKey), {
                    courseSubject:subject,
                    courseLevel:courseLevelGroup,
                    courseTitle:courseTitle,
                    totalStudents:0,
                    averageMark:0,
                    underPerformingStudents:0,
                    totalEarnings:0,
                    courseDescription:courseDescription,
                    completionTime:completionTime,
                    courseThumbnail:"",
                    createdTime:serverTimestamp(),
                    status:"unpublished",
                    courseTrailer:'',
                    isTest:false,
                    intendedAgeGroup:intendedAgeGroup,
                    courseSkills:courseSkills,
                          certificateId:"",
                          certificate:false,
        certificateStudentName:"",
        certificateDescription:"",
        certificateInstructorName:"",
        certificateInstructorSignature:"",
                    courseId:uniqueKey,
                    totalLectures:0,
                    instructorId:uid,
                    
                    coursePrice:0,
                    
                    })
              ).then(
                setDoc(doc(firestore, 'AccountDetails',uid,"Notification",v4()),{
                  notificationTitle:`You have successfully created a new course`,
                  time:serverTimestamp(),
                  seen:false,
                }


                )
              ).then(
          
              
                        setLoading(false),
                        setSuccess(true)
                          
              )
            }
          }
         }
        }
      }

    }
  }
      }


}
else if(plan == 'Basic'){
  if(myCourse.length >= 15){
    setplanError('For unrestricted course creation, we recommend upgrading to a higher plan. The current plan allows up to 15 courses')
  }

  else{
        
if(subject.length == 0){
setSubjectError("error")
}
else{
if(courseTitle.length == 0){
  setcourseTitleError("error")
}

else{
  if(intendedAgeGroup.length == 0){
    setintendedAgeGroupError("error")
  }
  else{
    if(completionTime.length == 0){
      setcompletionTimeError("error")
    }
    else{
     if(courseSkills.length == 0){
      setcourseSkillsError("error")

     }
     else{
      if(completionTime.length == 0){
        setcompletionTimeError('error')
      }
      else{
        if(courseDescription.length == 0){
          setcourseDescriptionError("error")
          
        }
        
        else{
          setLoading(true)
          setDoc(doc(firestore, "Courses",uniqueKey),
          {
            courseSubject:subject,
            courseLevel:courseLevelGroup,
            courseTitle:courseTitle,
            totalStudents:0,
            averageMark:0,
            underPerformingStudents:0,
            totalEarnings:0,
            courseDescription:courseDescription,
            completionTime:completionTime,
            courseThumbnail:"",
            createdTime:serverTimestamp(),
            status:"unpublished",
            courseTrailer:'',

      isTest:false,
            intendedAgeGroup:intendedAgeGroup,
            courseSkills:courseSkills,
                  certificateId:"",
                  certificate:false,
    certificateStudentName:"",
    certificateDescription:"",
    certificateInstructorName:"",
    certificateInstructorSignature:"",
            courseId:uniqueKey,
            totalLectures:0,
            instructorId:uid,
            
            coursePrice:0,
            
      
      
      
        }
          ).then(
              setDoc(doc(firestore, 'AccountDetails',uid,"CoursesCreated",uniqueKey), {
                courseSubject:subject,
                courseLevel:courseLevelGroup,
                courseTitle:courseTitle,
                totalStudents:0,
                averageMark:0,
                underPerformingStudents:0,
                totalEarnings:0,
                courseDescription:courseDescription,
                completionTime:completionTime,
                courseThumbnail:"",
                createdTime:serverTimestamp(),
                status:"unpublished",
                courseTrailer:'',
                isTest:false,
                intendedAgeGroup:intendedAgeGroup,
                courseSkills:courseSkills,
                      certificateId:"",
                      certificate:false,
    certificateStudentName:"",
    certificateDescription:"",
    certificateInstructorName:"",
    certificateInstructorSignature:"",
                courseId:uniqueKey,
                totalLectures:0,
                instructorId:uid,
                
                coursePrice:0,
                
                }).then(
                  setDoc(doc(firestore, 'AccountDetails',uid,"Notification",v4()),{
                    notificationTitle:`You have successfully created a new course`,
                    time:serverTimestamp(),
                    seen:false,
                  }


                  )
                  

                )
          ).then(
      
          
                    setLoading(false),
                    setSuccess(true)
                      
          )
        }
      }
     }
    }
  }

}
}
  }


}
  else {
    if(subject.length == 0){
      setSubjectError("error")
    }
    else{
      if(courseTitle.length == 0){
        setcourseTitleError("error")
      }
  
      else{
        if(intendedAgeGroup.length == 0){
          setintendedAgeGroupError("error")
        }
        else{
          if(completionTime.length == 0){
            setcompletionTimeError("error")
          }
          else{
           if(courseSkills.length == 0){
            setcourseSkillsError("error")
  
           }
           else{
            if(completionTime.length == 0){
              setcompletionTimeError('error')
            }
            else{
              if(courseDescription.length == 0){
                setcourseDescriptionError("error")
                
              }
              
              else{
                setLoading(true)
                setDoc(doc(firestore, "Courses",uniqueKey),
                {
                  courseSubject:subject,
                  courseLevel:courseLevelGroup,
                  courseTitle:courseTitle,
                  totalStudents:0,
                  averageMark:0,
                  underPerformingStudents:0,
                  totalEarnings:0,
                  courseDescription:courseDescription,
                  completionTime:completionTime,
                  courseThumbnail:"",
                  createdTime:serverTimestamp(),
                  status:"unpublished",
                  courseTrailer:'',
  
            isTest:false,
                  intendedAgeGroup:intendedAgeGroup,
                  courseSkills:courseSkills,
                        certificateId:"",
                        certificate:false,
          certificateStudentName:"",
          certificateDescription:"",
          certificateInstructorName:"",
          certificateInstructorSignature:"",
                  courseId:uniqueKey,
                  totalLectures:0,
                  instructorId:uid,
                  
                  coursePrice:0,
                  
            
            
            
              }
                ).then(
                    setDoc(doc(firestore, 'AccountDetails',uid,"CoursesCreated",uniqueKey), {
                      courseSubject:subject,
                      courseLevel:courseLevelGroup,
                      courseTitle:courseTitle,
                      totalStudents:0,
                      averageMark:0,
                      underPerformingStudents:0,
                      totalEarnings:0,
                      courseDescription:courseDescription,
                      completionTime:completionTime,
                      courseThumbnail:"",
                      createdTime:serverTimestamp(),
                      status:"unpublished",
                      courseTrailer:'',
                      isTest:false,
                      intendedAgeGroup:intendedAgeGroup,
                      courseSkills:courseSkills,
                            certificateId:"",
                            certificate:false,
          certificateStudentName:"",
          certificateDescription:"",
          certificateInstructorName:"",
          certificateInstructorSignature:"",
                      courseId:uniqueKey,
                      totalLectures:0,
                      instructorId:uid,
                      
                      coursePrice:0,
                      
                      })
                ).then(
                  setDoc(doc(firestore, 'AccountDetails',uid,"Notification",v4()),{
                    notificationTitle:`You have successfully created a new course`,
                    time:serverTimestamp(),
                    seen:false,
                  }


                  )
                ).then(
                  setLoading(false),
                  setSuccess(true)
                )
              }
            }
           }
          }
        }
  
      }
    }
  
  }



}



      //first page add Course next click
      function addcourseDesktop(e){
        const first = document.getElementById("firstDesktop")
        const empty = document.getElementById("emptyCourseDesktop")
        const loading = document.getElementById("loadingDesktop")

            setTimeout(() => {
                empty.style.display="none"
                loading.style.display="flex"
        
                setTimeout(() => {
                loading.style.display="none"
                first.style.display="flex"
                    
                }, 1000);
            }, 1000)
    
    
    
    }
//first page next click
function handleSelectSubjectFirst(e){
    const first = document.getElementById("firstDesktop")
    const second = document.getElementById("secondDesktop")
    const loading = document.getElementById("loadingDesktop")


    e.preventDefault()
if(subject.length == 0){
    setSubjectError("error")
}
else{
    setTimeout(() => {
        first.style.display="none"
        loading.style.display="flex"

        setTimeout(() => {
        loading.style.display="none"
        second.style.display="flex"
            
        }, 1000);
    }, 1000);

}
}



// Subject select
function selectSubject (i){
    setSubject(i)

}


// Subjects List Array
    const subjects = [
        "English",
        "Amharic",
        "Information Technology",
        "Chemistry",
        "Physics",
        "Civics",
        "Maths",


    ]

// Subject Field selector Input
    function subjectSet(e){
        setSubject(e.target.value)

        if(e.target.value.length == 0){
            setSubjectError("error")
        }
        else {
            setSubjectError("")
        }
    }


    // Course Title Field selector Input
    function courseTitleSet(e){
        setcourseTitle(e.target.value)

        if(e.target.value.length == 0){
            setcourseTitleError("error")
        }
        else {
            setcourseTitleError("")
        }
    }

    // Course Model Field selector Input
    function courseModelSet(e){
        setcourseModel(e.target.value)
        if(e.target.value.length == 0){
            setcourseModelError("error")
        }
        else {
           
            setcourseModelError("")
        
                
             }
            }

             // Quiz check Field selector Input
    function quizCheckSet(e){
        setisThereAnyQuiz(e.target.value)
        if(e.target.value == ""){
            setisThereAnyQuizError("error")
        }
        else {
           
            setisThereAnyQuizError("")
        
                
             }
            }

                    // completion Time Field selector Input
    function completionTimeSet(e){
        setcompletionTime(e.target.value)
        if(e.target.value == ""){
            setcompletionTimeError("error")
        }
        else {
           
            setcompletionTimeError("")
        
                
             }
            }

    // Course Objective Field selector Input
    function courseObjectiveSet(e){
        setcourseObjective(e.target.value)

        if(e.target.value.length == 0){
            setcourseObjectiveError("error")
        }
        else {
            setcourseObjectiveError("")
        }
    }



const handleChangeCourseSkills = value => {
  setcourseSkills(value);
  if (value.length ==  0){
    setcourseSkillsError("error")
}
else{
    setcourseSkillsError("")

}
}

     // Course Description Field selector Input
     function courseDescriptionSet(e){
        setcourseDescription(e.target.value)

        if(e.target.value.length == 0){
            setcourseDescriptionError("error")
        }
        else {
            setcourseDescriptionError("")
        }
    }


       // Age group Field selector Input
       function ageGroupSet(e){
        setintendedAgeGroup(e.target.value)

        if(e.target.value.length == 0){
            setintendedAgeGroupError("error")
        }
        else {
            setintendedAgeGroupError("")
        }
    }

       // Age group Field selector Input
       function courseLevelSet(e){
        setcourseLevelGroup(e.target.value)

        if(e.target.value.length == 0){
            setcourseLevelGroupError("error")
        }
        else {
            setcourseLevelGroupError("")
        }
    }

    function closeSuccessDesktop(){
        const success = document.getElementById("successCourseAddDesktop")
    const empty = document.getElementById("emptyCourseDesktop")

        success.style.display ="none" 
        empty.style.display ="block" 

    }

    function closePopDeleteDesktop(){
      const pop = document.getElementById("deleteCourseDesktop")

        pop.style.display ="none" 

      
    }

    function handleLogOut(){
      signOut(auth).then(
       navigate('/')
      )
     }

   function desktopSpinnerHandler(){
    setspinner(false)   }

    function deleteHandlerDesktop(){
      const pop = document.getElementById("deleteCourseDesktop")
      const message= document.getElementById("alert-border-4")

      deleteDoc(doc(firestore, 'AccountDetails',uid,"CoursesCreated",courseId)).then(
        deleteDoc(doc(firestore, 'CourseCreated',courseId)).then(
          pop.style.display ="none",
          message.style.display ="flex"

        )
      )

    }

// Course Data Pop handler
function coursePopDataDesktop(){
  const courseData = document.getElementById("courseDataDesktop")
  if(courseData.style.display === "block"){
    courseData.style.display="none"
  }
  else {
    courseData.style.display="block"

  }
}

function closeMessage2Desktop(){
  const message= document.getElementById("alert-border-4")
  message.style.display="none"
  setprogress(0)
}
const popProfile = () =>{
  if(pop == true){
    setpop(false)
  setnotificationPop(false)


  }
  else{
  setpop(true)
  setnotificationPop(false)



  }
}
const convertTimestampToDate = (timestamp) => {
const date = timestamp.toDate();
const currentDate = new Date();
const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

const secondsInMinute = 60;
const secondsInHour = 3600;
const secondsInDay = 86400;
const secondsInWeek = 604800;
const secondsInMonth = 2592000;
const secondsInYear = 31536000;

let relativeTime = '';

if (timeDifferenceInSeconds < secondsInMinute) {
  relativeTime = `${timeDifferenceInSeconds}s ago`;
} else if (timeDifferenceInSeconds < secondsInHour) {
  const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
  relativeTime = `${minutes}m ago`;
} else if (timeDifferenceInSeconds < secondsInDay) {
  const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
  relativeTime = `${hours}h ago`;
} else if (timeDifferenceInSeconds < secondsInWeek) {
  const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
  relativeTime = `${days}d ago`;
} else if (timeDifferenceInSeconds < secondsInMonth) {
  const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
  relativeTime = `${weeks}w ago`;
} else if (timeDifferenceInSeconds < secondsInYear) {
  const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
  relativeTime = `${months}mo ago`;
} else {
  const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
  relativeTime = `${years}y ago`;
}

return relativeTime;
};
const popNotification = () =>{
if(notificationPop == true){
  setnotificationPop(false)
  setpop(false)
}
else{
setnotificationPop(true)
setpop(false)



}
}
 function removeNotification(id){
  const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

  setDoc(notificationRef,{
    seen:true
  },{merge:true})
  .then(() => {
    
    setnotification(prevArray => prevArray.filter(item => item.id !== id));
  })
  .catch((error) => {
  });


 }
let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
  return (
 user.map(i=>{
  const{accountType,plan,fullName,photo} = i

  return(
 accountType == 'Teacher' ?
 <div>
      {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
 <div id='desktop' className='relative'>
 <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <Link to='/notification'><h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1></Link>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>

<div className={`${planError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {planError}
  </div>
  </div>
  </div>



<div className='relative'>

     <div class="flex flex-wrap items-start justify-end -mb-3  p-6 sm:p-10 mr-3">
<Link to="/mycourse">
<button style={{fontSize:'12px'}} class="inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
     <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flex-shrink-0 h-3 w-3 -ml-1 mt-0.5 mr-2">
       <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
     </svg>
     Manage Courses
   </button></Link>
 </div>
 
{
  loading == true ?
  <div className='flex justify-center items-center '  style={{position:'absolute',top:'0',left:'0',width:'100%',height:'60vh',backgroundColor:'white',zIndex:'100'}}>
<div class="relative">
<div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
<div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
</div>

</div>
:
success == true ?
<div id='successCourseAddDesktop' className='flex items-center justify-center'>


<div class="relative p-4 w-full  h-full md:h-auto"style={{width:"45%"}}>
 <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
   
     <Link to="/mycourse">
     <button  type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
         <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         <span class="sr-only">Close modal</span>
     </button>

     </Link>

     <div class="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
         <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
         <span class="sr-only">Success</span>
     </div>
     <p class="mb-4 text-lg font-semibold text-gray-900  capitalize">Successfully Added {courseTitle}.</p>
<Link to="/mycourse">
<button  type="button" class="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800">Continue</button>


</Link>
 </div>
</div>



</div>
:
<div className='mx-auto flex justify-evenly pb-12' id='firstDesktop'>
 <div className='flex justify-center flex-col  ' style={{width:"40%"}}>
     



<img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Professor-bro.svg?alt=media&token=259d53c0-b628-48be-b527-5a3a4d8160fd&_gl=1*1wjl1lo*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODUyNzU3Ny4xNDcuMS4xNjk4NTI3NjQzLjU2LjAuMA.." alt="" />
 </div>
<div style={{width:"50%"}}>
<h1 className='anton text-left mb-6' style={{fontSize:'30px'}}>
Describe Your Course
</h1>


<form  autoComplete="off"   className="w-1/3 mt-12 mr-0 mb-0 ml-0 relative space-y-8  mx-auto w-full " id="first_modal" style={{width:"80%"}}>




<div className="relative">


<p style={{fontSize:'15px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Subject</p>
<input style={{fontSize:'12px',height:'80px'}} onChange={e=>subjectSet(e)}  required  autoComplete="off" placeholder="Information Technology" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${subjectError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Subject Can't be empty
</div>
</div>
</div>
</div>
<div className="relative">


<p style={{fontSize:'15px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Course Title</p>
<input onChange={e=>courseTitleSet(e)}style={{fontSize:'12px',height:'80px'}}  required  autoComplete="off" placeholder="Introduction to Database" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 borel mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${courseTitleError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Title Can't be empty
</div>
</div>
</div>
</div>
<div className="relative m-auto" >


<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Intended Student Group</p>

<select style={{fontSize:'12px',height:'80px'}} onChange={e=> ageGroupSet(e)} type="text" class="borel mx-2 border  focus:outline-none
focus:border-black w-full pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
                         <option className='skill__input-option'value="">Select Group</option>
                         <option className='skill__input-option' value="Elementary School ">Elementary School </option>
<option className='skill__input-option' value="High School ">High School </option>


                         <option className='skill__input-option'value="Undergraduate">Undergraduate</option>
<option className='skill__input-option' value="Postgraduate">Postgraduate</option>
<option className='skill__input-option' value="Doctoral">Doctoral</option>







</select> 
<div className={`${intendedAgeGroupError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
This field Can't be empty
</div>
</div>
</div>

</div>

<div className="relative m-auto" >


<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Intended Student Group</p>

<select onChange={e=> courseLevelSet(e)} style={{fontSize:'12px',height:'80px'}} type="text" class="mx-2 borel border  focus:outline-none
focus:border-black w-full pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option'value="">Select Group</option>
                         <option className='skill__input-option' value="Beginner  ">Beginner  </option>
<option className='skill__input-option' value="Intermediate ">Intermediate </option>


                         <option className='skill__input-option'value="Advanced">Advanced</option>





</select> 
<div className={`${courseLevelGroupError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
This field Can't be empty
</div>
</div>
</div>

</div>
<div class="relative m-auto mb-10">
<p class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-10 font-medium text-gray-600 absolute capitalize">Skills Obtained from the course (Max 10)</p>
<TagsInput
 inputProps={{
     placeholder: 'Gain knowledge of database components',
     className: 'form-control borel text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
 }}
 tabindex="5"
 className='form-control text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
 maxTags={10}
 value={courseSkills}
 onChange={handleChangeCourseSkills}
 renderTag={({ tag, key, disabled, onRemove }) => (
     <span key={key} style={{ color: 'white',margin:"3px",fontSize:'11px' }} className='borel px-5 py-2 capitalize skillGrid  bg-blue-800'>
         {tag}
         {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer'>X</span>}
     </span>
 )}
/>

{courseSkillsError &&  
 <p className={`${courseSkillsError == "" && "hidden"} ${courseSkillsError == "error" && "block"}`}>
     <div class="fjalla flex rounded bg-danger-100 text-base text-danger-700 barel px-3 py-3" role="alert">
         <svg xmlns="http://www.w3.org/2000/svg" width="25" fill="currentColor" class="mr-1 bi bi-bug" viewBox="0 0 16 16">
             <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"/>
         </svg>Course Skills can't be empty
     </div> 
 </p>
} 
</div>


<div className="relative">

<p style={{fontSize:'15px'}} className="bg-white pt-0 pr-2 pb-0 pl-2 fjalla -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Course completion time estimate </p>

<input style={{fontSize:'12px',height:'80px'}}onChange={e=>completionTimeSet(e)}  required  autoComplete="off" placeholder="4 Weeks" type="text" className="border  focus:outline-none
focus:border-black borel w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${completionTimeError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Completion time Can't be empty
</div>
</div>
</div>
</div>

<div className="flex w-full items-center"style={{width:"110%"}}>
<div className="relative" style={{width:"90%"}}>

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Course Description</p>
<textarea placeholder='Gain a solid understanding of data modeling, relational database design, and SQL language proficiency.'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  ref={textRefDescription} onChange={e=>courseDescriptionSet(e)}  type="text" class="border  focus:outline-none
      focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md" maxLength="5000"></textarea>
      <div className={`${courseDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Description Can't be empty
</div>
</div>
</div>

</div>
<div style={{width:"10%"}}>
<p onClick={openCourseDescriptionDesktop} className='Mooli text-blue-800 ml-4 hover:underline cursor-pointer'>Explain</p>
</div>
</div>


<div className="relative" >
<button onClick={handleFirstDesktop} type='submit' style={{backgroundColor:"#2141ce" ,fontSize:'14px'}}  className="w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5  text-center text-white 
rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">Create Course
</button>
</div>
</form>
</div>
</div>

}



{
  CourseDescriptionOpen == true &&
  <div id='courseDescriptionDesktop' className=' border rounded flex items-center justify-center'>
<div className='bg-white px-5 rounded-md relative' style={{width:'90%',height:'60%'}}>

<svg onClick={removeCourseDescriptionDesktop} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{position:"absolute",top:30,right:30}}>
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>


<h1 className='anton text-center pt-12 pb-6'style={{fontSize:"30px"}}><span className='text-blue-800 underline'>Course Description:</span> why do we need it?</h1>
<div className=''>
<p className='Mooli text-gray-500'>A course description is important for informing and setting expectations for potential learners. It provides a concise overview of the course's content, objectives, and benefits. By outlining the course structure, activities, and assessments, it helps learners make informed decisions about their enrollment. Additionally, a course description helps differentiate the course from others and highlights its unique features and value proposition</p>
</div>
</div>
</div>
}








 </div>

 <div>

 </div>

 <div>

 </div>
 </div>
 <div id='tablet'>
<DashboardNav/>
{
  CourseDescriptionOpen == true &&
  <div id='courseDescriptionDesktop' className=' border rounded flex items-center justify-center'>
<div className='bg-white px-5 rounded-md relative' style={{width:'90%',height:'60%'}}>

<svg onClick={removeCourseDescriptionDesktop} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{position:"absolute",top:30,right:30}}>
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>


<h1 className='anton text-center pt-12 pb-6'style={{fontSize:"30px"}}><span className='text-blue-800 underline'>Course Description:</span> why do we need it?</h1>
<div className=''>
<p className='Mooli text-gray-500'>A course description is important for informing and setting expectations for potential learners. It provides a concise overview of the course's content, objectives, and benefits. By outlining the course structure, activities, and assessments, it helps learners make informed decisions about their enrollment. Additionally, a course description helps differentiate the course from others and highlights its unique features and value proposition</p>
</div>
</div>
</div>

}
<div className={`${planError.length !=0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{planError}
</div>
</div>
</div>



<div className='relative'>

  

{
loading == true ?
<div className='flex justify-center items-center '  style={{position:'absolute',top:'0',left:'0',width:'100%',height:'60vh',backgroundColor:'white',zIndex:'100'}}>
<div class="relative">
<div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
<div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
</div>

</div>
:
success == true ?
<div id='successCourseAddDesktop' className='flex items-center justify-center'>


<div class="relative p-4 w-full  h-full md:h-auto"style={{width:"45%"}}>
<div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
 
   <Link to="/mycourse">
   <button  type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span class="sr-only">Close modal</span>
   </button>

   </Link>

   <div class="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span class="sr-only">Success</span>
   </div>
   <p class="mb-4 text-lg font-semibold text-gray-900  capitalize">Successfully Added {courseTitle}.</p>
<Link to="/mycourse">
<button  type="button" class="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800">Continue</button>


</Link>
</div>
</div>



</div>
:

<div style={{width:"85%"}} className='mx-auto '>
  <div className='flex justify-between w-full items-center' style={{width:'110%'}}>

<h1 className='anton text-left' style={{fontSize:'4vw'}}>
Course Details
</h1>
<div class="flex flex-wrap items-start justify-end -mb-3  p-6 sm:p-10 mr-3">
<Link to="/mycourse">
<button class="inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
   <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flex-shrink-0 h-5 w-5 -ml-1 mt-0.5 mr-2">
     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
   </svg>
   Manage Courses
 </button></Link>
  </div>
</div>


<form  autoComplete="off"   className=" mt-6 mr-0 mb-0 ml-0 relative space-y-8  mx-auto " id="first_modal" style={{width:"100%"}}>




<div className="relative">


<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute">Subject</p>
<input style={{height:'80px',fontSize:'13px'}} onChange={e=>subjectSet(e)}  required  autoComplete="off" placeholder="Information Technology" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md borel" />

<div className={`${subjectError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Subject Can't be empty
</div>
</div>
</div>
</div>
<div className="relative">


<p style={{fontSize:'15px'}}className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute">Course Title</p>
<input style={{height:'80px',fontSize:'13px'}} onChange={e=>courseTitleSet(e)}  required  autoComplete="off" placeholder="Introduction to Database" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md borel" />

<div className={`${courseTitleError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Title Can't be empty
</div>
</div>
</div>
</div>
<div className="relative m-auto" >


<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute">Intended Student Group</p>

<select style={{fontSize:'12px',height:'80px'}} onChange={e=> ageGroupSet(e)} type="text" class="borel mx-2 border  focus:outline-none
focus:border-black w-full pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
                       <option className='skill__input-option'value="">Select Group</option>
                       <option className='skill__input-option' value="Elementary School ">Elementary School </option>
<option className='skill__input-option' value="High School ">High School </option>


                       <option className='skill__input-option'value="Undergraduate">Undergraduate</option>
<option className='skill__input-option' value="Postgraduate">Postgraduate</option>
<option className='skill__input-option' value="Doctoral">Doctoral</option>







</select> 
<div className={`${intendedAgeGroupError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
This field Can't be empty
</div>
</div>
</div>

</div>

<div className="relative m-auto" >


<p style={{fontSize:'15px'}}className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute">Intended Student Group</p>

<select onChange={e=> courseLevelSet(e)} style={{fontSize:'13px',height:'80px'}} type="text" class="borel mx-2 border  focus:outline-none
focus:border-black w-full pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
             <option className='skill__input-option'value="">Select Group</option>
                       <option className='skill__input-option' value="Beginner  ">Beginner  </option>
<option className='skill__input-option' value="Intermediate ">Intermediate </option>


                       <option className='skill__input-option'value="Advanced">Advanced</option>





</select> 
<div className={`${courseLevelGroupError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
This field Can't be empty
</div>
</div>
</div>

</div>
<div class="relative m-auto mb-10">
<p style={{fontSize:'15px'}}class="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-12 font-medium text-gray-600 absolute capitalize">Skills Obtained from the course (Max 10)</p>
<TagsInput
inputProps={{
   placeholder: 'Gain knowledge of database components',
   className: 'form-control borel text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
   
}}
tabindex="5"
className='form-control text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
maxTags={10}
value={courseSkills}
onChange={handleChangeCourseSkills}
renderTag={({ tag, key, disabled, onRemove }) => (
   <span key={key} style={{ color: 'white',margin:"3px" }} className='px-5 py-2 skillGrid  bg-blue-800'>
       {tag}
       {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer'>X</span>}
   </span>
)}
/>

{courseSkillsError &&  
<p className={`${courseSkillsError == "" && "hidden"} ${courseSkillsError == "error" && "block"}`}>
   <div class="fjalla flex rounded bg-danger-100 text-base text-danger-700 barel px-3 py-3" role="alert">
       <svg xmlns="http://www.w3.org/2000/svg" width="25" fill="currentColor" class="mr-1 bi bi-bug" viewBox="0 0 16 16">
           <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"/>
       </svg>Course Skills can't be empty
   </div> 
</p>
} 
</div>


<div className="relative">

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute capitalize">Course completion time estimate </p>

<input style={{height:'80px',fontSize:'13px'}} onChange={e=>completionTimeSet(e)}  required  autoComplete="off" placeholder="4 Weeks" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md borel" />

<div className={`${completionTimeError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Completion time Can't be empty
</div>
</div>
</div>
</div>

<div className="flex w-full items-center "style={{width:"100%"}}>
<div className="relative" style={{width:"90%"}}>

<p style={{fontSize:'15px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-4 font-medium text-gray-600 absolute">Course Description</p>
<textarea  placeholder='Gain a solid understanding of data modeling, relational database design, and SQL language proficiency.'  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'13px'}}  ref={textRefDescription} onChange={e=>courseDescriptionSet(e)}  type="text" class="border  focus:outline-none
    focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
    border-gray-300 rounded-md" maxLength="1000"></textarea>
    <div className={`${courseDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Description Can't be empty
</div>
</div>
</div>

</div>
<div style={{width:"10%"}}>
<p onClick={openCourseDescriptionDesktop} className='Mooli text-blue-800 ml-4 hover:underline cursor-pointer'>Explain</p>
</div>
</div>


<div className="relative mb-12" >
<button onClick={handleFirstDesktop} type='submit' style={{backgroundColor:"#2141ce" ,marginBottom:'30px'}}  className="w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5  text-center text-white 
rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">Create Course
<svg style={{backgroundColor:"#2141ce" ,}} id="loadingFirst" aria-hidden="true" role="status" className="ml-2 inline w-5 h-5 mr-3 text-white animate-spin hidden" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
</svg></button>
</div>
</form>
</div>

}




<div id='courseObjectiveDesktop' className='hidden border rounded'>

<svg onClick={removeCourseObjectiveDesktop} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{position:"absolute",top:30,right:30}}>
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>


<h1 className='anton text-center pt-12 pb-6'style={{fontSize:"30px"}}><span className='text-blue-800 underline'>Course Description:</span> why do we need it?</h1>
<div className='border-b'>
<p className='Mooli text-gray-500'>A course description is important for informing and setting expectations for potential learners. It provides a concise overview of the course's content, objectives, and benefits. By outlining the course structure, activities, and assessments, it helps learners make informed decisions about their enrollment. Additionally, a course description helps differentiate the course from others and highlights its unique features and value proposition</p>
</div>
</div>

<div id='courseDescriptionDesktop' className='hidden border rounded'>

<svg onClick={removeCourseDescriptionDesktop} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{position:"absolute",top:30,right:30}}>
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>


<h1 className='anton text-center pt-12 pb-6'style={{fontSize:"30px"}}><span className='text-blue-800 underline'>Course Description:</span> why do we need it?</h1>
<div className='border-b'>
<p className='Mooli text-gray-500'>A course description is important for informing and setting expectations for potential learners. It provides a concise overview of the course's content, objectives, and benefits. By outlining the course structure, activities, and assessments, it helps learners make informed decisions about their enrollment. Additionally, a course description helps differentiate the course from others and highlights its unique features and value proposition</p>
</div>
</div>





</div>

<div>

</div>

<div>

</div>

 </div>

 <div id='mobile'>
<DashboardNav/>

{
  loading == true ?
<div style={{position:'fixed',top:'0',left:'0',width:'100%',height:'100%',backgroundColor:'white',zIndex:'100'}} className='flex items-center justify-center flex-col'>

<div class="relative">
<div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
<div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
</div>

</div>
:
success == true ?
<div style={{position:'fixed',top:'0',left:'0',width:'100%',height:'100%',backgroundColor:'blue',zIndex:'100'}} className='flex items-center justify-center flex-col'>
<Link to="/mycourse">
     <button  type="button" class="text-white absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
         <svg aria-hidden="true" style={{width:'7vw',height:'7vw'}} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         <span class="sr-only">Close modal</span>
     </button>

     </Link>
     <div style={{width:'12vw',height:'12vw'}}class=" rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
         <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
         <span class="sr-only">Success</span>
     </div>
     <p class="anton   capitalize text-center" style={{fontSize:'8vw',color:'white'}}>Successfully Added {courseTitle}</p>






</div>
:
<div className='mx-auto flex justify-evenly items-center pb-12'>

<div style={{width:"80%"}} className='mx-auto  '>
<h1 className='anton text-left' style={{fontSize:'7vw',margin:'10vw 0'}}>
Describe your course
</h1>
<div className={`${planError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
{planError}
</div>
</div>
</div> 

<div    className="flex flex-col items-center w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8  mx-auto w-full " id="first_modal" style={{width:"100%"}}>


<div className="relative m-auto " style={{width:"100%",marginBottom:"3vw",marginTop:"4vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Subject</p>

           <input type='text'  placeholder='Information Technology' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"3vw",paddingBottom:"3vw",paddingLeft:"4vw",fontSize:"3vw",width:"100%"}}   onChange={e=>subjectSet(e)}/>
               <div className={`${subjectError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Subject Can't be empty
</div>
</div>
</div> 
   </div>

   <div className="relative m-auto " style={{width:"100%",marginBottom:"3vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Course Title</p>

           <input type='text'  placeholder='Introduction to Database' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:"100%"}}   onChange={e=>courseTitleSet(e)}/>
               <div className={`${courseTitleError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Course Title Can't be empty
</div>
</div>
</div> 
   </div>
   <div className="relative m-auto " style={{width:"100%",marginBottom:"3vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Intended Student Group</p>

           <select style={{paddingRight:'4vw',fontSize:"3vw",width:"100%"}} onChange={e=> ageGroupSet(e)} type="text" class="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md">
                         <option className='skill__input-option'value="">Select Group</option>
                         <option className='skill__input-option' value="Elementary School ">Elementary School </option>
<option className='skill__input-option' value="High School ">High School </option>


                         <option className='skill__input-option'value="Undergraduate">Undergraduate</option>
<option className='skill__input-option' value="Postgraduate">Postgraduate</option>
<option className='skill__input-option' value="Doctoral">Doctoral</option>







</select>             <div className={`${intendedAgeGroupError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Age Group Can't be empty
</div>
</div>
</div> 
   </div>

   <div className="relative m-auto " style={{width:"100%",marginBottom:"3vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Course Level</p>

           <select style={{fontSize:"3vw",width:"100%"}} onChange={e=> courseLevelSet(e)} type="text" class="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md">
               <option className='skill__input-option'value="">Select Group</option>
                         <option className='skill__input-option' value="Beginner  ">Beginner  </option>
<option className='skill__input-option' value="Intermediate ">Intermediate </option>


                         <option className='skill__input-option'value="Advanced">Advanced</option>






</select>           
  <div className={`${courseLevelGroupError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Course Level Can't be empty
</div>
</div>
</div> 
   </div>

<form className='w-full'>
<div className="relative m-auto " style={{width:"100%",marginBottom:"3vw",lineHeight:"4vw"}}>
<p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Skills Obtained from the course (Max 10)</p>

<TagsInput
 inputProps={{
     placeholder: 'Gain knowledge of database components',
     
     className: 'tagClass text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
 }}
 tabindex="5"
 className=' text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
 maxTags={10}
 value={courseSkills}
 onChange={handleChangeCourseSkills}
 renderTag={({ tag, key, disabled, onRemove }) => (
     <span key={key} style={{ color: 'white',margin:"2vw" }} className=' py-2 skillGrid  bg-blue-800'>
         <p className='fjalla ' style={{fontSize:'3vw'}}>{tag}</p>
         {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer'>X</span>}
     </span>
 )}
/>

<div className={`${courseSkillsError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Course Skills Can't be empty
</div>
</div>
</div> 
</div></form>

<div className="relative m-auto " style={{width:"100%",marginBottom:"3vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Completion Time</p>
           <input type='text'  placeholder='4 Weeks' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{fontSize:"3vw",width:"100%"}}   onChange={e=>completionTimeSet(e)}/>
             
              
  <div className={`${completionTimeError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
completion Time Can't be empty
</div>
</div>
</div> 
   </div>

<div className="relative m-auto " style={{width:"100%",marginBottom:"3vw",lineHeight:"4vw"}}>

<p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"1vw",paddingRight:"2vw"}}>Course Description</p>


<textarea
  style={{
    padding: '3vw 3.5vw',
    fontSize: '3vw',
    minHeight: '50vw',
    maxHeight: '80vw',
  }}
  placeholder="Gain a solid understanding of data modeling, relational database design, and SQL language proficiency."
  ref={textRefDescription}
  onChange={e => courseDescriptionSet(e)}
  className="border text-gray-600 focus:outline-none focus:border-black w-full text-base block bg-white border-gray-300 rounded-md"
  maxLength={1000}
></textarea>
<p className='fjalla' style={{fontSize: '3vw'}}>{courseDescription.length + '/ 1,000'}</p>
   <div className={`${courseDescriptionError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Course Description Can't be empty
</div>
</div>
</div> 

</div>
{/* <div className="flex w-full items-center"style={{width:"110%"}}>

<div style={{width:"10%"}}>
<p onClick={openCourseDescriptionDesktop} className='Mooli text-blue-800 ml-4 hover:underline cursor-pointer'>Explain</p>
</div>
</div> */}


<div className="relative w-full" >
<button onClick={handleFirstDesktop} style={{backgroundColor:"#2141ce" ,padding:"3vw",fontSize:"4vw",height:"12vw",width:'100%'}}  class="rounded w-full flex justify-center items-center   text-center text-white 
                  rounded-md transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">Create Course
    </button>
</div>
</div>
</div>
</div>
  
}


 </div>

</div>
:
navigate('/dashboard')
  )
 })
  )
}
