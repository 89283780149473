import { doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import Nav from './Nav'
import moment from 'moment'
export default function Checkout() {
    const [users,setuser] = useState([])
    const [isloading,setisloading] = useState(false)
    const [cbebirr,setcbebirr] = useState(false)
    const [telebirr,settelebirr] = useState(false)
    const [boa,setboa] = useState(false)
    const [cbe,setcbe] = useState(false)
    const [progress,setprogress] = useState('')
    const [chosenMethod,setchosenMethod] = useState("")
    const [instructorId,setinstructorId] = useState("")

    const navigate = useNavigate()

    const [screenshot,setscreenshot] = useState([])
    const [transaction,settransaction] = useState([])

    const [screenshotError,setscreenshotError] = useState("")

    const [fullName,setFullName] = useState("")
    const [fullNameError,setfullNameError] = useState("")
    const [transferredFrom,setTransferredFrom] = useState("")
    const [transferredFromError,setTransferredFromError] = useState("")

    const [fileMesssage,setfileMesssage] = useState("")
    const [selectedLecture,setSelectedLecture] = useState([])

    const [uid,setuid] = useState("")
    const [accountType,setaccountType] = useState("")

    const [courseTitle,setCourseTitle] = useState("")
    const [coursePrice,setCoursePrice] = useState("")
    const [error,seterror] = useState("")
    const currentTime = moment();

    const {checkoutId,plan,user,length} = useParams()
    

  const amount = (plan == 'basic' && length == 'month' && accountType == 'Teacher' ? '3,000':
  plan == 'basic' && length == '3months' && accountType == 'Teacher' ? '7,650' :
  plan == 'basic' && length == 'annual' && accountType == 'Teacher' ?'30,600' :
  plan == 'premium' && length == 'month' && accountType == 'Teacher' ? '8,000':
  plan == 'premium' && length == '3months' && accountType == 'Teacher' ? '20,400':
  plan == 'premium' && length == 'annual' && accountType == 'Teacher' && '81,600'
  
  
  
  )


  function getCurrentDateTimeString() {
    const currentDate = new Date();
    return currentDate.toLocaleString('en-GB', {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })
    .replace(/,/g, '')
    .replace(/\s/g, '');
  }
  const currentDateTimeString = getCurrentDateTimeString();


    const [screenshotHandler,setscreenshotHandler] = useState([])






   
      



      function fullNameSet(e) {
        setFullName(e.target.value)
        seterror('')
        if(e.target.value.length == 0){
            setfullNameError("error")
        }
        else{
            setfullNameError("")
        }
      }
      
    function transferredFromSet(e) {
        setTransferredFrom(e.target.value)
        seterror('')

        if(e.target.value.length == 0){
            setTransferredFromError("error")
        }
        else{
            setTransferredFromError("")
        }
      }

    
      
 

      function handleImgSelect(event) {
        const selectedFiles = event.target.files;
      
        // Check if a file is selected
        if (selectedFiles.length > 0) {
          const selectedFile = selectedFiles[0];
      
          // Check file type
          if (selectedFile.type.startsWith('image/')) {
            // Check file size
            const maxSize = 10 * 1024 * 1024; // 10MB
            if (selectedFile.size > maxSize) {
              setfileMesssage(`File ${selectedFile.name} exceeds the size limit of 10MB.`);
              return false;
            } else {
              setfileMesssage('');
              setscreenshot(selectedFile);
            }
          } else {
            setfileMesssage(`File ${selectedFile.name} is not a valid image file.`);
            return false;
          }
        } else {
          setfileMesssage('');
          setscreenshot(null);
        }
      
       setscreenshotError('');
      
        // Clear the file input value to allow selecting the same file gain
        event.target.value = '';
      }
        
        const minimizeUploadImg = ()=>{
          const upload = document.getElementById("uploadFileDesktop")
          upload.style.display="none"
          setscreenshot([])
        }

        function backImageDesktop (){
            const upload = document.getElementById("uploadImageDesktop")
            upload.style.display="none"
            setscreenshot([])
          }
       

          function openImageDesktop (){
            const upload = document.getElementById("uploadImageDesktop")
            upload.style.display="flex"
          
          }

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
           
      
      setuid(uid)
    
   
            
      const userRef = doc(firestore,"TotalUsers",uid);
      const transactionRef = doc(firestore,"Transactions",checkoutId);
    
      onSnapshot(userRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      setuser(main)
      setaccountType(snapshot.data().accountType)
    
        
        
      
    
      })

    
     
          
      onSnapshot(transactionRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      settransaction(main)
    
        
        
      
    
      })
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);


      function handleCbeBirrDesktop(){
        setchosenMethod('Cbe Birr')

        if(cbebirr == true){
            setcbebirr(false)
            setscreenshotHandler([])

        }
        else {
            setcbebirr(true)
            setboa(false)
            settelebirr(false)
            setcbe(false)
            setscreenshotHandler([])
        }
      }

      function handleTelebirrDesktop(){
        setchosenMethod('Telebirr')

        if(telebirr == true){
            settelebirr(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)
            setscreenshotHandler([])

            setboa(false)
            settelebirr(true)
            setcbe(false)
        }
      }

      function handleCbeDesktop(){
        setchosenMethod('Commercial Bank Of Ethiopia')

        if(cbe == true){
            setcbe(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)

            setscreenshotHandler([])

            setboa(false)
            settelebirr(false)
            setcbe(true)
        }
      }
      function handleBoaDesktop(){
        setchosenMethod('Bank Of Abyssinia')

        if(boa == true){
            setboa(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)

            setscreenshotHandler([])

            setboa(true)
            settelebirr(false)
            setcbe(false)
        }
      }




      function handlePaidDesktop(checkoutId){


        const documentRef3 = doc(firestore,"AccountDetails",uid,"Transaction",checkoutId)
        const documentRef5 = doc(firestore,"Transactions",checkoutId)
        const documentRef6 = doc(firestore,"TotalUsers",uid)
        const documentRef7 = doc(firestore,"TransactionNewUsers",uid)




        const storage = getStorage()
        const reff = ref(storage, `resume/${screenshot.name}`)
        const uploadImage= uploadBytesResumable(reff,screenshot)
            
      
        if(fullName.length == 0){
            setfullNameError("error")
            seterror('error')
        }
        else{

          if(transferredFrom.length == 0){
            setTransferredFromError("error")
            seterror('error')
        }

        else{
          if(screenshot.length == 0){
            setscreenshotError("error")
            seterror('error')
        }

        else{
            setisloading(true)

         
          uploadImage.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setprogress(progress)
        
          }, 
          (error) => {
            console.log(error)
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          
        
          getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
          
              const transactionData = {
                  screenshot:downloadURL,
                  paid:false,
                  isPending:true,
                createdTime:currentTime.format('YYYY-MM-DD HH:mm:ss'),
                accountHolder:fullName,
                accountNumber:transferredFrom,
                transactionID:checkoutId,
                chosenMethod:chosenMethod,
                uid:uid,
                paymentFor: plan,
                amount:amount
,

                plan:plan,
                length:length,

                state:'Pending'
              

        
        
                };
              
                 setDoc(documentRef3, transactionData, { merge: true });
                 setDoc(documentRef6, {
                    isPending:true,
                 }, { merge: true });
                 
                 setDoc(documentRef5, transactionData, { merge: true }).then(
                 setDoc(documentRef7, transactionData, { merge: true }).then(
                    setisloading(false)

                 )

                 )



        
            
          })
             
            
              
          }
        
          // )}
         
        )


         
        }
        }
        
         
        }
       
  
      }
  
  return (
    <div>
       {
        users.map(i=>{
          const {accountType,isPending,paid,state} = i
          return(
            (accountType =="Teacher" || accountType =="Institute") && checkoutId == uid + currentDateTimeString ?
  <div >
<div  >
    

            <body>
    
          <div>
            {
              isPending === true?
              <div>

                <div id='desktop' >
    <nav className='flex justify-between w-full  px-5 navDesktop'>

   <Link to="/dashboard" className=' bg-blue-800 ml-5' style={{width:"8%",objectFit:"cover"}}> 
                        <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                          </Link>

                <div>

   </div>


    </nav>


</div>
                <div>
          {
            transaction && transaction.map(i=>{
              const {chosenMethod,amount} = i
              return(
            <div>
                  <div id='desktop' class="h-screen   flex items-center">
                <div class=" flex flex-col md:flex-row items-center justify-between  text-gray-700">
                        <div class="w-full px-6 " style={{width:'50%'}}>
                            <div style={{fontSize:'30px',lineHeight:'30px'}} class=" text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                            <p className='fjalla flex flex-col mt-4' style={{fontSize:'15px',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                            </div>
                       
                            <Link to={`/dashboard`}>
                            <button style={{fontSize:'11px'}}  class="px-5 py-2 capitalize inline-flex  py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                          
                            Check out my account until the payment is verified
                                        </button>
                            </Link>      
             
                        </div>
                    <div class="" style={{width:'50%'}}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
                    </div>
                
                </div>
            </div>
            <div id='mobile' class="h-screen flex-col   flex items-center">
            <nav className='flex justify-between navMobile border-b w-full '>

            <Link to="/"  style={{width:'21%'}}>

            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 

                          </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>

<div></div>
</div>
    </nav>
                <div class="  flex-col md:flex-row items-center justify-between  text-gray-700">
                        <div class="w-full flex flex-col items-center justify-center m-auto " style={{width:'90%',marginTop:'30%'}}>
                            <div style={{fontSize:'8vw',lineHeight:'8.5vw'}} class=" text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                            </div>
                            <p className='fjalla flex flex-col ' style={{fontSize:'3.5vw',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                       
                            <Link to={`/dashboard`}>
                            <button style={{padding:'2vw 5vw',marginTop:'3vw',fontSize:'3vw'}}  class=" capitalize inline-flex  py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                          
                            Check out my account until the payment is verified
                                        </button>
                            </Link>      
             
                        </div>
           
                
                </div>
            </div>

            <div id='tablet' class="h-screen flex-col   flex items-center">
            <nav className='flex justify-between navTablet w-full  pt-5'>

            <Link to="/"  style={{width:'20%'}}>
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                      
                          </Link>

<div className='flex justify-end' style={{width:'30%'}}>

</div>
    </nav>
                <div class="  flex-col md:flex-row items-center justify-between  text-gray-700">
                        <div class="w-full flex flex-col items-center justify-center m-auto " style={{width:'90%',marginTop:'20%'}}>
                            <div style={{fontSize:'6vw',lineHeight:'6.5vw'}} class=" text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                            </div>
                            <p className='fjalla flex flex-col ' style={{fontSize:'3vw',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                       
                            <Link to={`/dashboard`}>
                            <button style={{padding:'2vw 5vw',marginTop:'3vw',fontSize:'2.5vw'}}  class=" capitalize inline-flex  py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                          
                            Check out my account until the payment is verified
                                        </button>
                            </Link>      
             
                        </div>
           
                
                </div>
            </div>
            </div>
              )
            })
          }
              </div>
              </div>
              :

              plan != 'basic' & plan != 'premium'  ?
              <div class="h-screen  flex items-center">
              <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                      <div class="w-full lg:w-1/2 mx-8">
                          <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
                      <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
                          Sorry we couldn't find the page you're looking for
                      </p>
                      <Link to='/'>
                      <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>
          
                      </Link>
              </div>
                  <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
                  <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
                  </div>
              
              </div>
          </div>
              :
              length != 'month' & length != '3months' & length != 'annual'?
              <div class="h-screen  flex items-center">
              <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                      <div class="w-full lg:w-1/2 mx-8">
                          <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
                      <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
                          Sorry we couldn't find the page you're looking for
                      </p>
                      <Link to='/'>
                      <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>
          
                      </Link>
              </div>
                  <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
                  <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
                  </div>
              
              </div>
          </div>
          :
              <div>
              {
                isloading == true ?
            <div>
                  <div id='desktop' className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                <div class="relative">
                <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>
            <div class="w-full bg-neutral-200 mt-3">
      <div
        class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
        style={{ width: progress + '%' }}
      >
        {Math.round(progress)} %
      </div>
    </div>
    <p className='fjalla mt-7'>Uploading</p>

                  </div>
                  <div id='mobile' className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                <div class="relative">
                <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>
            <div class="w-full bg-neutral-200 mt-3">
      <div
        class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
        style={{ width: progress + '%' }}
      >
        {Math.round(progress)} %
      </div>
    </div>
    <p className='fjalla mt-7'>Uploading</p>

                  </div>
                  <div id='tablet' className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                <div class="relative">
                <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>
            <div class="w-full bg-neutral-200 mt-3">
      <div
        class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
        style={{ width: progress + '%' }}
      >
        {Math.round(progress)} %
      </div>
    </div>
    <p className='fjalla mt-7'>Uploading</p>

                  </div>
                  
            </div>
                  :
               
                  state == 'Approved' ?
                  navigate('/dashboard') 
                  :
                  <div>
                  <div id='desktop' class=" ">
                     
                  <div class="px-5">
                    
                      <div class="mb-2 flex w-full justify-between">
                     
                      <h1 class=" font-bold text-gray-900 anton ml-5 mt-3" style={{fontSize:'30px'}}>Checkout for kesht Basic</h1>
                      </div>
                      <div class="mb-5 text-gray-400 flex ">
                      </div>
                  </div>
                  <p className='fjalla text-green-800 w-full bg-green-50 py-2 text-center' style={{fontSize:'11px'}}>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
                  {
              error == "error" &&
              <div id="alert-border-2" class="flex w-full text-left items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  " role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <p class=" Mooli mr-1" style={{fontSize:'11px'}}>
                   Please make sure all fields are filled out correctly
             
              </p>
             
             </div>
             
     
            }
         
                  <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                      <div class="w-full">
                          <div class="flex justify-between items-center w-full">
                              <div class="px-3  " style={{width:'50%'}}>
                                  <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                      <div class="w-full flex items-center">
                                          <div class="flex items-center justify-center overflow-hidden rounded-lg w-16 h-16 bg-gray-50 border border-gray-200">
                                              <img src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt=""/>
                                          </div>
                                          <div class="flex-grow pl-3">
                                              <h6 class="fjalla uppercase text-gray-800" style={{fontSize:'13px'}}>kesht  {plan} Plan {length == 'month' ? 'Monthly Subcription Fee' : length == '3months' ? '3 Months Subcription Fee' : 'Annual Subcription Fee' }</h6>
                                          </div>
                                          <div>
                                          <span class="anton" style={{fontSize:"20px"}}>
                                          {plan == 'basic' && length == 'month' && accountType == 'Teacher' && '3,000 '}
                                              {plan == 'basic' && length == '3months' && accountType == 'Teacher' && '7,650 '}
                                              {plan == 'basic' && length == 'annual' && accountType == 'Teacher' && '30,600 '}
                                              {plan == 'premium' && length == 'month' && accountType == 'Teacher' && '8,000 '}
                                              {plan == 'premium' && length == '3months' && accountType == 'Teacher' && '20,400 '}
                                              {plan == 'premium' && length == 'annual' && accountType == 'Teacher' && '81,600 '}
                                            
                                          
                                            
                                            ETB</span>
                                          </div>
                                      </div>
                                  </div>
                                  {
                                      cbebirr == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                  <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                  </div>
                  <div class="flex flex-col">
                    <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                    </p>
                  </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              
              {
                                      telebirr == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                  <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                  <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                  
                  </div>
                  <div class="flex flex-col">
                    <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                    </p>
                  </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              {
                                      cbe == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                                      <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                            </p>
                          </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              {
                                      boa == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                                      <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                            </p>
                          </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
                      
                         
                                  <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                      <div class="w-full flex items-center">
                                          <div class="flex-grow">
                                              <span class="text-gray-800 fjalla">Total</span>
                                          </div>
                                          <div class="pl-3">
                                              <span class="anton" style={{fontSize:"20px"}}>
                                          {plan == 'basic' && length == 'month' && accountType == 'Teacher' && '3,000 '}
                                              {plan == 'basic' && length == '3months' && accountType == 'Teacher' && '7,650 '}
                                              {plan == 'basic' && length == 'annual' && accountType == 'Teacher' && '30,600 '}
                                              {plan == 'premium' && length == 'month' && accountType == 'Teacher' && '8,000 '}
                                              {plan == 'premium' && length == '3months' && accountType == 'Teacher' && '20,400 '}
                                              {plan == 'premium' && length == 'annual' && accountType == 'Teacher' && '81,600 '}
                                            
                                          
                                            
                                            ETB</span>



                                          </div>
                                      </div>
                                  </div>
                                    {
                                      plan == 'free'?
                                      <Link to='/dashboard'>
                                         <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Start For Free
                                  </button></Link>
                                      :
                                      <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit' style={{fontSize:'13px'}}   className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5  text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">I have transferred the funds
                                  </button>
                                    }
                              </div>
                              
                              <div class="px-3" style={{width:'45%'}}>
              
                          
              
                                                                    {
                                                                      
            plan != 'free' &&

            <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-center text-gray-800 font-light mb-6">

            <div>
            <span style={{fontSize:"30px"}} class="text-center anton">Choose Payment Method</span>

            <div className='flex justify-evenly'>
            <div onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
           
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                         </p>
                       </div>
                     </div>
                               <div onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
           
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                         </p>
                       </div>
                     </div>
                               <div onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
           
                       
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE
                         </p>
                       </div>
                     </div>
           
                     <div onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">BOA
                         </p>
                       </div>
                     </div>
           
            </div>
            </div>

</div>
         
           }
                               
                                  <div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                
              </div>
              <div id='mobile' class="  ">
                     
                     <div class="px-5">
                       
                         <div class=" flex w-full justify-between">
                        
                         <h1 class=" font-bold text-gray-900 anton " style={{fontSize:'8vw',marginBottom:'3vw',paddingLeft:'3vw',paddingTop:'5vw'}}>Checkout for kesht Basic</h1>
                         </div>
                         <div class="mb-5 text-gray-400 flex ">
                         </div>
                     </div>
                     <p className='fjalla text-green-800 w-full bg-green-50  text-center'style={{fontSize:'3vw',padding:'2vw 5vw'}}>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
             
            
                     <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                         <div class="w-full">
                             <div class="">
                                 <div class=" ">
                                     <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                         <div class="w-full flex items-center" style={{marginBottom:'5vw'}}>
                                       
                                             <div class="flex flex-col " style={{width:'80%'}}>
                                                 <h6 class="Mooli uppercase text-gray-600" style={{fontSize:'3.3vw',fontWeight:'800',paddingLeft:'4vw'}}>kesht  {plan} Plan {length == 'month' ? 'Monthly Subcription Fee' : length == '3months' ? '3 Months Subcription Fee' : 'Annual Subcription Fee' }</h6>
                                             
                                             
                                             </div>
                                             
                                             <div>
                                              
                                             <span class="anton" style={{fontSize:"4vw"}}>
                                             {plan == 'basic' && length == 'month' && accountType == 'Teacher' && '1,000 '}
                                                 {plan == 'basic' && length == '3months' && accountType == 'Teacher' && '2,715 '}
                                                 {plan == 'basic' && length == 'annual' && accountType == 'Teacher' && '7,980 '}
                                                 {plan == 'premium' && length == 'month' && accountType == 'Teacher' && '5,000 '}
                                                 {plan == 'premium' && length == '3months' && accountType == 'Teacher' && '13,600 '}
                                                 {plan == 'premium' && length == 'annual' && accountType == 'Teacher' && '39,900 '}
                                               
                                             
                                               
                                               ETB</span>
                                             </div>
                                         </div>
                                                            
                 
                                         {
                                                                         
                                                                         plan != 'free' &&
                                                             
                                                                         <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-center text-gray-800 font-light mb-6">
                                                             
                                                                         <div>
                                                                         <span style={{fontSize:"6vw",marginBottom:'5vw'}} class="text-center anton">Choose Payment Method</span>
                                                             
                                                                         <div className='flex justify-evenly' style={{marginTop:'5vw'}}>
                                                                         <div onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    <div class=" rounded-md  border-blue-gray-50 "style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p style={{fontSize:'3vw'}}class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                                            <div onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    <div class=" rounded-md  border-blue-gray-50 "style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p style={{fontSize:'3vw'}}class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                                            <div onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    
                                                                                    <div class=" rounded-md  border-blue-gray-50 " style={{height:'17vw'}}> 
                                                                                      <img style={{width:'80%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize" style={{fontSize:'3vw'}}>CBE
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                        
                                                                                  <div onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                                    <div class=" rounded-md  border-blue-gray-50 " style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize" style={{fontSize:'3vw'}}>BOA
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                        
                                                                         </div>
                                                                         </div>
                                                             
                                                             </div>
                                                                      
                                                                        }
                                     </div>
                                     {
                                         cbebirr == true ?
                                         <div>
                                                 <div class="flex items-center gap-3 mb-2">
                             <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                               </p>
                             </div>
                           </div>
                                          <p className='fjalla ' style={{fontSize:'3vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                  
                 <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

           <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black   text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
              <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

           <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
              <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
                 
               
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
          
               <button    onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
    Upload Screenshot

  </button>
   
       
              <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 
                 {
                                         telebirr == true ?
                           
                                               <div>
                                               <div class="flex items-center gap-3 mb-2">
                           <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                             <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                           </div>
                           <div class="flex flex-col">
                             <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                            
                             </p>
                           </div>
                         </div>
                                        <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                
               <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
         
         <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>
      
         <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
               focus:border-black   text-gray-700 block bg-white
               border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
            <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      Account Holder Can't be Empty
      </div>
      </div>
      </div> 
      </div> 
      <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
         
         <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>
      
         <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
               focus:border-black w-full  text-gray-700 block bg-white
               border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
            <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      Account Holder's Account Can't be Empty
      </div>
      </div>
      </div> 
      </div> 
               
             
      
            <div className='flex items-center'>
       <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
      
        
             <button    onClick={() => {
                 document.getElementById('hidden-input-img').click();
               }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
      Upload Screenshot
      
      </button>
      
      
            <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>
      
              
            </div>
            {
             fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>
      
            }
      
      <div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      This Field Can't be Empty
      </div>
      </div>
      </div>
                                       </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         cbe == true ?
                                         <div>
                                         <div class="flex items-center gap-3 mb-2">
                     <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                       <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                     </div>
                     <div class="flex flex-col">
                       <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                      
                       </p>
                     </div>
                   </div>
                                  <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
          
         <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

   <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black   text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
      <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

   <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
      <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
         
       

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

  
       <button    onClick={() => {
           document.getElementById('hidden-input-img').click();
         }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
Upload Screenshot

</button>


      <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>

      }

<div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                 </div>
                                 
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         boa == true ?

                                         <div>
                                         <div class="flex items-center gap-3 mb-2">
                     <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                       <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                     </div>
                     <div class="flex flex-col">
                       <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                      
                       </p>
                     </div>
                   </div>
                                  <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
          
         <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

   <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black   text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
      <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

   <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
      <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
         
       

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

  
       <button    onClick={() => {
           document.getElementById('hidden-input-img').click();
         }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
Upload Screenshot

</button>


      <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>

      }

<div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                 </div>
                                      
                                         :
                                         <div>
                 
                                         </div>
                                     }
                         
                            
                                     <div class=" " style={{margin:'10vw 0'}}>
                                         <div class="w-full flex items-center">
                                             <div class="flex-grow">
                                                 <span class="text-gray-800 fjalla" style={{fontSize:'4vw'}}>Total</span>
                                             </div>
                                             <div class="pl-3">
                                                 <span class="font-semibold text-gray-400 text-sm"></span> <span class="anton" style={{fontSize:"4.5vw"}}>{plan == 'free' && '0 '}
                                                 {plan == 'basic' && length == 'month' && accountType == 'Teacher' && '1,000 '}
                                                 {plan == 'basic' && length == '3months' && accountType == 'Teacher' && '2,715 '}
                                                 {plan == 'basic' && length == 'annual' && accountType == 'Teacher' && '7,980 '}
                                                 {plan == 'premium' && length == 'month' && accountType == 'Teacher' && '5,000 '}
                                                 {plan == 'premium' && length == '3months' && accountType == 'Teacher' && '13,600 '}
                                                 {plan == 'premium' && length == 'annual' && accountType == 'Teacher' && '39,900 '}
   
   
   
                                               ETB</span>
                                             </div>
                                         </div>
                                     </div>
                                       {
                                         plan == 'free'?
                                         <Link to='/dashboard'>
                                            <button type="submit" style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "90%", height: "15vw" }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
                                        Start For Free
                                      </button></Link>
                                         :
                                   
                                      <div class="flex justify-center">
                                      <button onClick={()=>handlePaidDesktop(checkoutId)}type="submit" style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "90%", height: "15vw" }} class="fjalla w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
                                        I have transferred the funds
                                      </button>
                                    </div>
                                       }
                                 </div>
                                 
                                 <div class="px-3 md:w-5/12">
                 
          
                                  
                                     <div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                   
                 </div>
                 <div id='tablet' class=" ">
                     
                     <div class="px-5">
                       
                         <div class="mb-2 flex w-full justify-between">
                        
                         <h1 class=" font-bold text-gray-900 anton text-center w-full mt-10">Checkout for kesht Basic</h1>
                         </div>
                         <div class=" text-gray-400 flex ">
                         </div>
                     </div>
                     <p className='fjalla text-green-800 w-full bg-green-50 py-2 text-center px-5'>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
                     
            
                     <div class="w-full bg-white border-t  border-gray-200 px-5 py-10 text-gray-800">
                         <div class="w-full">
                             <div class="">
                                 <div class="">
                                     <div class="w-full mx-auto text-gray-800 font-light mb-6  border-gray-200 pb-6">
                                         <div class="w-full flex items-center mb-10">
                                             <div class="flex items-center justify-center overflow-hidden rounded-lg w-16 h-16 bg-gray-50 border border-gray-200">
                                                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/kesht%20(5).png?alt=media&token=7d713581-1de4-4745-9686-2a8f504d36d0" alt=""/>
                                             </div>
                                             <div class="flex-grow pl-3 w-full ">
                                                 <h6 class="font-semibold uppercase text-gray-600" style={{width:'70%'}}>kesht  {plan} Plan {length == 'month' ? 'Monthly Subcription Fee' : length == '3months' ? '3 Months Subcription Fee' : 'Annual Subcription Fee' }</h6>
                                             </div>
                                             <div style={{width:'50%'}} className='flex '>
                                             <span class="anton w-full text-right " style={{fontSize:"20px"}}>
                                             {plan == 'basic' && length == 'month' && accountType == 'Teacher' && '1,000 '}
                                                 {plan == 'basic' && length == '3months' && accountType == 'Teacher' && '2,715 '}
                                                 {plan == 'basic' && length == 'annual' && accountType == 'Teacher' && '7,980 '}
                                                 {plan == 'premium' && length == 'month' && accountType == 'Teacher' && '5,000 '}
                                                 {plan == 'premium' && length == '3months' && accountType == 'Teacher' && '13,600 '}
                                                 {plan == 'premium' && length == 'annual' && accountType == 'Teacher' && '39,900 '}
                                               
                                             
                                               
                                               ETB</span>
                                             </div>
                                         </div>
                                         <span style={{fontSize:"30px"}} class="text-center anton">Choose Payment Method</span>
   
   <div className='flex justify-evenly'>
   <div onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
  
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                </p>
              </div>
            </div>
                      <div onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
  
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                </p>
              </div>
            </div>
                      <div onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
  
              
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE
                </p>
              </div>
            </div>
  
            <div onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">BOA
                </p>
              </div>
            </div>
  
   </div>
                                     </div>
                                     {
                                         cbebirr == true ?
                                         <div>
                                                 <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                               </p>
                             </div>
                           </div>
                                          <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 
                 {
                                         telebirr == true ?
                                         <div>
                                             <div >
                                                    <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                               </p>
                             </div>
                           </div>
                                          <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                                     
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                 
                 
                                           
                 
                 
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                                             </div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         cbe == true ?
                                         <div>
                                                                   <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                               </p>
                             </div>
                             
                           </div>
                           <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                 
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                 
                 
                                            
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 
                 </div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         boa == true ?
                                         <div>
                                                                                     <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                               </p>
                             </div>
                             
                           </div>
                           <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                 
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                                           
                                              
                 
                                            
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div></div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                         
                            
                                     <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                         <div class="w-full flex items-center">
                                             <div class="flex-grow">
                                                 <span class="text-gray-800 fjalla">Total</span>
                                             </div>
                                             <div class="pl-3">
                                                 <span class="font-semibold text-gray-400 text-sm"></span> <span class="anton" style={{fontSize:"25px"}}>{plan == 'free' && '0 '}
                                                 {plan == 'basic' && length == 'month' && accountType == 'Teacher' && '1,000 '}
                                                 {plan == 'basic' && length == '3months' && accountType == 'Teacher' && '2,715 '}
                                                 {plan == 'basic' && length == 'annual' && accountType == 'Teacher' && '7,980 '}
                                                 {plan == 'premium' && length == 'month' && accountType == 'Teacher' && '5,000 '}
                                                 {plan == 'premium' && length == '3months' && accountType == 'Teacher' && '13,600 '}
                                                 {plan == 'premium' && length == 'annual' && accountType == 'Teacher' && '39,900 '}
   
   
   
                                               ETB</span>
                                             </div>
                                         </div>
                                     </div>
                                       {
                                         plan == 'free'?
                                         <Link to='/dashboard'>
                                            <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Start For Free
                                     </button></Link>
                                         :
                                         <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">I have transferred the funds
                                     </button>
                                       }
                                 </div>
                                 
                                 <div class="">
                 
                                 
                 
                                                                       {
                                                                         
               plan != 'free' &&
   
               <div class="w-full mx-auto rounded-lg bg-white  border-gray-200 p-3 text-center text-gray-800 font-light mb-6">
   
               <div>
       
               </div>
   
   </div>
            
              }
                                  
                                     <div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                   
                 </div>
              </div>

                  
               }
            </div>
            
            }
          </div>

        
      
          
          
   



</body>
      
         

</div>
            </div>

            :
         <div>
         <div id='desktop' class="h-screen  flex items-center">
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
    <div class="w-full lg:w-1/2 mx-8">
        <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
    <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
        Sorry we couldn't find the page you're looking for
    </p>
    <Link to='/'>
    <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

    </Link>
</div>
<div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
<img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
</div>

</div>
</div>
<div id='tablet' class="h-screen  flex items-center">
<div class=" flex  px-5 text-gray-700">
    <div class="w-full  mx-8" style={{width:'70%'}}>
        <div class="text-7xl text-gray-800 font-dark font-extrabold mb-8"style={{fontSize:'8vw',lineHeight:'9vw'}}> 404</div>
    <p class="text-2xl md:text-3xl font-light leading-normal mb-8" style={{fontSize:'4vw'}}>
        Sorry we couldn't find the page you're looking for
    </p>
    <Link to='/'>
    <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900 fjalla">Back to homepage</a>

    </Link>
</div>
<div class="w-full lg:flex lg:justify-end  mx-5 my-12" style={{width:'40%'}}>
<img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
</div>

</div>
</div>
<div  id="mobile" class="h-screen  flex items-center">
<div class=" flex flex-col  px-5 text-gray-700">
    <div class="w-full  mx-auto" style={{width:'90%'}}>

        <div class="text-7xl text-gray-800 font-dark font-extrabold mb-8"style={{fontSize:'8vw',lineHeight:'9vw'}}> 404</div>

    <p class="text-2xl md:text-3xl font-light leading-normal mb-8" style={{fontSize:'5vw'}}>
        Sorry we couldn't find the page you're looking for
    </p>
    <Link to='/'
    className="flex items-center justify-center text-center text-white bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm  mr-2 mb-2 " style={{width:'33.33%',fontSize:'2.3vw',height:'7vw'}}
>
   <button type="button"
  
   
>Back to Dashboard</button>
    </Link>
</div>


</div>
</div>
         </div>
          )
        })
       }

    </div>
  )
}
