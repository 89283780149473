import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    function popTerms(){
        const terms = document.getElementById("default-modal_main")
      
            if (terms.style.display == "none"){
            terms.style.display = "block"
          }
          else {
            terms.style.display = "none"
          }
        }
        function popTermsRemove(){
            const terms = document.getElementById("default-modal_main")
          
            terms.style.display = "none"
          
          
          }
      
   
  return (
    <div>
      <div id='desktop'>
           <footer aria-label="Site Footer" class="bg-white  mt-52">
           
        {/* <!-- This is an example component --> */}
<div class=" mx-auto relative">



</div>

<script src="https://unpkg.com/flowbite@1.4.4/dist/flowbite.js"></script>
          
        <div class="" >
          <div class="">
          <div  >
<div class="flex flex-col" style={{width:"90%",margin:"auto"}}>
        <div>

        </div>
      
            <div
              class=" "
            >
             
      
          
              </div>
              </div>
      
            
      
          
<div className="flex justify-evenly w-full ">
  
<div class="col-span-2 sm:col-span-1">
                <p style={{fontSize:'15px'}} class="fjalla text-gray-900">Teacher</p>
      
                <nav aria-label="Footer Navigation - Services" class="mt-2">
                  <ul class=" text-sm">
                    <li>
                   
                      <Link to="/howtopost">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel">
  How to create courses

</p>
</Link>
                    </li>
      
                    <li>
                  
                      <Link to="/pricing/teacher">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel mt-1">

 Pricing Plan

 </p>

</Link>
                    </li>
      
                    <li>
                  <Link to="/signup/teacher">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel mt-1">

                        Become a teacher
 </p>

                      </Link>

                    </li>
      
                 
            
                 
                  </ul>
                </nav>
              </div>

              <div class="col-span-2 sm:col-span-1">
                <p style={{fontSize:'15px'}} class="fjalla text-gray-900">Students</p>
      
                <nav aria-label="Footer Navigation - Services" class="mt-2">
                  <ul class=" text-sm">
                    <li>
                    <Link to="/howtoenroll">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel">
  How to enroll in a course

</p>
</Link>
                    </li>
      
                    <li>
                  
                    <Link to="/courses">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel my-1">
  Course List

</p>
</Link>
                    </li>
      
                    <li>
                    <Link to="/signup/student">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel my-1">
  Become a student

</p>
</Link>

                    </li>
      
                 
           
                 
                  </ul>
                </nav>
              </div>
              <div class="col-span-2 sm:col-span-1">
                <p style={{fontSize:'15px'}} class="fjalla text-gray-900">Institutes</p>
      
                <nav aria-label="Footer Navigation - Services" class="mt-2">
                  <ul class=" text-sm">
                    <li>
                    <Link to="/how-to-create-courses">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel">
How to create courses

</p>
</Link>
                    </li>
      
                    <li>
                  
                    <Link to="/courses">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel my-1">
  Course List

</p>
</Link>
                    </li>
      
                    <li>
                    <Link to="/signup/student">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel my-1">
  Become a student

</p>
</Link>

                    </li>
      
                 
           
                 
                  </ul>
                </nav>
              </div>
              <div class="">
                <p style={{fontSize:'15px'}} class="fjalla text-gray-900">Kesht Academy</p>
      
                <nav aria-label="Footer Navigation - Services" class="mt-2">
                  <ul class=" text-sm">
                    <li>
                    <Link to="/aboutus">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel">
About Us

</p>
</Link>
                    </li>
      
                    <li>
                  
                    <Link to="/careers">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel my-1">
  Careers

</p>
</Link>
                    </li>
      
                    <li>
                    <Link to="/contact">

<p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel my-1">
 Contact Us

</p>
</Link>

                    </li>
      
                 
           
                 
                  </ul>
                </nav>
              </div>
      
            
      
              
      
              <div class="col-span-2 sm:col-span-1">
                <p style={{fontSize:'15px'}} class="fjalla text-gray-900">Location</p>
      
                <nav aria-label="Footer Navigation - Downloads" class="mt-2">
                 
                      <p style={{fontSize:'11px'}}class="text-gray-500 transition hover:opacity-75 borel">
                        Bole Rwand <br />
                        Addis Ababa, Ethiopia
                      </p>
      
      
                </nav>
              </div>
</div>
      
              <ul
                class="flex justify-center col-span-2 gap-6 lg:col-span-5  mt-12"
              >
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61557154706789&mibextid=ZbWKwL"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">Facebook</span>
      
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a
                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">Instagram</span>
      
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a
                    href="https://twitter.com/Keshtplatform"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">Twitter</span>
      


      
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a
                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">linkedin</span>
      

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-6 h-6 bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
</svg>

                 
                  </a>
                </li>
      

              </ul>
            </div>
          </div>
      
          <div class="pt-8 my-8  border-gray-100 px-10">
            <div class="flex justify-between w-full">
              <p class="text-xs text-left text-gray-500" style={{fontSize:'11px'}}>
                &copy; 2024. Kesht. All rights reserved.
              </p>
      
              <nav aria-label="Footer Navigation - Support">
                <ul class="flex flex-wrap justify-start gap-4 text-xs lg:justify-end">
                  <li >
                   
                    <a style={{fontSize:'11px'}} onClick={popTerms} href="#termsAndConditions" class="text-gray-500 transition hover:opacity-75" >
                    Terms & Conditions
                    </a>
                  </li>
      
      
                  <li>
                    <a style={{fontSize:'11px'}} href="#cookies" class="text-gray-500 transition hover:opacity-75" onClick={popTerms}>
                      Cookies
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer></div>
      
      
      <div id='tablet' style={{marginTop:'80px'}}>
           <footer aria-label="Site Footer" class="bg-white  pt-12">
           
        {/* <!-- This is an example component --> */}
<div class=" mx-auto relative">



</div>

<script src="https://unpkg.com/flowbite@1.4.4/dist/flowbite.js"></script>
          
        <div class="" >
          <div class="">
          <div  >
<div class="flex flex-col" style={{width:"90%",margin:"auto"}}>
        <div>

        </div>
      
            <div
              class=" "
            >
             
      
          
              </div>
              </div>
      
            
      
          
<div className="flex justify-evenly w-full ">
  
<div class="col-span-2 sm:col-span-1" style={{marginLeft:'2vw'}}>
                <p class="home_description-header  text-gray-900 poppins" style={{fontSize:'2.3vw'}}>Teacher</p>
      
                  <ul class=" text-sm" style={{marginTop:'1.5vw'}}>
                    <li style={{marginBottom:'1vw'}}>
                   
                    <Link to="/howtopost">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  How to create courses

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                  
                
                    <Link to="/pricing/teacher">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Pricing Plan

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                     
                    <Link to="/signup/teacher">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Become a teacher

</p>
</Link>

                    </li>
      
                 
            
                 
                  </ul>
              </div>

              <div class="col-span-2 sm:col-span-1" style={{marginLeft:'2vw'}}>
                <p class="home_description-header  text-gray-900 poppins" style={{fontSize:'2.3vw'}}>Students</p>
                <ul class=" text-sm" style={{marginTop:'1.5vw'}}>
                    <li style={{marginBottom:'1vw'}}>
                   
                    <Link to="/howtoenroll">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  How to enroll in a course

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                  
                
                    <Link to="/courses">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Course List

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                     
                    <Link to="/signup/student">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Become a student

</p>
</Link>

                    </li>
      
                 
            
                 
                  </ul>
              </div>
              <div class="col-span-2 sm:col-span-1" style={{marginLeft:'2vw'}}>
                <p class="home_description-header  text-gray-900 poppins" style={{fontSize:'2.3vw'}}>Institutes</p>
      
                <ul class=" text-sm" style={{marginTop:'1.5vw'}}>
                    <li style={{marginBottom:'1vw'}}>
                   
                    <Link to="/howtoenroll">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  How to enroll in a course

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                  
                
                    <Link to="/courses">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Course List

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                     
                    <Link to="/signup/student">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Become a student

</p>
</Link>

                    </li>
      
                 
            
                 
                  </ul>
              </div>
              <div class="col-span-2 sm:col-span-1" style={{marginLeft:'2vw'}}>
                <p class="home_description-header  text-gray-900 poppins" style={{fontSize:'2.3vw'}}>Kesht</p>
      
                <ul class=" text-sm" style={{marginTop:'1.5vw'}}>
                    <li style={{marginBottom:'1vw'}}>
                   
                    <Link to="/aboutus">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
 About Us

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                  
                
                    <Link to="/contact">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Contact Us

</p>
</Link>
                    </li>
      
                    <li style={{marginBottom:'1vw'}}>
                     
                    <Link to="/careers">

<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
  Careers

</p>
</Link>

                    </li>
      
                 
            
                 
                  </ul>
              </div>
      
            
      
              
      
              <div class="col-span-2 sm:col-span-1" style={{marginLeft:'2vw'}}>
                <p class="home_description-header  " style={{fontSize:'2.3vw',marginBottom:'2vw'}}>Location</p>
      
<p class="text-gray-800 transition hover:opacity-75 borel" style={{fontSize:'1.5vw'}}>
                 
                        Bole Rwand <br />
                        Addis Ababa, Ethiopia
                      </p>
      
      
              </div>
</div>
      
              <ul
                class="flex justify-center col-span-2 gap-6 lg:col-span-5  mt-12"
              >
                <li>
                  <a
                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">Facebook</span>
      
                    <svg
                      class=""
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      style={{width:'3vw'}}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                        
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a
                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">Instagram</span>
      
                    <svg
                    style={{width:'3vw'}}
                      class=""
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a
                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">Twitter</span>
      


      
                    <svg
                      class=""
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      style={{width:'3vw'}}
                      aria-hidden="true"
                    >
                      <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a
                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">linkedin</span>
      

                    <svg 
                      style={{width:'3vw'}}
                      xmlns="http://www.w3.org/2000/svg"  fill="currentColor"  class=" bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
</svg>

                 
                  </a>
                </li>
      

              </ul>
            </div>
          </div>
      
          <div class="pt-8 my-8  border-gray-100 px-10">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
              <p style={{fontSize:'1.7vw'}} class="text-xs text-left text-gray-500">
                &copy; 2024 Kesht. All rights reserved.
              </p>
      
              {/* <nav aria-label="Footer Navigation - Support">
                <ul class="flex flex-wrap justify-start gap-4 text-xs lg:justify-end">
                  <li >
                   
                    <a onClick={popTerms} href="#termsAndConditions" class="text-gray-500 transition hover:opacity-75" >
                    Terms & Conditions
                    </a>
                  </li>
      
      
                  <li>
                    <a href="#cookies" class="text-gray-500 transition hover:opacity-75" onClick={popTerms}>
                      Cookies
                    </a>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
        </div>
      </footer></div>

      <div id='mobile' className='bg-blue-800'>
      <footer aria-label="Site " class="mobile-Footer w-full">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="lg:flex lg:items-start lg:gap-8">

      
            <div
              class="" 
            >
     
{/*       
              <div class="col-span-2 lg:col-span-3 lg:flex lg:items-end">
                <form class="w-full">
                  <label for="UserEmail" class="sr-only"> Email </label>
      
                  <div
                    class="p-2 border border-gray-100 focus-within:ring sm:flex sm:items-center sm:gap-4"
                  >
                    <input
                      type="email"
                      id="UserEmail"
                      placeholder="john@rhcp.com"
                      class="w-full border-none focus:ransparent focus:ring-transparent sm:text-sm"
                    />
      
                    <button
                     style={{backgroundColor:" #4c00ff"}} class="w-full px-6 py-3 mt-1 text-sm font-bold tracking-wide text-white uppercase transition-none bg-teal-500 hover:black sm:mt-0 sm:w-auto sm:flex-shrink-0"
                    >
                     Subscribe
                    </button>
                  </div>
                </form>
              </div> */}
      
        <div class="col-span-2 sm:col-span-1 " style={{marginLeft:"5vw",marginBottom:"10vw"}}>
                <p class="text-base text-white footer_header anton" style={{fontSize:"3.5vw",marginBottom:"1vw"}}>Teacher</p>
                
      
                <nav aria-label="Footer Navigation - Services" class="mt-3">
                  <ul class="space-y-2 text-sm">
                  <li>
                   
                   <Link to="/howtopost">

<div style={{fontSize:"3vw",lineHeight:"3vw"}}class="borel text-gray-50 transition  hover:opacity-75">
How to create courses

</div>
</Link>
                 </li>
   
                 <li>
               
                   <Link to="/pricing/teacher">

<p style={{fontSize:"3vw",lineHeight:"3vw"}} class="borel hover:bg-slate-600 text-gray-50 transition  hover:opacity-75">
Pricing Plan


</p>
</Link>
                 </li>
                 <li>
                  <Link to="/signup/teacher">

                      <div style={{fontSize:"3vw",lineHeight:"3vw"}} class="borel text-gray-50 transition  hover:opacity-75">
                      Become a teacher
                      </div>
                      </Link>

                    </li>
      
                 
             
      
            
                  </ul>
                </nav>
              </div>
      
              <div class=" " style={{marginLeft:"5vw",marginBottom:"10vw"}}>
                <p class="text-base text-white footer_header anton" style={{fontSize:"3.5vw",marginBottom:"1vw"}}>Company</p>
      
                <nav aria-label="Footer Navigation - Company" class="mt-3">
                  <ul class="space-y-2 text-sm">
              <Link to="/howtoenroll">
              <li className='mb-0'>
                      <div style={{fontSize:"3vw",lineHeight:"3vw"}}href="#" class="borel text-gray-50 transition  hover:opacity-75 ">
                      How to enroll in a course
                      </div>
                    </li>
              </Link>
      
                    <li>
                   
                      <Link to="/courses">
                     
                      <div style={{fontSize:"3vw",lineHeight:"3vw"}} href="#" class="borel text-gray-50 transition  hover:opacity-75">
                      Course List
                        
                        </div>
                      </Link>
                    </li>
      
                    <li>
                      <Link to="/signup/student">
                      <div style={{fontSize:"3vw",lineHeight:"3vw"}} href="#" class="borel text-gray-50 transition  hover:opacity-75">
                        Become a Student
                      </div>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-span-2 sm:col-span-1 " style={{marginLeft:"5vw",marginBottom:"10vw"}}>
                <p class="text-base text-white footer_header anton" style={{fontSize:"3.5vw",marginBottom:"1vw"}}>Institutes</p>
                
      
                <nav aria-label="Footer Navigation - Services" class="mt-3">
                  <ul class="space-y-2 text-sm">
                  <li>
                   
                   <Link to="/how-to-create-courses">

<div style={{fontSize:"3vw",lineHeight:"3vw"}}class="borel text-gray-50 transition  hover:opacity-75">
How to create courses

</div>
</Link>
                 </li>
   
                 <li>
               
                   <Link to="/pricing/institute">

<p style={{fontSize:"3vw",lineHeight:"3vw"}} class="borel hover:bg-slate-600 text-gray-50 transition  hover:opacity-75">
Pricing Plan


</p>
</Link>
                 </li>
                 <li>
                  <Link to="/signup/institute">

                      <div style={{fontSize:"3vw",lineHeight:"3vw"}} class="borel text-gray-50 transition  hover:opacity-75">
                      Register your Company
                      </div>
                      </Link>

                    </li>
      
                 
             
      
            
                  </ul>
                </nav>
              </div>
              <div class="col-span-2 sm:col-span-1 " style={{marginLeft:"5vw",marginBottom:"10vw"}}>
                <p class="text-base text-white footer_header anton" style={{fontSize:"3.5vw",marginBottom:"1vw"}}>Kesht Academy</p>
                
      
                <nav aria-label="Footer Navigation - Services" class="mt-3">
                  <ul class="space-y-2 text-sm">
                  <li>
                   
                   <Link to="/aboutus">

<div style={{fontSize:"3vw",lineHeight:"3vw"}}class="borel text-gray-50 transition  hover:opacity-75">
About us

</div>
</Link>
                 </li>
   
                 <li>
               
                   <Link to="/careers">

<p style={{fontSize:"3vw",lineHeight:"3vw"}} class="borel hover:bg-slate-600 text-gray-50 transition  hover:opacity-75">
Careers


</p>
</Link>
                 </li>
                 <li>
                  <Link to="/contact">

                      <div style={{fontSize:"3vw",lineHeight:"3vw"}} class="borel text-gray-50 transition  hover:opacity-75">
                      Contact Us
                      </div>
                      </Link>

                    </li>
      
                 
             
      
            
                  </ul>
                </nav>
              </div>
      
              <div class=" " style={{marginLeft:"5vw",marginBottom:"10vw"}}>

         {/* <div class="col-span-2 sm:col-span-1">
                <p class="text-base text-white footer_header">Helpful Links</p>
      
                <nav aria-label="Footer Navigation - Company" class="mt-3">
                  <ul class="space-y-2 text-sm">
                    <li>
                      <a href="#" class="text-gray-50 text-center transition hover:opacity-75">
                        Contact
                      </a>
                    </li>
      
                    <li>
                      <a href="#" class="text-gray-50 text-center transition hover:opacity-75">
                        FAQs
                      </a>
                    </li>
      
                    <li>
                      <a href="#" class="text-gray-50 text-center transition hover:opacity-75">
                        Live Chat
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
              
      
                <p class="text-base text-white footer_header anton" style={{fontSize:"3.5vw",marginBottom:"1vw"}}>Location</p>
      
                <nav aria-label="Footer Navigation - Legal" class="mt-3">
                  <ul class="space-y-2 text-sm">
              
      
                  <p style={{fontSize:"3vw",lineHeight:"4vw"}} class="borel text-gray-50 transition  m-auto">
                        Bole Rwand <br />
                        Addis Ababa, Ethiopia
                      </p>
                  </ul>
                </nav>
              </div>
      
             
   
             
              
            </div>
          </div>   <div className='footermobilecopy'>
      <ul
                class="flex justify-evenly items-center w-full   "
              >
                
        
                <li>
                  <a
                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-white transition hover:opacity-75"
                  >
                  
                    <span  class="sr-only" style={{fontSize:"2.5vw",lineHeight:"3vw",color:"white"}}>Facebook</span>
      
                    <svg
                  style={{width:"8vw"}}

                      class="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a

                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only" style={{fontSize:"2.5vw",lineHeight:"3vw"}}>Instagram</span>
      
                    <svg
                  style={{width:"8vw"}}

                      class="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a
                  style={{width:"8vw"}}

                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only" style={{fontSize:"2.5vw",lineHeight:"3vw"}}>Twitter</span>
      


      
                    <svg
                    style={{width:"8vw"}}
                      class="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </a>
                </li>
      
                <li>
                  <a

                    href="/"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only" style={{fontSize:"2.5vw",lineHeight:"3vw"}}>linkedin</span>
      

                  
                    <svg  style={{width:"8vw"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-6 h-6 text-white bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
</svg>

                 
                  </a>
                </li>
      

              </ul>
      </div>
      
          <div class="footermobilecopy pt-8 mt-8  border-gray-100">
            <div class=" gap-8 lg:grid-cols-2">
              <p style={{fontSize:"2.5vw",lineHeight:"3vw"}} class="text-xs text-left text-gray-50">
                &copy; 2024 Kesht. All rights reserved.
              </p>
      
             
            </div>
          </div>
        </div>
      </footer>

      </div>
      </div>
  )
}
