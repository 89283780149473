import React, { useEffect, useRef, useState } from 'react'
import Nav from './Nav'
import Footer from './footer';
import { Link } from 'react-router-dom';

export default function HowToPost() {
    const videoRef = useRef(null);
    const [pop,setPop] = useState(false)
      const [showPlayButton, setShowPlayButton] = useState(true);
      const [spinner, setspinner] = useState(true);


      const handlePlay = () => {
        if (videoRef.current) {
          videoRef.current.play();
          setShowPlayButton(false);
       
        }
      };
      useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }, []);

      function tabletNavPop(){
        if(pop == true){
          setPop(false)
        }
        else{
          setPop(true)
        }
      }

      function tabletNavPopMinimize(){
        if(pop == true){
          setPop(false)
        }
        else{
          setPop(true)
        }
      }

      function desktopSpinnerHandler(){
        setspinner(false)
      }

  return (
    <div>
       {
    spinner == true &&
    <div id='spinnerDesktop'>
      <div
  class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
  role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>

      </div>
  }
            <div id='desktop'>
                <Nav/>
           <h1 className='anton pb-10  capitalize  mt-10 mx-auto'  style={{lineHeight:"50px",width:'85%',fontSize:'40px'}}>Steps to creating your own course and making a decent income </h1>

                <div className="video-player-container mx-auto mb-20" style={{width:'85%'}}>
       
      <video ref={videoRef} className="video-player" poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/How%20to%20create%20(1).png?alt=media&token=939553df-3538-4b66-944d-0ec616a1697b'>
        <source type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
    <Footer/>
            </div>
            <div id='tablet'>

            <nav className='flex justify-between navTablet w-full  pt-5'>

           
<Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>
<Link to="/courses">

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"25px"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</Link>

    <svg onClick={tabletNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
    {
        pop == true &&
        <div id='popoutTabletHome'>
        <nav className='flex justify-between navTablet py-5'>
        <Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>
    
                <div className='flex justify-end'style={{width:'30%'}} >
                    <Link to="/courses">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg></Link>
                    
                    <svg onClick={tabletNavPop} xmlns="http://www.w3.org/2000/svg"  width="25" height="25" fill="currentColor" className="text-blue-800 menuIcon bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    </div>
        </nav>
        <ul >
            <Link to="/signin">
            <li className='listPopoutTablet border-b border-t' >Sign in</li>
    
             </Link>
             <Link to="/signup/student">
            <li className='listPopoutTablet  border-b' >Sign up</li>
    
    
             </Link>
             <Link to="/aboutus">
            <li className='listPopoutTablet  border-b' >About Us</li>
    
             </Link>
    
             <Link to="/pricing/teacher">
            <li className='listPopoutTablet  border-b' >Pricing</li>
    
             </Link>
    
       
    
        </ul>
      
        
    <Link to='/contact'>
    <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button>
     </Link>   </div>
    }
           <h1 className='anton pl-10  capitalize  mt-10' style={{lineHeight:"6vw",fontSize:'5vw'}}>Steps to creating your own course and making a decent income </h1>

<div className="video-player-container mx-auto mb-20" style={{width:'95%',marginTop:'30px'}}>
      <video ref={videoRef} className="video-player" poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/How%20to%20create%20(1).png?alt=media&token=939553df-3538-4b66-944d-0ec616a1697b'>
        <source type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
    <Footer/>

            </div>
            <div id='mobile'>
            <nav className='flex justify-between navMobile  w-full '>

<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={tabletNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"10vw",marginLeft:"3vw"}}  fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
</nav>
{
pop == true &&
<div id='popoutMobileHome' className='relative '>
<nav className='flex justify-between navMobile '>
<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={tabletNavPopMinimize} xmlns="http://www.w3.org/2000/svg"  style={{width:"5vw",marginLeft:"3vw"}} fill="currentColor" className="text-blue-800  bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
</div>

</nav>
<ul >

<Link to="/signin">
<li className='listPopoutTablet border-b border-t' >Sign in</li>

</Link>
<Link to="/signup/student">
<li className='listPopoutTablet  border-b' >Sign up</li>


</Link>
<Link to="/aboutus">

<li className='listPopoutTablet  border-b' >About Us</li>
</Link>



<Link to="/pricing/teacher">
<li className='listPopoutTablet  border-b' >Pricing</li>
</Link>


</ul>
<Link to='/contact'>
<button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button></Link>
</div>
}
<div style={{height:'80vh'}}>

<h1 className='anton   capitalize  mt-10' style={{lineHeight:"8vw",fontSize:'7vw',width:'95%',paddingLeft:'8%'}}>Steps to creating your own course and making a decent income </h1>

<div className="video-player-container mx-auto mb-20" style={{width:'95%',marginTop:'30px'}}>
<video ref={videoRef} className="video-player" poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/How%20to%20create%20(1).png?alt=media&token=939553df-3538-4b66-944d-0ec616a1697b'>
<source type="video/mp4" />
Your browser does not support the video tag.
</video>
{showPlayButton && 
<div className="play-button-overlay" onClick={handlePlay}>
<svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
<path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
</div>
}
</div>
</div>
<Footer/>

</div>

    </div>
  )
}
