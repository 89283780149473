import { collection, doc, onSnapshot, setDoc,query,where, updateDoc,orderBy, getDoc, serverTimestamp,deleteDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { v4 } from 'uuid';
import DashboardNav from './dashboardNav';

export default function CourseStudents() {
    const [uid,setuid] = useState("")
    const [studentName,setStudentName] = useState("")
    const [addStudent,setaddStudent] = useState(false)
    const [pop,setpop] = useState(false)
    const [isLoading,setisLoading] = useState(false)

    const [notificationPop,setnotificationPop] = useState(false)
    const [notification,setnotification] = useState([])


    const [alreadyExists,setalreadyExists] = useState('')
    const {courseId} = useParams()


    const [course,setCourse] = useState([])
    const [enrolledFor,setenrolledFor] = useState([])

    const [paid,setpaid] = useState('')
    const [status,setstatus] = useState('')



    const [courseError,setCourseError] = useState("")
    const [courseTitle,setCourseTitcourseTitle] = useState()

    const [spinner,setspinner] = useState(false)
    const [success,setsuccess] = useState(false)

    const [found,setfound] = useState('false')
    const [student,setstudent] = useState([])


    const [user,setuser] = useState([])

    const navigate = useNavigate()



    const [studentEmail,setStudentEmail] = useState("")
    const [studentPassword,setStudentPassword] = useState("")
    const [studentConfirmPassword,setStudentConfirmPassword] = useState("")
    const [studentNameError,setStudentNameError] = useState("")
    const [studentEmailError,setStudentEmailError] = useState("")
    const [studentPasswordError,setStudentPasswordError] = useState("")
    const [studentConfirmPasswordError,setStudentConfirmPasswordError] = useState("")
    const [students,setStudents] = useState([])
    const [myCourse,setmyCourse] = useState([])



    function studentNameSet(e){
        setStudentName(e.target.value)

        if(e.target.value.length == 0){
            setStudentNameError("error")
        }

        else{
            setStudentNameError("")
            
        }

    }

    function studentEmailSet(e){
        setStudentEmail(e.target.value)
  

        if(e.target.value.length == 0){
            setStudentEmailError("error")
        }

        else{
         
            setStudentEmailError("")
            
        }

    }
    
  // Profile Photo Click Handler
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
const convertTimestampToDate = (timestamp) => {
if(timestamp){
  const date = timestamp.toDate();
  const currentDate = new Date();
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }

  return relativeTime;
}
};

    
    useEffect(() => {
      const q = query(collection(firestore, "Students"), where("email", "==", studentEmail));
      onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const temp = [];
          snapshot.forEach((doc) => {
            temp.push({ ...doc.data(), id: doc.id });
          });
          setstudent(temp);
          setfound(true);
        } else {
          setfound(false);
          setstudent([]);
        }
      });
    }, [studentEmail]);

    function studentPasswordSet(e){
        setStudentPassword(e.target.value)

        if(e.target.value.length <= 7){
            setStudentPasswordError("error")
        }

        else{
            setStudentPasswordError("")
            
        }

    }

    function studentConfirmPasswordSet(e){
        setStudentConfirmPassword(e.target.value)

        if(e.target.value != studentPassword){
            setStudentConfirmPasswordError("error")
        }

        else{
            setStudentConfirmPasswordError("")
            
        }

    }



  

  

    // Notification Icon Click Handler

    function handleLogOut(){
      signOut(auth).then(
       navigate('/')
      )
     }
    
    
    
   
   
     function desktopSpinnerHandler(){
       setspinner(false)
      }

    useEffect(()=>{
       
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {

          
              const uid = user.uid;


            //  setcred(uid)
          setuid(uid);

          const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

          const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));
      
          onSnapshot(notificationRef,snapshot=>{
            let main =[]
            
        
        
        
            snapshot.docs.forEach(doc=>{
              main.push({...doc.data(),id:doc.id})
             
            setnotification(main)
            })
        
            
            
          
        
          })
             
          const userRef = doc(firestore,"TotalUsers",uid);
  onSnapshot(userRef,snapshot=>{
   
    let main =[]



      main.push({...snapshot.data(),id:snapshot.id})
      
  setuser(main)

    
    
  

  })
        
  
  onSnapshot(collection(firestore, 'AccountDetails', uid, 'Students'), (snapshot) => {
    const temp = [];
  
    snapshot.docs.forEach((docSnapshot) => {

      const studentId = docSnapshot.id;
      setpaid(docSnapshot.data().paid)
      
      setstatus(docSnapshot.data().status)
      setenrolledFor(docSnapshot.data().enrolledFor)

  
      onSnapshot(doc(firestore, 'TotalUsers', studentId), (studentSnapshot) => {
        const studentData = studentSnapshot.data();
        temp.push({ ...studentData,paid:docSnapshot.data().paid });
        setStudents(temp)

        
      })

    })

  });
   


    const snapRef = collection(firestore, 'AccountDetails',uid,"CoursesCreated")
    onSnapshot(snapRef,(snapshot)=>{
        let temp2 = []
            snapshot.docs.forEach(doc=>{
                temp2.push({...doc.data(),id:doc.id})
            })
          
           
       setmyCourse(temp2)


          

  });
        
        
                  
        
               
        
                      
            
        
              
        
             
              
              // ...
            } else {
              console.log("logged out")
              
            }
          });

          return () => {
            unsubscribe();
          };

    },[])


    function handleRemoveAddStudentDesktop(){
      setaddStudent(false)
        setstudent([])
        setalreadyExists('')
          
  
  
      }

      function addStudentPopDesktop(){
        setaddStudent(true)



      }


      async function addStudentDesktop(i) {
        if (courseId.length === 0) {
          setCourseError('error');
        } else {
          const documentRef = doc(firestore, 'AccountDetails', uid, 'Students', i.uid);
    const students = doc(firestore, 'AccountDetails', uid, 'CoursesCreated',courseId, 'Students', i.uid);

          const courseDocument = doc(firestore, 'AccountDetails', uid, 'CoursesCreated', courseId);
          const documentRef2 = doc(firestore, 'AccountDetails', i.uid, 'Enrollments', courseId);
          // Retrieve the document data
          const documentSnap = await getDoc(documentRef);
          const courseSnap = await getDoc(courseDocument);
          if (documentSnap.exists()) {
            let currentArray = documentSnap.data().enrolledFor || [];
      
            // Check if courseId already exists in currentArray
            if (currentArray.includes(courseId)) {
              setalreadyExists('The student has already enrolled in this course');
            } 
            else if (courseSnap.exists() && courseSnap.data().status === 'published') {
              // Push the courseId to currentArray
              currentArray.push(courseId);
              // Update the document with the updated array
              const data = {
                uid: i.uid,
                enrollmentDate: serverTimestamp(),
                paid: 0,
                enrolledFor: currentArray,
                status: 'Active',
                progress:[]
              };
      
              const data2 = {
                uid: i.uid,
                enrollmentDate: serverTimestamp(),
                paid: 0,
                status: 'Active',
                courseId: courseId,
                progress:[]

              };
      
              await setDoc(documentRef, data);
              await setDoc(students, data);

              await setDoc(documentRef2, data2);
              await setDoc(doc(firestore, 'AccountDetails', uid, 'Notification', v4()), {
                notificationTitle: `You enrolled ${studentName} for ${courseTitle}`,
                time: serverTimestamp(),
              });
      
              setsuccess(true);
            } else if(courseSnap.exists() && courseSnap.data().status === 'unpublished'|| courseSnap.data().status === 'pending' ) {
              setalreadyExists('Your course has not been published');
            }
          } else {
            // Document does not exist, create a new document
            const data = {
              fullName: i.fullName,
              email: i.email,
              uid: i.uid,
              enrolledFor: [courseId],
              paid: 0,
              progress:[],
              status: 'Active'


            };
            if (courseSnap.exists() && courseSnap.data().status === 'published') {
              await setDoc(documentRef, data);
              await setDoc(students, data);
  
              setsuccess(true);
      
            } 
            else if(courseSnap.exists() && courseSnap.data().status === 'unpublished'|| courseSnap.data().status === 'pending' ) {

              setalreadyExists('Your course has not been published');
            }
      
          }
        }
      }
      function minimizeSuccessDesktop(){
        setsuccess(false)
        setaddStudent(false)
        setstudent([])
        setalreadyExists('')
      }
      function removeNotification(id){
        const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
    
        setDoc(notificationRef,{
          seen:true
        },{merge:true})
        .then(() => {
          
          setnotification(prevArray => prevArray.filter(item => item.id !== id));
        })
        .catch((error) => {
        });
    
    
       }
      let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
  return (
   user.map(i=>{
    const{accountType,plan,fullName,photo} = i

    return(
      accountType =='Student'?
      navigate('/dashboard')
      :
      <div>
      <div id="desktop">
    
      <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>

         {
          addStudent == true &&
          <div id="addStudentDesktop" >
      
          {
            myCourse.length !=0 ? 
            <div className='bg-white m-auto  rounded my-5' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
       
       
           <div className='flex w-full justify-between items-center'>
        <div style={{paddingLeft:"100px"}}>
        <h1 className='anton text-gray-900 capitalize' style={{fontSize:"40px",paddingTop:"50px"}}>Manually Enroll Students </h1>
        </div>
                  <svg onClick={handleRemoveAddStudentDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
                
     <div className='flex '>
     <div  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"50%"}}>
    
     <div className={`${alreadyExists.length != 0 ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    {alreadyExists}
    </div>
    </div>
    </div>
    <div className="relative my-10">
     <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute fjalla" >Student's Email</p>
     <input onChange={e=>{studentEmailSet(e)}}  style={{color:"black",height:'70px'}} required  autoComplete="off"  placeholder="abebe@gmail.com" type="email" className="border  focus:outline-none
         focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white borel
         border-gray-300 rounded-md "/>
    {
   found ?  student.map(i=>{
        return(
          <div>
<div  className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <a style={{fontSize:'11px'}} target='_blank' href={`/user/${i.uid}`} className="borel hover:underline cursor-pointer">

            {i.fullName}
            </a>
        

          </div>
          </div>
   
    

    

    
    
    
    
    
    
    
    
    
    
    <div className="relative" >
     <button onClick={()=>addStudentDesktop(i)}  style={{fontSize:'14px'}}  className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Enroll Student
     </button>
    </div>
          </div>
        
        )
      })
      :
      studentEmail.length !=0 &&
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='borel' style={{fontSize:'11px'}}>
      A student with that email address could not be found
      </div>
      </div>

    }

  

    
    </div>

    </div>
    <div style={{width:"40%"}}>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Nerd-bro.svg?alt=media&token=e0b57dbe-b74e-41b0-b70b-d65bc541c10c" alt="add student" />
    </div>
      </div>  
              </div>
    
         
           
    
                  :
                                <div className=' flex-col justify-center bg-white m-auto  rounded ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
               <div className='py-5 flex w-full justify-between items-center'>
      <div>
    
      </div>
                  <svg onClick={handleRemoveAddStudentDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
                                <div className='flex items-center flex-col justify-center  w-full text-center anton capitalize py-5 ' style={{fontSize:"20px"}}>
                                Student enrollment is not possible because you have not created a course
      <p className="fjalla text-gray-500 normal-case" style={{fontSize:"15px"}}>In order to manually enroll students, you will need to create a course</p>
      <img  style={{width:"25%"}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA.." alt="no content" />
      <Link to="/create">
      <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Create course</button>
    
    </Link>
    </div> 
    </div>
          }
          </div>
         }
      
           {
            success == true &&
            <div id='successStudentDesktop' > 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            <div class="w-20 h-20 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='fjalla text-center text-gray-900 mt-2' style={{fontSize:"35px"}}>The student has successfully been enrolled in your course</h1>
            </div>
            </div>
           }
           
      
                 <div class=" flex flex-wrap items-start justify-end   p-6 sm:p-10 mr-3">
           <button style={{fontSize:'11px'}} onClick={addStudentPopDesktop}  class="inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                
                  Manually Add Students 
                </button>
              </div>
              {
                  students.length != 0 ?
              <div className=' w-full flex-col'>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400  border mt-5">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 Mooli">
                    Student's Name
                </th>
                <th scope="col" class="px-6 py-3 Mooli">
                Student's Email
                </th>
                <th scope="col" class="px-6 py-3 Mooli">
                    Courses Enrolled
                </th>
              
                <th scope="col" class="px-6 py-3 Mooli">
                    Paid
                </th>
                <th scope="col" class="px-6 py-3 Mooli">
                    Status
                </th>
               
            </tr>
        </thead>
      
      <tbody>
      {students &&
  students.map((i) => {
    const { fullName,  email,paid } = i;

    return (
      <tr key={i.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          className="underline fjalla px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          <Link to={`/course/${uid}`}>
            <span className="hover:text-blue-800 cursor-pointer capitalize">{fullName}</span>
          </Link>
        </th>
        <td className="px-6 py-4 borel" style={{ fontSize: "14px" }}>
          {email}
        </td>
        <td className="px-6 py-4 borel flex flex-col" style={{ fontSize: "14px", wordBreak: "break-all" }}>
          {enrolledFor &&
            enrolledFor.map((course) => (
              <div key={course} className="flex flex-col capitalize">
                {course}
              </div>
            ))}
        </td>
        <td className="px-6 py-4 borel" style={{ fontSize: "14px" }}>
          {paid == 0 ? 'Free' : paid + ' ETB'} 
        </td>
        {
          status == 'Active' ?
          <div class="relative flex items-center justify-center w-1/2 font-sans font-bold uppercase  select-none  text-green-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
          <span class="">Active</span>
        </div>
        :
        status == 'Pending' &&
        <div class="mr-1 relative flex items-center justify-center w-1/2 font-sans font-bold uppercase  select-none  text-red-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
         <span class="">Completed</span>
         
       </div>
     
        }
      </tr>
    );
  })}
      
                
              </tbody>
      </table>
      
               
      
              
      
      </div>   
     :
      <div className='border mb-12 py-5 mx-auto  flex flex-col items-center' style={{width:"90%"}}>
      <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Your students will appear here once they have enrolled in your course.  </h1>
      <p className='borel text-gray-500 text-center'style={{fontSize:"15px"}}>No students are enrolled in your course</p>
     <img style={{width:"50%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/college%20students-bro.svg?alt=media&token=0e3d628f-3d74-41dc-87e5-32e48168dd1f" alt="No students" />
    
    </div>
                 }
      
      </div>
      <div id="tablet">
  <DashboardNav/>
      
         {
          addStudent == true &&
          <div id="addStudentDesktop" >
      
          {
            myCourse.length !=0 ? 
            <div className='bg-white m-auto  rounded my-5' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
       
       
           <div className='flex w-full justify-between items-center'>
        <div style={{paddingLeft:"80px"}}>
        <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>Manually Enroll Students </h1>
        </div>
                  <svg onClick={handleRemoveAddStudentDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
                
     <div className='flex '>
     <div  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"50%"}}>
    
     <div className={`${alreadyExists.length != 0 ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    {alreadyExists}
    </div>
    </div>
    </div>
    <div className="relative my-10">
     <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Student's Email</p>
     <input onChange={e=>{studentEmailSet(e)}}  style={{color:"black"}} required  autoComplete="off"  placeholder="abebe@gmail.com" type="email" className="border  focus:outline-none
         focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
         border-gray-300 rounded-md "/>
    {
   found ?  student.map(i=>{
        return(
          <div>
<div  className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <a target='_blank' href={`/user/${i.uid}`} className="font-bold hover:underline cursor-pointer">

            {i.fullName}
            </a>
        

          </div>
          </div>
   
    

    

    
    
    
    
    
    
    
    
    
    
    <div className="relative" >
     <button onClick={()=>addStudentDesktop(i)}    className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Enroll Student
     </button>
    </div>
          </div>
        
        )
      })
      :
      studentEmail.length !=0 &&
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='borel' style={{fontSize:'1vw'}}>
      A student with that email address could not be found
      </div>
      </div>

    }

  

    
    </div>

    </div>
    <div style={{width:"40%"}}>
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Nerd-bro.svg?alt=media&token=e0b57dbe-b74e-41b0-b70b-d65bc541c10c" alt="add student" />
    </div>
      </div>  
              </div>
    
         
           
    
                  :
                                <div className=' flex-col justify-center bg-white m-auto  rounded ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
               <div className='py-5 flex w-full justify-between items-center'>
      <div>
    
      </div>
                  <svg onClick={handleRemoveAddStudentDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
                                <div className='flex items-center flex-col justify-center  w-full text-center anton capitalize py-5 ' style={{fontSize:"20px"}}>
                                Student enrollment is not possible because you have not created a course
      <p className="fjalla text-gray-500 normal-case" style={{fontSize:"15px"}}>In order to manually enroll students, you will need to create a course</p>
      <img  style={{width:"25%"}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA.." alt="no content" />
      <Link to="/create">
      <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Create course</button>
    
    </Link>
    </div> 
    </div>
          }
          </div>
         }
      
           {
            success == true &&
            <div id='successStudentDesktop' > 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            <div class="w-20 h-20 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='fjalla text-center text-gray-900 mt-2' style={{fontSize:"35px"}}>The student has successfully been enrolled in your course</h1>
            </div>
            </div>
           }
           
      
                 <div class=" flex flex-wrap items-start justify-end   p-6 sm:p-10 mr-3">
           <button style={{fontSize:'11px'}} onClick={addStudentPopDesktop}  class="inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                
                  Manually Add Students 
                </button>
              </div>
              {
                  students.length != 0 ?
              <div className=' w-full flex-col'>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400  border mt-5">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 Mooli">
                    Student's Name
                </th>
                <th scope="col" class="px-6 py-3 Mooli">
                Student's Email
                </th>
                <th scope="col" class="px-6 py-3 Mooli">
                    Courses Enrolled
                </th>
              
                <th scope="col" class="px-6 py-3 Mooli">
                    Paid
                </th>
                <th scope="col" class="px-6 py-3 Mooli">
                    Status
                </th>
               
            </tr>
        </thead>
      
      <tbody>
      {students &&
  students.map((i) => {
    const { fullName,  email } = i;

    return (
      <tr key={i.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          className="underline fjalla px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          <Link to={`/course/${uid}`}>
            <span className="hover:text-blue-800 cursor-pointer capitalize">{fullName}</span>
          </Link>
        </th>
        <td className="px-6 py-4 borel" style={{ fontSize: "14px" }}>
          {email}
        </td>
        <td className="px-6 py-4 borel flex flex-col" style={{ fontSize: "14px", wordBreak: "break-all" }}>
          {enrolledFor &&
            enrolledFor.map((course) => (
              <div key={course} className="flex flex-col capitalize">
                {course}
              </div>
            ))}
        </td>
        <td className="px-6 py-4 borel" style={{ fontSize: "14px" }}>
          {paid == 'Free' ? paid : paid + ' ETB'} 
        </td>
        {
          status == 'Active' ?
          <div class="relative flex items-center justify-center w-1/2 font-sans font-bold uppercase  select-none  text-green-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
          <span class="">Active</span>
        </div>
        :
        status == 'Pending' &&
        <div class="mr-1 relative flex items-center justify-center w-1/2 font-sans font-bold uppercase  select-none  text-red-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
         <span class="">Completed</span>
         
       </div>
     
        }
      </tr>
    );
  })}
      
                
              </tbody>
      </table>
      
               
      
              
      
      </div>   
        :
        <div className='border mb-12 py-5 mx-auto  flex flex-col items-center' style={{width:"90%"}}>
        <h1 className='fjalla text-center text-gray-900 mt-8 px-3' style={{fontSize:"28px"}}>Your students will appear here once they have enrolled in your course.  </h1>
        <p className='borel text-gray-500 text-center'>No students are enrolled in your course</p>
       <img style={{width:"50%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/college%20students-bro.svg?alt=media&token=0e3d628f-3d74-41dc-87e5-32e48168dd1f" alt="No students" />
      
      </div>
                 }
      
      </div>
      <div id='mobile'>
<DashboardNav/>
     {
          addStudent == true &&
          <div id="addStudentDesktop" className='flex justify-center items-center'>
      
          {
            myCourse.length !=0 ? 
            <div className='bg-white m-auto  rounded ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
       
       
           <div className='flex w-full justify-between items-center'>
        <div style={{paddingLeft:"10vw"}}>
        <h1 className='anton text-gray-900 capitalize' style={{fontSize:"6vw",paddingTop:"8vw"}}>Manually Enroll Students </h1>
        </div>
                  <svg onClick={handleRemoveAddStudentDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
                
     <div className='flex '>
     <div  autoComplete="off" className=" mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"70%"}}>
    
     <div className={`${alreadyExists.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'2.5vw'}}>
{alreadyExists}
</div>
</div>
</div> 
    <div className="relative m-auto " style={{width:"100%",marginBottom:"10vw",marginTop:"6vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Student's Email</p>

           <input type='email'  placeholder='abebe@gmail.com' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>{studentEmailSet(e)}} />
           

    {
   found ?  student.map(i=>{
        return(
          <div>
<div  style={{padding:'2vw 2vw'}}  className="flex items-center  text-sm text-green-800 border border-green-300 rounded-lg bg-green-50" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <a style={{fontSize:'3vw'}} target='_blank' href={`/user/${i.uid}`} className="font-bold hover:underline cursor-pointer">

            {i.fullName}
            </a>
        

          </div>
          </div>
   


    

    

    
    
    
    
    
    
    
    
    
    
    <div className="relative" >

     <button onClick={()=>addStudentDesktop(i)} style={{backgroundColor:"#2141ce",fontSize:'3vw',padding:'3vw 5vw' }} className=" w-full inline-block  text-xl font-medium text-center text-white 
                    rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Enroll Student</button>
   
                 
    </div>
          </div>
        
        )
      })
      :
      studentEmail.length !=0 &&
      <div style={{padding:'2vw 2vw'}} className="flex items-center mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='borel' style={{fontSize:'1.8vw'}}>
      A student with that email address could not be found
      </div>
      </div>

    }

  

    
    </div>

    </div>

      </div>  
              </div>
    
         
           
    
                  :
                                <div className=' flex-col justify-center bg-white m-auto  rounded ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
               <div className='py-5 flex w-full justify-between items-center' style={{padding:'0 5vw'}}>
      <div>
    
      </div>
                  <svg style={{width:"8vw"}}onClick={handleRemoveAddStudentDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
                                <div className='flex items-center flex-col justify-center  w-full text-center anton capitalize py-5 ' >
    <h1 style={{fontSize:"5vw"}}>
    Student enrollment is not possible because you have not created a course

    </h1>
      <p className="fjalla text-gray-500 normal-case" style={{fontSize:"3vw",marginTop:'2vw'}}>In order to manually enroll students, you will need to create a course</p>
      <img  style={{width:"25%"}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA.." alt="no content" />
      <Link to="/create">
      <button type='submit' style={{backgroundColor:"#2141ce",padding:'3vw 5vw',fontSize:'3vw' }} className=" w-full inline-block  text-xl font-medium text-center text-white 
                    rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Create course</button></Link>
   
                 
    </div> 
    </div>
          }
          </div>
         }
      
           {
            success == true &&
            <div id='successStudentDesktop' > 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            <div class="w-20 h-20 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='fjalla text-center text-gray-900 mt-2' style={{fontSize:"35px"}}>The student has successfully been enrolled in your course</h1>
            </div>
            </div>
           }
           
      
                 <div class=" flex flex-wrap items-start justify-end   ">
           <button onClick={addStudentPopDesktop}  style={{fontSize:'2.5vw',padding:'2vw 2vw',marginTop:'3vw',marginBottom:'3vw',marginRight:'2vw'}} class="inline-flex  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md ">
                
                  Manually Add Students 
                </button>
              </div>
              {
                 isLoading == true?
                 <div className='flex items-center justify-center'>
                 <div
               class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
               role="status">
               <span
               class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
               >Loading...</span
               >
               </div>
               
                 </div>
                  : 
                  students.length != 0 ?
                 
                  
              <div className=' w-full flex-col'>
      <table class="w-full text-sm text-left text-gray-500   border mt-5">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'1.5vw',padding:'1vw 0'}}>
                    Student's Name
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'1.5vw',padding:'1vw 0'}}>
                Student's Email
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'1.5vw',padding:'1vw 0'}}>
                    Courses Enrolled
                </th>
              
                <th scope="col" class=" fjalla text-center"style={{fontSize:'1.5vw',padding:'1vw 0'}}>
                    Paid
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'1.5vw',padding:'1vw 0'}}>
                    Status
                </th>
               
            </tr>
        </thead>
      
      <tbody>
      {students &&
  students.map((i) => {
    const { fullName,  email,paid,uid } = i;

    return (
      <tr key={i.id} className="bg-white border-b " 
      >
        <th
          scope="row"
          className="underline fjalla text-center font-medium text-gray-900 whitespace-nowrap "
        >
          <Link to={`/user/${uid}`}>
            <span className="hover:text-blue-800 cursor-pointer capitalize" style={{fontSize:'2vw',padding:'2vw 0'}}>{fullName}</span>
          </Link>
        </th>
        <td className="text-center borel" style={{ fontSize: "2vw" }}>
          {email}
        </td>
        <td className="text-center borel flex flex-col" style={{ fontSize: "2vw", wordBreak: "break-all",padding:'2vw 0' }}>
          {enrolledFor &&
            enrolledFor.map((course) => (
              <div key={course} className="flex flex-col capitalize">
                {course}
              </div>
            ))}
        </td>
        <td className="text-center borel" style={{ fontSize: "2vw",padding:'2vw 0' }}>
          {paid == 'Free' ? paid : paid + ' ETB'} 
        </td>
        <td className="text-center borel" style={{ fontSize: "2vw",padding:'2vw 0' }}>

        {
          status == 'Active' ?
          <div style={{fontSize:'2vw'}}class="relative flex items-center text-center justify-center  font-sans font-bold uppercase  select-none  text-green-900 text-center text-xs rounded-md" style={{opacity: 1}}>
          Active
        </div>
        :
        status == 'Pending' &&
        <div class=" relative flex items-center text-center justify-center  font-sans font-bold uppercase  select-none  text-red-900 text-center text-xs rounded-md" style={{opacity: 1}}>
         <span style={{fontSize:'2vw',padding:'2vw 0'}}>Completed</span>
         
       </div>

     
        }
        </td>

      </tr>
    );
  })}
      
                
              </tbody>
      </table>
      
               
      
              
      
      </div>   
        :
        <div className='border mb-12 py-5 mx-auto  flex flex-col items-center' style={{width:"90%"}}>
        <h1 className='fjalla text-center text-gray-900 mt-8 capitalize' style={{fontSize:"5vw"}}>Your students will appear here once they have enrolled in your course.  </h1>
        <p className='borel text-gray-500 text-center' style={{fontSize:"3vw"}}>No students are enrolled in your course</p>
       <img style={{width:"50%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/college%20students-bro.svg?alt=media&token=0e3d628f-3d74-41dc-87e5-32e48168dd1f" alt="No students" />
      
      </div>
                 }


      </div>
      
      
          </div>
    )
   })
  )
}
