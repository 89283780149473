import React, { useEffect, useState } from 'react'
import DashboardNav from './dashboardNav'
import { Link, useHref, useNavigate, useParams } from 'react-router-dom'
import Chart from './Chart';
import { collection, doc, onSnapshot, orderBy, query,where,getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { UseAuth } from '../context/contex';


export default function User() {
  const [pop,setpop] = useState(false)
  const [notificationPop,setnotificationPop] = useState(false)
  const [spinner,setspinner] = useState(true)
  const [copied,setcopied] = useState(false)
    const navigate = useNavigate()

  const [notification,setnotification] = useState([])
  const [uid,setuid] = useState('')

  const [user,setuser] = useState([])
  const {accountType} = UseAuth()
  const {userId} = useParams()



   // Profile Photo Click Handler
   const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
function closeMessage6Desktop(){
    setcopied(false)

  }
  const convertTimestampToDateNavigation = (timestamp) => {
    const date = timestamp.toDate();
    const currentDate = new Date();
    const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
  
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;
  
    let relativeTime = '';
  
    if (timeDifferenceInSeconds < secondsInMinute) {
      relativeTime = `${timeDifferenceInSeconds}s ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      relativeTime = `${minutes}m ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      relativeTime = `${hours}h ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      relativeTime = `${days}d ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      relativeTime = `${weeks}w ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      relativeTime = `${months}mo ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      relativeTime = `${years}y ago`;
    }
  
    return relativeTime;
  };
  
  const convertTimestampToDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };
  

useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       

   
    

  const userRef = doc(firestore,"TotalUsers",userId);




  const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

  const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

  onSnapshot(notificationRef,snapshot=>{
    let main =[]
    



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
     
    setnotification(main)
    })

    
    
  

  })



  onSnapshot(userRef,snapshot=>{
   
    let main =[]



      main.push({...snapshot.data(),id:snapshot.id})
      
  setuser(main)

    
    
  

  })
        

           
 
//    })
  
            
  
  
  
  
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
      
  
        
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, []);
  function desktopSpinnerHandler(){
    setspinner(false)
   }
   function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
   const handleCopyUrl = () => {
    const url = `https://chekay.com/user/${uid}`;
  
    // Create a temporary input element
    const input = document.createElement('input');
    input.setAttribute('value', url);
    document.body.appendChild(input);
  
    // Select the input value
    input.select();
    input.setSelectionRange(0, 99999);
  
    // Execute the copy command
    document.execCommand('copy');
  
    // Remove the temporary input element
    document.body.removeChild(input);
  
    // Optionally, provide some visual feedback to the user
    setcopied(true)
  };
  function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    setDoc(notificationRef,{
      seen:true
    },{merge:true})
    .then(() => {
      
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
    });


   }
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
  return (
   user.map(i=>{
    const{accountType,plan,fullName,photo,aboutMe,billingDate,region,email} = i

    return(
        <div>
            {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
        <div id='desktop'>
     

           <div>
           <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDateNavigation(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
     
           <div>
           <ul className='flex justify-start border-b'>
             <div className=' flex justify-evenly   '>
                   <Link to='/dashboard'>
                <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
     
                   </Link>
     <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
     <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
     
                   </Link>
     <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
     <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
     
                   </Link>
                   <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
     <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
     
                   </Link>
     
                   <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
     <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
     
                   </Link>
     
                </div>
               
              
     
     
     
     
             </ul>
           </div>
     

      
   
           </div>
           <div class="">
 

           {
     copied == true &&
     <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
   <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div class="ml-3 text-sm font-medium Mooli capitalize">
    successfully Copied User Profile
   </div>
   <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
     <span class="sr-only">Dismiss</span>
     <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
     </svg>
   </button>
</div>
   }
  
   <div class=" mx-auto py-8">
       <div class="grid grid-cols-3 sm:grid-cols-12 gap-6 px-4">
           <div class="col-span-4 sm:col-span-3">
               <div class="bg-white shadow border rounded-lg p-6 relative">
 
                   <div class="flex flex-col items-center">
                     {
                       photo.length != 0 ?
                        <div className="relative"  >
                    
                       
                       <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                       :
                    
                       <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover `} style={{ position: 'relative' }}>
                         <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
                           {fullName && fullName[0]}
                         </p>
                       </div>

                     }
                       <div className=' rounded m-auto' >
                   
                   <div className='flex justify-between items-center  mb-2'>
                  <div>
 <h1 className='fjalla  text-gray-900  mx-2' style={{fontSize:"25px"}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>

                  </div>
                            
              
                   </div>
              
                   <div>
               
                   </div>
                           
                 </div>
                       <p class="text-gray-700 borel" style={{fontSize:'11px'}}>{email}</p>
                       <button onClick={handleCopyUrl} style={{fontSize:'11px'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
           
                     Copy Profile URL
                 </button>
                   </div>
               
               </div>
             
           </div>
           <div class="col-span-4 sm:col-span-9">
               <div class="bg-white shadow rounded-lg p-6 border">
     
                
                   <div className='rounded m-auto' >
                   
                   <div className='flex justify-between px-5 py-5 w-full'>
    <div className='w-full'>

                  <h1 className='fjalla  text-gray-900 mb-1 flex justify-between w-full' style={{fontSize:"30px"}}>About Me
          
 
 </h1>
    <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"12px",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>
    
    
                  </div>
               
         
          
                   </div>
               
         
  
                   <div>
               
                   </div>
                           
                 </div>

              
              


                 
             
                
               </div>

               <div class="bg-white shadow rounded-lg p-6 mt-6 border">
     
                
     <div className='rounded m-auto' >
     
     <div className='flex justify-between px-5 py-5 w-full'>
    <div className='w-full'>
    <div className='fjalla  text-gray-900 mb-1 flex justify-between w-full' style={{fontSize:"30px"}}>Region
 

</div>
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>



    </div>
 


     </div>
 


     <div>
 
     </div>
             
   </div>





   

  
 </div>
           </div>
       </div>
   </div>
</div>
     
         </div>
         <div id='tablet'>
     

     <div>
<DashboardNav/>




     </div>
     <div class="">


     {
copied == true &&
<div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
successfully Copied User Profile
</div>
<button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}

<div class=" mx-auto py-8">
 <div class="flex justify-evenly ">
     <div class="" style={{width:'25%'}}>
         <div class="bg-white shadow border rounded-lg  relative">

             <div class="flex flex-col items-center">
               {
                 photo.length != 0 ?
                  <div className="relative"  >
              
                 
                 <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                 :
              
                 <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover `} style={{ position: 'relative' }}>
                   <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
                     {fullName && fullName[0]}
                   </p>
                 </div>

               }
                 <div className=' rounded m-auto' >
             
             <div className='flex justify-between items-center  '>
            <div>
<h1 className='fjalla  text-gray-900  ' style={{fontSize:"3vw"}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>

            </div>
                      
        
             </div>
        
             <div>
         
             </div>
                     
           </div>
                 <p class="text-gray-700 borel" style={{fontSize:'1.2vw'}}>{email}</p>
                 <button onClick={handleCopyUrl} style={{fontSize:'1.3vw'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
     
               Copy Profile URL
           </button>
             </div>
         
         </div>
         <div class="bg-white shadow border rounded-lg p-6 relative mt-5">
         <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"3.5vw"}}>Membership
</h1>
<div className='flex justify-between'>
<p className='borel text-gray-500 block capitalize'>{plan}</p>
<Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize '>Change</p></Link>

</div>

         
         </div>
     </div>
     <div class="col-span-4 sm:col-span-9" style={{width:'65%'}}>
         <div class="bg-white shadow rounded-lg p-6 border">

          
             <div className='rounded m-auto' >
             
             <div className='flex justify-between  py-5 w-full'>
<div className='w-full'>

            <h1 className='fjalla  text-gray-900 mb-1 flex justify-between w-full' style={{fontSize:"4vw"}}>About Me
    

</h1>
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"12px",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>


            </div>
         
   
    
             </div>
         
   

             <div>
         
             </div>
                     
           </div>

        
        


           
       
          
         </div>

         <div class="bg-white shadow rounded-lg p-6 mt-6 border">

          
<div className='rounded m-auto' >

<div className='flex justify-between px-5 py-5 w-full'>
<div className='w-full'>
<div className='fjalla  text-gray-900 mb-1 flex justify-between w-full' style={{fontSize:"30px"}}>Region


</div>
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>



</div>



</div>



<div>

</div>
       
</div>








</div>
     </div>
 </div>
</div>
</div>

   </div>
   <div id='mobile'>
     

     <div>
<DashboardNav/>




     </div>
     <div class="">


     {
copied == true &&
<div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
successfully Copied User Profile
</div>
<button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}

<div class=" mx-auto py-8">
 <div class="flex justify-evenly flex-col ">
     <div class="mx-auto" style={{width:'95%'}}>
         <div class="bg-white shadow border rounded-lg  relative">

             <div class="flex flex-col items-center">
               {
                 photo.length != 0 ?
                  <div className="relative"  >
              
                 
                 <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
                 :
              
                 <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover `} style={{ position: 'relative' }}>
                   <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
                     {fullName && fullName[0]}
                   </p>
                 </div>

               }
                 <div className=' rounded m-auto' >
             
             <div className='flex justify-between items-center  '>
            <div>
<h1 className='fjalla  text-gray-900  ' style={{fontSize:"3vw"}}>{fullName && fullName.length < 20 ? fullName : (fullName.slice(0,20) + '...')} </h1>

            </div>
                      
        
             </div>
        
             <div>
         
             </div>
                     
           </div>
                 <p class="text-gray-700 borel" style={{fontSize:'2.5vw'}}>{email}</p>
                 <button onClick={handleCopyUrl} style={{fontSize:'2.8vw'}}  class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
     
               Copy Profile URL
           </button>
             </div>
         
         </div>
         <div class="bg-white shadow border rounded-lg p-6 relative mt-5">
         <h1 className='fjalla  text-gray-900 mb-1' style={{fontSize:"4vw"}}>Membership
</h1>
<div className='flex justify-between'>
<p className='borel text-gray-500 block capitalize'>{plan}</p>
<Link to={`/pricing`}><p className='borel text-blue-800 underline block capitalize '>Change</p></Link>

</div>
       
         </div>
     </div>
     <div class="col-span-4 sm:col-span-9 mx-auto" style={{width:'95%',marginTop:'4vw',marginBottom:'4vw'}}>
         <div class="bg-white shadow rounded-lg p-6 border">

          
             <div className='rounded m-auto' >
             
             <div className='flex justify-between  py-5 w-full'>
<div className='w-full'>

            <h1 className='fjalla  text-gray-900 mb-1 flex justify-between w-full' style={{fontSize:"4vw"}}>About Me
    

</h1>
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"2.5vw",maxWidth:"100%",wordBreak:"break-all"}}>{aboutMe}</p>


            </div>
         
   
    
             </div>
         
   

             <div>
         
             </div>
                     
           </div>

        
        


           
       
          
         </div>

         <div class="bg-white shadow rounded-lg p-6 mt-6 border">

          
<div className='rounded m-auto' >

<div className='flex justify-between px-5 py-5 w-full'>
<div className='w-full'>
<div className='fjalla  text-gray-900 mb-1 flex justify-between w-full' style={{fontSize:"4vw"}}>Region


</div>
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{region}</p>



</div>



</div>



<div>

</div>
       
</div>








</div>
     </div>
 </div>
</div>
</div>

   </div>
   </div>
    )
   })
  )
}
