import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Body from "./home/home"
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './Authenticate/signin';
import Signup from './Authenticate/signup';
import Dashboard from './dashboard/dashboard';
import CourseCreate from './dashboard/courseCreate';
import MyCourse from './dashboard/myCourse';
import Course from './dashboard/course';
import PricingPlanCreator from './dashboard/pricingPlanCreator';
import Lecture from './dashboard/lecture';
import Certificate from './dashboard/certificate';
import Preview from './dashboard/preview';
import Withdraw from './dashboard/withdraw';
import MyStudents from './dashboard/myStudents';
import Transactions from './dashboard/transactions';
import Certificates from './dashboard/certificates';
import Profile from './dashboard/profile';
import Quiz from './dashboard/quiz';
import Test from './dashboard/test';
import LecturePreview from './dashboard/lecturePreview';
import TakeQuiz from './dashboard/takeQuiz';
import Pay from './dashboard/pay';
import AboutUs from './home/aboutus';
import ContactUs from './home/contactUs';
import Pricing from './home/pricingPage';
import PricingSignUp from './home/pricingSignUp';
import Checkout from './home/checkout';
import EnrolledCourses from './dashboard/enrolledCourses';
import Achievements from './dashboard/Achievements';
import Enroll from './dashboard/enroll';
import Courses from './dashboard/courses';
import ChoosePricingSignUp from './home/choosePricingSignUp';
import NotFound from './home/NotFound';
import Context from './context/contex';
import CourseStudent from './dashboard/courseStudent';
import TakeTest from './dashboard/takeTest';
import HowToPost from './home/howToPost';
import HowToEnroll from './home/howToEnroll';
import HowToCreateCourses from './home/howToCreateCourses';
import Careers from './home/careers';
import Notification from './dashboard/notification';
import User from './dashboard/user';
import UserVerified from './context/userVerified';
import ProtectedRoute from './context/protectedRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Context>
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
    <Route element={<Body/>} path='/'/>
    <Route element={<AboutUs/>} path='/aboutus'/>
    <Route element={<ContactUs/>} path='/contact'/>
    <Route element={<Signin/>} path='/signin'/>
    <Route element={<Signup/>} path='/signup/:user/'/>
    <Route element={<ChoosePricingSignUp/>} path='/signup/pricing/:user'/>
    <Route element={<Pricing/>} path='/pricing/:user'/>
    <Route element={<PricingSignUp/>} path='/pricing'/>
    <Route element={<Courses/>} path='/courses'/>
    <Route path="*" element={<NotFound />} />
    <Route element={<HowToPost/>} path='/howtopost'/>
    <Route element={<Careers/>} path='/careers'/>
    <Route element={<HowToCreateCourses/>} path='/how-to-create-courses'/>
    <Route element={<HowToEnroll/>} path='/howtoenroll'/>
    <Route element={<Certificate/>} path='/course/:courseId/certificate/:certificateId'/>
    <Route element={<User/>} path='/user/:userId'/>
    <Route element={<Checkout />} path='/checkout/:plan/:length/:checkoutId'/>

    
      <Route
        path='/notification'
        element={
          <ProtectedRoute>
            <Notification />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dashboard'
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path='/achievements'
        element={
          <ProtectedRoute>
            <Achievements />
          </ProtectedRoute>
        }
      />

      <Route
        path='/enrolledcourses'
        element={
          <ProtectedRoute>
            <EnrolledCourses />
          </ProtectedRoute>
        }
      />

      <Route
        path='/withdraw'
        element={
          <ProtectedRoute>
            <Withdraw />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mystudents'
        element={
          <ProtectedRoute>
            <MyStudents />
          </ProtectedRoute>
        }
      />

      <Route
        path='/transactions'
        element={
          <ProtectedRoute>
            <Transactions />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId'
        element={
          <ProtectedRoute>
            <Course />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/pricing'
        element={
          <ProtectedRoute>
            <PricingPlanCreator />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/test/:testId/:questions'
        element={
          <ProtectedRoute>
            <Test />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/enroll/:lectureId'
        element={
          <ProtectedRoute>
            <Enroll />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/students/'
        element={
          <ProtectedRoute>
            <CourseStudent />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/lecture/:lectureId/quiz/:questions'
        element={
          <ProtectedRoute>
            <Quiz />
          </ProtectedRoute>
        }
      />

      <Route
        path='/certificate'
        element={
          <ProtectedRoute>
            <Certificates />
          </ProtectedRoute>
        }
      />

      <Route
        path='/profile'
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/preview'
        element={
          <ProtectedRoute>
            <Preview />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/lecture/:lectureId'
        element={
          <ProtectedRoute>
            <Lecture />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/checkout'
        element={
          <ProtectedRoute>
            <Pay />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/preview/:lectureId'
        element={
          <ProtectedRoute>
            <LecturePreview />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/quiz/:lectureId'
        element={
          <ProtectedRoute>
            <TakeQuiz />
          </ProtectedRoute>
        }
      />

      <Route
        path='/course/:courseId/test/:lectureId'
        element={
          <ProtectedRoute>
            <TakeTest />
          </ProtectedRoute>
        }
      />

      <Route
        path='/create'
        element={
          <ProtectedRoute>
            <CourseCreate />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mycourse'
        element={
          <ProtectedRoute>
            <MyCourse />
          </ProtectedRoute>
        }
      />
    
 



    </Routes>
    </BrowserRouter>
  </React.StrictMode>
  </Context>
);


reportWebVitals();
