import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Nav () {
   const [pop,setPop] = useState(false)



    // Mobile Nav Pop
    function navigationPop(){
        if(pop == true)
            setPop(false)
        else{
            setPop(true)
        }

        

    }



  

   
  return (
    <div>
{/* 

Mobile Devices


*/}


{/* 

Tablet Devices


*/}




<div id='desktop' >
<div>
                
                <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop"><div class="navigation-bar__left-container">
                  <Link to='/'style={{width:'10%',marginRight:'50px'}}>
                 <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
                 </Link>
                    
                 <Link to='/aboutus'>
                  <div class="navbar-item__container
                
                header-dropdown-one"><button  class="navbar-item__button 
                    navbar-item__button-default"style={{fontSize:'11px'}}>About Us</button></div></Link>
                    
                <Link to='/contact'>
                    
                <div class="navbar-item__container
                
                header-dropdown-two"><button  class="navbar-item__button 
                    navbar-item__button-default"style={{fontSize:'11px'}}>Contact Us</button>
                    
                    </div></Link>
                    <Link to='/courses'>
                    <div class="navbar-item__container
                
                header-dropdown-three"><button style={{fontSize:'11px'}} class="navbar-item__button 
                    navbar-item__button-default">Courses</button></div></Link>
                 
                 <Link to='/pricing/institute'>
                    
                 <div class="navbar-item__container
                
                header-dropdown-fourth"><button style={{fontSize:'11px'}} class="navbar-item__button 
                    navbar-item__button-default">Pricing</button></div></Link>
                    
                    
                    <div class="navbar-item__container
                
                header-dropdown-fifth"><button style={{fontSize:'11px'}} class="navbar-item__button 
                    navbar-item__button-default">Resources</button></div></div><div class="navigation-bar__right-container">
                      

                  <Link to='/signin'>
                  <div class="navbar-item__container
                
                header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
                    navbar-item__button-default" ><span style={{fontSize:'11px'}} class="navbar-item__button-text">Log in</span></a></div>
                    
                    </Link>
                  <Link to='/signup/student'>
                    
                    <div class="navbar-item__container
                
                header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa',fontSize:'11px'}}><span style={{fontSize:'11px'}} class="navbar-item__button-text text-gray-900 " >Register as a student</span></a></div>
                    </Link>
          
                    </div>
            
                       
          
                       
          
                    
                          
          
                           
                      </div>
                     
                      </div>

</div>
<div id='tablet' >
<div>
        {
              pop === false &&
              <div id='navPop'  class="">
              <ul className='w-1/2 bg-white px-5  rounded-b-xl' style={{width:'100%',background:'#0021db'}}>

              <div className='flex justify-between w-full mt-3'>
                    <div>
                    </div>
                    <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-8 h-8 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
                  </div>
        
                 {
                  <li>
                  <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
              </li>
                  }
  
  {
                  <li>
                  <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact</p> </Link>
              </li>
                  }
           <li>
                  <Link to='/services'><p class="block py-2 pr-4 pl-3 report-card-text text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Services</p> </Link>
              </li>
              
              </ul>
          </div>
                }          
                <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
                  <div className="flex w-full justify-between items-center">
                  <Link to='/'style={{width:'15%',marginRight:'50px'}}>
                 <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
                 </Link>
                    
            
           
         
                    
              
                 <div className='flex'>
                 <Link to='/signin'>
                  <div class="navbar-item__container
                
                header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
                    navbar-item__button-default" ><span style={{fontSize:'11px'}} class="navbar-item__button-text">Log in</span></a></div>
                    
                    </Link>
                  <Link to='/signup/student' className='mx-2'>
                    
                    <div class="navbar-item__container
                
                header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa',fontSize:'11px'}}><span style={{fontSize:'11px'}} class="navbar-item__button-text text-gray-900 " >Register as a student</span></a></div>
                    </Link>
                    <button  data-collapse-toggle="mega-menu" type="button" class="inline-flex items-center p-2  text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 " aria-controls="mega-menu" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg aria-hidden="true" class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
                 </div>
                    </div>
            
                       
                  
                       
          
                    
                          
          
                           
                      </div>
                     
                      </div>
                      </div>
</div>

    </div>
  )
}
