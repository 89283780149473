import React, { useEffect, useState } from 'react'
import Nav from '../home/Nav'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { RegionDropdown } from 'react-country-region-selector'
import { addDoc, collection, doc, setDoc,query,where,onSnapshot } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth"
import ReactCaptcha from 'modern-react-captcha';
import { v4 } from 'uuid';
import ReCAPTCHA from 'react-google-recaptcha'

export default function Signup() {
  const {user,plan,length} = useParams()
  
  // Student Data
  const [name,setName] = useState("")
  const [duplicate,setduplicate] = useState(false)
  const [matched,setmatched] = useState(false)
  const [pop,setpop] = useState(false)
  const [spinner,setspinner] = useState(true)
  const [studentReady,setstudentReady] = useState(false)



  const [institutesContact,setinstitutesContact] = useState(false)

  const [matchedError,setmatchedError] = useState('')



  const [isLoading,setIsLoading] = useState(false)


  const [school,setSchool] = useState("")

  const [confirmPassword,setconfirmPassword] = useState("")
  const [confirmPasswordError,setconfirmPasswordError] = useState("")




  // Student Data error handlers
  const [schoolError,setschoolError] = useState("")


  // Student Data
  const [email,setemail] = useState("")
  const [teacherSchool,setTeacherSchool] = useState("")
  const [region,setregion] = useState("")
  const [password,setpassword] = useState("")
  const [institutePassword,setInstitutePassword] = useState("")
  const [institutePasswordError,setInstitutePasswordError] = useState("")



  // teacher Data error handlers
  const [nameError,setNameError] = useState("")
  const [emailError,setemailError] = useState("")
  const [teacherSchoolError,setTeacherSchoolError] = useState("")
  const [regionError,setregionError] = useState("")
  const [passwordError,setpasswordError] = useState("")
  const navigate = useNavigate()


  
    const handleSuccess = () => {setmatched(true)
    setmatchedError('')};
    const handleFailure = () => {
      setmatched(false)

    }
    function desktopSpinnerHandler(){
      setspinner(false)
     }

         // Mobile Nav Pop
         function mobileNavPop(){
          setpop(true)
  
  
        
  
    }
  
    // Mobile Nav Pop minimize
    function mobileNavPopMinimize(){
      setpop(false)
  
  
  
    }

    function captchaOnChange(){
      setmatched(true)
      setmatchedError('')
      

    }

// Select City Input
  function selectRegion(val){
    setregion(val.target.value)
    setregion(val.target.value)
    if(val.target.value === 'Select Region'){
      setregionError('error')
    }
    else{
      setregionError('')
    }
   

    }


    useEffect(() => {
        
      (async()=>{
    
        const q = query(
          collection(firestore, "TotalUsers"),
          where("email", "in", [email, email])
        );
      if(email || email){
        onSnapshot(q,snapshot=>{
          snapshot.forEach((doc) => {
            
            if(doc.exists ){
              setduplicate(true)
            }
    
            else {
              setduplicate(false)
            }
           
     })
    
        });
      }
     
      })
     ()
    }, [email,email]);
    function validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    
    // Usage example:
  


    // Teacher Email Set Input
    const emailSet = (e)=>{
      setduplicate(false)
      setemail(e.target.value)
      if(e.target.value.length == 0){
        setemailError("error")
      }
      else {
        setemailError("")
        if (validateEmail(e.target.value)) {
          setemailError("")
  
          } else {
          setemailError("error")
  
          }

      }

    }

 

    // Teacher Name Set Input
    const nameSet = (e)=>{
      
      setName(e.target.value)
      if(e.target.value.length == 0){
        setNameError("error")
      }
      else {
        setNameError("")

      }

    }

     // Student School Set Input
     const schoolSet = (e)=>{
      
      setSchool(e.target.value)
      if(e.target.value.length == 0){
        setschoolError("error")
      }
      else {
        setschoolError("")

      }

    }

   

    const confirmPasswordSet = (e)=>{
      
      setconfirmPassword(e.target.value)
      if(e.target.value != password){
        setconfirmPasswordError("error")
      }
      else {
        setconfirmPasswordError("")

      }

    }

    function institutesContactSetRemove(){
      setinstitutesContact(false)
    }

    function institutesContactSetOpen(){
      setinstitutesContact(true)
    }

     // Teacher Password Set Input
     const passwordSet = (e)=>{

      setpassword(e.target.value)
      if(e.target.value.length <= 7){
        setpasswordError("error")
      }
      else {
        setpasswordError("")

      }
      
   

    }

    




    useEffect(() => {
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }, []);



 
    // const userSchema = new mongoose.Schema(
    // studentData
    // );

// Create user model
// const User = mongoose.model('TotalUser', userSchema);
// const user = new User(studentData);
   
const studentSignUp = (e)=>{

  e.preventDefault()
  // if(matched == false){
  //   setmatchedError('error')
  // }
    if(name.length == 0){
      setNameError('error')
      window.scrollTo(0, 0);

     }
     else{
      if(school.length == 0){
        setschoolError('error')
      window.scrollTo(0, 0);

      }
      else{
        if(region == 'Select Region'|| region.length == 0){
          setregionError('error')
      window.scrollTo(0, 0);

        }
        else{
          if(email.length == 0 || duplicate == true){
            setemailError('error')
          }
          else {
            if(password.length == 0){
              setpasswordError('error')
            }
            else{
              if( confirmPassword.length == 0  || password != confirmPassword){
                setconfirmPasswordError('error')
              }
              else{
                if(matched == false){
                  setmatchedError('error')
                }
                else{
                  setIsLoading(true)
                
                                createUserWithEmailAndPassword(auth,email,password).then(
                                  credential=>{
                                    setDoc(doc(firestore, "TotalUsers",credential.user.uid),
                                    {
                                      fullName:name,
                                      email:email,
                                      password:password,
                                      region:region,
                                      school:school,
                                      accountType:"Student",
                                          photo:'',
                
                                      uid:credential.user.uid,
                                
                                
                                
                                
                                  }
                                    ).then(sendEmailVerification(auth.currentUser)
                                    .then(() => {
                                      // Email verification sent!
                                      // ...
                                    })).then(
                                      setDoc(doc(firestore, "Students",credential.user.uid),
                                      {
                                        fullName:name,
                                      email:email,
                                      password:password,
                                      region:region,
                                      school:school,
                                      accountType:"Student",
                                          photo:'',
                
                                      
                                      uid:credential.user.uid
                                  
                                  
                                  
                                  
                                    }
                                      ).then(
                  setIsLoading(false),
                
                                        navigate("/dashboard"))
                                      
                                    )
                                  }
                                )
                              }
              }
             
            }
          }
        }
        
      }
     }

   
 
    }
 


    const teacherSignUp = (e)=>{
      e.preventDefault()
      
          if(name.length == 0){
            setNameError('error')
             window.scrollTo(0, 0);

           }
           else{
          
              if(region == 'Select Region'|| region.length == 0){
                setregionError('error')
                window.scrollTo(0, 0);

              }
              else{
                if(email.length == 0 ||emailError =='error' || duplicate == true){
                  setemailError('error')
                }
                else {
                  if(password.length == 0){
                   setpasswordError('error')
                  }
                  else{
                    if(confirmPassword.length == 0  || password != confirmPassword){
                      setconfirmPasswordError('error')
                    }
                 else{
                  if(matched == false){
                    setmatchedError('error')
                  }
                  else{
                    setIsLoading(true)
              
                                  createUserWithEmailAndPassword(auth,email,password).then(
                                    credential=>{
                                      setDoc(doc(firestore, "TotalUsers",credential.user.uid),
                                      {
                                        fullName:name,
                                        email:email,
                                        password:password,
                                        region:region,
                                        accountType:"Teacher",
                                        pendingWithdrawal:false,
                                        plan:'Free',
                                        uid:credential.user.uid,
                                        averageMark:0,
                                        photo:'',
                                        totalEarnings:0,
                                        totalStudents:0,
                                        underPerformingStudents:0,
                                        underPerformingPercentage:0,
                                        isPending:false

                                
                                        
              
                                  
                                  
                                  
                                  
                                    }
                                      ).then(
                                        setDoc(doc(firestore, "Teacher",credential.user.uid),
                                        {
                                          fullName:name,
                                          email:email,
                                        photo:'',
              
                                          password:password,
                                          region:region,
                                          accountType:"Teacher",
                                          pendingWithdrawal:false,
                                          plan:'Free',
                                          uid:credential.user.uid,
                                          averageMark:0,
                                          totalEarnings:0,
                                          totalStudents:0,
                                          underPerformingStudents:0,
                                          underPerformingPercentage:0,
                                        isPending:false,


                                  

                                    
                                    
                                    
                                    
                                      }
                                        ).then(
                    setIsLoading(false),
                    navigate(`/pricing`)
                    
                    
                    )
                                        
                                      )
                                    }
                                  )
                                }
                 }
                  }
                }
              
            }
           }

        
     
        }
        
    const instituteSignUp = (e)=>{
      e.preventDefault()
      
          if(name.length == 0){
            setNameError('error')
             window.scrollTo(0, 0);

           }
           else{
              if(region == 'Select Region'|| region.length == 0){
                setregionError('error')
                window.scrollTo(0, 0);

              }
              else{
                if(email.length == 0 ||emailError =='error' || duplicate == true){
                  setemailError('error')
                }
                else {
                  if(password.length == 0){
                   setpasswordError('error')
                  }
                  else{
                    if(confirmPassword.length == 0  || password != confirmPassword){
                      setconfirmPasswordError('error')
                    }
                 else{
                  if(matched == false){
                    setmatchedError('error')
                  }
                  else{
                    setIsLoading(true)
              
                                  createUserWithEmailAndPassword(auth,email,password).then(
                                    credential=>{
                                      setDoc(doc(firestore, "TotalUsers",credential.user.uid),
                                      {
                                        fullName:name,
                                        email:email,
                                        password:password,
                                        region:region,
                                        accountType:"Institute",
                                        pendingWithdrawal:false,
                                        plan:'Free',
                                        uid:credential.user.uid,
                                        averageMark:0,
                                        photo:'',
                                        totalEarnings:0,
                                        totalStudents:0,
                                        underPerformingStudents:0,
                                        underPerformingPercentage:0,
                                        isPending:false

                                
                                        
              
                                  
                                  
                                  
                                  
                                    }
                                      ).then(
                                        setDoc(doc(firestore, "Institute",credential.user.uid),
                                        {
                                          fullName:name,
                                          email:email,
                                        photo:'',
              
                                          password:password,
                                          region:region,
                                          accountType:"Institute",
                                          pendingWithdrawal:false,
                                          plan:'Free',
                                          uid:credential.user.uid,
                                          averageMark:0,
                                          totalEarnings:0,
                                          totalStudents:0,
                                          underPerformingStudents:0,
                                          underPerformingPercentage:0,
                                        isPending:false,


                                  

                                    
                                    
                                    
                                    
                                      }
                                        ).then(
                    setIsLoading(false),
                    navigate(`/pricing`)
                    
                    
                    )
                                        
                                      )
                                    }
                                  )
                                }
                 }
                  }
                }
              
            }
           }

        
     
        }

        function desktopSpinnerHandler(){
         }

  return (
    <div>
  <div className="customer-satisfaction-snackbar" />

        {/* {
    spinner == true &&
    <div id='spinnerDesktop'>
      <div
  class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
  role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>

      </div>
  } */}


 <div id='desktop' className='h-full'>
{
  user != 'institute' & user != 'student'?
 <div >
  <Nav/>
   <section class="bg-white  MuiBox-root mt-32" id='desktop'>
    <div class=" flex items-center  px-6 py-12 mx-auto">
        <div class="flex flex-col items-center max-w-sm mx-auto text-center">
            <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            </p>
            <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
            Page not found</h1>
            <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

            <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
   <Link to='/'>
   <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>


                    <span>Go back</span>
                </button></Link>
      <Link to='/contact'>
                <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                    Contact Us
                </button>
      </Link>

            </div>
        </div>
    </div>
</section>
 </div>
:
user === 'institute' ?
<div className="flex justify-between items-center">
    
    <div style={{width:'40%'}} className=" h-full">
      
    <div className="absolute top-0 let-0 ">
        <div>
          <Link  to='/' style={{width:'12%',display:'flex'}} className='mt-10 ml-10'>
              <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />

          </Link>
    </div>
    </div>
          <img style={{width:'80%'}} className='mx-auto mt-14' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/Add%20a%20heading%20(1).png?alt=media&token=4aee755f-36c5-4f31-8aff-d1b3b70f52b8" alt="Teacher" />
          
        
     
   
    </div>
    <div style={{width:'60%'}} className="flex justify-center flex-col py-10">
       
       
       <div class="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 ">
         <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-md border rounded-xl
             relative z-10">
              
           <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Institute Sign Up</p>

   <form onSubmit={instituteSignUp} autoComplete="off" className=" w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8" >
    
    <div className="relative my-10 w-full">
    <p style={{fontSize:'12px'}} className="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Institute Name</p>
    <input style={{fontSize:'11px',height:'60px'}}  onChange={e=>nameSet(e)}   autoComplete="off"  placeholder="Addis Ababa University" type="text" className="border  focus:outline-none
        focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
        border-gray-300 rounded-md input-field-signUp"/>
         <div className={`${nameError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    Institute Name Can't be empty
    </div>
    </div>
    </div>
    
    </div>
    <div class="relative  m-auto mb-10">
                <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Region</p>
    
                      <select style={{fontSize:'11px',height:'60px'}}  onChange={e=>selectRegion(e)} placeholder="abebe@gmail.com" type="text" class="border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md">
                 
                 <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Select Region" >Select Region</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Addis Ababa">Addis Ababa</option>
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Afar">Afar</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Benshangul-Gumz">Benshangul-Gumz</option>     
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Dire Dawa" >Dire Dawa</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Gambela">Gambela</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Harari">Harari</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Oromia">Oromia</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Somali">Somali</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Tigray">Tigray</option>
    
    </select> 
    
    <div className={`${regionError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Region Can't be empty
    </div>
    </div>
    </div>
              </div>
              <div class="relative">
                      <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                          absolute">Email</p>
                      <input  style={{fontSize:'11px',height:'60px'}} autocomplete="off" onChange={e=>emailSet(e)} placeholder="abebe@gmail.com " type="email" 
                      class={`${duplicate == false && "border  focus:outline-none focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white border-gray-300 rounded-md lowercase input-field-signUp" }
                      ${duplicate == true && "input-field-signUp lowercase bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg pt-5 pr-4 pb-5 pl-4 focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" }
                      
                      `}/>
                          <p class={`${duplicate == true && "mt-2 text-sm text-red-600 dark:text-red-500" } ${duplicate == false && "hidden"}`}><span class="font-medium">Oops!</span> Email already
          taken!</p>
          <div className={`${emailError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Invalid Email
    </div>
    </div>
    </div>
                    </div>
                    <div className="relative">
                    <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
    <input  onChange={e=>passwordSet(e)} style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black w-full pt-5 borel pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
           <div className={`${passwordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     The password must be at least 8 characters long
    </div>
    </div>
    </div>
    </div>
    
    <div className="relative">
    <p style={{fontSize:'12px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Confirm Password</p>
    <input onChange={e=>confirmPasswordSet(e)}  style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black borel w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
        <div className={`${confirmPasswordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    There is a mismatch between the passwords
    </div>
    </div>
    </div>
    </div> 
    
    <div className={`${matchedError == 'error' ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-1 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    Must Complete the captcha
    </div>
    </div>
    </div>
    <ReCAPTCHA
    sitekey="6LfjD5UpAAAAAByAW_jN78Xl7-36jAOPDUbwvVLt"
    onChange={captchaOnChange}
  /> 
     <div className="relative" >
  {
    isLoading ?
    <div style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
    rounded-lg transition duration-200 bg-[#1333c0] cursor-not-allowed ease cursor-pointer">Sign Up
 
<div role="status">
<svg aria-hidden="true" class="w-5 h-5 ml-2 text-white animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
<span class="sr-only">Loading...</span>
</div>
</div>
:
<button type='submit' style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
rounded-lg transition duration-200 bg-[#2141ce] hover:bg-[#1333c0] cursor-pointer">Sign Up
</button>
  }
    </div>
    <div className="sign_up_link space-x-1 mt-4">
        <p className="text-sm font-light fjalla text-center" style={{fontSize:'12px'}}>Already a member? <Link to="/signin"><span className="text-blue-500 hover:text-blue-600 text-sm font-light hover:underline" >Sign in</span></Link></p> 
    </div>
    </form>
         </div>
         <svg viewbox="0 0 91 91" class="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
             fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
             cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
             r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
             cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
             transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
             r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
             cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
             r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
             cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
             cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
             r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
             transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
             r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
             cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
             r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
             cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
             cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
             r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
             transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
             r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
             cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
             r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
             cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
             cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
             r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
             transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
             r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
             cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
             r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
         <svg viewbox="0 0 91 91" class="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
             fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
             cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
             r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
             cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
             transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
             r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
             cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
             r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
             cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
             cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
             r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
             transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
             r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
             cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
             r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
             cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
             cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
             r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
             transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
             r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
             cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
             r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
             cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
             cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
             r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
             transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
             r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
             cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
             r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
       </div>
   
 
   </div>
  
  </div>
  :
  <div className="flex justify-between items-center">
    
    <div style={{width:'40%'}} className=" h-full">
      
    <div className="absolute top-0 let-0 ">
        <div>
          <Link  to='/' style={{width:'12%',display:'flex'}} className='mt-10 ml-10'>
              <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />

          </Link>
    </div>
    </div>
          <img style={{width:'80%'}} className='mx-auto mt-14' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/Add%20a%20heading%20(1).png?alt=media&token=4aee755f-36c5-4f31-8aff-d1b3b70f52b8" alt="Teacher" />
          
        
     
   
    </div>
    <div style={{width:'60%'}} className="flex justify-center flex-col py-10">
       
       
        <div class="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 ">
          <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-md border rounded-xl
              relative z-10">
            <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Student Sign Up</p>
            <form onSubmit={studentSignUp} autoComplete="off" className=" w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8" >
    
    <div className="relative my-10 w-full">
    <p style={{fontSize:'12px'}} className="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
    <input style={{fontSize:'11px',height:'60px'}}  onChange={e=>nameSet(e)}   autoComplete="off"  placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
        focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
        border-gray-300 rounded-md input-field-signUp"/>
         <div className={`${nameError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Name Can't be empty
    </div>
    </div>
    </div>
    
    </div>
    <div className="relative my-10 w-full">
    <p style={{fontSize:'12px'}} className="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">School</p>
    <input style={{fontSize:'11px',height:'60px'}}  onChange={e=>schoolSet(e)}   autoComplete="off"  placeholder="Addis Ababa University" type="text" className="border  focus:outline-none
        focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
        border-gray-300 rounded-md input-field-signUp"/>
         <div className={`${schoolError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     School Can't be empty
    </div>
    </div>
    </div>
    
    </div>
    <div class="relative  m-auto mb-10">
                <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Region</p>
    
                      <select style={{fontSize:'11px',height:'60px'}}  onChange={e=>selectRegion(e)} placeholder="abebe@gmail.com" type="text" class="border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md input-field-signUp">
                 
                 <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Select Region" >Select Region</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Addis Ababa">Addis Ababa</option>
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Afar">Afar</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Benshangul-Gumz">Benshangul-Gumz</option>     
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Dire Dawa" >Dire Dawa</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Gambela">Gambela</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Harari">Harari</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Oromia">Oromia</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Somali">Somali</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Tigray">Tigray</option>
    
    </select> 
    
    <div className={`${regionError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Region Can't be empty
    </div>
    </div>
    </div>
              </div>
              <div class="relative">
                      <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                          absolute">Email</p>
                      <input  style={{fontSize:'11px',height:'60px'}} autocomplete="off" onChange={e=>emailSet(e)} placeholder="abebe@gmail.com " type="email" 
                      class={`${duplicate == false && "border  focus:outline-none focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white border-gray-300 rounded-md lowercase input-field-signUp" }
                      ${duplicate == true && "input-field-signUp lowercase bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg pt-5 pr-4 pb-5 pl-4 focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" }
                      
                      `}/>
                          <p class={`${duplicate == true && "mt-2 text-sm text-red-600 dark:text-red-500" } ${duplicate == false && "hidden"}`}><span class="font-medium">Oops!</span> Email already
          taken!</p>
          <div className={`${emailError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Invalid Email
    </div>
    </div>
    </div>
                    </div>
                    <div className="relative">
                    <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
    <input  onChange={e=>passwordSet(e)} style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black w-full pt-5 borel pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
           <div className={`${passwordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     The password must be at least 8 characters long
    </div>
    </div>
    </div>
    </div>
    
    <div className="relative">
    <p style={{fontSize:'12px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Confirm Password</p>
    <input onChange={e=>confirmPasswordSet(e)}  style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black borel w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
        <div className={`${confirmPasswordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    There is a mismatch between the passwords
    </div>
    </div>
    </div>
    </div> 
    
    <div className={`${matchedError == 'error' ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-1 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    Must Complete the captcha
    </div>
    </div>
    </div>
    <ReCAPTCHA
    sitekey="6LfjD5UpAAAAAByAW_jN78Xl7-36jAOPDUbwvVLt"
    onChange={captchaOnChange}
  /> 
    <div className="relative" >
  {
    isLoading ?
    <div style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
    rounded-lg transition duration-200 bg-[#1333c0] cursor-not-allowed ease cursor-pointer">Sign Up
 
<div role="status">
<svg aria-hidden="true" class="w-5 h-5 ml-2 text-white animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
<span class="sr-only">Loading...</span>
</div>
</div>
:
<button type='submit' style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
rounded-lg transition duration-200 bg-[#2141ce] hover:bg-[#1333c0] cursor-pointer">Sign Up
</button>
  }
    </div>
    <div className="sign_up_link space-x-1 mt-4">
        <p className="text-sm font-light fjalla text-center" style={{fontSize:'12px'}}>Already a member? <Link to="/signin"><span className="text-blue-500 hover:text-blue-600 text-sm font-light hover:underline" >Sign in</span></Link></p> 
    </div>

    </form>
          </div>
          <svg viewbox="0 0 91 91" class="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
              fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
              cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
              r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
              cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
              transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
              r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
              cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
              r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
              cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
              cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
              r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
              transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
              r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
              cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
              r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
              cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
              cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
              r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
              transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
              r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
              cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
              r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
              cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
              cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
              r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
              transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
              r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
              cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
              r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
          <svg viewbox="0 0 91 91" class="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
              fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
              cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
              r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
              cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
              transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
              r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
              cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
              r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
              cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
              cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
              r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
              transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
              r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
              cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
              r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
              cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
              cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
              r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
              transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
              r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
              cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
              r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
              cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
              cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
              r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
              transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
              r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
              cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
              r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
        </div>
      <div className="MuiGrid-root MuiGrid-container MuiGrid-item css-lptq43">
        <div className="MuiGrid-root MuiGrid-container css-1d3bbye">
          <div className="w-full">
         
          </div>
        </div>
      </div>
  
    </div>
  </div>

}

</div> 
<div id='tablet' className='h-full'>
{
  user != 'institute' & user != 'student'?
 <div >
  <Nav/>
  <section class="bg-white  mt-32" id='tablet'>
    <div class=" flex items-center  px-6 py-12 mx-auto">
        <div class="flex flex-col items-center max-w-sm mx-auto text-center">
            <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            </p>
            <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
            Page not found</h1>
            <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

            <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
   <Link to='/'>
   <button class="flex items-center justify-center  px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>


                    <span>Go back</span>
                </button></Link>
      <Link to='/contact'>
                <button class=" px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                    Contact Us
                </button>
      </Link>

            </div>
        </div>
    </div>
</section>
 </div>
:
user === 'institute' ?
<div className="flex justify-between items-center">
    
    <div style={{width:'40%'}} className=" h-full">
      
    <div className="absolute top-0 let-0 ">
        <div>
          <Link  to='/' style={{width:'12%',display:'flex'}} className='mt-10 ml-10'>
              <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />

          </Link>
    </div>
    </div>
          <img style={{width:'80%'}} className='mx-auto mt-14' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/Add%20a%20heading%20(1).png?alt=media&token=4aee755f-36c5-4f31-8aff-d1b3b70f52b8" alt="Teacher" />
          
        
     
   
    </div>
    <div style={{width:'60%'}} className="flex justify-center flex-col py-10">
       
       
       <div class="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 ">
         <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-md border rounded-xl
             relative z-10">
              
           <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Institute Sign Up</p>

   <form onSubmit={instituteSignUp} autoComplete="off" className=" w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8" >
    
    <div className="relative my-10 w-full">
    <p style={{fontSize:'12px'}} className="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Institute Name</p>
    <input style={{fontSize:'11px',height:'60px'}}  onChange={e=>nameSet(e)}   autoComplete="off"  placeholder="Addis Ababa University" type="text" className="border  focus:outline-none
        focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
        border-gray-300 rounded-md input-field-signUp"/>
         <div className={`${nameError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    Institute Name Can't be empty
    </div>
    </div>
    </div>
    
    </div>
    <div class="relative  m-auto mb-10">
                <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Region</p>
    
                      <select style={{fontSize:'11px',height:'60px'}}  onChange={e=>selectRegion(e)} placeholder="abebe@gmail.com" type="text" class="border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md">
                 
                 <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Select Region" >Select Region</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Addis Ababa">Addis Ababa</option>
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Afar">Afar</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Benshangul-Gumz">Benshangul-Gumz</option>     
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Dire Dawa" >Dire Dawa</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Gambela">Gambela</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Harari">Harari</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Oromia">Oromia</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Somali">Somali</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Tigray">Tigray</option>
    
    </select> 
    
    <div className={`${regionError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Region Can't be empty
    </div>
    </div>
    </div>
              </div>
              <div class="relative">
                      <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                          absolute">Email</p>
                      <input  style={{fontSize:'11px',height:'60px'}} autocomplete="off" onChange={e=>emailSet(e)} placeholder="abebe@gmail.com " type="email" 
                      class={`${duplicate == false && "border  focus:outline-none focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white border-gray-300 rounded-md lowercase input-field-signUp" }
                      ${duplicate == true && "input-field-signUp lowercase bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg pt-5 pr-4 pb-5 pl-4 focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" }
                      
                      `}/>
                          <p class={`${duplicate == true && "mt-2 text-sm text-red-600 dark:text-red-500" } ${duplicate == false && "hidden"}`}><span class="font-medium">Oops!</span> Email already
          taken!</p>
          <div className={`${emailError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Invalid Email
    </div>
    </div>
    </div>
                    </div>
                    <div className="relative">
                    <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
    <input  onChange={e=>passwordSet(e)} style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black w-full pt-5 borel pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
           <div className={`${passwordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     The password must be at least 8 characters long
    </div>
    </div>
    </div>
    </div>
    
    <div className="relative">
    <p style={{fontSize:'12px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Confirm Password</p>
    <input onChange={e=>confirmPasswordSet(e)}  style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black borel w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
        <div className={`${confirmPasswordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    There is a mismatch between the passwords
    </div>
    </div>
    </div>
    </div> 
    
    <div className={`${matchedError == 'error' ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-1 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    Must Complete the captcha
    </div>
    </div>
    </div>
    <ReCAPTCHA
    sitekey="6LfjD5UpAAAAAByAW_jN78Xl7-36jAOPDUbwvVLt"
    onChange={captchaOnChange}
  /> 
     <div className="relative" >
  {
    isLoading ?
    <div style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
    rounded-lg transition duration-200 bg-[#1333c0] cursor-not-allowed ease cursor-pointer">Sign Up
 
<div role="status">
<svg aria-hidden="true" class="w-5 h-5 ml-2 text-white animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
<span class="sr-only">Loading...</span>
</div>
</div>
:
<button type='submit' style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
rounded-lg transition duration-200 bg-[#2141ce] hover:bg-[#1333c0] cursor-pointer">Sign Up
</button>
  }
    </div>
    <div className="sign_up_link space-x-1 mt-4">
        <p className="text-sm font-light fjalla text-center" style={{fontSize:'12px'}}>Already a member? <Link to="/signin"><span className="text-blue-500 hover:text-blue-600 text-sm font-light hover:underline" >Sign in</span></Link></p> 
    </div>
    </form>
         </div>
         <svg viewbox="0 0 91 91" class="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
             fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
             cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
             r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
             cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
             transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
             r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
             cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
             r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
             cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
             cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
             r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
             transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
             r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
             cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
             r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
             cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
             cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
             r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
             transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
             r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
             cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
             r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
             cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
             cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
             r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
             transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
             r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
             cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
             r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
         <svg viewbox="0 0 91 91" class="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
             fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
             cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
             r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
             cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
             transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
             r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
             cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
             r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
             cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
             cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
             r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
             transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
             r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
             cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
             r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
             cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
             cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
             r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
             transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
             r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
             cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
             r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
             cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
             cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
             r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
             transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
             r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
             cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
             r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
       </div>
   
 
   </div>
  
  </div>
  :
  <div className="flex justify-between items-center">
    
    <div style={{width:'40%'}} className=" h-full">
      
    <div className="absolute top-0 let-0 ">
        <div>
          <Link  to='/' style={{width:'12%',display:'flex'}} className='mt-10 ml-10'>
              <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />

          </Link>
    </div>
    </div>
          <img style={{width:'80%'}} className='mx-auto mt-14' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/Add%20a%20heading%20(1).png?alt=media&token=4aee755f-36c5-4f31-8aff-d1b3b70f52b8" alt="Teacher" />
          
        
     
   
    </div>
    <div style={{width:'60%'}} className="flex justify-center flex-col py-10">
       
       
        <div class="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 ">
          <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-md border rounded-xl
              relative z-10">
            <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Student Sign Up</p>
            <form onSubmit={studentSignUp} autoComplete="off" className=" w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8" >
    
    <div className="relative my-10 w-full">
    <p style={{fontSize:'12px'}} className="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
    <input style={{fontSize:'11px',height:'60px'}}  onChange={e=>nameSet(e)}   autoComplete="off"  placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
        focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
        border-gray-300 rounded-md input-field-signUp"/>
         <div className={`${nameError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Name Can't be empty
    </div>
    </div>
    </div>
    
    </div>
    <div className="relative my-10 w-full">
    <p style={{fontSize:'12px'}} className="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">School</p>
    <input style={{fontSize:'11px',height:'60px'}}  onChange={e=>schoolSet(e)}   autoComplete="off"  placeholder="Addis Ababa University" type="text" className="border  focus:outline-none
        focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
        border-gray-300 rounded-md input-field-signUp"/>
         <div className={`${schoolError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     School Can't be empty
    </div>
    </div>
    </div>
    
    </div>
    <div class="relative  m-auto mb-10">
                <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Region</p>
    
                      <select style={{fontSize:'11px',height:'60px'}}  onChange={e=>selectRegion(e)} placeholder="abebe@gmail.com" type="text" class="border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md input-field-signUp">
                 
                 <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Select Region" >Select Region</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Addis Ababa">Addis Ababa</option>
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Afar">Afar</option>
    
          <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Benshangul-Gumz">Benshangul-Gumz</option>     
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Dire Dawa" >Dire Dawa</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Gambela">Gambela</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Harari">Harari</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Oromia">Oromia</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Somali">Somali</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md capitalize' value="Southern Nations Nationalities and people's region">Southern Nations Nationalities and people's region</option>
              <option className='border  focus:outline-none
                    focus:border-black w-full borel pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                    border-gray-300 rounded-md' value="Tigray">Tigray</option>
    
    </select> 
    
    <div className={`${regionError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Region Can't be empty
    </div>
    </div>
    </div>
              </div>
              <div class="relative">
                      <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                          absolute">Email</p>
                      <input  style={{fontSize:'11px',height:'60px'}} autocomplete="off" onChange={e=>emailSet(e)} placeholder="abebe@gmail.com " type="email" 
                      class={`${duplicate == false && "border  focus:outline-none focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white border-gray-300 rounded-md lowercase input-field-signUp" }
                      ${duplicate == true && "input-field-signUp lowercase bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg pt-5 pr-4 pb-5 pl-4 focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400" }
                      
                      `}/>
                          <p class={`${duplicate == true && "mt-2 text-sm text-red-600 dark:text-red-500" } ${duplicate == false && "hidden"}`}><span class="font-medium">Oops!</span> Email already
          taken!</p>
          <div className={`${emailError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Invalid Email
    </div>
    </div>
    </div>
                    </div>
                    <div className="relative">
                    <p style={{fontSize:'12px'}} class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Password</p>
    <input  onChange={e=>passwordSet(e)} style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black w-full pt-5 borel pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
           <div className={`${passwordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     The password must be at least 8 characters long
    </div>
    </div>
    </div>
    </div>
    
    <div className="relative">
    <p style={{fontSize:'12px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Confirm Password</p>
    <input onChange={e=>confirmPasswordSet(e)}  style={{fontSize:'11px',height:'60px'}}   autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
      focus:border-black borel w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md input-field-signUp"/>
        <div className={`${confirmPasswordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    There is a mismatch between the passwords
    </div>
    </div>
    </div>
    </div> 
    
    <div className={`${matchedError == 'error' ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-1 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    Must Complete the captcha
    </div>
    </div>
    </div>
    <ReCAPTCHA
    sitekey="6LfjD5UpAAAAAByAW_jN78Xl7-36jAOPDUbwvVLt"
    onChange={captchaOnChange}
  /> 
    <div className="relative" >
  {
    isLoading ?
    <div style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
    rounded-lg transition duration-200 bg-[#1333c0] cursor-not-allowed ease cursor-pointer">Sign Up
 
<div role="status">
<svg aria-hidden="true" class="w-5 h-5 ml-2 text-white animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
<span class="sr-only">Loading...</span>
</div>
</div>
:
<button type='submit' style={{height:'60px',fontSize:'15px',color:'white'}}  className="fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
rounded-lg transition duration-200 bg-[#2141ce] hover:bg-[#1333c0] cursor-pointer">Sign Up
</button>
  }
    </div>
    <div className="sign_up_link space-x-1 mt-4">
        <p className="text-sm font-light fjalla text-center" style={{fontSize:'12px'}}>Already a member? <Link to="/signin"><span className="text-blue-500 hover:text-blue-600 text-sm font-light hover:underline" >Sign in</span></Link></p> 
    </div>

    </form>
          </div>
          <svg viewbox="0 0 91 91" class="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
              fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
              cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
              r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
              cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
              transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
              r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
              cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
              r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
              cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
              cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
              r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
              transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
              r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
              cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
              r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
              cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
              cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
              r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
              transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
              r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
              cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
              r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
              cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
              cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
              r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
              transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
              r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
              cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
              r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
          <svg viewbox="0 0 91 91" class="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
              fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
              cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
              r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
              cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
              transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
              r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
              cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
              r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
              cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
              cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
              r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
              transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
              r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
              cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
              r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
              cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
              cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
              r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
              transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
              r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
              cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
              r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
              cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
              cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
              r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
              transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
              r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
              cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
              r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
        </div>
      <div className="MuiGrid-root MuiGrid-container MuiGrid-item css-lptq43">
        <div className="MuiGrid-root MuiGrid-container css-1d3bbye">
          <div className="w-full">
         
          </div>
        </div>
      </div>
  
    </div>
  </div>

}

</div> 


    </div>
  )
}
