import React,{useEffect,useState} from 'react'
import Nav from './Nav'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { onAuthStateChanged,signOut } from 'firebase/auth'
import {auth, firestore} from '../firebase'
import { collection, doc, onSnapshot,deleteDoc } from 'firebase/firestore'
export default function PricingPage() {
    const {user} = useParams()
    const [institutes,setInstitutes] = useState(false)
    const [oneMonth,setoneMonth] = useState(true)
    const [threeMonth,setthreeMonth] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)

    
    const [year,setyear] = useState(false)
    const [pop,setpop] = useState(false)
    const [spinner,setspinner] = useState(true)
    const [uid,setuid] = useState([])



    const [User,setuser]=useState([])
    const [transaction,settransaction]=useState([])
    const [notification,setnotification]=useState([])


  const navigate = useNavigate() 

  const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
function handleLogOut(){
  signOut(auth).then(
   navigate('/')
  )
 }const convertTimestampToDate = (timestamp) => {
   const date = timestamp.toDate();
   const currentDate = new Date();
   const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
 
   const secondsInMinute = 60;
   const secondsInHour = 3600;
   const secondsInDay = 86400;
   const secondsInWeek = 604800;
   const secondsInMonth = 2592000;
   const secondsInYear = 31536000;
 
   let relativeTime = '';
 
   if (timeDifferenceInSeconds < secondsInMinute) {
     relativeTime = `${timeDifferenceInSeconds}s ago`;
   } else if (timeDifferenceInSeconds < secondsInHour) {
     const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
     relativeTime = `${minutes}m ago`;
   } else if (timeDifferenceInSeconds < secondsInDay) {
     const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
     relativeTime = `${hours}h ago`;
   } else if (timeDifferenceInSeconds < secondsInWeek) {
     const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
     relativeTime = `${days}d ago`;
   } else if (timeDifferenceInSeconds < secondsInMonth) {
     const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
     relativeTime = `${weeks}w ago`;
   } else if (timeDifferenceInSeconds < secondsInYear) {
     const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
     relativeTime = `${months}mo ago`;
   } else {
     const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
     relativeTime = `${years}y ago`;
   }
 
   return relativeTime;
 };
 
    function removeNotification(id){
     const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
 
     deleteDoc(notificationRef)
     .then(() => {
       // Document successfully deleted from the database
       // Now remove the item from the array
       setnotification(prevArray => prevArray.filter(item => item.id !== id));
     })
     .catch((error) => {
       console.error("Error deleting document: ", error);
     });
 
 
    }
  
  function desktopSpinnerHandler(){
    setspinner(false)
   }

  function mobileNavPop(){
    setpop(true)


  

}

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)



}

    function oneMonthTeacher(){
      if(oneMonth == false){
        setoneMonth(true)
        setyear(false)
        setthreeMonth(false)
  
      }
  
   
  
    }
  
    function threeMonthTeacher(){
      if(threeMonth == false){
        setthreeMonth(true)
        setoneMonth(false)
        setyear(false)
  
      }
  
    }
    function yearTeacher(){
      if(year == false){
        setoneMonth(false)
        setyear(true)
        setthreeMonth(false)
  
      }
   
  
    }

   
    function toInstitutesDesktop(){
      if(institutes == false){
   setInstitutes(true)
   navigate('/pricing/institute')
  
      }
    
  
  
    }
  
    function toTeacherDesktop(){
      if(institutes == true){
        setInstitutes(false)
   navigate('/pricing/teacher')

       
           }
       
  
    }
  
    


 

 


    useEffect(() => {
  
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
     
        onSnapshot(doc(firestore,'TotalUsers',uid),snapshot=>{
            let temp = []
            temp.push({...snapshot.data()})
            setuser(temp)

        })
    
    
    
    
        onSnapshot(collection(firestore,'AccountDetails',uid,'Transactions'),doc=>{
          let temp = []
          doc.docs.forEach(snapshot=>{
            temp.push({...snapshot.data()})
            settransaction(temp)
          })
        

      })
    
              
    
                
            
    
            
        
    
               
    
               
               
    
                  
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);

    function desktopSpinnerHandler(){
      setspinner(false)
    }
    
  return (
    <div>
      {
        
        spinner == true &&
        <div id='spinnerDesktop'>
        <div
    class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-[#31ffaa] align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
    role="status">
    <span
      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
      >Loading...</span
    >
  </div>
  
        </div>
      }

        <div>
{
   User.length !=0 ?
   User.map(i=>{
       const {accountType,billingDate,isPending,uid,plan} = i
       return(
           <div>
            
   {
    isPending == false ?
      <div>
            <div id="desktop">

{
user == 'institute' ?
<section

className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>
<h2
style={{fontSize:'3vw'}}
       className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
     >
       Our Pricing Plan
     </h2>
<div className='flex-col'>
<ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">



<li onClick={toTeacherDesktop} class=" flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",margin:0}}>

Teachers

</li>


<li  onClick={toInstitutesDesktop}class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 " style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>

<p className='block text-gray-900 text-center '>Institutes</p>

</li>
</ol>
<div className="flex justify-between">
<div>

</div>
<div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
           <div class="sm:-mx-0.5 flex">{
             oneMonth == true ?
               <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


           }

           {
             threeMonth == true ?
               <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


           }
                                 {
             year == true ?
               <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


           }
           </div>
       </div>
</div>
</div>
{
oneMonth == true ?
<div className="container mx-auto">
<div className="-mx-4 flex flex-wrap">
<div className="w-full px-4">
<div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">



</div>
</div>
</div>

<div className="-mx-4 flex flex-wrap justify-center">
<div className="w-full px-4 md: lg:w-1/3">
<div
 className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
 <span className="mb-4 block text-lg font-semibold text-primary">
  Basic
 </span>
 <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   <span>20,000 ETB</span>
 </h2>
 <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
 >
   Perfect for using in a personal website or a client project.
 </p>
 <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Progress Tracking and Assessment
   </p>

   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Teacher Support
   </p>
 </div>
<Link to="/signup/institute">

<button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
 >
   Choose Plan
 </button></Link>

 <div>
   <span className="absolute right-0 top-7 z-[-1]">
     <svg
       width="77"
       height="172"
       viewBox="0 0 77 172"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
       <defs>
         <linearGradient
           id="paint0_linear"
           x1="86"
           y1="0"
           x2="86"
           y2="172"
           gradientUnits="userSpaceOnUse"
         >
           <stop stopColor="#3056D3" stopOpacity="0.09" />
           <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
         </linearGradient>
       </defs>
     </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
     <svg
       width="41"
       height="89"
       viewBox="0 0 41 89"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle
         cx="38.9138"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 38.9138 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 38.9138 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 38.9138 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 38.9138 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 38.9138 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 38.9138 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 38.9138 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="1.42021"
         r="1.42021"
         transform="rotate(180 38.9138 1.42021)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 26.4157 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 26.4157 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 26.4157 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 26.4157 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 26.4157 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 26.4157 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 26.4157 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 26.4157 1.4202)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 13.9177 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 13.9177 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 13.9177 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 13.9177 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 13.9177 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 13.9177 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 13.9177 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="1.42019"
         r="1.42021"
         transform="rotate(180 13.9177 1.42019)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 1.41963 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 1.41963 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 1.41963 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 1.41963 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 1.41963 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 1.41963 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 1.41963 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 1.41963 1.4202)"
         fill="#3056D3"
       />
     </svg>
   </span>
 </div>
</div>
</div>

<div className="w-full px-4 md: lg:w-1/3">
<div
 className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
 <span className="mb-4 block text-lg font-semibold text-primary ">
  Premium
 </span>
 <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
   <span>50,000 ETB</span>
 </h2>
 <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
 >
   Perfect for using in a Professional website or a client project.
 </p>
 <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Progress Tracking and Assessment
   </p>

   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Teacher Support
   </p>
 </div>
<Link to="/signup/institute">
<button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
 >
   Choose Plan
 </button>
</Link>

 <div>
   <span className="absolute right-0 top-7 z-[-1]">
     <svg
       width="77"
       height="172"
       viewBox="0 0 77 172"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
       <defs>
         <linearGradient
           id="paint0_linear"
           x1="86"
           y1="0"
           x2="86"
           y2="172"
           gradientUnits="userSpaceOnUse"
         >
           <stop stopColor="#3056D3" stopOpacity="0.09" />
           <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
         </linearGradient>
       </defs>
     </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
     <svg
       width="41"
       height="89"
       viewBox="0 0 41 89"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle
         cx="38.9138"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 38.9138 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 38.9138 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 38.9138 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 38.9138 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 38.9138 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 38.9138 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 38.9138 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="1.42021"
         r="1.42021"
         transform="rotate(180 38.9138 1.42021)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 26.4157 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 26.4157 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 26.4157 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 26.4157 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 26.4157 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 26.4157 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 26.4157 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 26.4157 1.4202)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 13.9177 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 13.9177 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 13.9177 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 13.9177 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 13.9177 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 13.9177 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 13.9177 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="1.42019"
         r="1.42021"
         transform="rotate(180 13.9177 1.42019)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 1.41963 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 1.41963 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 1.41963 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 1.41963 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 1.41963 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 1.41963 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 1.41963 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 1.41963 1.4202)"
         fill="#3056D3"
       />
     </svg>
   </span>
 </div>
</div>
</div>
</div>
</div>
:
threeMonth == true ?
<div className="container mx-auto">
<div className="-mx-4 flex flex-wrap">
<div className="w-full px-4">
<div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">



</div>
</div>
</div>

<div className="-mx-4 flex flex-wrap justify-center">
<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
 Basic
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
 <span>30,000 ETB</span>
</h2>
<p
 className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
 Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Unlimited Courses
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Certification and Recognition
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Progress Tracking and Assessment
 </p>

 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Teacher Support
 </p>
</div>
<Link to="/signup/institute">
<button
 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
 Choose Plan
</button></Link>

<div>
 <span className="absolute right-0 top-7 z-[-1]">
   <svg
     width="77"
     height="172"
     viewBox="0 0 77 172"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
     <defs>
       <linearGradient
         id="paint0_linear"
         x1="86"
         y1="0"
         x2="86"
         y2="172"
         gradientUnits="userSpaceOnUse"
       >
         <stop stopColor="#3056D3" stopOpacity="0.09" />
         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
       </linearGradient>
     </defs>
   </svg>
 </span>
 <span className="absolute right-4 top-4 z-[-1]">
   <svg
     width="41"
     height="89"
     viewBox="0 0 41 89"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle
       cx="38.9138"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 38.9138 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 38.9138 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 38.9138 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 38.9138 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 38.9138 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 38.9138 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 38.9138 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="1.42021"
       r="1.42021"
       transform="rotate(180 38.9138 1.42021)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 26.4157 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 26.4157 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 26.4157 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 26.4157 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 26.4157 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 26.4157 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 26.4157 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 26.4157 1.4202)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 13.9177 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 13.9177 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 13.9177 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 13.9177 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 13.9177 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 13.9177 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 13.9177 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="1.42019"
       r="1.42021"
       transform="rotate(180 13.9177 1.42019)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 1.41963 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 1.41963 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 1.41963 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 1.41963 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 1.41963 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 1.41963 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 1.41963 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 1.41963 1.4202)"
       fill="#3056D3"
     />
   </svg>
 </span>
</div>
</div>
</div>

<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
 Premium
</span>
<h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
 <span>75,000 ETB</span>
</h2>
<p
 className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
 Perfect for using in a Professional website or a client project.
</p>
<div className="mb-7">
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   15 Courses
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Certification and Recognition
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Progress Tracking and Assessment
 </p>

 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Teacher Support
 </p>
</div>
<Link to="/signup/institute">

<button
 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
 Choose Plan
</button>
</Link>

<div>
 <span className="absolute right-0 top-7 z-[-1]">
   <svg
     width="77"
     height="172"
     viewBox="0 0 77 172"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
     <defs>
       <linearGradient
         id="paint0_linear"
         x1="86"
         y1="0"
         x2="86"
         y2="172"
         gradientUnits="userSpaceOnUse"
       >
         <stop stopColor="#3056D3" stopOpacity="0.09" />
         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
       </linearGradient>
     </defs>
   </svg>
 </span>
 <span className="absolute right-4 top-4 z-[-1]">
   <svg
     width="41"
     height="89"
     viewBox="0 0 41 89"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle
       cx="38.9138"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 38.9138 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 38.9138 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 38.9138 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 38.9138 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 38.9138 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 38.9138 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 38.9138 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="1.42021"
       r="1.42021"
       transform="rotate(180 38.9138 1.42021)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 26.4157 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 26.4157 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 26.4157 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 26.4157 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 26.4157 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 26.4157 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 26.4157 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 26.4157 1.4202)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 13.9177 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 13.9177 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 13.9177 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 13.9177 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 13.9177 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 13.9177 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 13.9177 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="1.42019"
       r="1.42021"
       transform="rotate(180 13.9177 1.42019)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 1.41963 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 1.41963 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 1.41963 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 1.41963 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 1.41963 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 1.41963 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 1.41963 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 1.41963 1.4202)"
       fill="#3056D3"
     />
   </svg>
 </span>
</div>
</div>
</div>
</div>
</div>
:
<div className="container mx-auto">
<div className="-mx-4 flex flex-wrap">
<div className="w-full px-4">
<div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">



</div>
</div>
</div>

<div className="-mx-4 flex flex-wrap justify-center">
<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
Basic
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
<span>60,000 ETB</span>
</h2>
<p
className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Unlimited Courses
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Certification and Recognition
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Progress Tracking and Assessment
</p>

<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Teacher Support
</p>
</div>

<Link to="/signup/institute">

<button
className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
Choose Plan
</button></Link>

<div>
<span className="absolute right-0 top-7 z-[-1]">
 <svg
   width="77"
   height="172"
   viewBox="0 0 77 172"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
   <defs>
     <linearGradient
       id="paint0_linear"
       x1="86"
       y1="0"
       x2="86"
       y2="172"
       gradientUnits="userSpaceOnUse"
     >
       <stop stopColor="#3056D3" stopOpacity="0.09" />
       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
     </linearGradient>
   </defs>
 </svg>
</span>
<span className="absolute right-4 top-4 z-[-1]">
 <svg
   width="41"
   height="89"
   viewBox="0 0 41 89"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle
     cx="38.9138"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 38.9138 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 38.9138 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 38.9138 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 38.9138 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 38.9138 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 38.9138 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 38.9138 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="1.42021"
     r="1.42021"
     transform="rotate(180 38.9138 1.42021)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 26.4157 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 26.4157 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 26.4157 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 26.4157 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 26.4157 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 26.4157 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 26.4157 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 26.4157 1.4202)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 13.9177 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 13.9177 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 13.9177 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 13.9177 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 13.9177 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 13.9177 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 13.9177 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="1.42019"
     r="1.42021"
     transform="rotate(180 13.9177 1.42019)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 1.41963 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 1.41963 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 1.41963 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 1.41963 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 1.41963 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 1.41963 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 1.41963 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 1.41963 1.4202)"
     fill="#3056D3"
   />
 </svg>
</span>
</div>
</div>
</div>

<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
Premium
</span>
<h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
<span>150,000 ETB</span>
</h2>
<p
className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
Perfect for using in a Professional website or a client project.
</p>
<div className="mb-7">
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Unlimited Courses
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Certification and Recognition
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Progress Tracking and Assessment
</p>

<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Teacher Support
</p>
</div>
<Link to="/signup/institute">

<button
className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
Choose Plan
</button>
</Link>

<div>
<span className="absolute right-0 top-7 z-[-1]">
 <svg
   width="77"
   height="172"
   viewBox="0 0 77 172"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
   <defs>
     <linearGradient
       id="paint0_linear"
       x1="86"
       y1="0"
       x2="86"
       y2="172"
       gradientUnits="userSpaceOnUse"
     >
       <stop stopColor="#3056D3" stopOpacity="0.09" />
       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
     </linearGradient>
   </defs>
 </svg>
</span>
<span className="absolute right-4 top-4 z-[-1]">
 <svg
   width="41"
   height="89"
   viewBox="0 0 41 89"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle
     cx="38.9138"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 38.9138 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 38.9138 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 38.9138 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 38.9138 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 38.9138 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 38.9138 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 38.9138 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="1.42021"
     r="1.42021"
     transform="rotate(180 38.9138 1.42021)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 26.4157 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 26.4157 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 26.4157 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 26.4157 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 26.4157 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 26.4157 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 26.4157 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 26.4157 1.4202)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 13.9177 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 13.9177 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 13.9177 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 13.9177 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 13.9177 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 13.9177 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 13.9177 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="1.42019"
     r="1.42021"
     transform="rotate(180 13.9177 1.42019)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 1.41963 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 1.41963 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 1.41963 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 1.41963 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 1.41963 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 1.41963 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 1.41963 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 1.41963 1.4202)"
     fill="#3056D3"
   />
 </svg>
</span>
</div>
</div>
</div>
</div>
</div>
}
</section>


:
user == 'teacher' ?
<section

className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>
<h2
       className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
style={{fontSize:'3vw'}}
     
     >
       Our Pricing Plan
     </h2>
<div className='flex-col'>
<ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">




<li onClick={toTeacherDesktop}  class="flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>

Teachers

</li>


<li  onClick={toInstitutesDesktop} class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50" style={{width:"50%",margin:0}}>

<p className='block text-gray-900 text-center '>Institutes</p>

</li>
</ol>
<div className="flex justify-between">
<div>

</div>
<div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
           <div class="sm:-mx-0.5 flex">{
             oneMonth == true ?
               <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


           }

           {
             threeMonth == true ?
               <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


           }
                                 {
             year == true ?
               <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


           }
           </div>
       </div>
</div>
</div>
{
oneMonth == true ?
<div className="container mx-auto">
<div className="-mx-4 flex flex-wrap">
<div className="w-full px-4">
<div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">



</div>
</div>
</div>

<div className="-mx-4 flex flex-wrap justify-center">
<div className="w-full px-4 md: lg:w-1/3">
<div
 className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
 <span className="mb-4 block text-lg font-semibold text-primary">
   Free Plan
 </span>
 <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   <span>0 ETB</span>
 </h2>
 <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
 >
   Perfect for using in a personal website or a client project.
 </p>
 <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     5 Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Progress Tracking and Assessment
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     15% Transactional Fee
   </p>

   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Teacher Support
   </p>
 </div>
<Link to="/signup/teacher">
<button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
 >
   Start For Free
 </button></Link>

 <div>
   <span className="absolute right-0 top-7 z-[-1]">
     <svg
       width="77"
       height="172"
       viewBox="0 0 77 172"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
       <defs>
         <linearGradient
           id="paint0_linear"
           x1="86"
           y1="0"
           x2="86"
           y2="172"
           gradientUnits="userSpaceOnUse"
         >
           <stop stopColor="#3056D3" stopOpacity="0.09" />
           <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
         </linearGradient>
       </defs>
     </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
     <svg
       width="41"
       height="89"
       viewBox="0 0 41 89"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle
         cx="38.9138"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 38.9138 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 38.9138 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 38.9138 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 38.9138 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 38.9138 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 38.9138 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 38.9138 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="1.42021"
         r="1.42021"
         transform="rotate(180 38.9138 1.42021)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 26.4157 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 26.4157 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 26.4157 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 26.4157 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 26.4157 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 26.4157 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 26.4157 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 26.4157 1.4202)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 13.9177 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 13.9177 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 13.9177 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 13.9177 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 13.9177 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 13.9177 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 13.9177 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="1.42019"
         r="1.42021"
         transform="rotate(180 13.9177 1.42019)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 1.41963 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 1.41963 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 1.41963 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 1.41963 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 1.41963 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 1.41963 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 1.41963 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 1.41963 1.4202)"
         fill="#3056D3"
       />
     </svg>
   </span>
 </div>
</div>
</div>
<div className="w-full px-4 md: lg:w-1/3">
<div
 className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
 <span className="mb-4 block text-lg font-semibold text-primary">
   Basic Plan
 </span>
 <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   <span>1,000 ETB</span>
 </h2>
 <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
 >
   Perfect for using in a personal website or a client project.
 </p>
 <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Progress Tracking and Assessment
   </p>

   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     10% Transactional Fee
   </p>

   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Teacher Support
   </p>
 </div>
<Link to="/signup/teacher">

<button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
 >
   Choose Plan
 </button></Link>

 <div>
   <span className="absolute right-0 top-7 z-[-1]">
     <svg
       width="77"
       height="172"
       viewBox="0 0 77 172"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
       <defs>
         <linearGradient
           id="paint0_linear"
           x1="86"
           y1="0"
           x2="86"
           y2="172"
           gradientUnits="userSpaceOnUse"
         >
           <stop stopColor="#3056D3" stopOpacity="0.09" />
           <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
         </linearGradient>
       </defs>
     </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
     <svg
       width="41"
       height="89"
       viewBox="0 0 41 89"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle
         cx="38.9138"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 38.9138 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 38.9138 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 38.9138 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 38.9138 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 38.9138 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 38.9138 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 38.9138 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="1.42021"
         r="1.42021"
         transform="rotate(180 38.9138 1.42021)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 26.4157 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 26.4157 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 26.4157 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 26.4157 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 26.4157 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 26.4157 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 26.4157 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 26.4157 1.4202)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 13.9177 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 13.9177 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 13.9177 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 13.9177 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 13.9177 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 13.9177 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 13.9177 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="1.42019"
         r="1.42021"
         transform="rotate(180 13.9177 1.42019)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 1.41963 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 1.41963 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 1.41963 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 1.41963 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 1.41963 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 1.41963 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 1.41963 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 1.41963 1.4202)"
         fill="#3056D3"
       />
     </svg>
   </span>
 </div>
</div>
</div>

<div className="w-full px-4 md: lg:w-1/3">
<div
 className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
 <span className="mb-4 block text-lg font-semibold text-primary">
   Premium Plan
 </span>
 <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

   <span>5,000 ETB</span>

 </h2>
 <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
 >
   Perfect for using in a Professional website or a client project.
 </p>
 <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Progress Tracking and Assessment
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     5% Transactional Fee
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
     Teacher Support
   </p>
 </div>
<Link to="/signup/teacher">

<button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
 >
   Choose Plan
 </button>
</Link>

 <div>
   <span className="absolute right-0 top-7 z-[-1]">
     <svg
       width="77"
       height="172"
       viewBox="0 0 77 172"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
       <defs>
         <linearGradient
           id="paint0_linear"
           x1="86"
           y1="0"
           x2="86"
           y2="172"
           gradientUnits="userSpaceOnUse"
         >
           <stop stopColor="#3056D3" stopOpacity="0.09" />
           <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
         </linearGradient>
       </defs>
     </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
     <svg
       width="41"
       height="89"
       viewBox="0 0 41 89"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <circle
         cx="38.9138"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 38.9138 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 38.9138 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 38.9138 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 38.9138 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 38.9138 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 38.9138 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 38.9138 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="38.9138"
         cy="1.42021"
         r="1.42021"
         transform="rotate(180 38.9138 1.42021)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 26.4157 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 26.4157 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 26.4157 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 26.4157 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 26.4157 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 26.4157 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 26.4157 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="26.4157"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 26.4157 1.4202)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 13.9177 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 13.9177 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 13.9177 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 13.9177 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 13.9177 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 13.9177 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 13.9177 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="13.9177"
         cy="1.42019"
         r="1.42021"
         transform="rotate(180 13.9177 1.42019)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="87.4849"
         r="1.42021"
         transform="rotate(180 1.41963 87.4849)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="74.9871"
         r="1.42021"
         transform="rotate(180 1.41963 74.9871)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="62.4892"
         r="1.42021"
         transform="rotate(180 1.41963 62.4892)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="38.3457"
         r="1.42021"
         transform="rotate(180 1.41963 38.3457)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="13.634"
         r="1.42021"
         transform="rotate(180 1.41963 13.634)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="50.2754"
         r="1.42021"
         transform="rotate(180 1.41963 50.2754)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="26.1319"
         r="1.42021"
         transform="rotate(180 1.41963 26.1319)"
         fill="#3056D3"
       />
       <circle
         cx="1.41963"
         cy="1.4202"
         r="1.42021"
         transform="rotate(180 1.41963 1.4202)"
         fill="#3056D3"
       />
     </svg>
   </span>
 </div>
</div>
</div>
</div>
</div>
:
threeMonth == true ?
<div className="container mx-auto">
<div className="-mx-4 flex flex-wrap">
<div className="w-full px-4">
<div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">



</div>
</div>
</div>

<div className="-mx-4 flex flex-wrap justify-center">
<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
 Free Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
 <span>0 ETB</span>
</h2>
<p
 className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
 Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   5 Courses
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Progress Tracking and Assessment
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   15% Transactional Fee
 </p>

 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Teacher Support
 </p>
</div>
<Link to="/signup/teacher">

<button
 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
 Start For Free
</button></Link>

<div>
 <span className="absolute right-0 top-7 z-[-1]">
   <svg
     width="77"
     height="172"
     viewBox="0 0 77 172"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
     <defs>
       <linearGradient
         id="paint0_linear"
         x1="86"
         y1="0"
         x2="86"
         y2="172"
         gradientUnits="userSpaceOnUse"
       >
         <stop stopColor="#3056D3" stopOpacity="0.09" />
         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
       </linearGradient>
     </defs>
   </svg>
 </span>
 <span className="absolute right-4 top-4 z-[-1]">
   <svg
     width="41"
     height="89"
     viewBox="0 0 41 89"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle
       cx="38.9138"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 38.9138 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 38.9138 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 38.9138 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 38.9138 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 38.9138 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 38.9138 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 38.9138 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="1.42021"
       r="1.42021"
       transform="rotate(180 38.9138 1.42021)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 26.4157 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 26.4157 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 26.4157 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 26.4157 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 26.4157 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 26.4157 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 26.4157 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 26.4157 1.4202)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 13.9177 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 13.9177 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 13.9177 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 13.9177 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 13.9177 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 13.9177 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 13.9177 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="1.42019"
       r="1.42021"
       transform="rotate(180 13.9177 1.42019)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 1.41963 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 1.41963 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 1.41963 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 1.41963 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 1.41963 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 1.41963 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 1.41963 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 1.41963 1.4202)"
       fill="#3056D3"
     />
   </svg>
 </span>
</div>
</div>
</div>
<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
 Basic Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
 <span>2,715 ETB</span>
</h2>
<p
 className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
 Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Unlimited Courses
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Certification and Recognition
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Progress Tracking and Assessment
 </p>

 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   10% Transactional Fee
 </p>

 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Teacher Support
 </p>
</div>

<Link to="/signup/teacher">

<button
 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
 Choose Plan
</button></Link>

<div>
 <span className="absolute right-0 top-7 z-[-1]">
   <svg
     width="77"
     height="172"
     viewBox="0 0 77 172"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
     <defs>
       <linearGradient
         id="paint0_linear"
         x1="86"
         y1="0"
         x2="86"
         y2="172"
         gradientUnits="userSpaceOnUse"
       >
         <stop stopColor="#3056D3" stopOpacity="0.09" />
         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
       </linearGradient>
     </defs>
   </svg>
 </span>
 <span className="absolute right-4 top-4 z-[-1]">
   <svg
     width="41"
     height="89"
     viewBox="0 0 41 89"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle
       cx="38.9138"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 38.9138 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 38.9138 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 38.9138 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 38.9138 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 38.9138 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 38.9138 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 38.9138 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="1.42021"
       r="1.42021"
       transform="rotate(180 38.9138 1.42021)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 26.4157 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 26.4157 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 26.4157 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 26.4157 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 26.4157 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 26.4157 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 26.4157 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 26.4157 1.4202)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 13.9177 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 13.9177 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 13.9177 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 13.9177 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 13.9177 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 13.9177 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 13.9177 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="1.42019"
       r="1.42021"
       transform="rotate(180 13.9177 1.42019)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 1.41963 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 1.41963 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 1.41963 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 1.41963 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 1.41963 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 1.41963 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 1.41963 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 1.41963 1.4202)"
       fill="#3056D3"
     />
   </svg>
 </span>
</div>
</div>
</div>

<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
 Premium Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

 <span>13,600 ETB</span>

</h2>
<p
 className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
 Perfect for using in a Professional website or a client project.
</p>
<div className="mb-7">
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Unlimited Courses
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Certification and Recognition
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Progress Tracking and Assessment
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   5% Transactional Fee
 </p>
 <p className="mb-1 Mooli text-base leading-loose text-body-color">
   Teacher Support
 </p>
</div>
<Link to="/signup/teacher">

<button
 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
 Choose Plan
</button>
</Link>

<div>
 <span className="absolute right-0 top-7 z-[-1]">
   <svg
     width="77"
     height="172"
     viewBox="0 0 77 172"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
     <defs>
       <linearGradient
         id="paint0_linear"
         x1="86"
         y1="0"
         x2="86"
         y2="172"
         gradientUnits="userSpaceOnUse"
       >
         <stop stopColor="#3056D3" stopOpacity="0.09" />
         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
       </linearGradient>
     </defs>
   </svg>
 </span>
 <span className="absolute right-4 top-4 z-[-1]">
   <svg
     width="41"
     height="89"
     viewBox="0 0 41 89"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <circle
       cx="38.9138"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 38.9138 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 38.9138 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 38.9138 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 38.9138 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 38.9138 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 38.9138 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 38.9138 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="38.9138"
       cy="1.42021"
       r="1.42021"
       transform="rotate(180 38.9138 1.42021)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 26.4157 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 26.4157 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 26.4157 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 26.4157 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 26.4157 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 26.4157 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 26.4157 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="26.4157"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 26.4157 1.4202)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 13.9177 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 13.9177 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 13.9177 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 13.9177 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 13.9177 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 13.9177 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 13.9177 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="13.9177"
       cy="1.42019"
       r="1.42021"
       transform="rotate(180 13.9177 1.42019)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="87.4849"
       r="1.42021"
       transform="rotate(180 1.41963 87.4849)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="74.9871"
       r="1.42021"
       transform="rotate(180 1.41963 74.9871)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="62.4892"
       r="1.42021"
       transform="rotate(180 1.41963 62.4892)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="38.3457"
       r="1.42021"
       transform="rotate(180 1.41963 38.3457)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="13.634"
       r="1.42021"
       transform="rotate(180 1.41963 13.634)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="50.2754"
       r="1.42021"
       transform="rotate(180 1.41963 50.2754)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="26.1319"
       r="1.42021"
       transform="rotate(180 1.41963 26.1319)"
       fill="#3056D3"
     />
     <circle
       cx="1.41963"
       cy="1.4202"
       r="1.42021"
       transform="rotate(180 1.41963 1.4202)"
       fill="#3056D3"
     />
   </svg>
 </span>
</div>
</div>
</div>
</div>
</div>
:
<div className="container mx-auto">
<div className="-mx-4 flex flex-wrap">
<div className="w-full px-4">
<div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">



</div>
</div>
</div>

<div className="-mx-4 flex flex-wrap justify-center">
<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
Free Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
<span>0 ETB</span>
</h2>
<p
className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 5 Courses
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Progress Tracking and Assessment
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 15% Transactional Fee
</p>

<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Teacher Support
</p>
</div>
<Link to="/signup/teacher">
  
<button
className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
Start For Free
</button></Link>

<div>
<span className="absolute right-0 top-7 z-[-1]">
 <svg
   width="77"
   height="172"
   viewBox="0 0 77 172"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
   <defs>
     <linearGradient
       id="paint0_linear"
       x1="86"
       y1="0"
       x2="86"
       y2="172"
       gradientUnits="userSpaceOnUse"
     >
       <stop stopColor="#3056D3" stopOpacity="0.09" />
       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
     </linearGradient>
   </defs>
 </svg>
</span>
<span className="absolute right-4 top-4 z-[-1]">
 <svg
   width="41"
   height="89"
   viewBox="0 0 41 89"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle
     cx="38.9138"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 38.9138 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 38.9138 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 38.9138 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 38.9138 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 38.9138 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 38.9138 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 38.9138 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="1.42021"
     r="1.42021"
     transform="rotate(180 38.9138 1.42021)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 26.4157 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 26.4157 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 26.4157 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 26.4157 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 26.4157 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 26.4157 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 26.4157 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 26.4157 1.4202)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 13.9177 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 13.9177 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 13.9177 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 13.9177 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 13.9177 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 13.9177 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 13.9177 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="1.42019"
     r="1.42021"
     transform="rotate(180 13.9177 1.42019)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 1.41963 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 1.41963 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 1.41963 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 1.41963 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 1.41963 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 1.41963 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 1.41963 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 1.41963 1.4202)"
     fill="#3056D3"
   />
 </svg>
</span>
</div>
</div>
</div>
<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
Basic Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
<span>7,980 ETB</span>
</h2>
<p
className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Unlimited Courses
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Certification and Recognition
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Progress Tracking and Assessment
</p>

<p className="mb-1 Mooli text-base leading-loose text-body-color">
 10% Transactional Fee
</p>

<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Teacher Support
</p>
</div>

<Link to="/signup/teacher">

<button
className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
Choose Plan
</button></Link>

<div>
<span className="absolute right-0 top-7 z-[-1]">
 <svg
   width="77"
   height="172"
   viewBox="0 0 77 172"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
   <defs>
     <linearGradient
       id="paint0_linear"
       x1="86"
       y1="0"
       x2="86"
       y2="172"
       gradientUnits="userSpaceOnUse"
     >
       <stop stopColor="#3056D3" stopOpacity="0.09" />
       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
     </linearGradient>
   </defs>
 </svg>
</span>
<span className="absolute right-4 top-4 z-[-1]">
 <svg
   width="41"
   height="89"
   viewBox="0 0 41 89"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle
     cx="38.9138"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 38.9138 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 38.9138 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 38.9138 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 38.9138 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 38.9138 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 38.9138 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 38.9138 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="1.42021"
     r="1.42021"
     transform="rotate(180 38.9138 1.42021)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 26.4157 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 26.4157 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 26.4157 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 26.4157 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 26.4157 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 26.4157 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 26.4157 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 26.4157 1.4202)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 13.9177 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 13.9177 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 13.9177 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 13.9177 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 13.9177 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 13.9177 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 13.9177 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="1.42019"
     r="1.42021"
     transform="rotate(180 13.9177 1.42019)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 1.41963 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 1.41963 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 1.41963 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 1.41963 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 1.41963 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 1.41963 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 1.41963 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 1.41963 1.4202)"
     fill="#3056D3"
   />
 </svg>
</span>
</div>
</div>
</div>

<div className="w-full px-4 md: lg:w-1/3">
<div
className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
Premium Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

<span>39,900 ETB</span>

</h2>
<p
className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
Perfect for using in a Professional website or a client project.
</p>
<div className="mb-7">
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Unlimited Courses
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Certification and Recognition
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Progress Tracking and Assessment
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 5% Transactional Fee
</p>
<p className="mb-1 Mooli text-base leading-loose text-body-color">
 Teacher Support
</p>
</div>

<Link to="/signup/teacher">

<button
className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
Choose Plan
</button>
</Link>

<div>
<span className="absolute right-0 top-7 z-[-1]">
 <svg
   width="77"
   height="172"
   viewBox="0 0 77 172"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
   <defs>
     <linearGradient
       id="paint0_linear"
       x1="86"
       y1="0"
       x2="86"
       y2="172"
       gradientUnits="userSpaceOnUse"
     >
       <stop stopColor="#3056D3" stopOpacity="0.09" />
       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
     </linearGradient>
   </defs>
 </svg>
</span>
<span className="absolute right-4 top-4 z-[-1]">
 <svg
   width="41"
   height="89"
   viewBox="0 0 41 89"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
 >
   <circle
     cx="38.9138"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 38.9138 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 38.9138 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 38.9138 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 38.9138 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 38.9138 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 38.9138 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 38.9138 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="38.9138"
     cy="1.42021"
     r="1.42021"
     transform="rotate(180 38.9138 1.42021)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 26.4157 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 26.4157 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 26.4157 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 26.4157 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 26.4157 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 26.4157 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 26.4157 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="26.4157"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 26.4157 1.4202)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 13.9177 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 13.9177 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 13.9177 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 13.9177 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 13.9177 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 13.9177 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 13.9177 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="13.9177"
     cy="1.42019"
     r="1.42021"
     transform="rotate(180 13.9177 1.42019)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="87.4849"
     r="1.42021"
     transform="rotate(180 1.41963 87.4849)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="74.9871"
     r="1.42021"
     transform="rotate(180 1.41963 74.9871)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="62.4892"
     r="1.42021"
     transform="rotate(180 1.41963 62.4892)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="38.3457"
     r="1.42021"
     transform="rotate(180 1.41963 38.3457)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="13.634"
     r="1.42021"
     transform="rotate(180 1.41963 13.634)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="50.2754"
     r="1.42021"
     transform="rotate(180 1.41963 50.2754)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="26.1319"
     r="1.42021"
     transform="rotate(180 1.41963 26.1319)"
     fill="#3056D3"
   />
   <circle
     cx="1.41963"
     cy="1.4202"
     r="1.42021"
     transform="rotate(180 1.41963 1.4202)"
     fill="#3056D3"
   />
 </svg>
</span>
</div>
</div>
</div>
</div>
</div>
}
</section>
:
user != 'institute' & user != 'teacher'  &&
<div class="h-screen  flex items-center">
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
<div class="w-full lg: mx-8">
   <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
<p class="text-2xl md:text-3xl font-light leading-normal mb-8">
   Sorry we couldn't find the page you're looking for
</p>
<Link to='/'>
<a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-[#31ffaa] active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

</Link>
</div>
<div class="w-full lg:flex lg:justify-end lg: mx-5 my-12">
<img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
</div>

</div>
</div>
}




</div>
 <div id="mobile" className='mobile_steps ' style={{marginBottom:'30vw',paddingBottom:'10vw',width:"100%",margin:0}}>
 <nav className='flex justify-between navMobile  w-full '>

<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={mobileNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"10vw",marginLeft:"3vw"}}  fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
</nav>
{
pop == true &&
<div id='popoutMobileHome' className='relative '>
<nav className='flex justify-between navMobile '>
<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={mobileNavPopMinimize} xmlns="http://www.w3.org/2000/svg"  style={{width:"5vw",marginLeft:"3vw"}} fill="currentColor" className="text-blue-800  bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
</div>

</nav>
<ul >

<Link to="/signin">
<li className='listPopoutTablet border-b border-t' >Sign in</li>

</Link>
<Link to="/signup/student">
<li className='listPopoutTablet  border-b' >Sign up</li>


</Link>
<Link to="/aboutus">

<li className='listPopoutTablet  border-b' >About Us</li>
</Link>



<Link to="/pricing/teacher">
<li className='listPopoutTablet  border-b' >Pricing</li>
</Link>


</ul>
<Link to='/contact'>
<button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button></Link>
</div>
}

{
 user == 'institute' && institutes == true ?
 <section
 className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>  
   <h2
   style={{fontSize:'9vw',marginTop:'10vw',paddingBottom:'5vw'}}
           className="text-center anton mb-4 text-blue-800 "
         >
           Our Pricing Plan
         </h2>
         <div className='flex-col'>
 <ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">
 
 
 
 <li onClick={toTeacherDesktop} class=" flex items-center   text-center text-gray-800 justify-center cursor-pointer hover:bg-gray-50 " style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>
 
 Teachers
 
 </li>
 
 
 <li  onClick={toInstitutesDesktop}class="flex items-center justify-center   text-center border-l  cursor-pointer   bg-[#31ffaa]" style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>
 
  <p className='block  text-white text-center '>Institutes</p>
 
 </li>
 </ol>
 <div className="flex justify-between">
     <div style={{width:'40%'}}>
 
     </div>
     <div style={{width:'60%',marginTop:'5vw',marginBottom:'5vw'}} class="overflow-hidden  border rounded-lg">
                   <div class=" flex justify-between">{
                     oneMonth == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none   text-white bg-[#31ffaa]  rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg  fjalla"onClick={oneMonthTeacher}>1 Month</button>


                   }

                   {
                     threeMonth == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white  rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                   }
                                         {
                     year == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none   sm:w-auto py-1  text-gray-800  bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                   }
                   </div>
               </div>
   </div>
 </div>

 {
     oneMonth == true ?
     <div className=" mx-auto">
  
 
 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>20,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>50,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}

             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
   :
   threeMonth == true ?
   <div className=" mx-auto">
  
 
 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>30,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>75,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}

             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
 :
 <div className=" mx-auto">
  
 
 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>60,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>150,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}

             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
    }
   

   
</section>
:
<section
className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>  
 <h2
 style={{fontSize:'9vw',marginTop:'10vw',paddingBottom:'5vw'}}
         className="text-center anton mb-4  text-blue-800"
       >
         Our Pricing Plan
       </h2>
       <div className='flex-col'>
<ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border  rounded-lg shadow-sm ">



<li onClick={toTeacherDesktop} class=" flex items-center text-white text-center justify-center cursor-pointer  bg-[#31ffaa]" style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>

Teachers

</li>


<li  onClick={toInstitutesDesktop}class="flex items-center justify-center   text-center border-l  cursor-pointer   " style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>

<p className='block  text-gray-800 text-center '>Institutes</p>

</li>
</ol>
<div className="flex justify-between">
   <div style={{width:'40%'}}>

   </div>
   <div style={{width:'60%',marginTop:'5vw',marginBottom:'5vw'}} class="overflow-hidden  border rounded-lg">
                   <div class=" flex justify-between">{
                     oneMonth == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none   text-white bg-[#31ffaa]  rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg  fjalla"onClick={oneMonthTeacher}>1 Month</button>


                   }

                   {
                     threeMonth == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white  rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                   }
                                         {
                     year == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none   sm:w-auto py-1  text-gray-800  bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                   }
                   </div>
               </div>
 </div>
</div>

{
   oneMonth == true ?
   <div className=" mx-auto">


<div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
<div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Free Plan
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>0 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             5 Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             15% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/signup/teacher">
     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Basic
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>1,000 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           10% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/signup/teacher">
     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>

     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Premium
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>5,000 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           5% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/signup/teacher">
       
     <button
     style={{fontSize:'3vw',padding:'3vw'}}

           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
   </div>
   </div>
 </div>
 :
 threeMonth == true ?
 <div className=" mx-auto">


 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
 <div class=" pb-10" style={{display:"flex",}}>
 <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Free Plan
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>0 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               5 Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               15% Transactional Fee
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/teacher">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>2,715 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             10% Transactional Fee
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/teacher">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>13,600 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             5% Transactional Fee
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/teacher">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
 
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
:
<div className=" mx-auto">


<div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
<div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Free Plan
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>0 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             5 Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             15% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/signup/teacher">
     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Basic
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>7,980 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           10% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/signup/teacher">
     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>

     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Premium
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>39,900 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           5% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/signup/teacher">
       
     <button
     style={{fontSize:'3vw',padding:'3vw'}}

           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
   </div>
   </div>
 </div>
  }
 

 
</section>
}




</div>
      </div>
    :
      transaction && transaction.map(i=>{
        const {chosenMethod,amount} = i
        return(
          <div class="h-screen   flex items-center">
          <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                  <div class="w-full lg: mx-8">
                      <div style={{fontSize:'3vw',lineHeight:'4vw'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                      <p className='fjalla flex flex-col' style={{fontSize:'1.5vw',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                      </div>
                 
                      <Link to={`/dashboard`}>
                      <button class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                    
                      Check out my account until the payment is verified
                                  </button>
                      </Link>      
       
                  </div>
              <div class="w-full lg:flex lg:justify-end lg: mx-5 my-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
              </div>
          
          </div>
      </div>
        )
      })
    

   }
        
       
            
       
      
       
               </div>
       )
   })
 
   :
  <div>
 <div id="desktop">

<Nav/>
 

{
   user == 'institute' ?
  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 Months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basic3month`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premium3month`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicannual`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiumannual`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>


:
user == 'teacher' ?
<section
  
  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>
   <h2
            className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
style={{fontSize:'40px'}}
          
          >
            Our Pricing Plan
          </h2>
  <div className='flex-col'>
<ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">




<li onClick={toTeacherDesktop}  class="flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>

Teachers

</li>


<li  onClick={toInstitutesDesktop} class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50" style={{width:"50%",margin:0}}>

<p className='block text-gray-900 text-center '>Institutes</p>

</li>
</ol>
<div className="flex justify-between">
<div>

</div>
<div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                <div class="sm:-mx-0.5 flex">{
                  oneMonth == true ?
                    <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
                </div>
            </div>
</div>
</div>
{
      oneMonth == true ?
      <div className=" mx-auto">
  
  
   
  <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'40px'}}>

<div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>
          <div
          
            className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Free Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>0 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 500 students

              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Quizzes are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                25% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Start For Free
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
<div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>

          <div
            className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Basic Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>3,000 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 1,500 students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
             
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">

        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
<div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>

          <div
            className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Premium Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

              <span>8,000 ETB</span>

            </h2>
     
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">

         <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button>
         </Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    threeMonth == true ?
    <div className=" mx-auto">
   

   <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'40px'}}>

   <div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>
          <div
          
            className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Free Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>0 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 500 students

              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Quizzes are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                25% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Start For Free
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
<div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>

        <div
          className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Basic Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>7,650 ETB</span>
          </h2>

             <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 1,500 students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
             
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
      <Link to="/signup/teacher">

      <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

<div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>

        <div
          className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Premium Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

            <span>20,400 ETB</span>

          </h2>
 
           <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
      
      <Link to="/signup/teacher">

       <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button>
       </Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  :
  <div className=" mx-auto">
  

   <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'40px'}}>

   <div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>
          <div
          
            className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Free Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>0 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 500 students

              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Quizzes are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                25% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Start For Free
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
    <div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>

      <div
        className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
      >
        <span className="mb-4 block text-lg font-semibold text-primary">
          Basic Plan
        </span>
        <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
          <span>30,600 ETB</span>
        </h2>
 
        <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 1,500 students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
             
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
    <Link to="/signup/teacher">

    <button
          className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
        >
          Choose Plan
        </button></Link>

        <div>
          <span className="absolute right-0 top-7 z-[-1]">
            <svg
              width="77"
              height="172"
              viewBox="0 0 77 172"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="86"
                  y1="0"
                  x2="86"
                  y2="172"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.09" />
                  <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute right-4 top-4 z-[-1]">
            <svg
              width="41"
              height="89"
              viewBox="0 0 41 89"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="38.9138"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 38.9138 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 38.9138 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 38.9138 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 38.9138 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 38.9138 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 38.9138 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 38.9138 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="1.42021"
                r="1.42021"
                transform="rotate(180 38.9138 1.42021)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 26.4157 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 26.4157 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 26.4157 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 26.4157 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 26.4157 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 26.4157 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 26.4157 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 26.4157 1.4202)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 13.9177 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 13.9177 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 13.9177 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 13.9177 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 13.9177 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 13.9177 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 13.9177 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="1.42019"
                r="1.42021"
                transform="rotate(180 13.9177 1.42019)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 1.41963 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 1.41963 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 1.41963 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 1.41963 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 1.41963 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 1.41963 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 1.41963 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 1.41963 1.4202)"
                fill="#3056D3"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div className="w-full " style={{minWidth:'350px',marginRight:'60px'}}>

      <div
        className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
      >
        <span className="mb-4 block text-lg font-semibold text-primary">
          Premium Plan
        </span>
        <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

        <span>81,600 ETB</span>

        </h2>
    
        <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
    <Link to="/signup/teacher">

     <button
          className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
        >
          Choose Plan
        </button>
     </Link>

        <div>
          <span className="absolute right-0 top-7 z-[-1]">
            <svg
              width="77"
              height="172"
              viewBox="0 0 77 172"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="86"
                  y1="0"
                  x2="86"
                  y2="172"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.09" />
                  <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute right-4 top-4 z-[-1]">
            <svg
              width="41"
              height="89"
              viewBox="0 0 41 89"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="38.9138"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 38.9138 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 38.9138 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 38.9138 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 38.9138 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 38.9138 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 38.9138 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 38.9138 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="1.42021"
                r="1.42021"
                transform="rotate(180 38.9138 1.42021)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 26.4157 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 26.4157 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 26.4157 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 26.4157 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 26.4157 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 26.4157 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 26.4157 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 26.4157 1.4202)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 13.9177 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 13.9177 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 13.9177 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 13.9177 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 13.9177 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 13.9177 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 13.9177 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="1.42019"
                r="1.42021"
                transform="rotate(180 13.9177 1.42019)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 1.41963 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 1.41963 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 1.41963 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 1.41963 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 1.41963 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 1.41963 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 1.41963 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 1.41963 1.4202)"
                fill="#3056D3"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

}
</section>
:
user != 'institute' & user != 'teacher'  &&
<div class="h-screen  flex items-center">
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
    <div class="w-full lg: mx-8">
        <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
    <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
        Sorry we couldn't find the page you're looking for
    </p>
    <Link to='/'>
    <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-[#31ffaa] active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

    </Link>
</div>
<div class="w-full lg:flex lg:justify-end lg: mx-5 my-12">
<img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
</div>

</div>
</div>
}




</div>


<div id="tablet" className='' style={{backgroundColor:''}}>
<nav className='flex justify-between navTablet w-full  pt-5'>

           
<Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>
<Link to="/courses">

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"25px"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</Link>

    <svg onClick={mobileNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
    {
        pop == true &&
        <div id='popoutTabletHome'>
        <nav className='flex justify-between navTablet py-5'>
        <Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>
    
                <div className='flex justify-end'style={{width:'30%'}} >
                    <Link to="/courses">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg></Link>
                    
                    <svg onClick={mobileNavPopMinimize} xmlns="http://www.w3.org/2000/svg"  width="25" height="25" fill="currentColor" className="text-blue-800 menuIcon bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    </div>
        </nav>
        <ul >
            <Link to="/signin">
            <li className='listPopoutTablet border-b border-t' >Sign in</li>
    
             </Link>
             <Link to="/signup/student">
            <li className='listPopoutTablet  border-b' >Sign up</li>
    
    
             </Link>
             <Link to="/aboutus">
            <li className='listPopoutTablet  border-b' >About Us</li>
    
             </Link>
    
             <Link to="/pricing/teacher">
            <li className='listPopoutTablet  border-b' >Pricing</li>
    
             </Link>
    
       
    
        </ul>
      
        
    <Link to='/contact'>
    <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button>
     </Link>   </div>
    }
      {
        user == 'institute' ?
        <section
    
        className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
      >
         <h2
          style={{fontSize:'40px'}}
                  className="mb-10 text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
                >
                  Our Pricing Plan
                </h2>
                <ol class="mt-10 flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">



<li onClick={toTeacherDesktop} class=" bg-white flex items-center text-gray-900  text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",fontSize:'',margin:0}}>

Teachers

</li>


<li  onClick={toInstitutesDesktop}class=" text-white  flex items-center justify-center bg-[#31ffaa]  text-white text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 " style={{width:"50%",fontSize:'',margin:0}}>

<p className='block  text-center '>Institutes</p>

</li>
</ol>
        <div className='flex-col'>
 
  <div className="flex justify-between ">

   
<div style={{width:'20%'}}>

</div>
      <div style={{width:'80%'}} class=" p-0.5 mt-6 border rounded-lg " >
   
                      <div  class=" flex">
                        {
                        oneMonth == true ?
                          <button style={{fontSize:'15px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'15px'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                      }

                      {
                        threeMonth == true ?
                          <button style={{fontSize:'15px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'15px'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                      }
                                            {
                        year == true ?
                          <button style={{fontSize:'15px'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'15px'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                      }
                      </div>
                  </div>
    </div>
    </div>
     {
      oneMonth == true ?
      <div className=" mx-auto">
     
  
  <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'30px'}}>

        <div className="w-full " style={{minWidth:'400px',marginRight:'20px'}}>
          <div
            className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
             Basic
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>20,000 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              Perfect for using in a personal website or a client project.
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/institute">
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
        <div className="w-full  "style={{minWidth:'400px',marginLeft:'50px'}}>
          <div
            className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary ">
             Premium
            </span>
            <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
              <span>50,000 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              Perfect for using in a Professional website or a client project.
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
         <Link to="/signup/institute">
         <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button>
         </Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    threeMonth == true ?
    <div className=" mx-auto">


  <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'30px'}}>

      <div className="w-full " style={{minWidth:'400px',marginRight:'20px'}}>
        <div
          className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Basic
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>30,000 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            Perfect for using in a personal website or a client project.
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
      <Link to="/signup/institute">

      <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className="w-full " style={{minWidth:'400px',marginLeft:'50px'}}>
        <div
          className="shadow-md  relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Premium
          </span>
          <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
            <span>75,000 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            Perfect for using in a Professional website or a client project.
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
    <Link to="/signup/institute">

       <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button>
       </Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  :
  <div className=" mx-auto">


  <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'30px'}}>

    <div className="w-full " style={{minWidth:'400px',marginRight:'20px'}}>
      <div
        className="shadow-md  relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
      >
        <span className="mb-4 block text-lg font-semibold text-primary">
          Basic
        </span>
        <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
          <span>60,000 ETB</span>
        </h2>
        <p
          className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
        >
          Perfect for using in a personal website or a client project.
        </p>
        <div className="mb-7">
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Unlimited Courses
          </p>
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Certification and Recognition
          </p>
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Progress Tracking and Assessment
          </p>
       
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Teacher Support
          </p>
        </div>
    <Link to="/signup/institute">

    <button
          className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
        >
          Choose Plan
        </button></Link>

        <div>
          <span className="absolute right-0 top-7 z-[-1]">
            <svg
              width="77"
              height="172"
              viewBox="0 0 77 172"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="86"
                  y1="0"
                  x2="86"
                  y2="172"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.09" />
                  <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute right-4 top-4 z-[-1]">
            <svg
              width="41"
              height="89"
              viewBox="0 0 41 89"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="38.9138"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 38.9138 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 38.9138 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 38.9138 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 38.9138 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 38.9138 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 38.9138 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 38.9138 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="1.42021"
                r="1.42021"
                transform="rotate(180 38.9138 1.42021)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 26.4157 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 26.4157 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 26.4157 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 26.4157 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 26.4157 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 26.4157 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 26.4157 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 26.4157 1.4202)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 13.9177 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 13.9177 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 13.9177 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 13.9177 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 13.9177 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 13.9177 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 13.9177 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="1.42019"
                r="1.42021"
                transform="rotate(180 13.9177 1.42019)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 1.41963 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 1.41963 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 1.41963 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 1.41963 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 1.41963 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 1.41963 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 1.41963 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 1.41963 1.4202)"
                fill="#3056D3"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div className="w-full " style={{minWidth:'400px',marginLeft:'50px'}}>
      <div
        className="shadow-md  relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
      >
        <span className="mb-4 block text-lg font-semibold text-primary">
          Premium
        </span>
        <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
          <span>150,000 ETB</span>
        </h2>
        <p
          className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
        >
          Perfect for using in a Professional website or a client project.
        </p>
        <div className="mb-7">
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Unlimited Courses
          </p>
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Certification and Recognition
          </p>
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Progress Tracking and Assessment
          </p>
       
          <p className="mb-1 Mooli text-base leading-loose text-body-color">
            Teacher Support
          </p>
        </div>
    <Link to="/signup/institute">

     <button
          className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
        >
          Choose Plan
        </button>
     </Link>

        <div>
          <span className="absolute right-0 top-7 z-[-1]">
            <svg
              width="77"
              height="172"
              viewBox="0 0 77 172"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="86"
                  y1="0"
                  x2="86"
                  y2="172"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.09" />
                  <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute right-4 top-4 z-[-1]">
            <svg
              width="41"
              height="89"
              viewBox="0 0 41 89"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="38.9138"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 38.9138 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 38.9138 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 38.9138 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 38.9138 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 38.9138 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 38.9138 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 38.9138 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="38.9138"
                cy="1.42021"
                r="1.42021"
                transform="rotate(180 38.9138 1.42021)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 26.4157 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 26.4157 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 26.4157 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 26.4157 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 26.4157 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 26.4157 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 26.4157 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="26.4157"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 26.4157 1.4202)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 13.9177 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 13.9177 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 13.9177 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 13.9177 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 13.9177 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 13.9177 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 13.9177 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="13.9177"
                cy="1.42019"
                r="1.42021"
                transform="rotate(180 13.9177 1.42019)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="87.4849"
                r="1.42021"
                transform="rotate(180 1.41963 87.4849)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="74.9871"
                r="1.42021"
                transform="rotate(180 1.41963 74.9871)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="62.4892"
                r="1.42021"
                transform="rotate(180 1.41963 62.4892)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="38.3457"
                r="1.42021"
                transform="rotate(180 1.41963 38.3457)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="13.634"
                r="1.42021"
                transform="rotate(180 1.41963 13.634)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="50.2754"
                r="1.42021"
                transform="rotate(180 1.41963 50.2754)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="26.1319"
                r="1.42021"
                transform="rotate(180 1.41963 26.1319)"
                fill="#3056D3"
              />
              <circle
                cx="1.41963"
                cy="1.4202"
                r="1.42021"
                transform="rotate(180 1.41963 1.4202)"
                fill="#3056D3"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
     }
      </section>
  
  
  :
user == 'teacher' ?

<section
        
className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>
 <h2
  style={{fontSize:'40px'}}
          className="mb-10 text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
        >
          Our Pricing Plan
        </h2>
<div className='flex-col'>
<ol class="mt-10 flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">



<li onClick={toTeacherDesktop} class="  flex items-center text-white  text-center justify-center bg-[#31ffaa] cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",fontSize:'',margin:0}}>

Teachers

</li>


<li  onClick={toInstitutesDesktop}class=" text-gray-900  bg-white  flex items-center justify-center   text-gray-900  text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 " style={{width:"50%",fontSize:'',margin:0}}>

<p className='block  text-center '>Institutes</p>

</li>
</ol>
<div className='flex-col'>
 
 <div className="flex justify-between overflow-scroll">

  
<div style={{width:'20%'}}>

</div>
     <div style={{width:'80%'}} class=" p-0.5 mt-6 border rounded-lg " >
  
                     <div  class=" flex">
                       {
                       oneMonth == true ?
                         <button style={{fontSize:'15px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-gray-900 bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'15px'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                     }

                     {
                       threeMonth == true ?
                         <button style={{fontSize:'15px'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'15px'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                     }
                                           {
                       year == true ?
                         <button style={{fontSize:'15px'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'15px'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                     }
                     </div>
                 </div>
   </div>
   </div>
</div>
{
oneMonth == true ?
<div className=" mx-auto">

  
<div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'30px'}}>

<div className="w-full " style={{minWidth:'400px',marginRight:'20px'}}>
  <div
    className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
  >
    <span className="mb-4 block text-lg font-semibold text-primary">
      Free Plan
    </span>
    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
      <span>0 ETB</span>
    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a personal website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        5 Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        15% Transactional Fee
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
<Link to="/signup/teacher">
<button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
    >
      Start For Free
    </button></Link>

    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
  </div>
</div>
<div className="w-full  "style={{minWidth:'400px',marginLeft:'50px'}}>

  <div
    className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
  >
    <span className="mb-4 block text-lg font-semibold text-primary">
      Basic Plan
    </span>
    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
      <span>1,000 ETB</span>
    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a personal website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Unlimited Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Certification and Recognition
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>

      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        10% Transactional Fee
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
<Link to="/signup/teacher">
  

<button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
    >
      Choose Plan
    </button></Link>

    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
  </div>
</div>

<div className="w-full " style={{minWidth:'400px',marginLeft:'50px'}}>

  <div
    className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
  >
    <span className="mb-4 block text-lg font-semibold text-primary">
      Premium Plan
    </span>
    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

      <span>5,000 ETB</span>

    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a Professional website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Unlimited Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Certification and Recognition
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        5% Transactional Fee
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
<Link to="/signup/teacher">

 <button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
    >
      Choose Plan
    </button>
 </Link>

    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
  </div>
</div>
</div>
</div>
:
threeMonth == true ?
<div className=" mx-auto">


<div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'30px'}}>

<div className="w-full " style={{minWidth:'400px',marginRight:'20px'}}>

<div
  className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
  <span className="mb-4 block text-lg font-semibold text-primary">
    Free Plan
  </span>
  <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
    <span>0 ETB</span>
  </h2>
  <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
  >
    Perfect for using in a personal website or a client project.
  </p>
  <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      5 Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      15% Transactional Fee
    </p>
 
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
  </div>
<Link to="/signup/teacher">

<button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
  >
    Start For Free
  </button></Link>

  <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
  </div>
</div>
</div>
<div className="w-full " style={{minWidth:'400px',marginLeft:'50px'}}>

<div
  className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
  <span className="mb-4 block text-lg font-semibold text-primary">
    Basic Plan
  </span>
  <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
    <span>2,715 ETB</span>
  </h2>
  <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
  >
    Perfect for using in a personal website or a client project.
  </p>
  <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Certification and Recognition
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>

    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      10% Transactional Fee
    </p>
 
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
  </div>
<Link to="/signup/teacher">

<button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
  >
    Choose Plan
  </button></Link>

  <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
  </div>
</div>
</div>

<div className="w-full " style={{minWidth:'400px',marginLeft:'50px'}}>

<div
  className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
  <span className="mb-4 block text-lg font-semibold text-primary">
    Premium Plan
  </span>
  <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

    <span>13,600 ETB</span>

  </h2>
  <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
  >
    Perfect for using in a Professional website or a client project.
  </p>
  <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Certification and Recognition
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      5% Transactional Fee
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
  </div>
<Link to="/signup/teacher">

<button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
  >
    Choose Plan
  </button>
</Link>

  <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
  </div>
</div>
</div>
</div>
</div>
:
<div className=" mx-auto">

<div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'30px'}}>

<div className="w-full " style={{minWidth:'400px',marginRight:'20px'}}>

<div
className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
  Free Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
  <span>0 ETB</span>
</h2>
<p
  className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
  Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    5 Courses
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    15% Transactional Fee
  </p>

  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
  </p>
</div>
<Link to="/signup/teacher">

<button
  className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
  Start For Free
</button></Link>

<div>
  <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </span>
  <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
  </span>
</div>
</div>
</div>
<div className="w-full " style={{minWidth:'400px',marginLeft:'50px'}}>

<div
className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
  Basic Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
  <span>7,980 ETB</span>
</h2>
<p
  className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
  Perfect for using in a personal website or a client project.
</p>
<div className="mb-7">
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited Courses
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Certification and Recognition
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
  </p>

  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    10% Transactional Fee
  </p>

  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
  </p>
</div>
<Link to="/signup/teacher">

<button
  className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
  Choose Plan
</button></Link>

<div>
  <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </span>
  <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
  </span>
</div>
</div>
</div>

<div className="w-full " style={{minWidth:'400px',marginLeft:'50px'}}>

<div
className="shadow-md relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
>
<span className="mb-4 block text-lg font-semibold text-primary">
  Premium Plan
</span>
<h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>

<span>39,900 ETB</span>

</h2>
<p
  className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
>
  Perfect for using in a Professional website or a client project.
</p>
<div className="mb-7">
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited Courses
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Certification and Recognition
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    5% Transactional Fee
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
  </p>
</div>
<Link to="/signup/teacher">

<button
  className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
>
  Choose Plan
</button>
</Link>

<div>
  <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </span>
  <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
  </span>
</div>
</div>
</div>
</div>
</div>
}
</section>
      :
      user != 'institute' & user != 'teacher'  &&
      <div class="h-screen  flex items-center">
      <div class=" flex  px-5 text-gray-700">
          <div class="w-full  mx-8" style={{width:'70%'}}>
              <div class="text-7xl text-gray-800 font-dark font-extrabold mb-8"style={{fontSize:'8vw',lineHeight:'9vw'}}> 404</div>
          <p class="text-2xl md:text-3xl font-light leading-normal mb-8" style={{fontSize:'4vw'}}>
              Sorry we couldn't find the page you're looking for
          </p>
          <Link to='/'>
          <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-[#31ffaa] active:bg-blue-900 hover:bg-blue-900 fjalla">Back to homepage</a>
      
          </Link>
      </div>
      <div class="w-full lg:flex lg:justify-end  mx-5 my-12" style={{width:'40%'}}>
      <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
      </div>
      
      </div>
      </div>
      }




  </div>
  <div id="mobile" className='mobile_steps ' style={{marginBottom:'30vw',paddingBottom:'10vw',width:"100%",margin:0}}>
  <nav className='flex justify-between navMobile  w-full '>

<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={mobileNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"10vw",marginLeft:"3vw"}}  fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
</nav>
{
pop == true &&
<div id='popoutMobileHome' className='relative '>
<nav className='flex justify-between navMobile '>
<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={mobileNavPopMinimize} xmlns="http://www.w3.org/2000/svg"  style={{width:"5vw",marginLeft:"3vw"}} fill="currentColor" className="text-blue-800  bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
</div>

</nav>
<ul >

<Link to="/signin">
<li className='listPopoutTablet border-b border-t' >Sign in</li>

</Link>
<Link to="/signup/student">
<li className='listPopoutTablet  border-b' >Sign up</li>


</Link>
<Link to="/aboutus">

<li className='listPopoutTablet  border-b' >About Us</li>
</Link>



<Link to="/pricing/teacher">
<li className='listPopoutTablet  border-b' >Pricing</li>
</Link>


</ul>
<Link to='/contact'>
<button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button></Link>
</div>
}

 {
  user == 'institute'  ?
  <section
  className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>  
    <h2
    style={{fontSize:'9vw',marginTop:'11px',paddingBottom:'5vw'}}
            className="text-center anton mb-4 text-gray-800 "
          >
            Our Pricing Plan
          </h2>
          <div className='flex-col'>
  <ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">
  
  
  
  <li onClick={toTeacherDesktop} class=" flex items-center   text-center text-gray-800 justify-center cursor-pointer hover:bg-gray-50 " style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>
  
  Teachers
  
  </li>
  
  
  <li  onClick={toInstitutesDesktop}class="flex items-center justify-center   text-center border-l  cursor-pointer   bg-[#31ffaa]" style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>
  
   <p className='block  text-white text-center '>Institutes</p>
  
  </li>
  </ol>
  <div className="flex justify-between">
      <div style={{width:'30%'}}>
  
      </div>
      <div style={{width:'70%',marginTop:'5vw',marginBottom:'5vw'}} class="overflow-hidden  border rounded-lg">
                    <div class=" flex justify-between">{
                      oneMonth == true ?
                        <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none   text-white bg-[#31ffaa]  rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg  fjalla"onClick={oneMonthTeacher}>1 Month</button>


                    }

                    {
                      threeMonth == true ?
                        <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white  rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                    }
                                          {
                      year == true ?
                        <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none   sm:w-auto py-1  text-gray-800  bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                    }
                    </div>
                </div>
    </div>
  </div>

  {
      oneMonth == true ?
      <div className=" mx-auto">
   
  
  <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Basic
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>20,000 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/institute">
        <button
        style={{fontSize:'3vw',padding:'3vw'}}
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Premium
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>50,000 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/institute">
          
        <button
        style={{fontSize:'3vw',padding:'3vw'}}

              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    :
    threeMonth == true ?
    <div className=" mx-auto">
   
  
  <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Basic
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>30,000 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/institute">
        <button
        style={{fontSize:'3vw',padding:'3vw'}}
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Premium
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>75,000 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/institute">
          
        <button
        style={{fontSize:'3vw',padding:'3vw'}}

              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  :
  <div className=" mx-auto">
   
  
  <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Basic
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>60,000 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/institute">
        <button
        style={{fontSize:'3vw',padding:'3vw'}}
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Premium
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>150,000 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/institute">
          
        <button
        style={{fontSize:'3vw',padding:'3vw'}}

              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
     }
    

    
</section>
:
user == 'teacher' ?
<section
className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>  
  <h2
  style={{fontSize:'9vw',marginTop:'11px',paddingBottom:'5vw'}}
          className="text-center anton mb-4  text-gray-800"
        >
          Our Pricing Plan
        </h2>
        <div className='flex-col'>
<ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border  rounded-lg shadow-sm ">



<li onClick={toTeacherDesktop} class=" flex items-center text-white text-center justify-center cursor-pointer  bg-[#31ffaa]" style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>

Teachers

</li>


<li  onClick={toInstitutesDesktop}class="flex items-center justify-center   text-center border-l  cursor-pointer   " style={{width:"50%",fontSize:'3vw',margin:0,padding:'2vw 0vw'}}>

 <p className='block  text-gray-800 text-center '>Institutes</p>

</li>
</ol>
<div className="flex justify-between">
    <div style={{width:'30%'}}>

    </div>
    <div style={{width:'70%',marginTop:'5vw',marginBottom:'5vw'}} class="overflow-hidden  border rounded-lg">
                    <div class=" flex justify-between">{
                      oneMonth == true ?
                        <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none   text-white bg-[#31ffaa]  rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg  fjalla"onClick={oneMonthTeacher}>1 Month</button>


                    }

                    {
                      threeMonth == true ?
                        <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white  rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                    }
                                          {
                      year == true ?
                        <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-[#31ffaa] text-white rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none   sm:w-auto py-1  text-gray-800  bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                    }
                    </div>
                </div>
  </div>
</div>

{
    oneMonth == true ?
    <div className=" mx-auto">
 

<div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
<div className=" w-full " style={{width:'100vw'}}>
        <div
        style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
           Free Plan
          </span>
          <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
            <span>0 ETB</span>
          </h2>
       
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              5 Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              15% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Teacher Support
            </p>
          </div>
      <Link to="/signup/teacher">
      <button
      style={{fontSize:'3vw',padding:'3vw'}}
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Start for Free
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className=" w-full " style={{width:'100vw'}}>
        <div
        style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
           Basic
          </span>
          <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
            <span>1,000 ETB</span>
          </h2>
       
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
            10% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Teacher Support
            </p>
          </div>
      <Link to="/signup/teacher">
      <button
      style={{fontSize:'3vw',padding:'3vw'}}
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className=" w-full " style={{width:'100vw'}}>
        <div
        style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
           Premium
          </span>
          <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
            <span>5,000 ETB</span>
          </h2>
       
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
            5% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Teacher Support
            </p>
          </div>
      <Link to="/signup/teacher">
        
      <button
      style={{fontSize:'3vw',padding:'3vw'}}

            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  :
  threeMonth == true ?
  <div className=" mx-auto">
 

  <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
  <div class=" pb-10" style={{display:"flex",}}>
  <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Free Plan
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>0 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                15% Transactional Fee
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">
        <button
        style={{fontSize:'3vw',padding:'3vw'}}
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Start For Free
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Basic
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>2,715 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              10% Transactional Fee
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">
        <button
        style={{fontSize:'3vw',padding:'3vw'}}
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
        <div className=" w-full " style={{width:'100vw'}}>
          <div
          style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
             Premium
            </span>
            <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
              <span>13,600 ETB</span>
            </h2>
         
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              5% Transactional Fee
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher">
          
        <button
        style={{fontSize:'3vw',padding:'3vw'}}
  
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
:
<div className=" mx-auto">
 

<div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
<div className=" w-full " style={{width:'100vw'}}>
        <div
        style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
           Free Plan
          </span>
          <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
            <span>0 ETB</span>
          </h2>
       
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              5 Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              15% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Teacher Support
            </p>
          </div>
      <Link to="/signup/teacher">
      <button
      style={{fontSize:'3vw',padding:'3vw'}}
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Start For Free
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className=" w-full " style={{width:'100vw'}}>
        <div
        style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
           Basic
          </span>
          <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
            <span>7,980 ETB</span>
          </h2>
       
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
            10% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Teacher Support
            </p>
          </div>
      <Link to="/signup/teacher">
      <button
      style={{fontSize:'3vw',padding:'3vw'}}
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className=" w-full " style={{width:'100vw'}}>
        <div
        style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
           Premium
          </span>
          <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
            <span>39,900 ETB</span>
          </h2>
       
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
            5% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
              Teacher Support
            </p>
          </div>
      <Link to="/signup/teacher">
        
      <button
      style={{fontSize:'3vw',padding:'3vw'}}

            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-[#31ffaa] hover:text-white"
          >
            Choose Plan
          </button></Link>

          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
   }
  

  
</section>
:

user != 'institute' & user != 'teacher'  &&
<div  id="mobile" class="h-screen  flex items-center">
<div class=" flex flex-col  px-5 text-gray-700">
    <div class="w-full  mx-auto" style={{width:'90%'}}>

        <div class="text-7xl text-gray-800 font-dark font-extrabold mb-8"style={{fontSize:'8vw',lineHeight:'9vw'}}> 404</div>

    <p class="text-2xl md:text-3xl font-light leading-normal mb-8" style={{fontSize:'5vw'}}>
        Sorry we couldn't find the page you're looking for
    </p>
    <Link to='/'
    className="flex items-center justify-center text-center text-white bg-[#31ffaa] hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm  mr-2 mb-2 " style={{width:'33.33%',fontSize:'2.3vw',height:'7vw'}}
>
   <button type="button"
  
   
>Back to Dashboard</button>
    </Link>
</div>


</div>
</div>
 }
 



</div>

  </div>
}




</div>
    </div>
  )
}
