import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import Footer from './footer';
import { Link } from 'react-router-dom';

export default function Careers() {
  const [pop,setpop] = useState (false)
  const [spinner,setspinner] = useState (true)
  


  function popRemove(){
    setpop(false)
  }
  function popOpenSet(){
    setpop(true)
  }

  function load(){
setspinner(false)
  }

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
    {
    spinner == true &&
    <div id='spinnerDesktop'>
      <div
  class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
  role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>

      </div>
  }
        <div id='desktop'>
          <Nav/>
          <div style={{height:"90vh"}}>
                <div className='flex items-center h-full' >
                    {/* <div id='preLoader' >
                    <div class="animate-spin inline-block w-20 h-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
</div>
        </div> */}
        <div onLoad={load} className="homebody__rightside" style={{width:"40%"}}>  <img className='homebody__rightside-img' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FTeam%20work-amico.svg?alt=media&token=63b988d3-9ed0-4014-9c2a-c573f8b6b7a1" alt="" /></div>
       
         <div className="ml-10"style={{width:"60%"}}>

      
         <h1 className='anton ' style={{lineHeight:'45px',color:"black",width:"95%",textAlign:"left",textTransform:"none",fontSize:"40px"}}> We invite you to join the Kesht Team and become part of an exciting future</h1>
         
    
         <p className='  text-sm text-gray-500 md:text-base borel mt-5'style={{textAlign:"left",fontSize:"17px",textTransform:"none"}}> We offer a unique and rewarding experience working with a diverse team of professionals who are passionate about their work and are committed to helping you reach your goals.</p>
<a href='#jobOpening' type="button" style={{backgroundColor:"#2141ce"}} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-5 ">Join our team now</a>
        
          {/* <Link to="/signup"> <button className='homebody-getstarted-btn'>Find a job</button></Link>  */}

         </div>

            
         


        </div> 
        <div>
        </div>
        </div>
{/* <!-- This is an example component --> */}
<h1 id="jobOpening" className='anton mb-8' style={{textAlign:"center",color:"#2141ce",width:"100%",textTransform:"none"}}> See if there are any job openings at Kesht</h1>

<div class="max-w-2xl mx-auto flex"  style={{marginBottom:"200px"}}>



    <div onLoad={load} className="homebody__rightside" style={{width:"40%"}}>  <img className='homebody__rightside-img' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FHonesty-rafiki.svg?alt=media&token=2b38ed78-2803-4a99-9567-befcb58e6402" alt="" /></div>
    <div className="homebody__leftside">
    
<h1 className='fjalla' style={{color:"black",width:"95%",textAlign:"left",textTransform:"none",fontSize:30}}> We're sorry, but we don't have any job openings at the moment.</h1>

</div>  
</div>  
          <Footer/>

        </div>
        <div id='tablet'>

                     <nav className='flex justify-between navTablet w-full  pt-5'>

           
<Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={load} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>
<Link to="/courses">

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"25px"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</Link>

    <svg onClick={popOpenSet} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
    {
        pop == true &&
        <div id='popoutTabletHome'>
        <nav className='flex justify-between navTablet py-5'>
        <Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={load} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>
    
                <div className='flex justify-end'style={{width:'30%'}} >
                    <Link to="/courses">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg></Link>
                    
                    <svg onClick={popRemove} xmlns="http://www.w3.org/2000/svg"  width="25" height="25" fill="currentColor" className="text-blue-800 menuIcon bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    </div>
        </nav>
        <ul >
            <Link to="/signin">
            <li className='listPopoutTablet border-b border-t' >Sign in</li>
    
             </Link>
             <Link to="/signup/student">
            <li className='listPopoutTablet  border-b' >Sign up</li>
    
    
             </Link>
             <Link to="/aboutus">
            <li className='listPopoutTablet  border-b' >About Us</li>
    
             </Link>
    
             <Link to="/pricing/teacher">
            <li className='listPopoutTablet  border-b' >Pricing</li>
    
             </Link>
    
       
    
        </ul>
      
        
    <Link to='/contact'>
    <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button>
     </Link>   </div>
    }
          <div style={{height:"90vh"}}>
                <div className='flex items-center h-full' >
                    {/* <div id='preLoader' >
                    <div class="animate-spin inline-block w-20 h-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
</div>
        </div> */}
        <div onLoad={load} className="homebody__rightside" style={{width:"40%"}}>  <img className='homebody__rightside-img' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FTeam%20work-amico.svg?alt=media&token=63b988d3-9ed0-4014-9c2a-c573f8b6b7a1" alt="" /></div>
       
         <div className="ml-10"style={{width:"60%"}}>

      
         <h1 className='anton ' style={{lineHeight:'45px',color:"black",width:"95%",textAlign:"left",textTransform:"none",fontSize:"4.5vw"}}> We invite you to join the Kesht Team and become part of an exciting future</h1>
         
    
         <p className='  text-sm text-gray-500 md:text-base borel mt-5'style={{textAlign:"left",fontSize:"1.8vw",textTransform:"none",lineHeight:'2.8vw'}}> We offer a unique and rewarding experience working with a diverse team of professionals who are passionate about their work and are committed to helping you reach your goals.</p>
<a href='#Opening' type="button" style={{backgroundColor:"#2141ce"}} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-5 ">Join our team now</a>
        
          {/* <Link to="/signup"> <button className='homebody-getstarted-btn'>Find a job</button></Link>  */}

         </div>

            
         


        </div> 
        <div>
        </div>
        </div>
{/* <!-- This is an example component --> */}
<h1 id="Opening" className='anton mb-8' style={{textAlign:"center",color:"#2141ce",width:"100%",textTransform:"none",fontSize:'4.6vw',lineHeight:'4.6vw'}}> See if there are any job openings at Kesht</h1>

<div class="max-w-2xl mx-auto flex items-center"  style={{marginBottom:"200px"}}>



    <div onLoad={load} className="homebody__rightside" style={{width:"40%"}}>  <img className='homebody__rightside-img' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FHonesty-rafiki.svg?alt=media&token=2b38ed78-2803-4a99-9567-befcb58e6402" alt="" /></div>
    <div className="homebody__leftside">
    
<h1 className='fjalla' style={{color:"black",width:"95%",textAlign:"left",textTransform:"none",fontSize:'3.5vw',lineHeight:'4.5vw'}}> We're sorry, but we don't have any job openings at the moment.</h1>

</div>  
</div>  
          <Footer/>

        </div>
        <div id="mobile" className='mobile__home'>
        <div className='body'>
        <div>
        <nav className='flex justify-between navMobile  w-full '>

            <Link to="/"  style={{width:"32%",objectFit:"cover"}}> 
                        <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                          </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
    <svg onClick={popOpenSet} xmlns="http://www.w3.org/2000/svg" style={{width:"10vw",marginLeft:"3vw"}}  fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
   {
    pop == true &&
    <div id='popoutMobileHome' className='relative '>
    <nav className='flex justify-between navMobile '>
    <div style={{width:'32%',marginLeft:'3vw'}}>

<Link to="/"  style={{width:"100%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>
    </div>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={popRemove} xmlns="http://www.w3.org/2000/svg"  style={{width:"5vw",marginLeft:"3vw"}} fill="currentColor" className="text-blue-800  bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
</div>
  
    </nav>
    <ul >
       
        <Link to="/signin">
        <li className='listPopoutTablet border-b border-t' >Sign in</li>

         </Link>
         <Link to="/signup/student">
        <li className='listPopoutTablet  border-b' >Sign up</li>


         </Link>
         <Link to="/aboutus">

        <li className='listPopoutTablet  border-b' >About Us</li>
        </Link>
    


<Link to="/pricing/teacher">
<li className='listPopoutTablet  border-b' >Pricing</li>
</Link>


    </ul>
  <Link to='/contact'>
  <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                    rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button></Link>
    </div>
   }
      

            

        </div>
        <div>
<div onClick={popRemove} className='mobile_steps' style={{backgroundColor:"white"}}>
         <h1 className='anton' style={{color:"black",fontSize:"8vw",lineHeight:'9vw',width:"100%",margin:0,marginTop:"12vw",textAlign:"center"}}>See if there are any job openings at Kesht</h1>
        <div class="" >  
     <div class="">
     <div class="max-w-2xl mx-auto flex" id="jobOpening" style={{marginBottom:"200px"}}>



<div className="">
    <img onLoad={load} style={{width:'90%'}} className='mx-auto' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/Feeling%20sorry-bro.svg?alt=media&token=9e7c1947-ca6c-4104-af6e-cf64df9f11ca&_gl=1*dmvcbs*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5NTkxODU2Mi43Ny4xLjE2OTU5MTg1ODMuMzkuMC4w" alt="" />
<h1 className='fjalla' style={{textAlign:"center",color:"#2141ce",fontSize:"6vw",width:"100%",margin:0,padding:"2%",lineHeight:"30px"}}>We're sorry, but we don't have any job openings at the moment.</h1>


</div>  
</div> 
     </div>
</div>
        
        
     </div>
<Footer/>

</div>

        
        </div>
        
        </div>

    </div>
  )
}
