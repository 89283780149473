import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import { Link } from 'react-router-dom'
import Pricing from './pricing'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, firestore } from '../firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import Footer from './footer'

export default function Home() {
 const [uid,setUid] = useState("")
 const [course,setCourse] = useState([])
 const [pop,setpop] = useState(false)
 const [spinner,setspinner] = useState(false)




  
    // Tablet Nav Pop
    function tabletNavPop(){
        setpop(true)


  }

   // Tablet Nav Pop minimize
   function tabletNavPopMinimize(){
        setpop(false)


   }

     // Mobile Nav Pop
     function mobileNavPop(){
        setpop(true)


      

  }

  // Mobile Nav Pop minimize
  function mobileNavPopMinimize(){
    setpop(false)



  }


  useEffect(()=>{

  onSnapshot(collection(firestore,"Courses"),snapshot=>{
    let temp = []
    snapshot.docs.forEach(doc=>{
      temp.push({...doc.data(),id:doc.id})
      setCourse(temp)
      

    })
  })

  

  },[])

  function desktopSpinnerHandler(){
    setspinner(false)
   }

  return (
<div>
  
  {
    spinner == true &&
    <div id='spinnerDesktop'>
      <div
  class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
  role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>

      </div>
  }
 


            <div id='desktop'className='' >
   
                  
                    <div className='relative ' id='desktop'>
               
               {/* Navigation */}
                  <Nav/>
  
        
               {/*  Body */}
              <div className=" " >
                <div className="mainBodyHomePage ">

                  <div className="MuiBox-root css-16pdqkj relative">
                    <div className="MuiBox-root css-vp313s">
                      <div className="MuiBox-root css-n8ap69">
                        <div className="MuiBox-root css-0 ">
                          <h1 className="MuiTypography-root MuiTypography-h1  oswald">
                            There has never been an <br />easier way to get <br/> high quality education
                          </h1>
                          <div className="MuiBox-root css-x2aj4f">
                          <Link
                            className="css-68zbsl"
                            to='/courses'
                          >
                            <button style={{fontSize:'13px'}} type="button" className="mainBodyHomePage-Btn barlow">
                             Courses
                            </button>
                          </Link>
                        </div>
                        </div>
                    
                      
                      </div>
                      <div className="flex ">
                        {/* <img
                          alt="Image"
                          loading="lazy"
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(24).png?alt=media&token=b125c8f5-2014-437b-95ca-5603da8897f7"
                          style={{ color: "transparent", maxWidth: '50%' }}
                        /> */}
                        <img 
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: '50%' }}

                          src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(25).png?alt=media&token=bafaa349-12a6-46d7-8b02-42a3b6e8f80a" alt="" />
                        <video id="my-video" width="640" height="360" loop>
    <source src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina.mp4?alt=media&token=fd9973c9-a356-4e9a-8fec-22270eccd310" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>
                      </div>
    
                  
                    </div>
                  </div>
  
              
                  
                  <div className=" css-hbu6zj">
                    <div className=" css-8bp21p">
                      <div className="css-106mqxj">
                        <div className=" css-wo8mfg">
                          <h2
                            className="MuiTypography-root MuiTypography-h2 css-448w5t"
                           
                            
                          >
                           What makes Kesht a good choice for educational institutions around the world
                          </h2>
                        </div>
                      </div>
                      <div className=" css-1w59bsm" id="nav-testimonials-slider">
                    
                      </div>
                      <div
                        className="  css-kjyy56 swiper-initialized swiper-horizontal swiper-backface-hidden"
                      >
                        <div className="flex justify-center mb-10 ">
                          <div
                            className="swiper-slide css-382l81 swiper-slide-active"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-zpwqyx">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
                                </p>
                              </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="swiper-slide css-382l81 swiper-slide-next"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-19orgr">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                  “The best payroll solution, period. We've used every payroll
                                  solution out there - Gusto, Rippling, you name it - and Deel
                                  is the only one that is simple, easy to use, and global.
                                  We've used it for 4 years and haven't looked back.”
                                </p>
                              </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    
                         
                        
                        </div>
                     
                        <div className="flex justify-center">
                          <div
                            className="swiper-slide css-382l81 swiper-slide-active"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-zpwqyx">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
                                </p>
                              </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="swiper-slide css-382l81 swiper-slide-next"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-19orgr">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                  “The best payroll solution, period. We've used every payroll
                                  solution out there - Gusto, Rippling, you name it - and Deel
                                  is the only one that is simple, easy to use, and global.
                                  We've used it for 4 years and haven't looked back.”
                                </p>
                              </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    
                         
                        
                        </div>
                      
                      </div>
                      
                    
                    </div>
                  </div>
                  {/* <div className=" css-w4770a">
                <div className=" css-exnllr">
                  <h5
                    className="MuiTypography-root MuiTypography-h5 css-fa5zzh"
                    
                  >
                    Choose Deel for an industry-leading experience
                  </h5>
                  <div className=" css-1c26xg0">
                    <div className=" css-l9c3d3">
                      <div className=" css-6rpn08">
                        <img
                          alt="Image"
                          loading="lazy"
                          width={224}
                          height={240}
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: 224 }}
                          src="https://deel-website-media-prod.s3.amazonaws.com/User_Group_e1f4da88d2.png"
                        />
                      </div>
                      <div className=" css-12vj066">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-68zxer"
                          type="key-fig/lg"
                          
                        >
                          500K
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
                          type="medium"
                          
                        >
                          workers onboarded globally
                        </p>
                      </div>
                    </div>
                    <div className=" css-l9c3d3">
                      <div className=" css-6rpn08">
                        <img
                          alt="Image"
                          loading="lazy"
                          width={229}
                          height={240}
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: 229 }}
                          src="https://deel-website-media-prod.s3.amazonaws.com/Globe_2118ad8b7b.png"
                        />
                      </div>
                      <div className=" css-12vj066">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-68zxer"
                          type="key-fig/lg"
                          
                        >
                          35K
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
                          type="medium"
                          
                        >
                          global businesses supported
                        </p>
                      </div>
                    </div>
                    <div className=" css-l9c3d3">
                      <div className=" css-6rpn08">
                        <img
                          alt="Image"
                          loading="lazy"
                          width={343}
                          height={240}
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: 343 }}
                          src="https://deel-website-media-prod.s3.amazonaws.com/Bills_Coins_ff5c0d19fc.png"
                        />
                      </div>
                      <div className=" css-12vj066">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-68zxer"
                          type="key-fig/lg"
                          
                        >
                          $10B
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
                          type="medium"
                          
                        >
                          compliantly processed Global Payroll
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
  
                  <div className="MuiBox-root css-g6fhwr">
                    <div className="MuiBox-root css-qqilog">
                      <div className="MuiBox-root css-1dk9sdk">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-9vbhak"
                          type="d/xlarge"
                          
                        >
                         
                        </h5>
                      </div>
                    
                      
                    </div>
                  </div>
                  <div className=" css-1g1qdt1">
                    <svg
                      className="css-1fllxtj"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
                    </svg>
                  </div>
                  <div className="MuiBox-root css-wkfzqz">
                    <div className="MuiBox-root css-2fgig6">
                      <div className="MuiBox-root css-1vrckbq">
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-14dya1u"
                          type="overline.lg.md"
                          
                        >
                          Kesht University
                        </p>
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-1d7ezcq"
                          type="d/xlarge"
                          
                        >
                          Our platform makes it easy for universities to sell their courses to students
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-10afrnc"
                          type="xlarge"
                          
                        >
                      Our platform provides an easy way for universities to create and sell online courses, as well as provide students with a secure platform for purchasing and completing their courses. We also offer a range of features to help universities track student progress and manage their accounts.</p>
                        <div className="MuiBox-root css-wsfch7">
                          <Link
                          to='/signup/institute'
                            className="css-68zbsl"
                          >
                            <button type="button" className="css-1i4wc1c">
                              Get started
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="css-1cjibqg">
                        <div className=" css-1vpisfy">
                            <img
                              alt="Image"
                              loading="lazy"
                              width={832}
                              height={720}
                              decoding="async"
                              data-nimg={1}
                              className="css-ducv57"
                              src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(31).png?alt=media&token=c1bff77a-ee1d-4660-8590-65fa4b61674a"
                              style={{ color: "transparent", maxWidth: 832 }}
                            />
                          <div className="MuiBox-root css-166nr2m">
                            <h5
                              className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
                              style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
                              
                            >
                              Digital certificates can be issued to students by universities
                            </h5>
                          </div>
                        </div>
                        <div className="css-1vpisfy">
                            <img
                              alt="Image"
                              loading="lazy"
                              width={832}
                              height={720}
                              decoding="async"
                              data-nimg={1}
                              className="css-ducv57"
                              src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(32).png?alt=media&token=4ed55d3f-8d1e-4dfc-b908-0ea9e15b76e4"
                              style={{ color: "transparent", maxWidth: 832 }}
                            />
                          <div className="MuiBox-root css-166nr2m">
                            <h5
                              className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
                              style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
                              
                              
                            >
                             Student data can be easily tracked by universities
                            </h5>
                          </div>
                        </div>
                        <div className=" css-1vpisfy">
                            <img
                              alt="Image"
                              loading="lazy"
                              width={832}
                              height={720}
                              decoding="async"
                              data-nimg={1}
                              className="css-ducv57"
                              src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(34).png?alt=media&token=6f39a7fe-a587-4b6d-93a6-9a4da936d6c3"
                              style={{ color: "transparent", maxWidth: 832 }}
                            />
                          <div className="MuiBox-root css-166nr2m">
                            <h5
                              className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
                              style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
                              
                              
                            >
                            It is easy for universities to sell their courses to students
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="MuiBox-root css-wkfzqz" >
                    <div className="MuiBox-root css-2fgig6" >
                      <div className="MuiBox-root css-1vrckbq"style={{width:'90%'}}>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-v3wz6g"
                          type="overline.lg.md"
                        >
                          Kesht student
                        </p>
                        <h2
                          className="MuiTypography-root MuiTypography-h2 css-1d7ezcq "
                         
                          
                        >
                          Using our platform, students can improve their grades and earn certificates by taking courses from top universities at affordable prices
                        </h2>
                        <div className="fixed-gap check-list MuiBox-root css-133cowe" 
                            style={{color:'#FAF4EE'}}
                            >
                    In order for students to get the best education, we provide them with the best learning tools and provide them with certificates. We believe that education should be accessible to all, regardless of their financial situation. We strive to create an environment where students can learn and grow, and have access to the best educational resources.
                        </div>
                        <Link
                        to='/signup/student'
                          className="css-68zbsl"
                        >
                          <button type="button" className="css-ngu94h">
                            Get started
                          </button>
                        </Link>
                      </div>
                      <div className=" css-x8al29">
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
           <img
             alt="Image"
             loading="lazy"
             width={180}
             height={170}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(36).png?alt=media&token=952beeb9-5176-4d99-a614-11334b6d9d83"
             style={{ color: "transparent", maxWidth: 180 }}
           />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
             
             className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}
             
           >
             Most Experienced Team
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
       We have worked with a wide range of clients and understand what it takes give our clients the best results.
</p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
             alt="Image"
             loading="lazy"
             width={180}
             height={170}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(40).png?alt=media&token=b3b6c39e-40bc-4068-9364-5c233f6f9c59"
             style={{ color: "transparent", maxWidth: 180 }}
           />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
             className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}
             
             
           >
            Best customer support
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
           Our customer support team is committed to providing the best customer service possible..
           </p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
           <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(41).png?alt=media&token=61b9ad3a-e558-4368-ad78-6bdbcc4b1207"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
             
             className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}
             
           >
             Fast and Reliable
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
            Our services are fast and reliable, ensuring the highest quality of output.
           </p>
         </div>
       </div>
    
     </div>
                      <div className=" css-x8al29">
      
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(44).png?alt=media&token=e9c9f82e-6334-4eb8-836a-9900d869e59f"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
                                          className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}

             
             
           >
             Outstanding performance
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
            We have been able to maintain a positive reputation in the marketplace!
           </p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(43).png?alt=media&token=0246df31-5b3d-42d5-9436-33c9aca6944e"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
                                          className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}

             
             
           >
             Safe and secure
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
          With our system, your data is guaranteed to remain safe and secure.
           </p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(42).png?alt=media&token=f6a0548f-48b3-4913-a982-8e23273455e7"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
                                          className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}

             
             
           >
             Affordable price
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
             without sacrificing quality, we offer an affordable price that won't break the bank.
           </p>
         </div>
       </div>
     </div>
                    </div>
                  </div>
                  <div className=" css-1oru8ap">
                    <svg
                      className="css-129n9a0"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                      fill='#1B1B1B'
                    >
                      <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
                    </svg>
                  </div>
                
                  


 
 
  



                  <div className="MuiBox-root css-ewwtfb">
                    <div className="MuiBox-root css-13pmkot">
                      <div className="MuiBox-root css-1h43wvm">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-13p1s5k"
                          type="d/xlarge"
                          
                        >
                          Set up your educational institution on Kesht by registering now
                        </h5>
                      </div>
                      <div className=" css-13fxktb" style={{display:'flex'}}>
                      <div className="w-full css-1oyb61h">
                      
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">1</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Sign up and Select your membership type  
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                                  From new hires to your existing workforce, onboard
                                  effortlessly with our self-serve platform.
                                </p>
                              </div>
                            </div>
                          </div>
                           
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">2</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Get your course content ready
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                                  From new hires to your existing workforce, onboard
                                  effortlessly with our self-serve platform.
                                </p>
                              </div>
                            </div>
                          </div>
                           
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">3</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Set your course price
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                                  From new hires to your existing workforce, onboard
                                  effortlessly with our self-serve platform.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">4</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Publish your content and get paid
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                                  From navigating local laws to support for your team members,
                                  our dedicated team will help you get set up seamlessly.
                                </p>
                                <Link
                                to='/signup/institute'
                                  type="button"
                                  className="css-18kphx1"
                                >
                                  Get started
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                    
                        <div className="MuiBox-root css-fxa9l1">
                          <img
                            alt="Image"
                            loading="lazy"
                            width={640}
                            height={420}
                            decoding="async"
                            data-nimg={1}
                            className="css-ducv57"
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(22).png?alt=media&token=16df57e0-1412-406e-aac3-de21fdd0a98b"
                            style={{ color: "transparent", maxWidth: 640 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
  
        
  
         
  </div>


  <div className=" css-1g1qdt1 overflow-hidden">
                    <svg
                      className="css-1fllxtj"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
                    </svg>
                  </div>
                  <div className="MuiBox-root css-wkfzqz">
                    <div className="MuiBox-root css-2fgig6">
   
                    </div>
                  </div>
                  <div className="MuiBox-root css-wkfzqz" >
                    <div className="MuiBox-root css-2fgig6" >
                     
                    <div className="MuiBox-root css-x8al29">
                    <h2
                          className="MuiTypography-root MuiTypography-h2 css-1d7ezcq w-full"

                >
                 Our most popular courses
                </h2>
                </div>

      <div style={{rowGap:'20px'}} className="mt-8 justify-evenly w-full flex">

{
  course.filter(i=>i.status=== 'pending').slice(0,3).map(i=>{
    const {courseTitle,courseThumbnail,courseLevel,certificate,intendedAgeGroup,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
    return(
      <Link to={`/course/${courseId}`} key={courseId}>
        

      <div className="border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-md  h-full transform hover:scale-105 transition duration-500">
      <h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
      <div className="relative">
      {
      courseThumbnail == false ?
      <img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
      
      :
      <img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>
      
      }
      
      <p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
      </div>
      <h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
      <p style={{fontSize:"11px",marginLeft:'5px',marginBottom:'4px'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
          {
            certificate == true ?
          <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
          :
          <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>
          
          }
             
      </div>
      </Link>
  
    )
  })
}


</div>
<div className='flex justify-center my-12'>

<Link to='/courses'>
                    
                    <div class="navbar-item__container cursor-pointer
                
                header-button-two"><button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span class="navbar-item__button-text text-gray-900 " >View More</span></button></div>
                    </Link>
</div>
                    </div>
                  </div>
                  <div className=" css-1oru8ap overflow-hidden">
                    <svg
                      className="css-129n9a0"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                      fill='#1B1B1B'
                    >
                      <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
                    </svg>
                  </div>          
 

         


<Footer/>

            </div>
            <div id='tablet'className='' >
   
                  
   <div className='relative ' id='tablet'>

{/* Navigation */}
 <Nav/>


{/*  Body */}
<div className=" " >
<div className="mainBodyHomePage ">

 <div className="MuiBox-root css-16pdqkj relative">
   <div className="MuiBox-root css-vp313s">
     <div className="MuiBox-root css-n8ap69">
       <div className="MuiBox-root css-0 ">
         <h1 className="MuiTypography-root MuiTypography-h1  oswald">
           There has never been an <br />easier way to get <br/> high quality education
         </h1>
         <div className="MuiBox-root css-x2aj4f">
         <Link
           className="css-68zbsl"
           to='/courses'
         >
           <button style={{fontSize:'13px'}} type="button" className="mainBodyHomePage-Btn barlow">
            Courses
           </button>
         </Link>
       </div>
       </div>
   
     
     </div>
     <div className="flex ">
       {/* <img
         alt="Image"
         loading="lazy"
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(24).png?alt=media&token=b125c8f5-2014-437b-95ca-5603da8897f7"
         style={{ color: "transparent", maxWidth: '50%' }}
       /> */}
       <img 
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: '50%' }}

         src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(25).png?alt=media&token=bafaa349-12a6-46d7-8b02-42a3b6e8f80a" alt="" />
       <video id="my-video" width="640" height="360" loop>
<source src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina.mp4?alt=media&token=fd9973c9-a356-4e9a-8fec-22270eccd310" type="video/mp4"/>
Your browser does not support the video tag.
</video>
     </div>

 
   </div>
 </div>


 
 <div className=" css-hbu6zj">
   <div className=" css-8bp21p">
     <div className="css-106mqxj">
       <div className=" css-wo8mfg">
         <h2
           className="MuiTypography-root MuiTypography-h2 css-448w5t"
          
           
         >
          What makes Kesht a good choice for educational institutions around the world
         </h2>
       </div>
     </div>
     <div className=" css-1w59bsm" id="nav-testimonials-slider">
   
     </div>
     <div
       className="  css-kjyy56 swiper-initialized swiper-horizontal swiper-backface-hidden"
     >
       <div className="flex justify-center mb-10 ">
         <div
           className="swiper-slide css-382l81 swiper-slide-active"
           style={{ marginRight: 40 }}
         >
           <div className=" css-zpwqyx">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
               Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div
           className="swiper-slide css-382l81 swiper-slide-next"
           style={{ marginRight: 40 }}
         >
           <div className=" css-19orgr">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                 “The best payroll solution, period. We've used every payroll
                 solution out there - Gusto, Rippling, you name it - and Deel
                 is the only one that is simple, easy to use, and global.
                 We've used it for 4 years and haven't looked back.”
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
   
        
       
       </div>
    
       <div className="flex justify-center">
         <div
           className="swiper-slide css-382l81 swiper-slide-active"
           style={{ marginRight: 40 }}
         >
           <div className=" css-zpwqyx">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
               Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div
           className="swiper-slide css-382l81 swiper-slide-next"
           style={{ marginRight: 40 }}
         >
           <div className=" css-19orgr">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                 “The best payroll solution, period. We've used every payroll
                 solution out there - Gusto, Rippling, you name it - and Deel
                 is the only one that is simple, easy to use, and global.
                 We've used it for 4 years and haven't looked back.”
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
   
        
       
       </div>
     
     </div>
     
   
   </div>
 </div>
 {/* <div className=" css-w4770a">
<div className=" css-exnllr">
 <h5
   className="MuiTypography-root MuiTypography-h5 css-fa5zzh"
   
 >
   Choose Deel for an industry-leading experience
 </h5>
 <div className=" css-1c26xg0">
   <div className=" css-l9c3d3">
     <div className=" css-6rpn08">
       <img
         alt="Image"
         loading="lazy"
         width={224}
         height={240}
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: 224 }}
         src="https://deel-website-media-prod.s3.amazonaws.com/User_Group_e1f4da88d2.png"
       />
     </div>
     <div className=" css-12vj066">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-68zxer"
         type="key-fig/lg"
         
       >
         500K
       </h5>
       <p
         className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
         type="medium"
         
       >
         workers onboarded globally
       </p>
     </div>
   </div>
   <div className=" css-l9c3d3">
     <div className=" css-6rpn08">
       <img
         alt="Image"
         loading="lazy"
         width={229}
         height={240}
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: 229 }}
         src="https://deel-website-media-prod.s3.amazonaws.com/Globe_2118ad8b7b.png"
       />
     </div>
     <div className=" css-12vj066">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-68zxer"
         type="key-fig/lg"
         
       >
         35K
       </h5>
       <p
         className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
         type="medium"
         
       >
         global businesses supported
       </p>
     </div>
   </div>
   <div className=" css-l9c3d3">
     <div className=" css-6rpn08">
       <img
         alt="Image"
         loading="lazy"
         width={343}
         height={240}
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: 343 }}
         src="https://deel-website-media-prod.s3.amazonaws.com/Bills_Coins_ff5c0d19fc.png"
       />
     </div>
     <div className=" css-12vj066">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-68zxer"
         type="key-fig/lg"
         
       >
         $10B
       </h5>
       <p
         className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
         type="medium"
         
       >
         compliantly processed Global Payroll
       </p>
     </div>
   </div>
 </div>
</div>
</div> */}

 <div className="MuiBox-root css-g6fhwr">
   <div className="MuiBox-root css-qqilog">
     <div className="MuiBox-root css-1dk9sdk">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-9vbhak"
         type="d/xlarge"
         
       >
        
       </h5>
     </div>
   
     
   </div>
 </div>
 <div className=" css-1g1qdt1">
   <svg
     className="css-1fllxtj"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
   >
     <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
   </svg>
 </div>
 <div className="MuiBox-root css-wkfzqz">
   <div className="MuiBox-root css-2fgig6">
     <div className="MuiBox-root css-1vrckbq">
       <p
         className="MuiTypography-root MuiTypography-body1 css-14dya1u"
         type="overline.lg.md"
         
       >
         Kesht University
       </p>
       <h5
         className="MuiTypography-root MuiTypography-h5 css-1d7ezcq"
         type="d/xlarge"
         
       >
         Our platform makes it easy for universities to sell their courses to students
       </h5>
       <p
         className="MuiTypography-root MuiTypography-body1 css-10afrnc"
         type="xlarge"
         
       >
     Our platform provides an easy way for universities to create and sell online courses, as well as provide students with a secure platform for purchasing and completing their courses. We also offer a range of features to help universities track student progress and manage their accounts.</p>
       <div className="MuiBox-root css-wsfch7">
         <Link
         to='/signup/institute'
           className="css-68zbsl"
         >
           <button type="button" className="css-1i4wc1c">
             Get started
           </button>
         </Link>
       </div>
     </div>
     <div className="css-1cjibqg">
       <div className=" css-1vpisfy">
           <img
             alt="Image"
             loading="lazy"
             width={832}
             height={720}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(31).png?alt=media&token=c1bff77a-ee1d-4660-8590-65fa4b61674a"
             style={{ color: "transparent", maxWidth: 832 }}
           />
         <div className="MuiBox-root css-166nr2m">
           <h5
             className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
             style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
             
           >
             Digital certificates can be issued to students by universities
           </h5>
         </div>
       </div>
       <div className="css-1vpisfy">
           <img
             alt="Image"
             loading="lazy"
             width={832}
             height={720}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(32).png?alt=media&token=4ed55d3f-8d1e-4dfc-b908-0ea9e15b76e4"
             style={{ color: "transparent", maxWidth: 832 }}
           />
         <div className="MuiBox-root css-166nr2m">
           <h5
             className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
             style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
             
             
           >
            Student data can be easily tracked by universities
           </h5>
         </div>
       </div>
       <div className=" css-1vpisfy">
           <img
             alt="Image"
             loading="lazy"
             width={832}
             height={720}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(34).png?alt=media&token=6f39a7fe-a587-4b6d-93a6-9a4da936d6c3"
             style={{ color: "transparent", maxWidth: 832 }}
           />
         <div className="MuiBox-root css-166nr2m">
           <h5
             className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
             style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
             
             
           >
           It is easy for universities to sell their courses to students
           </h5>
         </div>
       </div>
     </div>
   </div>
 </div>
 <div className="MuiBox-root css-wkfzqz" >
   <div className="MuiBox-root css-2fgig6" >
     <div className="MuiBox-root css-1vrckbq"style={{width:'90%'}}>
       <p
         className="MuiTypography-root MuiTypography-body1 css-v3wz6g"
         type="overline.lg.md"
       >
         Kesht student
       </p>
       <h2
         className="MuiTypography-root MuiTypography-h2 css-1d7ezcq "
        
         
       >
         Using our platform, students can improve their grades and earn certificates by taking courses from top universities at affordable prices
       </h2>
       <div className="fixed-gap check-list MuiBox-root css-133cowe" 
           style={{color:'#FAF4EE'}}
           >
   In order for students to get the best education, we provide them with the best learning tools and provide them with certificates. We believe that education should be accessible to all, regardless of their financial situation. We strive to create an environment where students can learn and grow, and have access to the best educational resources.
       </div>
       <Link
       to='/signup/student'
         className="css-68zbsl"
       >
         <button type="button" className="css-ngu94h">
           Get started
         </button>
       </Link>
     </div>
     <div className=" css-x8al29">
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(36).png?alt=media&token=952beeb9-5176-4d99-a614-11334b6d9d83"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6

className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}

>
Most Experienced Team
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
We have worked with a wide range of clients and understand what it takes give our clients the best results.
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(40).png?alt=media&token=b3b6c39e-40bc-4068-9364-5c233f6f9c59"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}


>
Best customer support
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
Our customer support team is committed to providing the best customer service possible..
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(41).png?alt=media&token=61b9ad3a-e558-4368-ad78-6bdbcc4b1207"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6

className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}

>
Fast and Reliable
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
Our services are fast and reliable, ensuring the highest quality of output.
</p>
</div>
</div>

</div>
     <div className=" css-x8al29">

<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(44).png?alt=media&token=e9c9f82e-6334-4eb8-836a-9900d869e59f"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                         className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}



>
Outstanding performance
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
We have been able to maintain a positive reputation in the marketplace!
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(43).png?alt=media&token=0246df31-5b3d-42d5-9436-33c9aca6944e"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                         className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}



>
Safe and secure
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
With our system, your data is guaranteed to remain safe and secure.
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(42).png?alt=media&token=f6a0548f-48b3-4913-a982-8e23273455e7"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                         className="MuiTypography-root MuiTypography-h6 fjalla text-white" style={{fontSize:'25px',color:'white'}}



>
Affordable price
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
without sacrificing quality, we offer an affordable price that won't break the bank.
</p>
</div>
</div>
</div>
   </div>
 </div>
 <div className=" css-1oru8ap">
   <svg
     className="css-129n9a0"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
     fill='#1B1B1B'
   >
     <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
   </svg>
 </div>

 








 <div className="MuiBox-root css-ewwtfb">
   <div className="MuiBox-root css-13pmkot">
     <div className="MuiBox-root css-1h43wvm">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-13p1s5k"
         type="d/xlarge"
         
       >
         Set up your educational institution on Kesht by registering now
       </h5>
     </div>
     <div className=" css-13fxktb" style={{display:'flex'}}>
     <div className="w-full css-1oyb61h">
     
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">1</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Sign up and Select your membership type  
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
                 From new hires to your existing workforce, onboard
                 effortlessly with our self-serve platform.
               </p>
             </div>
           </div>
         </div>
          
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">2</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Get your course content ready
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
                 From new hires to your existing workforce, onboard
                 effortlessly with our self-serve platform.
               </p>
             </div>
           </div>
         </div>
          
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">3</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Set your course price
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
                 From new hires to your existing workforce, onboard
                 effortlessly with our self-serve platform.
               </p>
             </div>
           </div>
         </div>
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">4</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Publish your content and get paid
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
                 From navigating local laws to support for your team members,
                 our dedicated team will help you get set up seamlessly.
               </p>
               <Link
               to='/signup/institute'
                 type="button"
                 className="css-18kphx1"
               >
                 Get started
               </Link>
             </div>
           </div>
         </div>
       </div>
   
       <div className="MuiBox-root css-fxa9l1">
         <img
           alt="Image"
           loading="lazy"
           width={640}
           height={420}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(22).png?alt=media&token=16df57e0-1412-406e-aac3-de21fdd0a98b"
           style={{ color: "transparent", maxWidth: 640 }}
         />
       </div>
     </div>
   </div>
 </div>

</div>
</div>





</div>


<div className=" css-1g1qdt1 overflow-hidden">
   <svg
     className="css-1fllxtj"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
   >
     <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
   </svg>
 </div>
 <div className="MuiBox-root css-wkfzqz">
   <div className="MuiBox-root css-2fgig6">

   </div>
 </div>
 <div className="MuiBox-root css-wkfzqz" >
   <div className="MuiBox-root css-2fgig6" >
    
   <div className="MuiBox-root css-x8al29">
   <h2
         className="MuiTypography-root MuiTypography-h2 css-1d7ezcq w-full"

>
Our most popular courses
</h2>
</div>

<div style={{rowGap:'20px'}} className="mt-8 justify-evenly w-full flex">

{
course.filter(i=>i.status=== 'pending').slice(0,3).map(i=>{
const {courseTitle,courseThumbnail,courseLevel,certificate,intendedAgeGroup,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
return(
<Link to={`/course/${courseId}`} key={courseId}>


<div className="border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-md  h-full transform hover:scale-105 transition duration-500">
<h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
<div className="relative">
{
courseThumbnail == false ?
<img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>

}

<p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
</div>
<h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
<p style={{fontSize:"11px",marginLeft:'5px',marginBottom:'4px'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
{
certificate == true ?
<p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
:
<p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>

}

</div>
</Link>

)
})
}


</div>
<div className='flex justify-center my-12'>

<Link to='/courses'>
   
   <div class="navbar-item__container cursor-pointer

header-button-two"><button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span class="navbar-item__button-text text-gray-900 " >View More</span></button></div>
   </Link>
</div>
   </div>
 </div>
 <div className=" css-1oru8ap overflow-hidden">
   <svg
     className="css-129n9a0"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
     fill='#1B1B1B'
   >
     <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
   </svg>
 </div>          





<Footer/>

</div>
    
            
            
            <div id='mobile'>
            <nav className='flex justify-between navMobile  w-full '>

            <Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
                        <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                          </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
    <svg onClick={mobileNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"10vw",marginLeft:"3vw"}}  fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
   {
    pop == true &&
    <div id='popoutMobileHome' className='relative '>
    <nav className='flex justify-between navMobile '>
    <Link  onClick={tabletNavPopMinimize} to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
                        <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                          </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={mobileNavPopMinimize} xmlns="http://www.w3.org/2000/svg"  style={{width:"5vw",marginLeft:"3vw"}} fill="currentColor" className="text-blue-800  bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
</div>
  
    </nav>
    <ul >
       
        <Link to="/signin">
        <li className='listPopoutTablet border-b border-t' >Sign in</li>

         </Link>
         <Link to="/signup/student">
        <li className='listPopoutTablet  border-b' >Sign up</li>


         </Link>
         <Link to="/aboutus">

        <li className='listPopoutTablet  border-b' >About Us</li>
        </Link>
    


<Link to="/pricing/teacher">
<li className='listPopoutTablet  border-b' >Pricing</li>
</Link>


    </ul>
  <Link to='/contact'>
  <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl  text-center text-white 
                    rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button></Link>
    </div>
   }
        
    <div className='flex justify-center  items-center' >
                   

                        <div className='flex flex-col items-center' style={{width:"80%",height:'100%',marginTop:'10vw'}}>
                         
         <h1 className='hire_header  text-3xl font-semibold justify-center flex flex-col items-center anton h-full ' style={{ display:"flex",alignItems:"center",lineHeight:"12vw",color:"black",width:"100%",fontSize:"11vw"}}> 
         Unleash Your <br />Learning Potential: <br />Knowledge at Your<br /> Fingertips
         <div className='flex  w-full'style={{marginTop:'7vw'}}>
                            <Link to='/signup/teacher' 
className="flex items-center justify-center text-center text-white bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300  rounded   mr-2 mb-2 " style={{width:'33.33%',fontSize:'2.3vw',height:'7vw'}}
>
   <button type="button"
  
   
>Teachers</button>
   </Link>
   <Link to='/signup/student'  className="flex items-center justify-center text-center text-white bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300  rounded   mr-2 mb-2 " style={{width:'33.33%',fontSize:'2.3vw',height:'7vw'}}
>
   <button type="button"
  
   
>Students</button>
   </Link>
   <Link to='/signup/institute' 
className="flex items-center justify-center text-center text-white bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300  rounded   mr-2 mb-2 " style={{width:'33.33%',fontSize:'2.3vw',height:'7vw'}}
>
   <button type="button"
  
   
>Institutes</button>
   </Link>                        </div>
         
          </h1>

                   
                        </div>
                    </div>
                    <div className='intro__component-mobile '>
         
         
         <div className="w-full"style={{backgroundColor:"#2141ce"}} >
         <h1 className='anton text-white' style={{color:"white",fontSize:"10vw",lineHeight:"13vw",paddingTop:"10vw",paddingLeft:"3vw",paddingRight:"3vw"}}>WHAT MAKES US YOUR BEST CHOICE</h1>
         
         <div className="intro__wrapper">
            <div className='flex'>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width:'9vw'}} fill="currentColor" class="bi bi-pen mx-3" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
</svg> 
         <h2 className='mobile-intro__subheader fjalla flex  ' style={{fontSize:"7vw",lineHeight:"10vw",marginBottom:'1vw',marginTop:'8vw'}}>Access to Courses from Top Universities</h2>
</div>

         <p className='mobile-intro__para borel normal-case ' style={{fontSize:"3vw",textTransform:"none",marginLeft:'4vw',marginBottom:'5vw'}}>Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields.</p>
         </div>
         
         <div className="intro__wrapper">

            <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'7vw'}} fill="currentColor" class="bi bi-pen mx-3" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
</svg>
<h2 className='mobile-intro__subheader fjalla flex ' style={{fontSize:"7vw",lineHeight:"10vw",marginBottom:'1vw'}}>  
  Learn Anytime, Anywhere </h2>
            </div>
  
         <p className='mobile-intro__para borel normal-case' style={{fontSize:"3vw",textTransform:"none",marginLeft:'4vw',marginBottom:'5vw'}}>Enjoy the flexibility of learning from the comfort of your own bedroom or office, at any time that suits you.</p>
         </div>
         
         <div className="intro__wrapper">
         <div className='flex'>
         <svg xmlns="http://www.w3.org/2000/svg" style={{width:'7vw'}} fill="currentColor" class="bi bi-pen mx-3" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
</svg>
         <h2 className='mobile-intro__subheader fjalla flex ' style={{fontSize:"7vw",lineHeight:"10vw",marginBottom:'1vw'}}>     
  Affordable Learning</h2>
         </div>
 
         <p className='mobile-intro__para borel normal-case intro__para--last' style={{fontSize:"3vw",textTransform:"none",marginLeft:'4vw',marginBottom:'5vw'}}>Our courses are more cost-effective compared to traditional offerings. Moreover, you can save on additional educational expenses such as transportation fees, printing costs, and book expenses
         
         </p>
         
         </div>
         
         
         </div>
         {/* <div className="intro__right--side">
         <img src="https://firebasestorage.googleapis.com/v0/b/test-f59f4.appspot.com/o/users%2Fundraw_join_re_w1lh.svg?alt=media&token=5cf2e664-78a1-44bd-81cf-caeec6f08905" alt=""  className='daw'/>
         </div> */}
         </div>
         <div className='home__para '>
        <h1 className='step__header home_description-header text-center capitalize' style={{color:"black",fontSize:"9vw",lineHeight:"9.5vw",width:"95%",margin:0,paddingLeft:"10%"}}>High Quality Education Has Never Been More Accessible</h1>
        {/* <p className='home__para-para mt-2  text-slate-500'>
        Finding expert professionals shouldn't be hard. We make it easy by taking care of the time-consuming parts for you. Our expert team will personally match you with vetted and dedicated professional for your job so you can hire trusted talent with ease.
        </p> */}

        <div className="home__para-main-wrapper mobile-home__para">

        <div class="px-3 md:lg:xl:px-40   " >
    <div class="flex flex-col group bg-white ">


        {/* <div
            class="p-10 flex flex-col items-center text-center group hover:bg-slate-50 cursor-pointer">
            <span class="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200"><svg
                    xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg></span>
            <p class="text-xl  text-slate-700 mt-3">Most Experienced Team</p>
            <p class="mt-2  text-slate-500">Team BrainEdge education is a bunch of highly focused, energetic
                set of people.</p>
        </div> */}
{/* 
        <div
            class="p-10 flex flex-col items-center text-center group hover:bg-slate-50 cursor-pointer">
            <span class="p-5 rounded-full bg-orange-500 text-white shadow-lg shadow-orange-200"><svg
                    xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                    <line x1="16" y1="13" x2="8" y2="13"></line>
                    <line x1="16" y1="17" x2="8" y2="17"></line>
                    <polyline points="10 9 9 9 8 9"></polyline>
                </svg></span>
            <p class="text-xl  text-slate-700 mt-3">Best
                Test preparation</p>
            <p class="mt-2  text-slate-500">Know where you stand and what next to do to succeed .</p>
        </div> */}

        {/* <div class="p-10 flex flex-col items-center text-center group   hover:bg-slate-50 cursor-pointer">
            <span class="p-5 rounded-full bg-yellow-500 text-white shadow-lg shadow-yellow-200"><svg
                    xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                </svg></span>
            <p class="text-xl  text-slate-700 mt-3">Admission process Guidance</p>
            <p class="mt-2  text-slate-500">Professional Advice for higher education abroad and select the
                top institutions worldwide.</p>
        </div> */}


        <div class=" flex flex-col items-center text-center group    hover:bg-slate-50 cursor-pointer">
        <span class=" rounded-full  text-white ">
                    <img style={{width:"55vw"}} src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FTeam%20work-rafiki.svg?alt=media&token=1afde778-c868-47e5-a557-6990235c5a82" alt="" />
                    
                    </span>
            <p class="text-xl  text-gray-800 mt-3 fjalla" style={{fontSize:"4.5vw",marginBottom:"3vw"}}>Most Experienced Team
                </p>
            <p  style={{fontSize:"3.5vw",textTransform:"none"}} class="  text-slate-500 borel">We have worked with a wide range of clients and understand what it takes give our clients the best results.</p>
        </div>

        <div class=" flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
        <span class=" rounded-full  text-white ">
                    <img style={{width:"55vw"}} src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSavings-rafiki.svg?alt=media&token=8b1007cb-cdd8-4eef-8010-eb3e261a4a51" alt="" />
                    
                    </span>
            <p class="text-xl  text-gray-800 mt-3 fjalla" style={{fontSize:"4.5vw",marginBottom:"3vw"}}>Affordable Price
</p>
            <p class="  text-slate-500 borel"style={{fontSize:"3.5vw",textTransform:"none",marginBottom:"4vw"}}>Without sacrificing quality, we offer an affordable price that won't break the bank.</p>
        </div>

        <div class="mb-12 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
        <span class=" rounded-full  text-white ">
                    <img style={{width:"55vw"}} src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSecure%20data-rafiki.svg?alt=media&token=49a8a277-d1c5-4cee-a022-08beb19bb35d" alt="" />
                    
                    </span>
            <p style={{fontSize:"4.5vw",marginBottom:"3vw"}}class="text-xl  text-slate-700 mt-3 fjalla"
            >Safe And Secure</p>
            <p style={{fontSize:"3.5vw",textTransform:"none"}} class="borel  text-slate-500">With our system, your data is guaranteed to remain safe and secure.
            </p>
        </div>




    </div>

 

</div>

            
          
        
    
    
        </div>
        
        
    </div>
         <div className='mobile_steps bg-blue-800' style={{width:"100%",margin:0,paddingBottom:'10vw',marginTop:'3vw'}}>
        <h1 className='anton homebody__search--title' style={{fontSize:"9.5vw",lineHeight:"10.5vw",width:"95%",margin:0,paddingLeft:"10%",color:"white",paddingTop:'8vw'}}>How Do You Enroll In A Course?</h1>
     
        <div class="py-16" >  
        <div class="  text-gray-500 flex    " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
     <div class=" pb-10" style={{display:"flex",}}>
         <div class="step-mobile mx-2  rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8" style={{backgroundColor:"white"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-purple-900 fjalla" style={{fontSize:"5vw"}}>Sign up</h3>
                 <p class="mb-6 normalcase borel " style={{fontSize:"3.5vw",textTransform:"none"}}>Fill in your name as it appears on your passport or identification card(id). make sure the email address you insert is formal. we discourage the usage of words other than your full name.</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_welcome_cats_thqn.svg?alt=media&token=95041a11-2ec9-4c04-a442-e218ea783abd" class="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
         <div class="step-mobile mx-2 bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-10 lg:px-8" style={{backgroundColor:"white"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-purple-900 fjalla" style={{fontSize:"5vw"}}>Create a profile</h3>
                 <p class="mb-6 borel" style={{fontSize:"3.5vw",textTransform:"none"}}>Remember to complete all the required questions with accuracy, providing detailed and precise responses. By being accurate, you will assist us in providing you with courses that align with your interests.</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_personal_information_re_vw8a.svg?alt=media&token=32d3e041-9c94-44e1-832d-e11670567701" class="w-2/3 ml-auto" alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
         <div class="step-mobile mx-2 bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8" style={{backgroundColor:"white"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-purple-900 fjalla" style={{fontSize:"5vw"}}>Find a Course</h3>
                 <p class="mb-6 borel" style={{fontSize:"3.5vw",textTransform:"none"}}>As soon as your profile is complete. You will be suggested a list of courses that align with your interests all you have to do is navigate through the course list and pick a course.</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_updated_resume_re_7r9j.svg?alt=media&token=d03438e1-406b-43e7-acd8-46599b355695" class="w-2/3 ml-auto " alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
     </div>
 </div>
</div>
        
<div className='flex items-center justify-center '>
<Link to='/howtoenroll'>

<button style={{color:'white',fontSize:'2vw'}}   className="fjalla  flex  items-center bg-blue-800 auto  hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg  w-full sm:w-auto px-5 py-2.5 text-center "><svg xmlns="http://www.w3.org/2000/svg" style={{width:'6vw',height:'6vw'}}  fill="currentColor" class="bi bi-play-circle mr-1" viewBox="0 0 16 16">

<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
<path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>Watch Video</button>

</Link>
        <Link to="/courses">
        <button
        style={{fontSize:'2.5vw',marginLeft:'3vw'}}
        class="hirebtn capitalize py-2 px-4 bg-transparent text-white font-semibold border border-black-600 rounded hover:bg-black hover:text-white hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0"
      >Search for courses</button></Link>
</div>
        
     </div>

     <div className='mobile_steps bg-blue-800' style={{paddingBottom:'10vw',width:"100%",margin:0,backgroundColor:'white'}}>
        <h1 className='anton homebody__search--title' style={{fontSize:"11vw",lineHeight:"10vw",width:"95%",margin:0,paddingLeft:"10%",color:"black",marginTop:'40vw'}}>Steps To Creating Your Own Course And Making A Decent Income</h1>
     
        <div class="py-16" >  
        <div class="  text-gray-500 flex    " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
     <div class=" pb-10" style={{display:"flex",}}>
         <div class="step-mobile mx-2  rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8" style={{backgroundColor:"#2141ce"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-white fjalla" style={{fontSize:"5vw"}}>Sign up</h3>
                 <p class="mb-6 normalcase borel text-gray-300 " style={{fontSize:"3.5vw",textTransform:"none"}}>Fill in your name as it appears on your passport or identification card(id). make sure the email address you insert is formal. we discourage the usage of words other than your full name.</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_welcome_cats_thqn.svg?alt=media&token=95041a11-2ec9-4c04-a442-e218ea783abd" class="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
         <div class="step-mobile mx-2 bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-10 lg:px-8" style={{backgroundColor:"#2141ce"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-white fjalla" style={{fontSize:"5vw"}}>Create a profile</h3>
                 <p class="mb-6 borel text-gray-300" style={{fontSize:"3.5vw",textTransform:"none"}}>Remember to complete all the required questions with accuracy, providing detailed and precise responses. By being accurate, we can boost the credibility and professionalism of your profile. Don't forget to invest some time and effort to make your profile stand out and truly showcase your unique qualities and capabilities. Let's make your profile shine!</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_personal_information_re_vw8a.svg?alt=media&token=32d3e041-9c94-44e1-832d-e11670567701" class="w-2/3 ml-auto" alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
         <div class="step-mobile mx-2 bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8" style={{backgroundColor:"#2141ce"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-white fjalla" style={{fontSize:"5vw"}}>Find a Course</h3>
                 <p class="mb-6 borel text-gray-300" style={{fontSize:"3.5vw",textTransform:"none"}}>Creating a course is an exciting opportunity to share your passion and knowledge with learners worldwide. Focus on learner outcomes, break down the content into digestible modules, and incorporate practical applications to make the course engaging and applicable. Engage with your students, leverage multimedia and visual aids, and be authentic to create a relatable learning experience. Continuously seek feedback, celebrate student achievements, and most importantly, enjoy the process of making a positive impact through education. As well as generating passive income.Best of luck!</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_updated_resume_re_7r9j.svg?alt=media&token=d03438e1-406b-43e7-acd8-46599b355695" class="w-2/3 ml-auto " alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
     </div>
 </div>
</div>
        
<div className='flex items-center justify-center'>
<Link>

<button style={{fontSize:'3vw'}}    className="fjalla  flex  items-center  auto  hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg  w-full sm:w-auto  text-center "><svg xmlns="http://www.w3.org/2000/svg" style={{width:'6vw',height:'6vw',marginRight:'2vw'}} fill="currentColor" class="bi bi-play-circle mr-1" viewBox="0 0 16 16">

<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
<path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>Watch Video</button>

</Link>
        <Link to="/signup/teacher">
        <button
        style={{fontSize:'2.5vw',backgroundColor:'#2141ce',marginLeft:'4vw'}}
        
        class="hirebtn bg-blue-800 capitalize py-2 px-4  text-white font-semibold border border-black-600 rounded hover:bg-black hover:text-white hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0"
      >Create Courses</button></Link>
</div>
        
     </div>

     <div className='mobile_steps bg-blue-800' style={{width:"100%",marginBottom:'10vw',paddingBottom:'10vw'}}>
        <h1 className='anton homebody__search--title' style={{fontSize:"8.5vw",lineHeight:"10vw",width:"95%",margin:0,paddingLeft:"10%",color:"white",paddingTop:'8vw'}}>How do you register your institute on kesht?</h1>
     
        <div class="py-16" >  
        <div class="  text-gray-500 flex    " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
     <div class=" pb-10" style={{display:"flex",}}>
         <div class="step-mobile mx-2  rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8" style={{backgroundColor:"white"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-purple-900 fjalla" style={{fontSize:"5vw"}}>Sign up</h3>
                 <p class="mb-6 normalcase borel " style={{fontSize:"3.5vw",textTransform:"none"}}>Fill in your name as it appears on your passport or identification card(id). make sure the email address you insert is formal. we discourage the usage of words other than your full name.</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_welcome_cats_thqn.svg?alt=media&token=95041a11-2ec9-4c04-a442-e218ea783abd" class="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
         <div class="step-mobile mx-2 bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-10 lg:px-8" style={{backgroundColor:"white"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-purple-900 fjalla" style={{fontSize:"5vw"}}>Create a profile</h3>
                 <p class="mb-6 borel" style={{fontSize:"3.5vw",textTransform:"none"}}>Remember to complete all the required questions with accuracy, providing detailed and precise responses. By being accurate, you will assist us in providing you with courses that align with your interests.</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_personal_information_re_vw8a.svg?alt=media&token=32d3e041-9c94-44e1-832d-e11670567701" class="w-2/3 ml-auto" alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
         <div class="step-mobile mx-2 bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8" style={{backgroundColor:"white"}}>
             <div class="mb-12 space-y-4">
                 <h3 class="text-2xl font-semibold text-purple-900 fjalla" style={{fontSize:"5vw"}}>Find a Course</h3>
                 <p class="mb-6 borel" style={{fontSize:"3.5vw",textTransform:"none"}}>As soon as your profile is complete. You will be suggested a list of courses that align with your interests all you have to do is navigate through the course list and pick a course.</p>
                 
             </div>
             <img src="https://firebasestorage.googleapis.com/v0/b/shekay-a0ef0.appspot.com/o/undraw_updated_resume_re_7r9j.svg?alt=media&token=d03438e1-406b-43e7-acd8-46599b355695" class="w-2/3 ml-auto " alt="illustration" loading="lazy" width="900" height="600"/>
         </div>
     </div>
 </div>
</div>
        
<div className='flex items-center justify-center '>
<Link>

<button style={{color:'white',fontSize:'2vw'}}   className="fjalla  flex  items-center bg-blue-800 auto  hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg  w-full sm:w-auto  text-center "><svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw',height:'5vw'}} fill="currentColor" class="bi bi-play-circle mr-1" viewBox="0 0 16 16">

<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
<path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>Watch Video</button>

</Link>
        <Link to="/courses">
        <button
        style={{fontSize:'2.5vw',marginLeft:'3vw'}}

        class="hirebtn capitalize py-2 px-4 bg-transparent text-white font-semibold border border-black-600 rounded hover:bg-black hover:text-white hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0"
      >Search for courses</button></Link>
</div>
        
     </div>

     <h1 className='anton  text-gray-900  capitalize ' style={{fontSize:"10vw",marginTop:'20vw',marginLeft:'9vw',lineHeight:'10vw',marginBottom:'8vw'}}>Our most popular courses
</h1>
      <div class="  text-gray-500 flex    " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>

{
  course.filter(i=>i.status == 'published').slice(0,3).map(i=>{
    const {courseTitle,courseThumbnail,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
    
    return(
<div class=" border mx-2 bg-white rounded-2xl shadow-xl " style={{backgroundColor:"white",width:'80vw',paddingLeft:'6vw',paddingBottom:'4vw',paddingRight:'6vw',paddingTop:'8vw'}}>
        
        <h3 className="mb-3 text-blue-800 anton" style={{fontSize:"5vw"}}>Beginner Friendly</h3>
        <div className="relative">
          {
            courseThumbnail == false ?
    <img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'60vw',objectFit:'cover'}}/>

          }

          <p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
        </div>
        <h1 className="mt-4 text-gray-800  fjalla capitalize" style={{fontSize:"5vw"}}>{courseTitle}</h1>
        <div className="my-4">
          <div className="flex space-x-1 mb-1.5 items-center ">
            <span>
              <svg style={{fontSize:"5vw",height:'5vw'}} xmlns="http://www.w3.org/2000/svg" className=" text-blue-600 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              
            </span>
           <span style={{fontSize:"3vw"}} className='text-blue-600 fjalla'>{completionTime} </span> 
          </div>
          <div className="flex space-x-1 items-center">
            <span>
              <svg style={{fontSize:"5vw",height:'5vw'}} xmlns="http://www.w3.org/2000/svg" className=" text-blue-600 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </span>
            <span style={{fontSize:"3vw"}} className='text-blue-600 fjalla'>{totalLectures} Lectures </span> 
  
          </div>
          <div className="flex space-x-1 items-center w-full">
            
         <div className='skillGrid   w-full overflow-hidden' >
          {
            courseSkills.map(i=>{
              return(
                <span style={{fontSize:"2.5vw",paddingLeft:'2vw',paddingRight:'2vw',paddingTop:'2vw',paddingBottom:'2vw'}} class="capitalize bg-green-200 text-green-600  rounded fjalla">{i}</span>

              )
            })
          }
  
         </div>
          </div>
        <Link to={`/course/${courseId}`}>
     <button type="submit" style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "50vw", height: "12vw",marginTop:'6vw' }} class="fjalla mx-auto w-full flex justify-center items-center  text-xl  text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
    Start Course
  </button>
        </Link>
  
        </div>
      </div>
  
    )
  })
}

</div>
<div className='flex justify-center my-12'>
<Link to='/courses'>
<button style={{backgroundColor:"#2141ce"}}  className="mx-auto text-white auto  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg  w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">View More</button>

</Link>
</div>

<Pricing/>

                    <Footer/>
            </div>
</div>
  )
}
