import { collection, doc, onSnapshot, orderBy, query,deleteDoc,setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import DashboardNav from './dashboardNav';

export default function Transactions() {
  const [transactions,setTransactions] = useState([])

  const [reasonMain,setreasonMain] = useState(false)
  const [uid,setuid] = useState('')

  const [user,setuser] = useState([])
  const [notification,setnotification] = useState([])

  const navigate = useNavigate()
  const [spinner,setspinner] =  useState(true)
  const [pop,setpop] =  useState(false)
  const [notificationPop,setnotificationPop] =  useState(false)
  const [copiedMessage,setcopiedMessage] =  useState(false)



  const handleCopyUrl = (id) => {
    const url = id;
  
    // Create a temporary input element
    const input = document.createElement('input');
    input.setAttribute('value', url);
    document.body.appendChild(input);
  
    // Select the input value
    input.select();
    input.setSelectionRange(0, 99999);
  
    // Execute the copy command
    document.execCommand('copy');
  
    // Remove the temporary input element
    document.body.removeChild(input);
  
    // Optionally, provide some visual feedback to the user
    setcopiedMessage(true)
  };
    
 
 
    function mobileNavPop(){
      setpop(true)
  
  
    
  
  }
  
  // Mobile Nav Pop minimize
  function mobileNavPopMinimize(){
  setpop(false)
  
  
  
  }

 
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
    setnotificationPop(false)


    }
    else{
    setpop(true)
    setnotificationPop(false)



    }
}
const convertTimestampToDate = (timestamp) => {
  const date = timestamp.toDate();
  const currentDate = new Date();
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }

  return relativeTime;
};
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
   function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    setDoc(notificationRef,{
      seen:true
    },{merge:true})
    .then(() => {
      
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
    });


   }


    
    useEffect(() => {

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
         setuid(uid)
    const transactionData = collection(firestore,"AccountDetails",uid,'Transaction')
    const q = query(transactionData,orderBy('createdTime','desc'))

  onSnapshot(q,snapshot=>{
      let main =[]
      snapshot.forEach(doc=>{
        main.push({...doc.data(),id:doc.id})
        setTransactions(main)
      })
      
   
  
    })

    const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

    const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

    onSnapshot(notificationRef,snapshot=>{
      let main =[]
      
  
  
  
      snapshot.docs.forEach(doc=>{
        main.push({...doc.data(),id:doc.id})
       
      setnotification(main)
      })
  
      
      
    
  
    })
  const userRef = doc(firestore,"TotalUsers",uid);

    onSnapshot(userRef,snapshot=>{
   
      let main =[]
  
  
  
        main.push({...snapshot.data(),id:snapshot.id})
        
    setuser(main)
  
      
      
    
  
    })
    
      
          // ...
        } else {
          navigate('/')          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);
   
    const reasonDisplay = (transactionID) => {
    if(reasonMain == true){
        // Update the reasonMain state to show the decline reason for the corresponding transactionID
        setreasonMain(false)
    }
    else{
        // Update the reasonMain state to show the decline reason for the corresponding transactionID
        setreasonMain(true)
    }
    };

    function removeReason(){
      setreasonMain(false)

    }

    function handleLogOut(){
      signOut(auth).then(
       navigate('/')
      )
     }

     function desktopSpinnerHandler(){
      setspinner(false)
     }

     function closeCopiedMessage(){
      setcopiedMessage(false)
     }
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

  return (
    <div>
 {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
         { 
         user.map(i=>{
          const{accountType,plan,fullName,photo} = i
          
        return(
          accountType == 'Student' ?
          <div>
    <div id="mobile">
  <DashboardNav/>
            {
      copiedMessage == true &&
      <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
successfully Copied Transactional Id


</div>
</div>
<svg  onClick={closeCopiedMessage}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 
   
    }
    {
      transactions.length != 0 &&
    <p className='fjalla mt-10 mb-4 ml-10 text-blue-700' style={{fontSize:'5vw'}}>Account Transaction Report</p>

    }
    <div class={`${transactions.length != 0 ? "flex w-full items-center justify-center bg-white ":"flex w-full items-center justify-center bg-white " }`}>
  <div class=" w-full  px-0">
{
transactions.length != 0 ?
<table class="w-full min-w-max table-auto text-left">
<thead>
  <tr>



 

    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'2.5vw'}}>Payment For</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'2.5vw'}}>Amount</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'2.5vw'}}>Date</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'2.5vw'}}>Status</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'2.5vw'}}>Method</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'2.5vw'}}></p>
    </th>
  </tr>
</thead>
{
transactions.map(i=>{
  const {accountHolder,accountNumber,buyerId,amount,bank,id,price,declinedReason,courseTitle,paymentFor,accountName,transactionID,chosenMethod,paid,state,createdTime,reason} = i
  return(
    <tbody>
    <tr>
   
   
      <td class=" border-b border-blue-gray-50  text-center">

        <p style={{fontSize:'1.5vw',padding:'3vw 0vw'}} class="borel mx-auto  text-gray-600 font-normal capitalize">{courseTitle} {paymentFor && paymentFor + ' Plan'}{reason}</p>
      </td>
      <td class=" border-b border-blue-gray-50  text-center">
        <p style={{fontSize:'1.5vw',padding:'3vw 0vw'}} class="borel mx-auto  text-gray-600 font-normal">{amount}{price} ETB</p>
      </td>
      <td class=" border-b border-blue-gray-50  text-center">
        <p style={{fontSize:'1.5vw',padding:'3vw 0vw'}} class="borel mx-auto  text-gray-600 font-normal">{createdTime}</p>
      </td>
      <td class=" border-b border-blue-gray-50 ">
        <div class="flex justify-center">
         {
          state == 'Approved' ?
          <div class="relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-green-900  text-xs rounded-md" style={{opacity: 1}}>
          <span class="borel text-center" style={{fontSize:'1.5vw'}}>Paid</span>
        </div>
        :
        state == 'Pending'?
        <div class="relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-yellow-900  text-xs rounded-md" style={{opacity: 1}}>
        <span class="borel text-center" style={{fontSize:'1.5vw'}}>Pending</span>
      </div>:
       <div className='flex items-center relative'>
          <div class="mr-1 relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-red-900  text-xs rounded-md" style={{opacity: 1}}>
         <span class="borel text-center" style={{fontSize:'1.5vw'}}>Declined </span>
         
       </div>
       <svg onClick={()=>reasonDisplay(transactionID)} xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="cursor-pointer bi bi-info-circle" viewBox="0 0 16 16">
       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
       <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
     </svg>
     
     {
       reasonMain[transactionID] &&
      <div id='reasonDesktop' className="bg-red-100 menu notpop border  rounded  z-100  shadow-md  z-20 w-84  py-2 animated faster">
      {/* top */}
      <div   className=" pl-4  flex flex-row justify-between items-center capitalize font-semibold text-sm">
        <h1 className='py-3 Mooli' style={{fontSize:'2.5vw'}}>What caused the decline?</h1>
        <div className="   text-xs rounded px-1">
        <svg onClick={removeReason} xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="cursor-pointer bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </div>

      </div>
      <hr />

      <div>
       
          <p className='text-red-800 fjalla  pb-2 capitalize pl-4' style={{fontSize:'2.5vw'}}>
{declinedReason}

          </p>
      </div>

 
      {/* end bottom */}
    </div>
     }

       </div>

         }
        </div>
      </td>
      {
        bank && bank.length !=0 ?
        <td class=" border-b border-blue-gray-50">
          
          <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">{bank} </p>

        </td>
        :
        <td class=" border-b border-blue-gray-50">
        {
         chosenMethod == 'Cbe Birr'?
         <div class="flex items-center justify-center">
         
         <div class="flex flex-col">
           <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
           </p>
         </div>
       </div>
       :
       chosenMethod == 'Telebirr'?
       <div class="flex items-center justify-center ">
     
       <div class="flex flex-col">
         <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
         </p>
       </div>
     </div>
     :
     chosenMethod == 'Bank Of Abyssinia'?
     <div class="flex items-center justify-center ">
  
     <div class="flex flex-col">
       <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">BOA
       </p>
     </div>
   </div>
   :
   chosenMethod === 'Commercial Bank Of Ethiopia' &&
   <div class="flex items-center justify-center ">

   <div class="flex flex-col">
     <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">CBE 
     </p>
   </div>
   </div>
   
   
   
   
        }
         </td>
      }
      <td className='border-b border-blue-gray-50  text-center'> 
      <button onClick={()=>handleCopyUrl(transactionID)} style={{fontSize:'1.5vw'}} class="fjalla inline-flex items-center text-sm  rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none ">
              
              Copy Transaction Id
            </button>
      </td>
   
   
 
    </tr>


 
  </tbody>
  )
})
}
</table>


:
<div>
<div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Money%20stress-bro.svg?alt=media&token=d59ea12d-9ff4-454d-9e89-8042319d3a60" alt="Transaction" style={{width:"25%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-4' style={{fontSize:"5vw"}}>Currently, your account doesn't have any transactions
  
  
  </h1>
      <p className='borel text-gray-500 text-center' style={{fontSize:"3vw"}}>You can find all of the transaction information for your account here</p>
    
    </div>

</div>
}

</div>

</div>
</div>
<div id="tablet">
  <DashboardNav/>
            {
      copiedMessage == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800  border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize" style={{fontSize:'12px'}}>
     successfully Copied Transactional Id
    </div>
    <button onClick={closeCopiedMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
    <p className='fjalla mt-10 mb-4 ml-10 text-blue-700' style={{fontSize:'20px'}}>Account Transaction Report</p>
    <div class={`${transactions.length != 0 ? "flex w-full items-center justify-center bg-white ":"flex w-full items-center justify-center bg-white " }`}>
  <div class=" w-full  px-0">
{
transactions.length != 0 ?
<table class="w-full min-w-max table-auto text-left">
<thead>
  <tr>



 

    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'1.7vw'}}>Payment For</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'1.7vw'}}>Amount</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'1.7vw'}}>Date</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'1.7vw'}}>Status</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'1.7vw'}}>Method</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'1.7vw'}}></p>
    </th>
  </tr>
</thead>
{
transactions.map(i=>{
  const {accountHolder,accountNumber,buyerId,amount,bank,id,price,declinedReason,courseTitle,paymentFor,accountName,transactionID,chosenMethod,paid,state,createdTime,reason} = i
  return(
    <tbody>
    <tr>
   
   
      <td class=" border-b border-blue-gray-50  text-center">

        <p style={{fontSize:'1.5vw',padding:'3vw 0vw'}} class="borel mx-auto  text-gray-600 font-normal capitalize">{courseTitle} {paymentFor && paymentFor + ' Plan'}{reason}</p>
      </td>
      <td class=" border-b border-blue-gray-50  text-center">
        <p style={{fontSize:'1.5vw',padding:'3vw 0vw'}} class="borel mx-auto  text-gray-600 font-normal">{amount}{price} ETB</p>
      </td>
      <td class=" border-b border-blue-gray-50  text-center">
        <p style={{fontSize:'1.5vw',padding:'3vw 0vw'}} class="borel mx-auto  text-gray-600 font-normal">{createdTime}</p>
      </td>
      <td class=" border-b border-blue-gray-50 ">
        <div class="flex justify-center">
         {
          state == 'Approved' ?
          <div class="relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-green-900  text-xs rounded-md" style={{opacity: 1}}>
          <span class="borel text-center" style={{fontSize:'1.5vw'}}>Paid</span>
        </div>
        :
        state == 'Pending'?
        <div class="relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-yellow-900  text-xs rounded-md" style={{opacity: 1}}>
        <span class="borel text-center" style={{fontSize:'1.5vw'}}>Pending</span>
      </div>:
       <div className='flex items-center relative'>
          <div class="mr-1 relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-red-900  text-xs rounded-md" style={{opacity: 1}}>
         <span class="borel text-center" style={{fontSize:'1.5vw'}}>Declined </span>
         
       </div>
       <svg onClick={()=>reasonDisplay(transactionID)} xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="cursor-pointer bi bi-info-circle" viewBox="0 0 16 16">
       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
       <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
     </svg>
     
     {
       reasonMain[transactionID] &&
      <div id='reasonDesktop' className="bg-red-100 menu notpop border  rounded  z-100  shadow-md  z-20 w-84  py-2 animated faster">
      {/* top */}
      <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
        <h1 className='py-3 Mooli' style={{fontSize:'2vw'}}>What caused the decline?</h1>
        <div className="   text-xs rounded px-1">
        <svg onClick={removeReason} xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="cursor-pointer bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </div>

      </div>
      <hr />

      <div>
       
          <p className='text-red-800 fjalla  pb-2 capitalize pl-4' style={{fontSize:'1.8vw'}}>
{declinedReason}

          </p>
      </div>

 
      {/* end bottom */}
    </div>
     }

       </div>

         }
        </div>
      </td>
      {
        bank && bank.length !=0 ?
        <td class=" border-b border-blue-gray-50">
          
          <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">{bank} </p>

        </td>
        :
        <td class=" border-b border-blue-gray-50">
        {
         chosenMethod == 'Cbe Birr'?
         <div class="flex items-center justify-center">
         
         <div class="flex flex-col">
           <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
           </p>
         </div>
       </div>
       :
       chosenMethod == 'Telebirr'?
       <div class="flex items-center justify-center ">
     
       <div class="flex flex-col">
         <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
         </p>
       </div>
     </div>
     :
     chosenMethod == 'Bank Of Abyssinia'?
     <div class="flex items-center justify-center ">
  
     <div class="flex flex-col">
       <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">BOA
       </p>
     </div>
   </div>
   :
   chosenMethod === 'Commercial Bank Of Ethiopia' &&
   <div class="flex items-center justify-center ">

   <div class="flex flex-col">
     <p style={{fontSize:'1.5vw'}} class="borel mx-auto  text-gray-600 font-normal">CBE 
     </p>
   </div>
   </div>
   
   
   
   
        }
         </td>
      }
      <td className='border-b border-blue-gray-50  text-center'> 
      <button onClick={()=>handleCopyUrl(transactionID)} style={{fontSize:'1.5vw'}} class="fjalla inline-flex items-center text-sm  rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none ">
              
              Copy Transaction Id
            </button>
      </td>
   
   
 
    </tr>


 
  </tbody>
  )
})
}
</table>


:
<div>
<div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Money%20stress-bro.svg?alt=media&token=d59ea12d-9ff4-454d-9e89-8042319d3a60" alt="Transaction" style={{width:"25%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-4' style={{fontSize:"30px"}}>Currently, your account doesn't have any transactions
  
  
  </h1>
      <p className='borel text-gray-500 text-center' style={{fontSize:"16px"}}>You can find all of the transaction information for your account here</p>
    
    </div>

</div>
}

</div>

</div>
</div>
  <div id="desktop">
<nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' ?
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 
 :
 plan == 'Basic' && 
 <Link to='/pricing'>
 
 <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
 </Link>
 
  }
      <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
       <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/enrolledcourses' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >Enrolled Courses</li>
      
                    </Link>
      <Link to="/courses" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 border-b border-blue-800' >Transactions</li>
      
                    </Link>
                    <Link to="/achievements" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Achievements</li>
      
                    </Link>
      
           
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            {
      copiedMessage == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800  border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize" style={{fontSize:'12px'}}>
     successfully Copied Transactional Id
    </div>
    <button onClick={closeCopiedMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
    <p className='fjalla mt-10 mb-4 ml-10 text-blue-700' style={{fontSize:'20px'}}>Account Transaction Report</p>
    <div class={`${transactions.length != 0 ? "flex w-full items-center justify-center bg-white ":"flex w-full items-center justify-center bg-white " }`}>
  <div class=" w-full  px-0">
{
transactions.length != 0 ?
<table class="w-full min-w-max table-auto text-left">
<thead>
  <tr>

    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Account Holder</p>
    </th>

 

    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Payment For</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Amount</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Date</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Status</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Method</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}></p>
    </th>
  </tr>
</thead>
{
transactions.map(i=>{
  const {accountHolder,accountNumber,buyerId,amount,bank,id,price,declinedReason,courseTitle,paymentFor,accountName,transactionID,chosenMethod,paid,state,createdTime,reason} = i
  return(
    <tbody>
    <tr>
   
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{accountHolder}{accountName}</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal capitalize">{courseTitle} {paymentFor && paymentFor + ' Plan'}{reason}</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{amount}{price} ETB</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{createdTime}</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2">
        <div class="flex justify-center">
         {
          state == 'Approved' ?
          <div class="relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-green-900  text-xs rounded-md" style={{opacity: 1}}>
          <span class="borel text-center" style={{fontSize:'11px'}}>Paid</span>
        </div>
        :
        state == 'Pending'?
        <div class="relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-yellow-900  text-xs rounded-md" style={{opacity: 1}}>
        <span class="borel text-center" style={{fontSize:'11px'}}>Pending</span>
      </div>:
       <div className='flex items-center relative'>
          <div class="mr-1 relative fjalla uppercase mx-auto text-center whitespace-nowrap select-none  text-red-900  text-xs rounded-md" style={{opacity: 1}}>
         <span class="borel text-center" style={{fontSize:'11px'}}>Declined </span>
         
       </div>
       <svg onClick={()=>reasonDisplay(transactionID)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-info-circle" viewBox="0 0 16 16">
       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
       <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
     </svg>
     
     {
       reasonMain[transactionID] &&
      <div id='reasonDesktop' className="bg-red-100 menu notpop border  rounded  z-100  shadow-md  z-20 w-84  py-2 animated faster">
      {/* top */}
      <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
        <h1 className='py-3 Mooli'>What caused the decline?</h1>
        <div className="   text-xs rounded px-1">
        <svg onClick={removeReason} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="cursor-pointer bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </div>

      </div>
      <hr />

      <div>
       
          <p className='text-red-800 fjalla  pb-2 capitalize pl-4'>
{declinedReason}

          </p>
      </div>

 
      {/* end bottom */}
    </div>
     }

       </div>

         }
        </div>
      </td>
      {
        bank && bank.length !=0 ?
        <td class="p-4 border-b border-blue-gray-50">
          
          <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{bank} </p>

        </td>
        :
        <td class="p-4 border-b border-blue-gray-50">
        {
         chosenMethod == 'Cbe Birr'?
         <div class="flex items-center justify-center">
         
         <div class="flex flex-col">
           <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
           </p>
         </div>
       </div>
       :
       chosenMethod == 'Telebirr'?
       <div class="flex items-center justify-center ">
     
       <div class="flex flex-col">
         <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
         </p>
       </div>
     </div>
     :
     chosenMethod == 'Bank of Abyssinia'?
     <div class="flex items-center justify-center ">
  
     <div class="flex flex-col">
       <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
       </p>
     </div>
   </div>
   :
   
   <div class="flex items-center justify-center ">

   <div class="flex flex-col">
     <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod} 
     </p>
   </div>
   </div>
   
   
   
   
        }
         </td>
      }
      <td className='border-b border-blue-gray-50 px-2 text-center'> 
      <button onClick={()=>handleCopyUrl(transactionID)} style={{fontSize:'11px'}} class="fjalla inline-flex items-center gap-x-2 text-sm  rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none ">
              
              Copy Transaction Id
            </button>
      </td>
   
   
 
    </tr>


 
  </tbody>
  )
})
}
</table>


:
<div>
<div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Money%20stress-bro.svg?alt=media&token=d59ea12d-9ff4-454d-9e89-8042319d3a60" alt="Transaction" style={{width:"25%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-4' style={{fontSize:"30px"}}>Currently, your account doesn't have any transactions
  
  
  </h1>
      <p className='borel text-gray-500 text-center' style={{fontSize:"16px"}}>You can find all of the transaction information for your account here</p>
    
    </div>

</div>
}

</div>

</div>
</div>
  </div>

          :
          <div>
<div id="desktop">
<nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
 

      
       <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 border-b border-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            {
      copiedMessage == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800  border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize" style={{fontSize:'12px'}}>
     successfully Copied Transactional Id
    </div>
    <button onClick={closeCopiedMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
    <div class={`${transactions.length != 0 ? "flex w-full items-center justify-center bg-white ":"flex w-full items-center justify-center bg-white " }`}>
  <div class=" w-full  px-0">
{
transactions.length != 0 ?
<table class="w-full min-w-max table-auto text-left mt-12">
<thead>
  <tr>

    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Account Holder</p>
    </th>

 

    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Payment For</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Amount</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Date</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Status</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}>Method</p>
    </th>
    <th class="border-y border-blue-gray-100 text-center bg-blue-gray-50/50 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none " style={{fontSize:'11px'}}></p>
    </th>
  </tr>
</thead>
{
transactions.map(i=>{
  const {accountHolder,accountNumber,buyerId,amount,bank,id,price,declinedReason,courseTitle,paymentFor,accountName,transactionID,chosenMethod,paid,state,createdTime,reason} = i
  return(
    <tbody>
    <tr>
   
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{accountHolder}{accountName}</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal capitalize">{courseTitle} {paymentFor && paymentFor + ' Plan'}{reason}</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{amount}{price} ETB</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{createdTime}</p>
      </td>
      <td class=" border-b border-blue-gray-50 px-2 text-center">
        <div class="text-center">
         {
          state == 'Approved' ?
          <div class="relative fjalla uppercase whitespace-nowrap select-none  text-green-900  text-xs rounded-md" style={{opacity: 1}}>
          <span class="borel" style={{fontSize:'11px'}}>Paid</span>
        </div>
        :
        state == 'Pending'?
        <div class="relative fjalla uppercase whitespace-nowrap select-none  text-yellow-900  text-xs rounded-md" style={{opacity: 1}}>
        <span class="borel" style={{fontSize:'11px'}}>Pending</span>
      </div>:
       <div className='text-center flex items-center justify-center relative'>
          <div class=" mr-1 relative fjalla uppercase whitespace-nowrap select-none  text-red-900  text-xs rounded-md" style={{opacity: 1}}>
         <span class="borel" style={{fontSize:'11px'}}>Declined </span>
         
       </div>
       <svg onClick={reasonDisplay} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-info-circle" viewBox="0 0 16 16">
       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
       <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
     </svg>
     
     {
      reasonMain == true &&
        <div id='reasonDesktop' className="bg-red-100 menu notpop border  rounded    shadow-md  z-20 w-84  py-2 animated faster">
        {/* top */}
        <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
          <h1 className='py-3 Mooli'>What caused the decline?</h1>
          <div className="   text-xs rounded px-1">
          <svg onClick={removeReason} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
  </svg>
          </div>
        </div>
        <hr />
  
        <div>
         
            <p className='text-red-800 fjalla  py-2 capitalize px-3'>
  {declinedReason}
  
            </p>
        </div>
  
   
        {/* end bottom */}
      </div>
     }

       </div>

         }
        </div>
      </td>
      {
        bank && bank.length !=0 ?
        <td class="p-4 border-b border-blue-gray-50">
          
          <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{bank} </p>

        </td>
        :
        <td class="p-4 border-b border-blue-gray-50">
        {
         chosenMethod == 'Cbe Birr'?
         <div class="flex items-center justify-center">
         
         <div class="flex flex-col">
           <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
           </p>
         </div>
       </div>
       :
       chosenMethod == 'Telebirr'?
       <div class="flex items-center justify-center ">
     
       <div class="flex flex-col">
         <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
         </p>
       </div>
     </div>
     :
     chosenMethod == 'Bank of Abyssinia'?
     <div class="flex items-center justify-center ">
  
     <div class="flex flex-col">
       <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod}
       </p>
     </div>
   </div>
   :
   
   <div class="flex items-center justify-center ">

   <div class="flex flex-col">
     <p style={{fontSize:'11px'}} class="borel mx-auto  text-gray-600 font-normal">{chosenMethod} 
     </p>
   </div>
   </div>
   
   
   
   
        }
         </td>
      }
      <td className='border-b border-blue-gray-50 px-2 text-center'> 
      <button onClick={()=>handleCopyUrl(transactionID)} style={{fontSize:'11px'}} class="fjalla inline-flex items-center gap-x-2 text-sm  rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none ">
              
              Copy Transaction Id
            </button>
      </td>
   
   
 
    </tr>


 
  </tbody>
  )
})
}
</table>


:
<div>
<div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Money%20stress-bro.svg?alt=media&token=d59ea12d-9ff4-454d-9e89-8042319d3a60" alt="Transaction" style={{width:"25%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-4' style={{fontSize:"30px"}}>Currently, your account doesn't have any transactions
  
  
  </h1>
      <p className='borel text-gray-500 text-center' style={{fontSize:"16px"}}>You can find all of the transaction information for your account here</p>
    
    </div>

</div>
}

</div>

</div>
</div>

<div id='mobile'>
<DashboardNav/>



<div className=''>

{
transactions.length != 0 ?
<div>

<table class="w-full min-w-max table-auto text-left ">
<thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
                <th  scope="col" class=" fjalla text-center  antialiased font-sans text-sm text-gray-900 font-normal leading-none "style={{fontSize:'2.3vw',padding:'2.5vw 1vw'}}>
                    Transaction Id
                </th>
                <th scope="col" class=" fjalla text-center  antialiased font-sans text-sm text-gray-900 font-normal leading-none "style={{fontSize:'2.3vw',padding:'2.5vw 1vw'}}>
                Account Holder
                </th>
            
              
                
                <th scope="col" class=" fjalla text-center  antialiased font-sans text-sm text-gray-900 font-normal leading-none "style={{fontSize:'2.3vw',padding:'2.5vw 1vw'}}>
                    Amount
                </th>
                <th scope="col" class=" fjalla text-center  antialiased font-sans text-sm text-gray-900 font-normal leading-none "style={{fontSize:'2.3vw',padding:'2.5vw 1vw'}}>
                    Date
                </th>
                <th scope="col" class=" fjalla text-center  antialiased font-sans text-sm text-gray-900 font-normal leading-none "style={{fontSize:'2.3vw',padding:'2.5vw 1vw'}}>
                    Status
                </th>
                <th scope="col" class=" fjalla text-center  antialiased font-sans text-sm text-gray-900 font-normal leading-none "style={{fontSize:'2.3vw',padding:'2.5vw 1vw'}}>
                    Method
                </th>
               
            </tr>
        </thead>
{
transactions.map(i=>{
  const {accountHolder,accountNumber,buyerId,amount,bank,id,price,declinedReason,courseTitle,paymentFor,accountName,transactionID,chosenMethod,paid,state,createdTime,reason} = i
  return(
    <tbody>
    <tr>
      <td class="py-4 border-b border-blue-gray-50">
        <div class="flex items-center " >
          <p class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize"style={{fontSize:'1.5vw',padding:'2vw 0vw'}}>{id}</p>
        </div>
      </td>
      <td class="text-center border-b border-blue-gray-50">
        <p style={{fontSize:'1.6vw',padding:'2vw 0vw'}}class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize">{accountHolder}{accountName}</p>
      </td>
     
      <td class="text-center border-b border-blue-gray-50">
        <p style={{fontSize:'1.6vw',padding:'2vw 0vw'}}class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize">{amount}{price} ETB</p>
      </td>
      <td class="text-center border-b border-blue-gray-50">
        <p style={{fontSize:'1.6vw',padding:'2vw 0vw'}}class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize">{createdTime}</p>
      </td>
      <td class=" border-b border-blue-gray-50">
        <div class="flex justify-center">
         {
          state == 'Approved' ?
          <div class="text-center mx-auto relative fjalla uppercase whitespace-nowrap select-none  text-green-900 py-1  text-xs rounded-md" style={{fontSize:'1.6vw',marginRight:'1.5vw',padding:'1vw 1vw',opacity: 1}}>
          <span class="">Completed</span>
        </div>
        :
        state == 'Pending'?
        <div class="text-center mx-auto relative fjalla uppercase whitespace-nowrap select-none   text-xs rounded-mde  text-yellow-600  text-xs rounded-md" style={{fontSize:'1.6vw',marginRight:'1.5vw',padding:'1vw 1vw',opacity: 1}}>
        <span class="">Pending</span>
      </div>:
       <div className='flex items-center relative'>
          <div class="mr-1 text-center mx-auto relative fjalla uppercase whitespace-nowrap select-none   text-xs rounded-mde  text-red-900  text-xs rounded-md" style={{fontSize:'1.6vw',marginRight:'1.5vw',padding:'1vw 1vw',opacity: 1}}>
         <span class="">Declined </span>
         
       </div>
       <svg onClick={reasonDisplay} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-info-circle" viewBox="0 0 16 16">
       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
       <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
     </svg>
     
     {
      reason == true &&
      <div id='reasonDesktop' className="bg-red-100 menu notpop border  rounded    shadow-md  z-20 w-84  py-2 animated faster">
      {/* top */}
      <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
        <h1 className='py-3 Mooli'>What caused the decline?</h1>
        <div className="   text-xs rounded px-1">
        <svg onClick={removeReason} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </div>
      </div>
      <hr />

      <div>
       
          <p className='text-red-800 fjalla  py-2 capitalize px-3'>
{declinedReason}

          </p>
      </div>

 
      {/* end bottom */}
    </div>
     }

       </div>

         }
        </div>
      </td>
      {
        bank && bank.length !=0 ?
        <td class="py-4 border-b border-blue-gray-50">
          
          <p class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize">{bank} </p>

        </td>
        :
        <td class="py-4 border-b border-blue-gray-50">
        {
         chosenMethod == 'Cbe Birr'?
         <div class="flex items-center ">
    
         <div class="flex flex-col">
           <p class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize"style={{fontSize:'1.6vw'}}>{chosenMethod}
           </p>
         </div>
       </div>
       :
       chosenMethod == 'Telebirr'?
       <div class="flex items-center ">
 
       <div class="flex flex-col">
         <p class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize"style={{fontSize:'1.6vw'}}>{chosenMethod}
         </p>
       </div>
     </div>
     :
     chosenMethod == 'Bank of Abyssinia'?
     <div class="flex items-center ">

     <div class="flex flex-col">
       <p class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize"style={{fontSize:'1.6vw'}}>{chosenMethod}
       </p>
     </div>
   </div>
   :
   
   <div class="flex items-center ">

   <div class="flex flex-col">
     <p class="borel mx-auto block antialiased font-sans text-sm leading-normal text-gray-600 font-normal capitalize"style={{fontSize:'1.6vw'}}>{chosenMethod} 
     </p>
   </div>
   </div>
   
   
   
   
        }
         </td>
      }
   
   
 
    </tr>


 
  </tbody>
  )
})
}
</table>
</div>


:
<div>
<div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Money%20stress-bro.svg?alt=media&token=d59ea12d-9ff4-454d-9e89-8042319d3a60" alt="Transaction" style={{width:"50%"}}/>
      <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"5vw",lineHeight:'6vw',marginTop:'3vw'}}>Currently, your account doesn't have any transactions
  
  
  </h1>
      <p className='borel text-gray-500 text-center' style={{fontSize:'2.5vw'}}>You can find all of the transaction information for your account here</p>
    
    </div>

</div>
}
</div>


</div>
<div id='tablet'>
<DashboardNav/>

<div  className={`${transactions.length == 0 ? ' w-full flex-col ' :' w-full flex-col '}`}>
  <div class=" w-full  px-0">
{
transactions.length != 0 ?
<table class="w-full">
<thead>
  <tr>
  <th class="border-y border-blue-gray-100 py-4" style={{width:'26%'}}>
      <p class="fjalla text-center  text-gray-900  leading-none" style={{fontSize:'1.7vw'}}>Transaction Id</p>
    </th>
    <th class="border-y border-blue-gray-100 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none" style={{fontSize:'1.7vw'}}>Account Holder</p>
    </th>

 

    <th class="border-y border-blue-gray-100 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none" style={{fontSize:'1.7vw'}}>Payment For</p>
    </th>
    <th class="border-y border-blue-gray-100 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none" style={{fontSize:'1.7vw'}}>Amount</p>
    </th>
    <th class="border-y border-blue-gray-100 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none" style={{fontSize:'1.7vw'}}>Date</p>
    </th>
    <th class="border-y border-blue-gray-100 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none" style={{fontSize:'1.7vw'}}>Status</p>
    </th>
    <th class="border-y border-blue-gray-100 py-4">
      <p class="fjalla text-center  text-gray-900  leading-none" style={{fontSize:'1.7vw'}}>Method</p>
    </th>
  
  </tr>
</thead>
{
transactions.map(i=>{
  const {accountHolder,accountNumber,buyerId,amount,bank,id,price,declinedReason,courseTitle,paymentFor,accountName,transactionID,chosenMethod,paid,state,createdTime,reason} = i
  return(
    <tbody>
    <tr>
      <td class=" border-b border-blue-gray-50" tyle={{width:'26%'}}>
          <p style={{fontSize:'1.5vw'}}class="borel mx-auto text-center  antialiased font-sans text-sm leading-normal text-gray-600  ">{id}</p>
      </td>
      <td class=" border-b border-blue-gray-50">
        <p style={{fontSize:'1.5vw'}}class="borel mx-auto text-center  antialiased font-sans text-sm leading-normal text-gray-600  ">{accountHolder}{accountName}</p>
      </td>
      <td class=" border-b border-blue-gray-50">
        <p style={{fontSize:'1.5vw'}}class="borel mx-auto text-center  antialiased font-sans text-sm leading-normal text-gray-600   capitalize">{courseTitle} {paymentFor && paymentFor + ' Plan'}{reason}</p>
      </td>
      <td class=" border-b border-blue-gray-50">
        <p style={{fontSize:'1.5vw'}}class="borel mx-auto text-center  antialiased font-sans text-sm leading-normal text-gray-600  ">{amount}{price} ETB</p>
      </td>
      <td class=" border-b border-blue-gray-50">
        <p style={{fontSize:'1.5vw'}}class="borel mx-auto text-center  antialiased font-sans text-sm leading-normal text-gray-600  ">{createdTime}</p>
      </td>
      <td class=" border-b border-blue-gray-50">
        <div class="flex justify-center">
         {
          state == 'Approved' ?
          <div class="text-center mx-auto relative fjalla uppercase whitespace-nowrap select-none  text-green-900 py-1  text-xs rounded-md" style={{opacity: 1}}>
          <span class="" style={{fontSize:'1.5vw'}}>Completed</span>
        </div>
        :
        state == 'Pending'?
        <div class="text-center mx-auto relative fjalla uppercase whitespace-nowrap select-none  text-yellow-700 py-1  text-xs rounded-md" style={{opacity: 1}}>
        <span class="" style={{fontSize:'1.5vw'}}>Pending</span>
      </div>:
       <div className='flex items-center relative'>
          <div class="mr-1 text-center mx-auto relative fjalla uppercase whitespace-nowrap select-none  text-red-900 py-1  text-xs rounded-md" style={{opacity: 1}}>
         <span class="" style={{fontSize:'1.5vw'}}>Declined </span>
         
       </div>
       <svg onClick={reasonDisplay} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-info-circle" viewBox="0 0 16 16">
       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
       <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
     </svg>
     
     {
      reason == true &&
      <div id='reasonDesktop' className="bg-red-100 menu notpop border  rounded    shadow-md  z-20 w-84  py-2 animated faster">
      {/* top */}
      <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
        <h1 className='py-3 borel'>What caused the decline?</h1>
        <div className="   text-xs rounded px-1">
        <svg onClick={removeReason} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </div>
      </div>
      <hr />

      <div>
       
          <p className='text-red-800 fjalla  py-2 capitalize px-3'>
{declinedReason}

          </p>
      </div>

 
      {/* end bottom */}
    </div>
     }

       </div>

         }
        </div>
      </td>
      {
        bank && bank.length !=0 ?
        <td class="py-4 border-b border-blue-gray-50">
          
          <p class="borel mx-auto text-center block antialiased font-sans text-sm leading-normal text-gray-600  ">{bank} </p>

        </td>
        :
        <td class="py-4 border-b border-blue-gray-50 flex justify-center">
        {
         chosenMethod == 'Cbe Birr'?
         <div class="flex items-center ">
      
         <div class="flex flex-col">
           <p style={{fontSize:'1.5vw'}} class="borel mx-auto text-center block antialiased font-sans text-sm leading-normal text-gray-600  ">{chosenMethod}
           </p>
         </div>
       </div>
       :
       chosenMethod == 'Telebirr'?
       <div class="flex items-center ">
      
       <div class="flex flex-col">
         <p style={{fontSize:'1.5vw'}} class="borel mx-auto text-center block antialiased font-sans text-sm leading-normal text-gray-600  ">{chosenMethod}
         </p>
       </div>
     </div>
     :
     chosenMethod == 'Bank of Abyssinia'?
     <div class="flex items-center justify-center ">
     
     <div class="flex flex-col">
       <p style={{fontSize:'1.5vw'}} class="borel mx-auto text-center block antialiased font-sans text-sm leading-normal text-gray-600  ">{chosenMethod}
       </p>
     </div>
   </div>
   :
   
   <div class="flex items-center ">

   <div class="flex flex-col">
     <p style={{fontSize:'1.5vw'}} class="borel mx-auto text-center block antialiased font-sans text-sm leading-normal text-gray-600  ">{chosenMethod} 
     </p>
   </div>
   </div>
   
   
   
   
        }
         </td>
      }
   
   
 
    </tr>


 
  </tbody>
  )
})
}
</table>


:
<div>
<div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Money%20stress-bro.svg?alt=media&token=d59ea12d-9ff4-454d-9e89-8042319d3a60" alt="Transaction" style={{width:"25%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"40px"}}>Currently, your account doesn't have any transactions
  
  
  </h1>
      <p className='borel text-gray-500 text-center'>You can find all of the transaction information for your account here</p>
    
    </div>

</div>
}

</div>

</div>
  </div>
          </div>
        )

            
          
         })
           
           }



    </div>
  )
}
