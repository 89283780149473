import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc,getDoc, serverTimestamp, deleteDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import aws from 'aws-sdk';
import { uploadFile } from 'react-s3';
import { v4 } from 'uuid';
import { set } from 'mongoose';
import DashboardNav from './dashboardNav';
import { UseAuth } from '../context/contex';

export default function Lecture() {
    const {courseId,lectureId} = useParams()
    const [uid,setuid] = useState("")
    const [notification,setnotification] = useState([])

    const [lectureTitleNew,setlectureTitleNew] = useState("")
    const [quizError,setquizError] = useState("")
    const [finalLecture,setfinalLecture] = useState()
    const [initialLecture,setinitialLecture] = useState()
    const [lectureUploadError,setlectureUploadError] = useState(false)
    const [lecturepdfError,setlecturepdfError] = useState(false)
    const [lectureaudioError,setlectureaudioError] = useState(false)
    const [lectureimgError,setlectureimgError] = useState(false)



    const{accountType} = UseAuth()


    const [totalCompletedLectures,settotalCompletedLectures] = useState()
    const [nextLectureId,setNextLectureId] = useState("")

    
    const [test,settest] = useState([])

    const [questions,setquestions] = useState("")
    const [isDescription,setisDescription] = useState(true)
    const [lectureSelect,setlectureSelect] = useState(false)


    const [isAudio,setisAudio] = useState(false)
    const [isPicture,setisPicture] = useState(false)
    const [isPdf,setisPdf] = useState(false)

    const [videoDurations,setVideoDurations] = useState(false)

    const [questionsError,setquestionsError] = useState("")
    const [user,setuser] = useState([])
    const [lectures,setlectures] = useState([])
    const [selectedlecture,setSelectedLecture] = useState([])
    const [completedLectures,setcompletedLectures] = useState({})
    const [pop,setpop] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)

    const [currentLectureId,setCurrentLectureId] = useState('')
    const [quiz,setQuiz] = useState([])

    const [lectureTitleEditor,setLectureTitleEditor] = useState(false)
    const [lectureDescriptionEditor,setLectureDescriptionEditor] = useState(false)
    
    const [lecturePdfEditor,setLecturePdfEditor] = useState(false)
    const [lectureAudioEditor,setLectureAudioEditor] = useState(false)
    
    const [lectureImageEditor,setLectureImageEditor] = useState(false)
    const [pdfUploaderModal,setpdfUploaderModal] = useState(false)
    const [pdfDeleteModal,setpdfDeleteModal] = useState(false)
    const [imageDeleteModal,setimageDeleteModal] = useState(false)
    const [audioDeleteModal,setaudioDeleteModal] = useState(false)
    const [lectureTitleMessage,setlectureTitleMessage] = useState(false)
    const [lectureDescriptionMessage,setlectureDescriptionMessage] = useState(false)
    const [lectureDescriptionNew,setlectureDescriptionNew] = useState('')





    const [audioUploaderModal,setaudioUploaderModal] = useState(false)
    const [imageUploaderModal,setimageUploaderModal] = useState(false)
    const [quizUploaderModal,setquizUploaderModal] = useState(false)
    const [lectureVideoUploaderModal,setlectureVideoUploaderModal] = useState(false)
    const [lectureVideoUploadMessage,setlectureVideoUploadMessage] = useState(false)
    const [lectureVideoDeleteMessage,setlectureVideoDeleteMessage] = useState(false)
    const [pdfUploadedMessage,setpdfUploadedMessage] = useState(false)
    const [pdfdeletedMessage,setpdfdeletedMessage] = useState(false)
    const [imagedeletedMessage,setimagedeletedMessage] = useState(false)
    const [audiodeletedMessage,setaudiodeletedMessage] = useState(false)



    const [audioUploaderMessage,setaudioUploaderMessage] = useState(false)
    const [imageUploadedMessage,setImageUploadedMessage] = useState(false)


    const [lectureVideoDeleteModal,setlectureVideoDeleteModal] = useState(false)











function openPdf(){
  setisPdf(true)
  setisAudio(false)
  setisDescription(false)
  setisPicture(false)

}
function openDescription(){
  setisPdf(false)
  setisAudio(false)
  setisDescription(true)
  setisPicture(false)

}

function openAudio(){
  setisPdf(false)
  setisAudio(true)
  setisDescription(false)
  setisPicture(false)

}
function openPicture(){
  setisPdf(false)
  setisAudio(false)
  setisDescription(false)
  setisPicture(true)

}
    const navigate = useNavigate()

    const videoRef = useRef(null);

   

    const [lectureDescription,setlectureDescription] = useState("")
    const [lectureDescriptionError,setlectureDescriptionError] = useState("")
    const [fileMesssage,setFileMessage] = useState("")

    const [files,setFiles] = useState([])
    const [lectureTitleError,setlectureTitleError] = useState("")
    const [error,setError] = useState("")



    const [progress,setProgress] = useState("")
    const [UploadedByte,setUploadedByte] = useState("")

    const [course,setcourse] = useState([])
    const [studentLecture,setstudentLecture] = useState([])
    const [lectureMain,setlectureMain] = useState([])


    const [lecture,setlecture] = useState([])

    const [lectureVideo,setlectureVideo] = useState([])
    const [lectureLength,setlectureLength] = useState([])
    const [lecturePdf,setlecturePdf] = useState([])
    const [lectureImg,setlectureImg] = useState([])

    const [lectureAudio,setlectureAudio] = useState([])
    const [spinner,setspinner] = useState(false)



    const [retrievedLectureVideo,setRetrievedlectureVideo] = useState("")

    const uniqueKey = v4().slice(0, 8);



    const textRef = React.useRef();
    React.useEffect(() => {
      if (textRef && textRef.current) {
        textRef.current.style.height = "0px";
        const taHeight = textRef.current.scrollHeight;
        textRef.current.style.height = taHeight + "px";
      }
    }, [lectureDescription]);

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
     
      
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
        let main =[]
        
        main.push({...snapshot.data()})
        setcourse(main)
    
      })
   
  
      
          

      const userRef = doc(firestore,"TotalUsers",uid);

  onSnapshot(userRef,snapshot=>{
   
    let main =[]



      main.push({...snapshot.data(),id:snapshot.id})
      
  setuser(main)
  main.map(i=>{
    if(i.accountType == 'Teacher'){
      onSnapshot(collection(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures"),snapshot=>{
        let main =[]
        snapshot.forEach(doc=>{
          main.push({...doc.data()})
          console.log(doc.data())
        })
        
        setlectureLength(main)
    
      })
      const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)

      onSnapshot(documentRef,snapshot=>{
        let main =[]
    if(snapshot){
        main.push({...snapshot.data()})
        setlecture(main)
        if(lecture.length != 0) {
            lecture.map(i=>[
                setRetrievedlectureVideo(i.lectureVideo)
            ])
            
        }
    }  
    
    else{
        setlecture([])
    }

      
      })
      const quizRef = collection(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId,"quiz");
  
  onSnapshot(quizRef,snapshot=>{
    let main =[]
    snapshot.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
      setQuiz(main)

    })

   
 
  
  
  })


 

    }

    else{
                  
const completedLectures = doc(firestore,"AccountDetails",uid,"Courses",courseId);
  
onSnapshot(completedLectures,snapshot=>{
  let main =[]
  main.push({...snapshot.data().progress,id:snapshot.id})
  setcompletedLectures(snapshot.data().progress)

})
const testRef = doc(firestore,'Courses',courseId,"Test",courseId);
  
onSnapshot(testRef,snapshot=>{
  let main =[]
    main.push({...snapshot.data(),id:snapshot.id})
    settest(main)
  })

 



  

    }
  })

    
    
  

  })

         
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

      const convertTimestampToDate = (timestamp) => {
        const date = timestamp.toDate();
        const currentDate = new Date();
        const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
      
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;
        const secondsInMonth = 2592000;
        const secondsInYear = 31536000;
      
        let relativeTime = '';
      
        if (timeDifferenceInSeconds < secondsInMinute) {
          relativeTime = `${timeDifferenceInSeconds}s ago`;
        } else if (timeDifferenceInSeconds < secondsInHour) {
          const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
          relativeTime = `${minutes}m ago`;
        } else if (timeDifferenceInSeconds < secondsInDay) {
          const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
          relativeTime = `${hours}h ago`;
        } else if (timeDifferenceInSeconds < secondsInWeek) {
          const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
          relativeTime = `${days}d ago`;
        } else if (timeDifferenceInSeconds < secondsInMonth) {
          const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
          relativeTime = `${weeks}w ago`;
        } else if (timeDifferenceInSeconds < secondsInYear) {
          const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
          relativeTime = `${months}mo ago`;
        } else {
          const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
          relativeTime = `${years}y ago`;
        }
      
        return relativeTime;
      };
      
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       
  
  

    const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

    const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

    onSnapshot(notificationRef,snapshot=>{
      let main =[]
      
  
  
  
      snapshot.docs.forEach(doc=>{
        main.push({...doc.data(),id:doc.id})
       
      setnotification(main)
      })
  
      
      
    
  
    })


    const lecturesRef1 = collection(firestore,"Courses",courseId,"lectures");
  const q1 = query(lecturesRef1, orderBy('createdTime'));
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(q1,snapshot=>{
      let main =[]
      snapshot.docs.forEach(i=>{
      main.push({...i.data(),id:i.id})
      setlectureMain(main)


      })
  
    })
              
   
    const lecturesRef = collection(firestore,"Courses",courseId,"lectures");
  const q = query(lecturesRef, orderBy('createdTime'));
  
  onSnapshot(q,snapshot=>{
    let main =[]
    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
      setlectures(main)
    })

    

    
    
  

  })
  

  const lectureRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId);
  
  onSnapshot(lectureRef,snapshot=>{
    let main =[]
    main.push({...snapshot.data(),id:snapshot.id})
    setSelectedLecture(main)
  })
           
  const documentRef = collection(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures")
  onSnapshot(documentRef,snapshot=>{
    let main =[]
    snapshot.docs.forEach(doc=>{
            main.push({...doc.data()})
            setlecture(main)
          

    })
         
  //   fetch the courses data from the courses collection which has courseId id 
  onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
    let main =[]
    
    main.push({...snapshot.data()})
    setcourse(main)

  })





  
  })

    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId,'lectures',lectureId),snapshot=>{
      let main =[]
      main.push({...snapshot.data()})
      setstudentLecture(main)
  
    })
        
    if(accountType == 'Student'){
      const ref = (doc(firestore,'AccountDetails',uid,'Courses',courseId))
    onSnapshot(ref, doc => {
      const progress = doc.data().progress;
      let completedCount = 0;
    
      for (const key in progress) {
        if (progress[key].status === 'completed') {
          completedCount++;
        }
      }
    settotalCompletedLectures(completedCount)
    });
    }
   
  


   
  
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
      
  
        
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, [lectureId]);

 

    
    // Notification Icon Click Handler
    
 

  
    function lectureDescriptionSet(e){
        setlectureDescriptionNew(e.target.value)
        if(e.target.value.length == 0){

            setlectureDescriptionError("error")
        }
        else{
            setlectureDescriptionError("")
        }
    }

    function lectureTitleSet(e){
        setlectureTitleNew(e.target.value)
        if(e.target.value.length == 0){

            setlectureTitleError("error")
        }
        else{
            setlectureTitleError("")
        }
    }

    function questionsSet(e){
      setquestions(e.target.value)
      if(e.target.value.length == 0){

          setquestionsError("error")
      }
      else{
          setquestionsError("")
      }
  }

      //first page add Course next click
      function addcourseDesktop(e){
        const first = document.getElementById("firstDesktop")
        const empty = document.getElementById("emptyCourseDesktop")
        const loading = document.getElementById("loadingDesktop")

            setTimeout(() => {
                empty.style.display="none"
                loading.style.display="flex"
        
                setTimeout(() => {
                loading.style.display="none"
                first.style.display="flex"
                    
                }, 1000);
            }, 1000)
    
    
    
    }
    // Course Data Pop handler
function coursePopDataDesktop(){
    const courseData = document.getElementById("courseDataDesktop")
    if(courseData.style.display === "block"){
      courseData.style.display="none"
    }
    else {
      courseData.style.display="block"
  
    }
  }
  // Edit Input Opener
function editInputOpenerDesktop(){
  setLectureTitleEditor(true)

  
  
  
  
    
  }
  // Edit Input Opener
  function editTitleOpenerDesktop(){
    setLectureTitleEditor(false)
  
  
  
    
  }


    // Edit Input Opener
function editInputDescripionOpenerDesktop(){
  setLectureDescriptionEditor(true)

  
  
  
    
  }

  
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
 
   function desktopSpinnerHandler(){
     setspinner(false)
    }
  // Edit Input Opener
  function editDescripionOpenerDesktop(){
    setLectureDescriptionEditor(false)

  
  
  
  
    
  }
  // Course Data Pop handler
  function coursePopDataDesktop(){
    const courseData = document.getElementById("courseDataDesktop")
    if(courseData.style.display === "block"){
      courseData.style.display="none"
    }
    else {
      courseData.style.display="block"
  
    }
  }
  function formatFileSize(size) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return `${size.toFixed(2)} ${units[index]}`;
}


function cancelUploadDesktop(){
 setlectureVideoUploaderModal(false)
    setlectureVideo("")
    setProgress(0)
}
const completedLectureIDs = Object.keys(completedLectures).filter(lectureID => completedLectures[lectureID].status === 'completed');

function cancelUploadImageDesktop(){
 setimageUploaderModal(false)
  
    setlectureVideo("")
    setProgress(0)
}
function cancelUploadDesktopPdf(){
 setpdfUploaderModal(false)
 
    setlectureVideo("")
    setProgress(0)
}

function cancelUploadDesktopAudio(){
  setaudioUploaderModal(false)
    setlectureAudio("")
    setProgress(0)
}



  function saveLectureData(){
   
    
    
    const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId)

      const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)
      const lectureData = {
        lectureTitle:lectureTitleNew,
        lectureId:lectureId,
      }
  if(lectureTitleNew){
    setDoc(documentRef, lectureData, { merge: true });
    setDoc(documentRef2, lectureData, { merge: true }).then(
    setLectureTitleEditor(false),
    setlectureTitleMessage(true),
    window.scrollTo(0, 0))
  }
  else{
setlectureTitleError('error')
  }

      



  }

  function deleteLectureData(){

    const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)
    const lectureData = {
      lectureDescription:lectureDescription,
      lectureTitle:lectureTitleNew,
      lectureId:lectureId,
      createdTime:serverTimestamp()
    }
    deleteDoc(documentRef);


}

function handlePdfSelect(event) {
  const selectedFile = event.target.files[0];

  // Check file size
  const maxSize = 10 * 1024 * 1024; // 10MB
  if (selectedFile.size > maxSize) {
    setFileMessage(`File ${selectedFile.name} exceeds the size limit of 10MB.`);
    return false;
  }

  // Check file type
  if (selectedFile.type !== 'application/pdf') {
    setFileMessage(`Only PDF files are allowed.`);
    return false;
  } else {
    setFileMessage('');
    setlecturePdf(selectedFile);
  }

  // Clear the file input value to allow selecting the same file again
  event.target.value = '';
}

function handleAudioSelect(event) {
  const selectedFile = event.target.files[0];

  // Check file size
  const maxSize = 70 * 1024 * 1024; // 70MB
  if (selectedFile.size > maxSize) {
    setFileMessage(`File ${selectedFile.name} exceeds the size limit of 70MB.`);
    return;
  }

  // Check file type
  if (selectedFile.type.startsWith('audio/')) {
    setFileMessage('');
    setlectureAudio(selectedFile);
  } else {
    setFileMessage(`File ${selectedFile.name} is not a valid audio file.`);
  }

  // Clear the file input value to allow selecting the same file again
  event.target.value = '';
}
function uploadPdf(){


  const storage = getStorage()


  const maxSize = 10 * 1024 * 1024; // 10MB

   
  // Check file type
 if (lecturePdf.size > maxSize) {
   setFileMessage(`File ${lecturePdf.name} exceeds the size limit of 10MB.`);

   return false;
 }
 else if (lecturePdf.length == 0){

 }
 else{
  const reff = ref(storage, `${lecturePdf.name}`)
  const uploadImage= uploadBytesResumable(reff,lecturePdf)
  uploadImage.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgress(progress)

  }, 
  (error) => {
    console.log(error)
  }, 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  

  getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
      const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)
  const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId)
      
   
      
        onSnapshot(documentRef,doc=>{
          if(doc.data()){
          if(doc.data().hasOwnProperty('createdTime')){
            setDoc(documentRef, {
                          lecturePdf:downloadURL,
    
              lectureId:lectureId,
              
      
            }, { merge: true });
            setDoc(documentRef2, {
                          lecturePdf:downloadURL,
    
              lectureId:lectureId,
              
      
            }, { merge: true }).then(
              setpdfUploadedMessage(true),
              window.scrollTo(0, 0)
            )
          }
          else{
            setDoc(documentRef, {
                          lecturePdf:downloadURL,
    
              lectureId:lectureId,
              createdTime:serverTimestamp(),
              lectureTitle:"Untitled Lecture",
      
      
            }, { merge: true });
            setDoc(documentRef2, {
                          lecturePdf:downloadURL,
    
              lectureId:lectureId,
              createdTime:serverTimestamp(),
              lectureTitle:"Untitled Lecture",
      
      
            }, { merge: true }).then(
              setpdfUploadedMessage(true),
              window.scrollTo(0, 0)
            )}
        }
          else{
            setDoc(documentRef, {
                lecturePdf:downloadURL,
              lectureId:lectureId,
              createdTime:serverTimestamp(),
              lectureTitle:"Untitled Lecture",
      
      
            }, { merge: true });
            setDoc(documentRef2, {
                lecturePdf:downloadURL,
              lectureId:lectureId,
              createdTime:serverTimestamp(),
              lectureTitle:"Untitled Lecture",
      
      
            }, { merge: true }).then(
              setpdfUploadedMessage(true),
              window.scrollTo(0, 0)
            )}
        })
    


    
  })
     
    
      
  }

  // )}
 
)

 

 
}

}


useEffect(() => {
  const fetchVideoDurations = async () => {
    const durations = {};
    for (const lectureItem of lectureMain) {
      const { lectureVideo } = lectureItem;
      const duration = await getVideoDuration(lectureVideo);
      durations[lectureVideo] = duration;
    }
    setVideoDurations(durations);
  };

  fetchVideoDurations();
}, [lectureMain]);

const formatVideoDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
function uploadImg() {
  const storage = getStorage();
  const maxSize = 20 * 1024 * 1024; // 20MB

  // Check if lectureImg is an image file
  if (lectureImg && !lectureImg.type.startsWith("image/")) {
    console.error("Error: File is not an image");
    return;
  }

  // Check if file size is within the limit
  if (lectureImg && lectureImg.size > maxSize) {
    console.error("Error: File size exceeds the limit of 20MB");
    return;
  }

  const reff = ref(storage, `${lectureImg.name}`);
  const uploadImage = uploadBytesResumable(reff, lectureImg);

  uploadImage.on(
    'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(progress);
    },
    (error) => {
      console.log(error);
    },
    async () => {
      try {
        const downloadURL = await getDownloadURL(uploadImage.snapshot.ref);
        const documentRef = doc(firestore, "AccountDetails", uid, "CoursesCreated", courseId, "lectures", lectureId);
        const documentRef2 = doc(firestore, "Courses", courseId, "lectures", lectureId);

        const doc1 = await getDoc(documentRef);
        const doc2 = await getDoc(documentRef2);

        if (doc1.exists() && doc2.exists()) {
          await setDoc(
            documentRef,
            {
              lectureImg: downloadURL,
              lectureId: lectureId,
            },
            { merge: true }
          );
          await setDoc(
            documentRef2,
            {
              lectureImg: downloadURL,
              lectureId: lectureId,
            },
            { merge: true }
          );
          setImageUploadedMessage(true);
          window.scrollTo(0, 0);
        } else {
          await setDoc(
            documentRef,
            {
              lectureImg: downloadURL,
              lectureId: lectureId,
              createdTime: serverTimestamp(),
              lectureTitle: "Untitled Lecture",
            },
            { merge: true }
          );
          await setDoc(
            documentRef2,
            {
              lectureImg: downloadURL,
              lectureId: lectureId,
              createdTime: serverTimestamp(),
              lectureTitle: "Untitled Lecture",
            },
            { merge: true }
          );
          setImageUploadedMessage(true);
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  );
}
function uploadFile() {
  const storage = getStorage();
  const maxSize = 2 * 1024 * 1024 * 1024; // 2GB

  // Check file type
  if (files.size > maxSize) {
    setFileMessage(`File ${files.name} exceeds the size limit of 2GB.`);
    return false;
  } else if (files.length === 0) {
    setlectureUploadError(true);
  } else {
    const reff = ref(storage, `${files.name}`);
    const uploadImage = uploadBytesResumable(reff, files);

    uploadImage.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadImage.snapshot.ref);
          const documentRef = doc(firestore, "AccountDetails", uid, "CoursesCreated", courseId, "lectures", lectureId);
          const documentRef2 = doc(firestore, "Courses", courseId, "lectures", lectureId);
  
          const doc1 = await getDoc(documentRef);
          const doc2 = await getDoc(documentRef2);
  
          if (doc1.exists() && doc2.exists()) {
            await setDoc(
              documentRef,
              {
                lectureVideo: downloadURL,
                lectureId: lectureId,
              },
              { merge: true }
            );
            await setDoc(
              documentRef2,
              {
                lectureVideo: downloadURL,
                lectureId: lectureId,
              },
              { merge: true }
            );
            setlectureVideoUploadMessage(true);
            window.scrollTo(0, 0);
          } else {
            await setDoc(
              documentRef,
              {
                lectureVideo: downloadURL,
                lectureId: lectureId,
                createdTime: serverTimestamp(),
                lectureTitle: "Untitled Lecture",
              },
              { merge: true }
            );
            await setDoc(
              documentRef2,
              {
                lectureVideo: downloadURL,
                lectureId: lectureId,
                createdTime: serverTimestamp(),
                lectureTitle: "Untitled Lecture",
              },
              { merge: true }
            );
            setlectureVideoUploadMessage(true);
            window.scrollTo(0, 0);
          }
        } catch (error) {
          console.error("Error uploading audio:", error);
        }
      }
   
    );
  }
}


  async function updateLectureDescription() {
    const documentRef = doc(firestore, "AccountDetails", uid, "CoursesCreated", courseId, "lectures", lectureId);
    const documentRef2 = doc(firestore, "Courses", courseId, "lectures", lectureId);
  
    if (lectureDescriptionNew.length !== 0) {
      try {
        const doc1 = await getDoc(documentRef);
        const doc2 = await getDoc(documentRef2);
  
        if (doc1.exists() && doc2.exists()) {
          await setDoc(
            documentRef,
            {
              lectureDescription: lectureDescriptionNew,
              lectureId: lectureId,
            },
            { merge: true }
          );
          await setDoc(
            documentRef2,
            {
              lectureDescription: lectureDescriptionNew,
              lectureId: lectureId,
            },
            { merge: true }
          );
          setLectureDescriptionEditor(false);
          setlectureDescriptionMessage(true);
          window.scrollTo(0, 0);
        } else {
          await setDoc(
            documentRef,
            {
              lectureDescription: lectureDescriptionNew,
              lectureId: lectureId,
              createdTime: serverTimestamp(),
              lectureTitle: "Untitled Lecture",
            },
            { merge: true }
          );
          await setDoc(
            documentRef2,
            {
              lectureDescription: lectureDescriptionNew,
              lectureId: lectureId,
              createdTime: serverTimestamp(),
              lectureTitle: "Untitled Lecture",
            },
            { merge: true }
          );
          setLectureDescriptionEditor(false);
          setlectureDescriptionMessage(true);
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.error("Error updating lecture description:", error);
        setlectureDescriptionError('error');
      }
    } else {
      setlectureDescriptionError('error');
    }
  }
  async function uploadAudioDesktop() {
    const storage = getStorage();
    const reff = ref(storage, `${lectureAudio.name}`);
    const uploadImage = uploadBytesResumable(reff, lectureAudio);
  
    uploadImage.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadImage.snapshot.ref);
          const documentRef = doc(firestore, "AccountDetails", uid, "CoursesCreated", courseId, "lectures", lectureId);
          const documentRef2 = doc(firestore, "Courses", courseId, "lectures", lectureId);
  
          const doc1 = await getDoc(documentRef);
          const doc2 = await getDoc(documentRef2);
  
          if (doc1.exists() && doc2.exists()) {
            await setDoc(
              documentRef,
              {
                lectureAudio: downloadURL,
                lectureId: lectureId,
              },
              { merge: true }
            );
            await setDoc(
              documentRef2,
              {
                lectureAudio: downloadURL,
                lectureId: lectureId,
              },
              { merge: true }
            );
            setaudioUploaderMessage(true);
            window.scrollTo(0, 0);
          } else {
            await setDoc(
              documentRef,
              {
                lectureAudio: downloadURL,
                lectureId: lectureId,
                createdTime: serverTimestamp(),
                lectureTitle: "Untitled Lecture",
              },
              { merge: true }
            );
            await setDoc(
              documentRef2,
              {
                lectureAudio: downloadURL,
                lectureId: lectureId,
                createdTime: serverTimestamp(),
                lectureTitle: "Untitled Lecture",
              },
              { merge: true }
            );
            setaudioUploaderMessage(true);
            window.scrollTo(0, 0);
          }
        } catch (error) {
          console.error("Error uploading audio:", error);
        }
      }
    );
  }

  
  function handleFileSelect(event) {
    const selectedFile = event.target.files[0];
  
    // Check file size
    const maxSize = 5 * 1024 * 1024 * 1024; // 2GB
    if (selectedFile.size > maxSize) {
      setFileMessage(`File ${selectedFile.name} exceeds the size limit of 2GB.`);
      return false;
    }
  
    // Check file type
    const allowedTypes = ['video/mp4', 'video/mpeg', 'video/quicktime'];
    if (allowedTypes.includes(selectedFile.type)) {
      setFileMessage('');
      setFiles(selectedFile);
    } else {
      setFileMessage(`File ${selectedFile.name} is not a valid video file.`);
    }
  
    event.target.value = '';
  }

  function handleImgSelect(event) {
    const selectedFile = (event.target.files[0]);
  
      // Check file size
      
      const maxSize = 10 * 1024 * 1024; // 10MB
      // Check file type and size
      if (selectedFile.type.startsWith('image/')) {
        if (selectedFile.size > maxSize) {
          setFileMessage(`File ${selectedFile.name} exceeds the size limit of 10MB.`);
          return false;
        } else {
          setFileMessage('');
          setlectureImg(selectedFile);
        }
      } else {
        setFileMessage(`File ${selectedFile.name} is not a valid image file.`);
        return false;
      }
  
  
    // Clear the file input value to allow selecting the same file again
    event.target.value = '';
  }
  

  
  
  
 
  const minimizeUploadImg = ()=>{
    setlectureVideoUploaderModal(false)

    setFiles([])
  }
  
  const openUploadPopDesktop = ()=>{
   setlectureVideoUploaderModal(true)
  }

  function pdfDesktopPop (){
    setpdfUploaderModal(true)

  }

  function MinimizepdfDesktopPop (){
    setpdfUploaderModal(false)

    setFiles([])
    setlecturePdf([])


  }

  function backQuizDesktop (){
    setquizUploaderModal(false)


  }
  function openQuizDesktop (lectureQuiz){
    console.log(lectureQuiz)
    if(lectureQuiz == true){
      navigate(`/course/${courseId}/lecture/${lectureId}/quiz/1`)
      
    }
    else{
      setquizUploaderModal(true)
  
    }

  }

  function backImageDesktop (){
    setimageUploaderModal(false)

    setlectureImg([])
  }
  
  function openImageDesktop (){
   setimageUploaderModal(true)
  
  }

function backAudioDesktop (){
  setaudioUploaderModal(false)

}

function openAudioDesktop (){
setaudioUploaderModal(true)

}

function deletePdfDesktop(){
setpdfDeleteModal(true)


}

function deleteImgDesktop(){
 setimageDeleteModal(true)


}

function deleteLectureVideoDesktop(){
  setlectureVideoDeleteModal(true)


}

function deleteAudioDesktop(){
setaudioDeleteModal(true)

}

function closePdfDeleteDesktop(){
setpdfDeleteModal(false)
}

function closeImgDeleteDesktop(){
 setimageDeleteModal(false)
}

function closeLectureVideoDeleteDesktop(){
setlectureVideoDeleteModal(false)
}

function closeAudioDeleteDesktop(){
 setaudioDeleteModal(false)
}
function closeAudioDeleteMessage(){
  setaudiodeletedMessage(false)
}

function closeImageDeleteMessage(){
setimagedeletedMessage(false)
}
function pdfDeletedMessageSet(){
setpdfdeletedMessage(false)
}

function closeMessage12Desktop(){
 setlectureVideoDeleteMessage(false)
}
function closeAudioErrorMessageDesktop(){
  setlectureaudioError(false)
 }
function closeLectureVideoErrorDesktop(){
  setlectureUploadError(false)
 }

function pdfMessageUpdate(){
 setpdfUploadedMessage(false)
}
function audioMessageUpdate(){
  setaudioUploaderMessage(false)
 }

function imageMessageUpdate(){
  setImageUploadedMessage(false)
 }
function closeLectureVideoUploadMessage(){
setlectureVideoUploadMessage(false)
}
function closeLectureDescriptionMessage(){
  setlectureDescriptionMessage(false)
  }
  function closeLectureTitleMessage(){
  setlectureTitleMessage(false)

    }


function deleteHandlerImgDesktop(){

  const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)
  const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId)

  updateDoc(documentRef,{
      lectureImg:""
  })

  updateDoc(documentRef2,{
    lectureImg:""
}).then
  (
setimageDeleteModal(false),
setimagedeletedMessage(true),
window.scrollTo(0, 0)



  )
  .catch(error=>{
    setError(error.message)
  })

    
}

function deleteHandlerPdfDesktop(){

  const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)
  const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId)

  updateDoc(documentRef,{
      lecturePdf:""
  })

  updateDoc(documentRef2,{
    lecturePdf:""
}).then
  (
    setpdfDeleteModal(false),
    setpdfdeletedMessage(true),
    window.scrollTo(0, 0)



  )
  .catch(error=>{
    setError(error.message)
  })

    
}

function deleteHandlerLectureVideoDesktop(){


  const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)
  const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId)

  updateDoc(documentRef,{
      lectureVideo:""
  })

  updateDoc(documentRef2,{
    lectureVideo:""
}).then
  (
setlectureVideoDeleteMessage(true),
setlectureVideoDeleteModal(false),
window.scrollTo(0, 0)



  )
  .catch(error=>{
    setError(error.message)
  })

    
}

const popProfile = () =>{
  if(pop == true){
    setpop(false)
    setnotificationPop(false)

  }
  else{
  setpop(true)
  setnotificationPop(false)


  }
}
const popNotification = () =>{
if(notificationPop == true){
  setnotificationPop(false)
  setpop(false)
}
else{
setnotificationPop(true)
setpop(false)



}
}
function deleteHandlerAudioDesktop(){
 

  const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId)
  const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId)

  updateDoc(documentRef,{
      lectureAudio:""
  })

  updateDoc(documentRef2,{
    lectureAudio:""
}).then
  (
 setaudioDeleteModal(false),
 setaudiodeletedMessage(true),
 window.scrollTo(0, 0)



  )
  .catch(error=>{
    setError(error.message)
  })

    
}
  // Function to handle click event and move to the next lecture
  const  handleNextLecture= () => {
    setstudentLecture([])
    const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
             
    const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
    onSnapshot(lecturesCollectionQuery, (snapshot) => {
      let main = [];
      snapshot.docs.forEach(doc => {
        main.push({ ...doc.data() });
      });
      const currentLectureIndex = main.findIndex(lecture => lecture.lectureId === lectureId);
      if (currentLectureIndex !== -1 && currentLectureIndex < main.length - 1) {
        const nextLecture = main[currentLectureIndex + 1];
        const nextLectureId = nextLecture?.lectureId;
        console.log(nextLectureId)

        if(nextLectureId != null){
          navigate(`/course/${courseId}/lecture/${nextLectureId}`);

        }

      }
    });
  };
  const handlePreviousLecture = () => {
    setstudentLecture([])

    const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
             
    const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
    onSnapshot(lecturesCollectionQuery, (snapshot) => {
      let main = [];
      snapshot.docs.forEach(doc => {
        main.push({ ...doc.data() });
      });
  
      const currentLectureIndex = main.findIndex(lecture => lecture.lectureId === lectureId);
      if (currentLectureIndex !== -1 && currentLectureIndex > 0) {
        const previousLecture = main[currentLectureIndex - 1];
        const previousLectureId = previousLecture?.lectureId;
        if(previousLectureId != null){
        navigate(`/course/${courseId}/lecture/${previousLectureId}`);

        }
      }
    });
  };
  const getVideoDuration = async (videoUrl) => {
    try {
      const video = document.createElement('video');
      video.src = videoUrl;
  
      await new Promise((resolve, reject) => {
        video.addEventListener('loadedmetadata', resolve);
        video.addEventListener('error', reject);
      });
  
      const duration = video.duration;
      return duration;
    } catch (error) {
      console.error('Error retrieving video duration:', error);
      return null;
    }
  };
  
  function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    setDoc(notificationRef,{
      seen:true
    },{merge:true})
    .then(() => {
      
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
    });


   }
    function resetVideoDesktop(currentIndex){
   
      if(lectureMain[currentIndex].lectureId == lectureId){
      }
      else{
        setstudentLecture([])
        setlectureSelect(false)
        const lecture = lectureMain[currentIndex]
    
        navigate(`/course/${courseId}/lecture/${lecture.id}`)
      }
 
    }
    
  
    function hideMenuDesktop(){
      const menu = document.getElementById("menuDesktop")
      const hide = document.getElementById("hideDesktop")
      const open = document.getElementById("openDesktop")
      const body = document.getElementById("lectureBody")
  
      menu.style.width="0"
      hide.style.display="none"
      open.style.display="flex"
      body.style.width="100%"
  
      
    }
    function hideMenuTablet(){
      setlectureSelect(false)

  
      
    }
    function openMenuDesktop(){
      const menu = document.getElementById("menuDesktop")
      const body = document.getElementById("lectureBody")
  
      const open = document.getElementById("openDesktop")
      const hide = document.getElementById("hideDesktop")
  
      hide.style.display="flex"
      open.style.display="none"
  
      body.style.width="67%"
      
      menu.style.width="25%"
    }
    function openMenuTablet(){
      setlectureSelect(true)
    }
  
    function quizPopDesktop(){
      const video = document.getElementById("courseVideoDesktop")
  
      
      video.style.display="none"
  
  
  
    }

function closeQuizeErrorMessage(){
  setquizError('')
}
const handleVideoTimeUpdate = (lectureId,instructorId,lectureQuiz) => {
  const video = videoRef.current;
  const currentTime = video.currentTime;
  const duration = video.duration;
  const completionPercentage = (currentTime / duration) * 100;

  if (completionPercentage >= 75) {
    // Call the function to complete the lecture automatically
    completeLecture(lectureId, instructorId,lectureQuiz);
  }
};

function completeLecture(lectureID, instructorId, lectureQuiz) {
  const ref = doc(firestore, 'AccountDetails', uid, 'Courses', courseId);
  const ref2 = doc(firestore, 'AccountDetails', instructorId, 'Students', uid);
  const ref3 = doc(firestore, 'AccountDetails', instructorId, 'CoursesCreated', courseId, 'Students', uid);
  
  const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
             
  const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
 
  window.scrollTo(0, 0);

  if (lectureQuiz == true) {
    getDoc(ref).then((doc) => {
      const currentProgress = doc.data().progress || {};
      // Update the progress object with the completed lecture
      currentProgress[lectureID] = {
        status: 'completed',
        completedTime: serverTimestamp(),
      };

      const lecturesTotal = Object.keys(currentProgress);
      const totalCompletedLectures = lecturesTotal.filter((lecture) => currentProgress[lecture].status === 'completed').length;

      let data = {
        progress: currentProgress,
        progressPercentage: (totalCompletedLectures / lectures.length) * 100,
      };

      let data2 = {
        progress: {
          [courseId]: currentProgress,
        },
      };

      let data3 = {
        progress: {
          [lectureID]: {
            status: 'completed',
            completedTime: serverTimestamp(),
          },
        },
      };
      // Check if the user has passed the quiz
      if (currentProgress.quizResults && (currentProgress.quizResults[lectureID].result) >= 75) {
        setDoc(ref, data, { merge: true }).then(() => {
          setDoc(ref3, data3, { merge: true }).then(() => {
            setDoc(ref2, data2, { merge: true });
          }).then(
            handleNextLecture()
          )
        });
      }
      else if(currentProgress.quizResults && (currentProgress.quizResults[lectureID].result) <= 75) {
        setquizError('You failed the quiz'); // Show error message if the user has not passed the quiz
      }
     if (!currentProgress.quizResults || !currentProgress.quizResults[lectureID])
    {setquizError('In order to attend the next lecture, you must take the quiz and score at least 75%');

      }
     
    });
  } else {
    getDoc(ref).then((doc) => {
      const currentProgress = doc.data().progress || {};
      currentProgress[lectureID] = {
        status: 'completed',
        completedTime: serverTimestamp(),
      };

      const lecturesTotal = Object.keys(currentProgress);
      const totalCompletedLectures = lecturesTotal.filter((lecture) => currentProgress[lecture].status === 'completed').length;

      let data = {
        progress: currentProgress,
        progressPercentage: (totalCompletedLectures / lectures.length) * 100,
      };

      let data2 = {
        progress: {
          [courseId]: currentProgress,
        },
      };

      let data3 = {
        progress: {
          [lectureID]: {
            status: 'completed',
            completedTime: serverTimestamp(),
          },
        },
      };
      // Check if the user has passed the quiz
        setDoc(ref, data, { merge: true }).then(() => {
          setDoc(ref3, data3, { merge: true }).then(() => {
            setDoc(ref2, data2, { merge: true });
          }).then(
            handleNextLecture()
          )
        });
   
   
     
    }); }
}
useEffect(() => {
  const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
  const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
  const unsubscribe = onSnapshot(lecturesCollectionQuery, (snapshot) => {
    let main = [];
    snapshot.docs.forEach(doc => {
      main.push({ ...doc.data() });
    });

    const currentLectureIndex = main.findIndex(lecture => lecture.lectureId === lectureId);
    const isFirstLecture = currentLectureIndex === 0;
    const isFinalLecture = currentLectureIndex === main.length - 1;

    if (!isFirstLecture && !isFinalLecture) {
      const nextLecture = main[currentLectureIndex + 1];
      const nextLectureId = nextLecture?.lectureId;
      // Handle logic for non-initial and non-final lectures
      setinitialLecture(false);
      setfinalLecture(false);
    } else if (isFirstLecture) {
      // Handle logic for initial lecture
      setinitialLecture(true);
      setfinalLecture(false);
    } else if (isFinalLecture) {
      // Handle logic for final lecture
      setinitialLecture(false);
      setfinalLecture(true);
    }
  });

  return () => {
    // Cleanup function to unsubscribe from the snapshot listener
    unsubscribe();
  };
}, [courseId, lectureId]);

let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

  return (
   user.map(i=>{
    const{accountType,plan,fullName,photo} = i

    return(
      accountType == 'Teacher' ?
      lectureId.length == 5 ?
      <div>
      <div id="desktop">
      <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>

            {
              lectureVideoUploadMessage == true &&
              <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <div class="ml-3 text-sm font-medium Mooli capitalize">
               lecture Video successfully Uploaded 
              </div>
              <button onClick={closeLectureVideoUploadMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
                <span class="sr-only">Dismiss</span>
                <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
          </div>
            }

{
              lectureTitleMessage == true &&
              <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <div class="ml-3 text-sm font-medium Mooli capitalize">
               lecture Title successfully Updated 
              </div>
              <button onClick={closeLectureTitleMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
                <span class="sr-only">Dismiss</span>
                <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
          </div>
            }

{
              lectureDescriptionMessage == true &&
              <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <div class="ml-3 text-sm font-medium Mooli capitalize">
               lecture Description successfully Updated 
              </div>
              <button onClick={closeLectureDescriptionMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
                <span class="sr-only">Dismiss</span>
                <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
          </div>
            }
   {
    pdfUploadedMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture pdf successfully updated 
    </div>
    <button onClick={pdfMessageUpdate} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
   }
     {
    audioUploaderMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Audio successfully updated 
    </div>
    <button onClick={audioMessageUpdate} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
   }

{
     imageUploadedMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Image successfully updated 
    </div>
    <button onClick={imageMessageUpdate} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
   }
{
  audiodeletedMessage == true &&
  <div id="alert-border-7" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Audio successfully removed 
    </div>
    <button onClick={closeAudioDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}

{
  imagedeletedMessage == true &&
  <div id="alert-border-8" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Image successfully removed 
    </div>
    <button onClick={closeImageDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}



{
  pdfdeletedMessage == true &&
  <div id="alert-border-15" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Pdf successfully removed 
    </div>
    <button onClick={pdfDeletedMessageSet} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}

{
  lectureVideoDeleteMessage == true &&
  <div id="alert-border-12" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Video successfully removed 
    </div>
    <button onClick={closeMessage12Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}
{
  pdfDeleteModal == true &&
  <div id='deletePdfDesktop' className=' flex items-center justify-center'>
       
     
            <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
    <a  onClick={closePdfDeleteDesktop}   class="absolute top-1.5 right-1.5">
        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
            
    </a>
    <h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Pdf </h1>
    <p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this pdf? </p>
    
    <div class="flex flex-row mt-6 space-x-2 justify-evenly">
        <a  onClick={deleteHandlerPdfDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
        <a  onClick={closePdfDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
    </div>
</div>
</div>
</div>  
 

        </div>

}
      
{
  imageDeleteModal == true &&
  <div id='deleteImgDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeImgDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Image </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Image? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerImgDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeImgDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
       
{
  lectureVideoDeleteModal == true &&
  <div id='deleteLectureVideoDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeLectureVideoDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>

<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Lecture Video </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Lecture Video? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerLectureVideoDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeLectureVideoDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
  
{
  audioDeleteModal == true &&
  <div id='deleteAudioDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeAudioDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Audio </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Audio? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerAudioDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeAudioDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
     
   {
    lectureVideoUploaderModal == true &&
<div className="uploadFileDesktop h-screen ">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
     
    >
      {
        lectureUploadError == true &&
        <div id="alert-border-12" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
        <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <div class="ml-3 text-sm font-medium Mooli capitalize">
         lecture Video can't be empty 
        </div>
        <button onClick={closeLectureVideoErrorDesktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
          <span class="sr-only">Dismiss</span>
          <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
    </div>
      }
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload lecture video</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input accept="video/mp4, video/quicktime, video/mov, video/avi" type="file" id="hidden-input"  className="hidden" onChange={handleFileSelect} />
          <button
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 fjalla text-white hover:bg-gray-900 focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input').click();
            }}
          >
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .mp4, .mov, and .avi</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {files.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : 
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
             <div class="video-container">
                <video controls >
  <source  
  type="video/mp4"
  
  className="mx-auto "
  src={URL.createObjectURL(files)}

  alt={files.name}
  ></source>
  Your browser does not support the video tag.
</video>
</div>
                <span className="text-small text-gray-500">{files.name}</span>
              </li>
          }
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={minimizeUploadImg}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadFile}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"35px"}}>Lecture Video Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel  mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
   }


{
  pdfUploaderModal == true &&
  <div  className="uploadFileDesktop h-screen  ">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

    >
      {
        lectureaudioError == true &&
        <div id="alert-border-12" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
        <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <div class="ml-3 text-sm font-medium Mooli capitalize">
         lecture Audio Can't be empty 
        </div>
        <button onClick={closeAudioErrorMessageDesktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
          <span class="sr-only">Dismiss</span>
          <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
    </div>
      }
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload pdf</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
          <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
         
          </p>
          <input
  type="file"
  id="hidden-input-pdf"
  accept="application/pdf"
  onChange={handlePdfSelect}
  style={{ display: 'none' }}
/>
          <button
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 text-white fjalla rounded-md focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-pdf').click();
            }}
          >
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .pdf</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lecturePdf.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : (
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
  
                <span className="text-small text-gray-500">{lecturePdf.name}</span>
              </li>
          )}
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={MinimizepdfDesktopPop}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadPdf}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div style={{width:"90%",height:"95%"}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktopPdf} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"35px"}}>Lecture Pdf Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
}

{
  imageUploaderModal == true &&
  <div id='uploadImageDesktop' className="uploadFileDesktop">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
  
    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload image</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
          <button
            className="mt-2 rounded-sm px-3 py-1 text-white bg-gray-800 hover:bg-gray-900 fjalla rounded focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-img').click();
            }}
          >
           
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lectureImg.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : (
           
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
                <img
                style={{maxHeight:"150px"}}
                  className="mx-auto w-32"
                  src={URL.createObjectURL(lectureImg)}
                  alt={lectureImg.name}
                />
                <span className="text-small text-gray-500">{lectureImg.name}</span>
              </li>
          )}
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={backImageDesktop}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadImg}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div  style={{width:"90%",height:"95%"}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center mt-8' style={{fontSize:"35px"}}>Lecture Image Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>
     
           </div>}
 

</div>
}

{
  quizUploaderModal == true &&
  <div id='quizDesktopPop' >
<main className=" mx-auto  my-5 bg-white" style={{width:"90%",height:"95%"}}>
<div className='py-5 flex w-full justify-between items-center'>
<div style={{paddingLeft:"50px"}}>
    <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>Prepare a quiz for this lecture</h1>
    <p className='borel text-gray-500' style={{fontSize:'11px'}}>It is necessary for your students to attain at least 75% to pass the next lecture</p>
    
    </div>

   




              <svg onClick={backQuizDesktop}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
       </div>

<div className='flex '>

  <div className='mt-10 w-1/2 mx-3' style={{marginTop:"7%"}}>
  <div className="relative " >
  <div className="relative  " >

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute capitalize">Number of questions</p>


<select style={{fontSize:'13px',height:'80px'}}onChange={e=>questionsSet(e)} type="text" class="mx-2 border borel  focus:outline-none
  focus:border-black w-full pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
  border-gray-300 rounded-md">
                              <option className='skill__input-option'value="">Select Field</option>


                              <option className='skill__input-option' value="1">1</option>
                              <option className='skill__input-option' value="2">2</option>
                              <option className='skill__input-option' value="3">3</option>
                              <option className='skill__input-option' value="4">4</option>
                              <option className='skill__input-option' value="5">5</option>
                              <option className='skill__input-option' value="6">6</option>
                              <option className='skill__input-option' value="7">7</option>
                              <option className='skill__input-option' value="8">8</option>
                              <option className='skill__input-option' value="9">9</option>
                              <option className='skill__input-option' value="10">10</option>
                              <option className='skill__input-option' value="11">11</option>
                              <option className='skill__input-option' value="12">12</option>
                              <option className='skill__input-option' value="13">13</option>
                              <option className='skill__input-option' value="14">14</option>
                              <option className='skill__input-option' value="15">15</option>















</select> 
<div className={`${questionsError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
This field Can't be empty
</div>
</div>
</div>
 
</div>

<Link to={`${questions == '' ? '' : `/course/${courseId}/lecture/${lectureId}/quiz/${questions}` }`}>
   <button onClick={()=>questions == '' && setquestionsError('error')} style={{fontSize:'12px'}} type='submit'   className="fjalla mt-5 bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
       rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Prepare Quiz
   </button>
   </Link>
 </div>
  </div>


  <img style={{width:"42.5%",marginTop:'-5%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Exams-bro%20(1).svg?alt=media&token=d83b0fda-0cc5-4924-9843-1a16cb33c559" alt="Prepare test" />

</div>
  


</main>

</div>
}

{
  audioUploaderModal == true &&
  <div id='uploadAudioDesktop' className="uploadFileDesktop ">



  {
    progress != 100 & progress == 0 ?
    <main className="mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
      
    >
          {
        lectureaudioError == true &&
        <div id="alert-border-12" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
        <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <div class="ml-3 text-sm font-medium Mooli capitalize">
         lecture Audio Can't be empty 
        </div>
        <button onClick={closeAudioErrorMessageDesktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
          <span class="sr-only">Dismiss</span>
          <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
    </div>
      }
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload Audio</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input
  type="file"
  id="hidden-input-audio"
  accept="audio/*"
  onChange={handleAudioSelect}
  style={{ display: 'none' }}
/>
          <button
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 text-white fjalla focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-audio').click();
            }}
          >
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .audio</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lectureAudio.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : (
            
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
  
                <span className="text-small text-gray-500">{lectureAudio.name}</span>
              </li>
          )}
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={backAudioDesktop}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadAudioDesktop}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktopAudio} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"35px"}}>Lecture Audio Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
}



<div class="w-full bg-neutral-200 " >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && "bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>

       {course.map((i)=>{
            const { courseTitle,status} = i
            return(
              status == 'pending' ?
              <div>
                <div className='flex items-center justify-center flex-col '>
<img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Customer%20Survey-amico.svg?alt=media&token=fd7c6202-fbb9-40d2-9bf6-84bcc2db89b8" alt="Review" style={{width:"20%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>We are currently reviewing this course for publication, so you cannot make any changes at this time


</h1>

</div>
              </div>
              :
                <div className=''>
         

                                    <div className='flex'>
                                  
                                                        <div class="relative  mx-auto mt-12 flex flex-col  "style={{width:"30%",height:"60%"}}>
                   
{
        studentLecture.map(i=>{
            const {lectureVideo,lectureTitle,lectureQuiz} = i
            return(
              lectureVideo && lectureVideo.length != 0 ?
<div>
<label  for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-white ">

<div class="flex justify-center m-auto w-full " style={{height:"500px"}} >


  <div class="video-container mr-4">
  <video controls>
    <source src={lectureVideo} type="video/mp4"></source>
    Your browser does not support the video tag.
  </video>
</div>
<svg onClick={deleteLectureVideoDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="ml-4 cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
</div> 
</label>

</div> 
:
    <div class="ml-4 flex items-center justify-center m-auto w-full" >
    <label onClick={openUploadPopDesktop} for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-white ">
    <img  onLoad={desktopSpinnerHandler} class="h-auto w-full rounded-lg"  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

<p  className='borel text-center mt-4'>Click here to upload a lecture video</p>
       
    </label>
</div> 
            )
            })

// :





}


</div>
               {
                studentLecture.map(i=>{
                  const {lectureQuiz,lectureTitle,lectureDescription,lecturePdf,lectureAudio,lectureImg} = i
                  return(
                    <div className=""style={{width:"70%"}}>

                    <div className='flex justify-center my-3 '>
                            <div onClick={pdfDesktopPop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center w-32 items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
      <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText '>PDF</p>
                                
                            </div>
    
                            <div onClick={openAudioDesktop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center w-32 items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
      <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
      <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
      <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Audio</p>
                                
                            </div>
                            <div onClick={()=>openQuizDesktop(lectureQuiz)} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center w-32 items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
      <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Quiz</p>
                                
                            </div>
                          
                            <div onClick={openImageDesktop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center w-32 items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="40" fill="currentColor" class="bi bi-file-image" viewBox="0 0 16 16">
      <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
      <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Photo</p>
                                
                            </div>
                            
        
                        </div>
                <div className='border rounded m-auto' style={{width:"70%"}}>
                    
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Title </h3>
           {
            lectureTitleEditor == false &&
           <p className="borel text-gray-500 block"id='editCourseTitleDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{lectureTitle}</p>

           }
          
           
                         </div>
                         {
            lectureTitleEditor == false &&
            <svg id="editIconDesktop" onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>

           }

           {
            lectureTitleEditor == true &&
            <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editTitleOpenerDesktop} id='cancelInputDesktop'>Cancel</p>

           }
                       
                          </div>
                          {
            lectureTitleEditor == true &&
            <div className="relative mx-5 " id='editInputDesktop'>
            <input style={{fontSize:'13px'}} defaultValue={lectureTitle}    onChange={e=>lectureTitleSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                focus:border-black w-full borel pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                border-gray-300 rounded-md" />
 <div className={`${lectureTitleError == "error" ? "block":"hidden"}`}>
 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Lecture Title can't be empty
</div>
</div>

 </div>
                <button style={{fontSize:'12px'}} onClick={saveLectureData} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">

Save
</button>
          </div>
           }
                      
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded m-auto mb-5' style={{width:"70%"}}>
                    
                    <div className='flex justify-between px-5 py-5 '>
                   <div>
                   <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Description </h3>
                   {
                    lectureDescriptionEditor == false &&
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}}>{lectureDescription}</p>

                   }
     
                   </div>

                    {
                    lectureDescriptionEditor == false &&
                    <svg id="editIconDescripionDesktop" onClick={editInputDescripionOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>

                    }
                {
                    lectureDescriptionEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editDescripionOpenerDesktop} id='cancelDescripionInputDesktop'>Cancel</p>

                }
                    </div>
               {
                    lectureDescriptionEditor == true &&
                    <div id='editDescripionInputDesktop' className="relative m-auto"style={{width:"90%"}}>
  
                    <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  ref={textRef} onChange={e=>lectureDescriptionSet(e)}  type="text" class="border  focus:outline-none
                                 focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                                 border-gray-300 rounded-md" maxLength="1000" defaultValue={lectureDescription}></textarea>

                                 <div className={`${lectureDescriptionError == "error" ? "block":"hidden"}`}>
                    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className='Mooli'>
                     Description Can't be empty
                    </div>
                    </div>
                    </div>
                    <p className={lectureDescriptionNew.length != 0 ? 'Mooli text-gray-500' : 'hidden'} style={{fontSize:'11px'}}>{lectureDescriptionNew && lectureDescriptionNew.length}/1,000</p>
                    <button style={{fontSize:'12px'}} onClick={updateLectureDescription} class="ml-10 inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                          
                          Save
                        </button>
                    </div>
               }
         

                    <div>
                
                    </div>
                            
                  </div>

{
  lecturePdf ?

  <div className='border rounded m-auto' style={{width:"70%"}}>
                    
  <div className='flex justify-between px-5 py-5 '>
 <div>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Pdf</h3>
<a target='_blank' className="borel text-gray-500 block hover:underline" style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} href={lecturePdf}>File</a>
 </div>
 {
  lecturePdfEditor == false &&
  <svg onClick={deletePdfDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
 }

  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}

{
  lectureImg ?

  <div className='border rounded m-auto' style={{width:"70%"}}>
                    
  <div className='flex justify-between px-5 py-5 '>
 <div>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Image</h3>
<a className="borel text-gray-500 block hover:underline"target='_blank' style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} href={lectureImg}>Image</a>
 </div>
 <svg onClick={deleteImgDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}

{
  lectureAudio ?

  <div className='border rounded m-auto' style={{width:"70%"}}>
                    
  <div className='flex justify-between px-5 py-5 '>
 <div>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Audio</h3>
<audio controls>
      <source src={lectureAudio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
 </div>
 <svg onClick={deleteAudioDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}
                
                  
                        </div>
                  )
                })
               }
                                    </div>
                       

                    
    
     
<div>
    
</div>
                </div>
            )

        }
        )} 
    </div>
    <div id="tablet">
     <DashboardNav/>

            {
              lectureVideoUploadMessage == true &&
              <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <div class="ml-3 text-sm font-medium Mooli capitalize">
               lecture Video successfully Uploaded 
              </div>
              <button onClick={closeLectureVideoUploadMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
                <span class="sr-only">Dismiss</span>
                <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
          </div>
            }

{
              lectureTitleMessage == true &&
              <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <div class="ml-3 text-sm font-medium Mooli capitalize">
               lecture Title successfully Updated 
              </div>
              <button onClick={closeLectureTitleMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
                <span class="sr-only">Dismiss</span>
                <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
          </div>
            }

{
              lectureDescriptionMessage == true &&
              <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <div class="ml-3 text-sm font-medium Mooli capitalize">
               lecture Description successfully Updated 
              </div>
              <button onClick={closeLectureDescriptionMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
                <span class="sr-only">Dismiss</span>
                <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
          </div>
            }
   {
    pdfUploadedMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture pdf successfully updated 
    </div>
    <button onClick={pdfMessageUpdate} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
   }
     {
    audioUploaderMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Audio successfully updated 
    </div>
    <button onClick={audioMessageUpdate} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
   }

{
     imageUploadedMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400  dark:border-blue-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Image successfully updated 
    </div>
    <button onClick={imageMessageUpdate} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
   }
{
  audiodeletedMessage == true &&
  <div id="alert-border-7" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Audio successfully removed 
    </div>
    <button onClick={closeAudioDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}

{
  imagedeletedMessage == true &&
  <div id="alert-border-8" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Image successfully removed 
    </div>
    <button onClick={closeImageDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}



{
  pdfdeletedMessage == true &&
  <div id="alert-border-15" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Pdf successfully removed 
    </div>
    <button onClick={pdfDeletedMessageSet} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}

{
  lectureVideoDeleteMessage == true &&
  <div id="alert-border-12" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400  dark:border-red-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     lecture Video successfully removed 
    </div>
    <button onClick={closeMessage12Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8  dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
}
{
  pdfDeleteModal == true &&
  <div id='deletePdfDesktop' className=' flex items-center justify-center'>
       
     
            <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
    <a  onClick={closePdfDeleteDesktop}   class="absolute top-1.5 right-1.5">
        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
            
    </a>
    <h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Pdf </h1>
    <p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this pdf? </p>
    
    <div class="flex flex-row mt-6 space-x-2 justify-evenly">
        <a  onClick={deleteHandlerPdfDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
        <a  onClick={closePdfDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
    </div>
</div>
</div>
</div>  
 

        </div>

}
      
{
  imageDeleteModal == true &&
  <div id='deleteImgDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeImgDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Image </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Image? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerImgDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeImgDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
       
{
  lectureVideoDeleteModal == true &&
  <div id='deleteLectureVideoDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeLectureVideoDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Lecture Video </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Lecture Video? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerLectureVideoDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeLectureVideoDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
  
{
  audioDeleteModal == true &&
  <div id='deleteAudioDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeAudioDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Audio </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Audio? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerAudioDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeAudioDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
     
   {
    lectureVideoUploaderModal == true &&
<div className="uploadFileDesktop h-screen ">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
     
    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload lecture video</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input accept="video/mp4, video/quicktime, video/mov, video/avi" type="file" id="hidden-input"  className="hidden" onChange={handleFileSelect} />
          <button
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 fjalla text-white hover:bg-gray-900 focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input').click();
            }}
          >
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .mp4, .mov, and .avi</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {files.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : 
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
             <div class="video-container">
                <video controls >
  <source  
  type="video/mp4"
  
  className="mx-auto "
  src={URL.createObjectURL(files)}

  alt={files.name}
  ></source>
  Your browser does not support the video tag.
</video>
</div>
                <span className="text-small text-gray-500">{files.name}</span>
              </li>
          }
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={minimizeUploadImg}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadFile}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"35px"}}>Lecture Video Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel  mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
   }


{
  pdfUploaderModal == true &&
  <div  className="uploadFileDesktop h-screen  ">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload pdf</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
          <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
         
          </p>
          <input
  type="file"
  id="hidden-input-pdf"
  accept="application/pdf"
  onChange={handlePdfSelect}
  style={{ display: 'none' }}
/>
          <button
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 text-white fjalla rounded-md focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-pdf').click();
            }}
          >
            {lecturePdf.name}
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .pdf</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lecturePdf.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : 
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
  
                <span className="text-small text-gray-500">{lecturePdf.name}</span>
              </li>
          }
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={MinimizepdfDesktopPop}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadPdf}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div style={{width:"90%",height:"95%"}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktopPdf} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"35px"}}>Lecture Pdf Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
}

{
  imageUploaderModal == true &&
  <div id='uploadImageDesktop' className="uploadFileDesktop">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
  
    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload image</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
          <button
            className="mt-2 rounded-sm px-3 py-1 text-white bg-gray-800 hover:bg-gray-900 fjalla rounded focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-img').click();
            }}
          >
           
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lectureImg.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : (
           
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
                <img
                style={{maxHeight:"150px"}}
                  className="mx-auto w-32"
                  src={URL.createObjectURL(lectureImg)}
                  alt={lectureImg.name}
                />
                <span className="text-small text-gray-500">{lectureImg.name}</span>
              </li>
          )}
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={backImageDesktop}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadImg}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div  style={{width:"90%",height:"95%"}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center mt-8' style={{fontSize:"35px"}}>Lecture Image Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>
     
           </div>}
 

</div>
}

{
  quizUploaderModal == true &&
  <div id='quizDesktopPop' >
<main className=" mx-auto  my-5 bg-white" style={{width:"90%",height:"95%"}}>
<div className='py-5 flex w-full justify-between items-center'>
<div style={{paddingLeft:"50px"}}>
    <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>Prepare a quiz for this lecture</h1>
    <p className='borel text-gray-500' style={{fontSize:'11px'}}>It is necessary for your students to attain at least 75% to pass the next lecture</p>
    
    </div>

   




              <svg onClick={backQuizDesktop}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
       </div>


  <div className='mt-10  mx-3' style={{marginTop:"7%"}}>
  <div className="relative " >
  <div className="relative  " >

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute capitalize">Number of questions</p>


<select style={{fontSize:'13px',height:'80px'}}onChange={e=>questionsSet(e)} type="text" class="mx-2 border borel  focus:outline-none
  focus:border-black w-full pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
  border-gray-300 rounded-md">
                              <option className='skill__input-option'value="">Select Field</option>


                              <option className='skill__input-option' value="1">1</option>
                              <option className='skill__input-option' value="2">2</option>
                              <option className='skill__input-option' value="3">3</option>
                              <option className='skill__input-option' value="4">4</option>
                              <option className='skill__input-option' value="5">5</option>
                              <option className='skill__input-option' value="6">6</option>
                              <option className='skill__input-option' value="7">7</option>
                              <option className='skill__input-option' value="8">8</option>
                              <option className='skill__input-option' value="9">9</option>
                              <option className='skill__input-option' value="10">10</option>
                              <option className='skill__input-option' value="11">11</option>
                              <option className='skill__input-option' value="12">12</option>
                              <option className='skill__input-option' value="13">13</option>
                              <option className='skill__input-option' value="14">14</option>
                              <option className='skill__input-option' value="15">15</option>















</select> 
<div className={`${questionsError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
This field Can't be empty
</div>
</div>
</div>
 
</div>

<Link to={`${questions == '' ? '' : `/course/${courseId}/lecture/${lectureId}/quiz/${questions}` }`}>
   <button onClick={()=>questions == '' && setquestionsError('error')} style={{fontSize:'12px'}} type='submit'   className="fjalla mt-5 bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
       rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Prepare Quiz
   </button>
   </Link>
 </div>
  </div>



  


</main>

</div>
}

{
  audioUploaderModal == true &&
  <div id='uploadAudioDesktop' className="uploadFileDesktop ">



  {
    progress != 100 & progress == 0 ?
    <main className="mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
      
    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload Audio</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input
  type="file"
  id="hidden-input-audio"
  accept="audio/*"
  onChange={handleAudioSelect}
  style={{ display: 'none' }}
/>
          <button
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 text-white fjalla focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-audio').click();
            }}
          >
            {lectureAudio.name}
            Upload a file
          </button>
          <p className='Mooli text-gray-500'>Supported file types include .audio</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lectureAudio.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">No files selected</span>
            </li>
          ) : (
            
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
  
                <span className="text-small text-gray-500">{lectureAudio.name}</span>
              </li>
          )}
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={backAudioDesktop}
          style={{ width: '20%' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadAudioDesktop}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%' }}
          className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktopAudio} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"35px"}}>Lecture Audio Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
}



<div class="w-full bg-neutral-200 " >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && "bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>

       {course.map((i)=>{
            const { courseTitle} = i
            return(
                <div className=''>
         

                                    <div className='flex justify-between'>
                                  
                                                        <div class="relative  mx-auto mt-12 flex flex-col  "style={{width:"50%",height:"60%"}}>
                   
{
        studentLecture.map(i=>{
            const {lectureVideo,lectureTitle,lectureQuiz} = i
            return(
              lectureVideo && lectureVideo.length != 0 ?
<div>
<label  for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-white ">

<div class="flex justify-center m-auto w-full " style={{height:"500px"}} >


  <div class="video-container mr-4">
  <video controls>
    <source src={lectureVideo} type="video/mp4"></source>
    Your browser does not support the video tag.
  </video>
</div>
<svg onClick={deleteLectureVideoDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="ml-4 cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
</div> 
</label>

</div> 
:
    <div class="ml-4 flex items-center justify-center m-auto w-full" >
    <label onClick={openUploadPopDesktop} for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-white ">
    <img  onLoad={desktopSpinnerHandler} class="h-auto w-full rounded-lg"  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

<p  className='borel text-center mt-4' style={{fontSize:'1.5vw'}}>Click here to upload a lecture video</p>
       
    </label>
</div> 
            )
            })

// :





}


</div>
               {
                studentLecture.map(i=>{
                  const {lectureTitle,lectureDescription,lecturePdf,lectureAudio,lectureImg,lectureQuiz} = i
                  return(
                    <div className=""style={{width:"70%"}}>

                    <div className='flex justify-center my-3 '>
                            <div style={{width:'20%'}} onClick={pdfDesktopPop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center  items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
      <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText '>PDF</p>
                                
                            </div>
    
                            <div style={{width:'20%'}} onClick={openAudioDesktop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center  items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
      <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
      <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
      <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Audio</p>
                                
                            </div>
                            <div style={{width:'20%'}} onClick={()=>openQuizDesktop(lectureQuiz)} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center  items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
      <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Quiz</p>
                                
                            </div>
                          
                            <div style={{width:'20%'}}onClick={openImageDesktop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center w-32 items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-file-image" viewBox="0 0 16 16">
      <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
      <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Photo</p>
                                
                            </div>
                            
        
                        </div>
                <div className='border rounded m-auto' style={{width:"80%"}}>
                    
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-5 " style={{fontSize:"3vw"}}>Lecture Title </h3>
           {
            lectureTitleEditor == false &&
           <p className="borel text-gray-500 block"id='editCourseTitleDesktop' style={{fontSize:"1.7vw",maxWidth:"100%",wordBreak:"break-all"}}>{lectureTitle}</p>

           }
          
           
                         </div>
                         {
            lectureTitleEditor == false &&
            <svg id="editIconDesktop" onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>

           }

           {
            lectureTitleEditor == true &&
            <p  style={{fontSize:'1.2vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editTitleOpenerDesktop} id='cancelInputDesktop'>Cancel</p>

           }
                       
                          </div>
                          {
            lectureTitleEditor == true &&
            <div className="relative mx-5 " id='editInputDesktop'>
            <input style={{fontSize:'1.5vw'}} defaultValue={lectureTitle}    onChange={e=>lectureTitleSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                focus:border-black w-full borel pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                border-gray-300 rounded-md" />
 <div className={`${lectureTitleError == "error" ? "block":"hidden"}`}>
 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Lecture Title can't be empty
</div>
</div>

 </div>
                <button style={{fontSize:'1.5vw'}} onClick={saveLectureData} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">

Save
</button>
          </div>
           }
                      
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded-md shadow m-auto my-5' style={{width:"80%"}}>
                    
                    <div className='flex justify-between px-5 py-5 w-full'>
                   <div className='w-full'>
               <div className='flex items-center justify-between w-full mb-3'>
               <h3 class="  home_description-header  " style={{fontSize:"3vw"}}>Lecture Description </h3>
                   {
                    lectureDescriptionEditor == false &&
                    <svg id="editIconDescripionDesktop" onClick={editInputDescripionOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>

                    }
               </div>
                   {
                    lectureDescriptionEditor == false &&
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"1.7vw",maxWidth:"100%",wordBreak:"break-all"}}>{lectureDescription}</p>

                   }
     
                   </div>

              
                {
                    lectureDescriptionEditor == true &&
                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editDescripionOpenerDesktop} id='cancelDescripionInputDesktop'>Cancel</p>

                }
                    </div>
               {
                    lectureDescriptionEditor == true &&
                    <div id='editDescripionInputDesktop' className="relative m-auto"style={{width:"90%"}}>
  
                    <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  ref={textRef} onChange={e=>lectureDescriptionSet(e)}  type="text" class="border  focus:outline-none
                                 focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                                 border-gray-300 rounded-md" maxLength="1000" defaultValue={lectureDescription}></textarea>

                                 <div className={`${lectureDescriptionError == "error" ? "block":"hidden"}`}>
                    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className='Mooli'>
                     Description Can't be empty
                    </div>
                    </div>
                    </div>
                    <p className={lectureDescriptionNew.length != 0 ? 'Mooli text-gray-500' : 'hidden'} style={{fontSize:'11px'}}>{lectureDescriptionNew && lectureDescriptionNew.length}/1,000</p>
                    <button style={{fontSize:'12px'}} onClick={updateLectureDescription} class="ml-10 inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                          
                          Save
                        </button>
                    </div>
               }
         

                    <div>
                
                    </div>
                            
                  </div>

{
  lecturePdf ?

  <div className='border rounded m-auto' style={{width:"80%"}}>
                    
  <div className='flex justify-between px-5 py-5 '>
 <div>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"3vw"}}>Lecture Pdf</h3>
<a target='_blank' className="borel text-gray-500 block hover:underline" style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} href={lecturePdf}>File</a>
 </div>
 {
  lecturePdfEditor == false &&
  <svg onClick={deletePdfDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
 }

  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}

{
  lectureImg ?

  <div className='border rounded m-auto' style={{width:"80%"}}>
                    
  <div className='flex justify-between px-5 py-5 '>
 <div>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"3vw"}}>Lecture Image</h3>
<a className="borel text-gray-500 block hover:underline"target='_blank' style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} href={lectureImg}>Image</a>
 </div>
 <svg onClick={deleteImgDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}

{
  lectureAudio ?

  <div className='border rounded m-auto' style={{width:"80%"}}>
                    
  <div className='flex justify-between px-5 py-5 w-full'>
 <div style={{width:'80%'}}>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"3vw"}}>Lecture Audio</h3>
<audio controls className='w-full'>
      <source src={lectureAudio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
 </div>
 <svg onClick={deleteAudioDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}
                
                  
                        </div>
                  )
                })
               }
                                    </div>
                       

                    
    
     
<div>
    
</div>
                </div>
            )

        }
        )} 
    </div>
    <div id="mobile">
     <DashboardNav/>

            {
              lectureVideoUploadMessage == true &&
                <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>
   lecture Video successfully Uploaded 


  </div>
  </div>
  <svg    onClick={closeLectureVideoUploadMessage} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
              
            }

{
              lectureTitleMessage == true &&
              <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
              <div className='flex justify-center items-center'>
            <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div className='Mooli' style={{fontSize:'3vw'}}>
          
             lecture Title successfully Updated 
          
          
            </div>
            </div>
            <svg   onClick={closeLectureTitleMessage} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
            </div> 
             
          
            }

{
              lectureDescriptionMessage == true &&
              <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
              <div className='flex justify-center items-center'>
            <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div className='Mooli' style={{fontSize:'3vw'}}>
          
             lecture Description successfully Updated 
          
          
            </div>
            </div>
            <svg   onClick={closeLectureDescriptionMessage}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
            </div> 
           
            }
   {
    pdfUploadedMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>
   lecture pdf successfully updated 



  </div>
  </div>
  <svg   onClick={pdfMessageUpdate} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
 
   }
     {
    audioUploaderMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>
   lecture Audio successfully updated 



  </div>
  </div>
  <svg   onClick={audioMessageUpdate} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
 
   }

{
     imageUploadedMessage == true &&
     <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'3vw'}}>
 
    lecture Image successfully updated 
 
 
   </div>
   </div>
   <svg   onClick={imageMessageUpdate} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
     </svg>
   </div> 
  
   }
{
  audiodeletedMessage == true &&
  <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 lecture Audio successfully removed 


</div>
</div>
<svg    onClick={closeAudioDeleteMessage}  style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
  </svg>
</div> 
  

}

{
  imagedeletedMessage == true &&
  <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 lecture Image successfully removed 


</div>
</div>
<svg    onClick={closeImageDeleteMessage}  style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
  </svg>
</div> 
  
}



{
  pdfdeletedMessage == true &&
  <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 lecture Pdf successfully removed 


</div>
</div>
<svg    onClick={pdfDeletedMessageSet}  style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
  </svg>
</div> 

}

{
  lectureVideoDeleteMessage == true &&
  <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
 lecture has been successfully removed 


</div>
</div>
<svg    onClick={closeMessage12Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
  </svg>
</div> 
}
{
  pdfDeleteModal == true &&
  <div id='deletePdfDesktop' className=' flex items-center justify-center'>
       
     
            <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
    <a  onClick={closePdfDeleteDesktop}   class="absolute top-1.5 right-1.5">
        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
            
    </a>
    <h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Delete Pdf </h1>
    <p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently remove this pdf? </p>
    
    <div class="flex flex-row mt-6 space-x-2 justify-evenly">
        <a  onClick={deleteHandlerPdfDesktop} style={{fontSize:"3vw"}}class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
        <a  onClick={closePdfDeleteDesktop}  style={{fontSize:"3vw"}}class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
    </div>
</div>
</div>
</div>  
 

        </div>

}
      
{
  imageDeleteModal == true &&
  <div id='deleteImgDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeImgDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Delete Image </h1>
<p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently remove this Image? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerImgDesktop} style={{fontSize:"3vw"}}class="fjalla w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeImgDeleteDesktop}  style={{fontSize:"3vw"}}class="fjalla w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
       
{
  lectureVideoDeleteModal == true &&
  <div id='deleteLectureVideoDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeLectureVideoDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Delete Lecture Video </h1>
<p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"2.5vw",marginTop:'3vw'}}>Would you like to permanently remove this Lecture Video? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerLectureVideoDesktop} style={{fontSize:"3vw"}} class="fjalla w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeLectureVideoDeleteDesktop} style={{fontSize:"3vw"}} class="fjalla w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
  
{
  audioDeleteModal == true &&
  <div id='deleteAudioDesktop' className=' flex items-center justify-center'>
       
     
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closeAudioDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Delete Audio </h1>
<p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently remove this Audio? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerAudioDesktop} style={{fontSize:"3vw"}}class="fjalla w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closeAudioDeleteDesktop}  style={{fontSize:"3vw"}}class="fjalla w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
     
   {
    lectureVideoUploaderModal == true &&
<div className="uploadFileDesktop h-screen ">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
     
    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"5vw"}}>Upload lecture video</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input accept="video/mp4, video/quicktime, video/mov, video/avi" type="file" id="hidden-input"  className="hidden" onChange={handleFileSelect} />
          <button
          style={{fontSize:'3vw'}}
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 fjalla text-white hover:bg-gray-900 focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input').click();
            }}
          >
            Upload a file
          </button>
          <p className='Mooli text-gray-500' 
          style={{fontSize:'2.5vw',marginTop:'2vw'}}
          >Supported file types include .mp4, .mov, and .avi</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900" 
          style={{fontSize:'4vw'}}
          >To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {files.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500" style={{fontSize:'3vw'}}>No files selected</span>
            </li>
          ) : 
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
             <div class="video-container">
                <video controls >
  <source  
  type="video/mp4"
  
  className="mx-auto "
  src={URL.createObjectURL(files)}

  alt={files.name}
  ></source>
  Your browser does not support the video tag.
</video>
</div>
                <span className="text-small text-gray-500" 
          style={{fontSize:'2.5vw'}}
          >{files.name}</span>
              </li>
          }
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={minimizeUploadImg}
          style={{ width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="bg-red-800 m-auto w-full fjalla flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadFile}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="m-auto w-full flex fjalla  justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"5vw"}}>Lecture Video Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel  mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
   }


{
  pdfUploaderModal == true &&
  <div  className="uploadFileDesktop flex items-center justify-center ">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  # " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"

    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"5vw"}}>Upload pdf</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
          <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
         
          </p>
          <input
  type="file"
  id="hidden-input-pdf"
  accept="application/pdf"
  onChange={handlePdfSelect}
  style={{ display: 'none' }}
/>
          <button
  style={{ fontSize:'3vw' }}
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 text-white fjalla rounded-md focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-pdf').click();
            }}
          >
            {lecturePdf.name}
            Upload a file
          </button>
          <p className='Mooli text-gray-500' 
  style={{ fontSize:'2.5vw' }}
  >Supported file types include .pdf</p>
          
          

        </header>
            <p className='Mooli text-red-800 capitalize'
  style={{ fontSize:'3vw' }}
  >{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900" 
  style={{ fontSize:'5vw' }}
  >To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lecturePdf.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500" style={{ fontSize:'3vw' }}>No files selected</span>
            </li>
          ) : 
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
  
                <span className="text-small text-gray-500" style={{ fontSize:'3vw' }}>{lecturePdf.name}</span>
              </li>
          }
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={MinimizepdfDesktopPop}
          style={{ width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="bg-red-800 fjalla m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadPdf}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="m-auto w-full fjalla flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div style={{width:"90%",height:"95%"}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktopPdf} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"5vw"}}>Lecture Pdf Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
}

{
  imageUploaderModal == true &&
  <div id='uploadImageDesktop' className="uploadFileDesktop">



  {
    progress != 100 & progress == 0 ?
    <main className=" mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
  
    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"5vw"}}>Upload image</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={handleImgSelect} />
          <button
          style={{fontSize:"3vw"}}
            className="mt-2 rounded-sm px-3 py-1 text-white bg-gray-800 hover:bg-gray-900 fjalla rounded focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-img').click();
            }}
          >
          
            Upload a file
          </button>
          <p className='Mooli text-gray-500' style={{fontSize:"3vw"}}>Supported file types include .png .jpg .jpeg</p>

        </header>
            <p className='Mooli text-red-800 capitalize' style={{fontSize:"3vw"}}>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900" style={{fontSize:"5vw"}}>To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lectureImg.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>No files selected</span>
            </li>
          ) : (
           
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
                <img
                style={{maxHeight:"150px"}}
                  className="mx-auto w-32"
                  src={URL.createObjectURL(lectureImg)}
                  alt={lectureImg.name}
                />
                <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>{lectureImg.name}</span>
              </li>
          )}
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={backImageDesktop}
          style={{ width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="bg-red-800 m-auto w-fullflex justify-center items-center fjalla text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadImg}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="m-auto w-full flex justify-center items-center fjalla text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div  style={{width:"90%",height:"95%"}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadImageDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center mt-8' style={{fontSize:"5vw"}}>Lecture Image Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>
     
           </div>}
 

</div>
}

{
  quizUploaderModal == true &&
  <div id='quizDesktopPop' >
<main className=" mx-auto  my-5 bg-white" style={{width:"90%",height:"95%"}}>
<div className='py-5 flex w-full justify-between items-center'>
<div style={{paddingLeft:"50px"}}>
    <h1 className='anton text-gray-900 capitalize' style={{fontSize:"5vw",paddingTop:"10vw",lineHeight:'6vw'}}>Prepare a quiz for this lecture</h1>
    <p className='borel text-gray-500' style={{fontSize:'2.5vw'}}>It is necessary for your students to attain at least 75% to pass the next lecture</p>
    
    </div>

   




              <svg onClick={backQuizDesktop}  xmlns="http://www.w3.org/2000/svg" style={{width:"8vw"}} fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
       </div>


  <div className='mt-10  mx-3' style={{marginTop:"7%"}}>
  <div className="relative " >
  <div className="relative  " >

<p style={{fontSize:'3vw'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3 font-medium text-gray-600 absolute capitalize">Number of questions</p>


<select style={{fontSize:'3vw',height:'14vw',paddingLeft:'4vw'}}onChange={e=>questionsSet(e)} type="text" class="mx-2 border borel  focus:outline-none
  focus:border-black w-full  mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
  border-gray-300 rounded-md">
                              <option className='skill__input-option'value="">Select Field</option>


                              <option className='skill__input-option' value="1">1</option>
                              <option className='skill__input-option' value="2">2</option>
                              <option className='skill__input-option' value="3">3</option>
                              <option className='skill__input-option' value="4">4</option>
                              <option className='skill__input-option' value="5">5</option>
                              <option className='skill__input-option' value="6">6</option>
                              <option className='skill__input-option' value="7">7</option>
                              <option className='skill__input-option' value="8">8</option>
                              <option className='skill__input-option' value="9">9</option>
                              <option className='skill__input-option' value="10">10</option>
                              <option className='skill__input-option' value="11">11</option>
                              <option className='skill__input-option' value="12">12</option>
                              <option className='skill__input-option' value="13">13</option>
                              <option className='skill__input-option' value="14">14</option>
                              <option className='skill__input-option' value="15">15</option>















</select> 
{
  questionsError == "error" &&
<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'3vw'}}>
   This field Can't be empty

   </div>
   </div>
   
   </div> 
  }

 
</div>

<Link to={`${questions == '' ? '' : `/course/${courseId}/lecture/${lectureId}/quiz/${questions}` }`}>
   <button onClick={()=>questions == '' && setquestionsError('error')} style={{fontSize:'3vw',height:'13vw'}} type='submit'   className="fjalla mt-5 bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
       rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Prepare Quiz
   </button>
   </Link>
 </div>
  </div>



  


</main>

</div>
}

{
  audioUploaderModal == true &&
  <div id='uploadAudioDesktop' className="uploadFileDesktop ">



  {
    progress != 100 & progress == 0 ?
    <main className="mx-auto  my-5 " style={{width:"90%",height:"95%"}}>

    {/* file upload modal */}
    <article
      aria-label="File Upload Modal"
      className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
      
    >
  <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"5vw"}}>Upload Audio</p>

      {/* overlay */}
      <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>

      <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
          <input
  type="file"
  id="hidden-input-audio"
  accept="audio/*"
  onChange={handleAudioSelect}
  style={{ display: 'none' }}
/>
          <button
          style={{fontSize:"3vw"}}
            className="mt-2 rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-900 text-white fjalla focus:shadow-outline focus:outline-none"
            onClick={() => {
              document.getElementById('hidden-input-audio').click();
            }}
          >
            
            Upload a file
          </button>
          <p className='Mooli text-gray-500' style={{fontSize:"3vw"}}>Supported file types include .audio</p>
          

        </header>
            <p className='Mooli text-red-800 capitalize' style={{fontSize:"3vw"}}>{fileMesssage}</p>

        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900" style={{fontSize:"3vw"}}>To Upload</h1>

        <ul className="flex justify-evenley  -m-1">
          {lectureAudio.length === 0 ? (
            <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>No files selected</span>
            </li>
          ) : (
            
              <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
             
  
                <span className="text-small text-gray-500"style={{fontSize:"3vw"}}>{lectureAudio.name}</span>
              </li>
          )}
        </ul>
      </section>

      {/* sticky footer */}
      <div class="w-full bg-neutral-200">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <div className="flex justify-evenly mt-10 mb-10">
        <button
          type="submit"
          onClick={backAudioDesktop}
          style={{ width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="bg-red-800 m-auto w-full fjalla flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Back
        </button>
        <button
          onClick={uploadAudioDesktop}
          type="submit"
          style={{ backgroundColor: '#2141ce', width: '20%',fontSize:'3vw',padding:'2vw 3vw' }}
          className="m-auto w-full flex justify-center items-center fjalla  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
        >
          Upload
        </button>
      </div>
    </article>
  </main>
  
  :
  progress == 100 ? <div className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         <div class="">
         <svg onClick={cancelUploadDesktopAudio} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
         <p className='anton  text-center' style={{fontSize:"5vw"}}>Lecture Audio Successfully Uploaded</p>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
     </div>
     
           </div>
           :
           <div className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
         
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         <p className='fjalla mt-4 text-center'>Uploading</p>

     </div>
     <p className='borel mt-4 text-center'>Please do not refresh or close this page</p>

     <div class="w-full bg-neutral-200 mt-5" >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && " bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>
           </div>}
 

</div>
}



<div class="w-full bg-neutral-200 " >
  <div
    class={`${progress == 0 && "hidden"} ${progress !=0 && "bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
    style={{width:progress + "%",}}>
    {Math.round(progress)} %
  </div>

</div>

       {course.map((i)=>{
            const { courseTitle} = i
            return(
                <div className=''>
         
         <h3 class="  home_description-header pb-4 " style={{fontSize:"5vw",marginTop:'10vw',marginLeft:'4vw'}}>Lecture Video </h3>

                                  
                                                        <div class="relative    flex flex-col  mx-auto"style={{width:"90%",height:"60%",marginTop:'5vw',marginBottom:'18vw'}}>
                   
{
        studentLecture.map(i=>{
            const {lectureVideo,lectureTitle,lectureQuiz} = i
            return(
              lectureVideo && lectureVideo.length != 0 ?
<div class=" flex items-center justify-center m-auto w-full">
<label  for="dropzone-file" class="flex flex-col items-center justify-center w-full   border-gray-300  rounded-lg cursor-pointer bg-white ">
<div className='flex justify-between w-full'>
  <div></div>
  <svg onClick={deleteLectureVideoDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:'5vw',marginBottom:'4vw'}} fill="currentColor" class="ml-4 cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
</div>
<div class="flex justify-center m-auto w-full "  >


  <div class="video-container mr-4">
  <video controls>
    <source src={lectureVideo} type="video/mp4"></source>
    Your browser does not support the video tag.
  </video>
</div>

</div> 
</label>

</div> 
:
    <div class=" flex items-center justify-center m-auto w-full" >
    <label onClick={openUploadPopDesktop} for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-white ">
    <img  onLoad={desktopSpinnerHandler} class="h-auto w-full rounded-lg"  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

<p  className='borel text-center mt-4' style={{fontSize:'2.6vw'}}>Click here to upload a lecture video</p>
       
    </label>
</div> 
            )
            })

// :





}


</div>
               {
                studentLecture.map(i=>{
                  const {lectureTitle,lectureDescription,lecturePdf,lectureAudio,lectureImg,lectureQuiz} = i
                  return(
                    <div className=""style={{width:"100%"}}>

                    <div className='flex justify-center my-3 '>
                            <div style={{width:'20%'}} onClick={pdfDesktopPop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center  items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
      <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText '>PDF</p>
                                
                            </div>
    
                            <div style={{width:'20%'}} onClick={openAudioDesktop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center  items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
      <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
      <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
      <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Audio</p>
                                
                            </div>
                            <div style={{width:'20%'}} onClick={()=>openQuizDesktop(lectureQuiz)} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center  items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
      <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Quiz</p>
                                
                            </div>
                          
                            <div style={{width:'20%'}}onClick={openImageDesktop} className='rounded-md overflow-hidden uploadText-wrapper relative flex justify-center w-32 items-center  py-6 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-file-image" viewBox="0 0 16 16">
      <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
      <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z"/>
    </svg>
    
    <p className='fjalla mt-2 absolute top-auto left-auto uploadText'>Photo</p>
                                
                            </div>
                            
        
                        </div>
                <div className='border rounded m-auto shadow' style={{width:"80%"}}>
                    
                          <div className='flex justify-between ' style={{padding:'5vw 6vw'}}>
                         <div>
                         <h3 class="  home_description-header pb-5 " style={{fontSize:"4vw"}}>Lecture Title </h3>
           {
            lectureTitleEditor == false &&
           <p className="borel text-gray-500 block"id='editCourseTitleDesktop' style={{fontSize:"2.5vw",maxWidth:"100%",wordBreak:"break-all"}}>{lectureTitle}</p>

           }
          
           
                         </div>
                         {
            lectureTitleEditor == false &&
            <svg id="editIconDesktop" onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>

           }

           {
            lectureTitleEditor == true &&
            <p  style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editTitleOpenerDesktop} id='cancelInputDesktop'>Cancel</p>

           }
                       
                          </div>
                          {
            lectureTitleEditor == true &&
            <div className="relative mx-5 " id='editInputDesktop'>
            <input style={{paddingTop:"3vw",paddingBottom:"3vw",paddingLeft:"4vw",fontSize:"3vw",width:"100%"}}  defaultValue={lectureTitle}    onChange={e=>lectureTitleSet(e)}required  autoComplete="off"  type="text" className="border  focus:outline-none
                focus:border-black w-full borel  mr-0 mb-0 ml-0 text-gray-700 block bg-white
                border-gray-300 rounded-md borel" />
                            
              <div className={`${lectureTitleError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'2vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'2.5vw'}}>
Lecture Title can't be empty

</div>
</div>
</div> 

                <button style={{fontSize:'3vw',padding:'2vw 3vw'}} onClick={saveLectureData} class="fjalla inline-flex  mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">

Save
</button>
          </div>
           }
                      
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded-md shadow m-auto my-8' style={{width:"80%"}}>
                    
                    <div className='flex justify-between  w-full' style={{padding:'5vw 6vw'}}>
                   <div className='w-full'>
               <div className='flex items-center justify-between w-full mb-3'>
               <h3 class="  home_description-header  " style={{fontSize:"4vw"}}>Lecture Description </h3>
                   {
                    lectureDescriptionEditor == false &&
                    <svg id="editIconDescripionDesktop" onClick={editInputDescripionOpenerDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>

                    }
               </div>
                   {
                    lectureDescriptionEditor == false &&
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"2.5vw",maxWidth:"100%",wordBreak:"break-all"}}>{lectureDescription}</p>

                   }
     
                   </div>

              
                {
                    lectureDescriptionEditor == true &&
                    <p style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editDescripionOpenerDesktop} id='cancelDescripionInputDesktop'>Cancel</p>

                }
                    </div>
               {
                    lectureDescriptionEditor == true &&
                    <div id='editDescripionInputDesktop' className="relative m-auto"style={{width:"90%"}}>
  
  <textarea
  style={{
    padding: '3vw 3.5vw',
    fontSize: '2.8vw',
    minHeight: '50vw',
    maxHeight: '80vw',
  }}
  ref={textRef}
  onChange={e => lectureDescriptionSet(e)}
  className="border text-gray-600 focus:outline-none focus:border-black w-full text-base block bg-white border-gray-300 rounded-md"
  maxLength={1000}
  defaultValue={lectureDescription}
></textarea>
<p className='fjalla' style={{fontSize:'3vw'}}> {lectureDescriptionNew && lectureDescriptionNew.length + ' / 1,000'}</p>
 <button style={{fontSize:'3vw'}} onClick={updateLectureDescription} class="fjalla ml-10 inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                          
                          Save
                        </button>
                    </div>
               }
         

                    <div>
                
                    </div>
                            
                  </div>

{
  lecturePdf ?

  <div className='border rounded m-auto shadow mb-8' style={{width:"80%"}}>
                    
  <div className='flex justify-between  ' style={{padding:'5vw 6vw'}}>
 <div>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"4vw"}}>Lecture Pdf </h3>

<a target='_blank' className="borel text-gray-500 block hover:underline" style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}} href={lecturePdf}>Pdf</a>
 </div>
 {
  lecturePdfEditor == false &&
  <svg onClick={deletePdfDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:'4vw'}} fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
 }

  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}

{
  lectureImg ?

  <div className='border rounded m-auto shadow  mb-8' style={{width:"80%"}}>
                    
  <div className='flex justify-between  '  style={{padding:'5vw 6vw'}}>
 <div>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"4vw"}}>Lecture Image</h3>
<a className="borel text-gray-500 block hover:underline"target='_blank' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}} href={lectureImg}>Image</a>
 </div>
 <svg onClick={deleteImgDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}

{
  lectureAudio ?

  <div className='border rounded m-auto shadow mb-8 ' style={{width:"80%"}}>
                    
  <div className='flex justify-between  w-full'  style={{padding:'5vw 6vw'}}>
 <div style={{width:'80%'}}>
 <h3 class="  home_description-header pb-5 " style={{fontSize:"4vw"}}>Lecture Audio</h3>
<audio controls className='w-full' >
      <source src={lectureAudio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
 </div>
 <svg onClick={deleteAudioDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="cursor-pointer hover:text-red-800 bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
  </div>

  <div>

  </div>
          
</div>
:
<div>

</div>
}
                
                  
                        </div>
                  )
                })
               }
                       

                    
    
     
<div>
    
</div>
                </div>
            )

        }
        )} 
    </div>
    </div>
    :
    <div>
<nav className='flex justify-between px-5 navDesktop'>
   
   <Link to="/" className='hire_header  logo' > <div className='logo     capitalize'style={{fontSize:"30px"}}>
                   <img style={{width:"45%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Chekay%20(5).png?alt=media&token=7d713581-1de4-4745-9686-2a8f504d36d0' alt="logo" /> 
                     </div>
                     </Link>

       <div>

       </div>

</nav>
<div class="h-screen  flex items-center">
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
    <div class="w-full lg:w-1/2 mx-8">
        <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
    <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
        Sorry we couldn't find the page you're looking for
    </p>
    <Link to='/dashboard'>
    <a  class="capitalize  px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to dashboard</a>

    </Link>
</div>
<div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
<img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
</div>

</div>
</div>
</div>
    :
    <div>
    <div id='desktop'  className='relative'>
      
           <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
{
 
  <div id='mainPage' className='relative'>
{
  course.length !=0 ?
course.map(i=>{
  const {courseTitle,isTest,instructorId,status} = i
  return(
  
    <div className='flex  '>
    <div id='menuDesktop' className=' shadow-md w-full bg-white ' style={{width:"27%",overflowY:"scroll",position:'fixed',left:'5%',top:'0'}}>
 
    <p id='hideDesktop' onClick={hideMenuDesktop} style={{fontSize:'11px'}} className='text-gray-800 cursor-pointer fjalla flex items-center hover:text-blue-800 justify-end mr-5 mt-8'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
</svg>Hide menu</p>

      {
       lectureMain && lectureMain.length !== 0 ?
       <div>
        {
              lectureMain.map((lecture,currentIndex) => {
                const { lectureTitle,  isCompleted,lectureVideo,id } = lecture;
                
                // Function to handle click event and move to the previous lecture
                const duration = videoDurations[lectureVideo];
                
                return (
                 <div>
                   
                    <div onClick={()=>resetVideoDesktop(currentIndex)} className={`  ${id === lectureId ? "bg-blue-50 borderBlue" : ''} border-b  cursor-pointer`} >
                   
                      <div className='flex     px-4 '>
                     
         
       
             <div
             // onClick={() => handleLectureClick(lectureTitle)}
             className="cursor-pointer  rounded pb-3  flex flex-col items-start"
             key={lectureTitle}
           >
             {/* Lecture content */}
             <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 `} style={{ fontSize: '15px' }}>
             {
               completedLectureIDs.includes(id) ? 
               <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="text-green-800 mr-2 bi bi-browser-edge" viewBox="0 0 16 16">
               <path d="M9.482 9.341c-.069.062-.17.153-.17.309 0 .162.107.325.3.456.877.613 2.521.54 2.592.538h.002c.667 0 1.32-.18 1.894-.519A3.84 3.84 0 0 0 16 6.819c.018-1.316-.44-2.218-.666-2.664l-.04-.08C13.963 1.487 11.106 0 8 0A8 8 0 0 0 .473 5.29C1.488 4.048 3.183 3.262 5 3.262c2.83 0 5.01 1.885 5.01 4.797h-.004v.002c0 .338-.168.832-.487 1.244l.006-.006z"/>
               <path d="M.01 7.753a8.14 8.14 0 0 0 .753 3.641 8 8 0 0 0 6.495 4.564 5 5 0 0 1-.785-.377h-.01l-.12-.075a5.5 5.5 0 0 1-1.56-1.463A5.543 5.543 0 0 1 6.81 5.8l.01-.004.025-.012c.208-.098.62-.292 1.167-.285q.194.001.384.033a4 4 0 0 0-.993-.698l-.01-.005C6.348 4.282 5.199 4.263 5 4.263c-2.44 0-4.824 1.634-4.99 3.49m10.263 7.912q.133-.04.265-.084-.153.047-.307.086z"/>
               <path d="M10.228 15.667a5 5 0 0 0 .303-.086l.082-.025a8.02 8.02 0 0 0 4.162-3.3.25.25 0 0 0-.331-.35q-.322.168-.663.294a6.4 6.4 0 0 1-2.243.4c-2.957 0-5.532-2.031-5.532-4.644q.003-.203.046-.399a4.54 4.54 0 0 0-.46 5.898l.003.005c.315.441.707.821 1.158 1.121h.003l.144.09c.877.55 1.721 1.078 3.328.996"/>
             </svg>
             :
                     <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
                       <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
                       <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
                     </svg>
       
       
             }
             
       
               {lectureTitle} 
             </h1>
           
             <p className='flex'>
               
               {duration && <span className='pt-3 borel text-gray-500'> {formatVideoDuration(duration)}</span>}
             </p>
           </div>
         
       
                      </div>
                     
                    </div>
                   
                 </div>
       
                );
              })
        }
         {
          isTest == true   &&
               <Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>
                   <div className=" ">
                  <div className='border-t px-4 border-b flex w-full cursor-pointer  rounded  flex flex-col items-start'>
             
             <h1 className={`flex items-center fjalla text-center text-gray-900 my-7 `} style={{ fontSize: '15px' }}>
             <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="mr-3 bi bi-calendar2-range-fill" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5m9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5M10 7a1 1 0 0 0 0 2h5V7zm-4 4a1 1 0 0 0-1-1H1v2h4a1 1 0 0 0 1-1"/>
</svg>
             Final assessment
</h1>

                      
                  </div>
                  </div></Link>
             }
       </div>

   
    
        :
        <div className='flex flex-col justify-center' style={{height:"80vh"}}>
          <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
    <p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>
    
        </div>
  
      }
  
    </div>
<div id='lectureBody' className=' border-l flex  flex-col ' style={{width:"72%",height:"100%",position:'absolute',right:'0%',top:0,}}>
    
{

lectureMain.length != 0 ?

      studentLecture.length !=0 ?
     studentLecture.map(i=>{
      const{lectureVideo,lectureTitle,lectureDescription,lectureQuiz,lecturePdf,lectureImg,lectureAudio} = i
      return(
        <div>
  <div className='flex justify-between mt-5  w-full '>
  <div className='flex items-center my-3 ml-6'>
  <p style={{fontSize:'14px'}}  id='openDesktop'onClick={openMenuDesktop} className='hidden cursor-pointer fjalla flex items-center hover:text-blue-800 text-gray-800 justify-end mr-5 '>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="mr-1 bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>Open Menu</p>
   <Link to={`/dashboard`}>
   <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="hover:text-gray-800 text-blue-800 bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg></Link>
<svg className='hover:text-gray-800 text-blue-800 mx-1' aria-hidden="true" width="15" height="15"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/1500/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>

  <Link to={`/course/${courseId}/`}>
<h1 className=" fjalla hover:text-gray-800  underline hover:text-gray-800 text-blue-800" style={{fontSize:'11px'}}>{courseTitle}</h1></Link>
<svg className='hover:text-gray-800 text-blue-800 mx-1' aria-hidden="true" width="15" height="15"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
<h1 className=" fjalla    text-blue-800" style={{fontSize:'11px'}} >{lectureTitle}</h1>

          </div>
<div className='flex'>
  {
    initialLecture == false &&
<p onClick={handlePreviousLecture} className='mr-8 fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"15px"}}>Previous</p>

  }
{
  finalLecture == false &&
<p onClick={handleNextLecture} className='mr-8 fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"15px"}}>Next</p>

}
        </div>  
     
  
      

   
  </div>
        <div className='' id='courseVideoDesktop'>
{
  quizError.length != 0 &&

    <div id="alert-border-10" class="flex items-center p-4 mb-4 text-red-800 border border-red-300 bg-red-50 rounded-xl" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli ">
    {quizError}
    </div>
    <button onClick={closeQuizeErrorMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
  </div>
}

    <div className='flex justify-between items-center my-5'>
        <h1 className='anton capitalize  pl-12 py-5 ' style={{fontSize:"30px"}}>{lectureTitle}</h1>
     {
      lectureQuiz == true &&  <Link to={`/course/${courseId}/quiz/${lectureId}`}>
        <button style={{fontSize:'12px'}}  class=" fjalla mt-5 mr-10 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Take Quiz
      </button></Link>
     }
    </div>
    <video
      id="myVideoDesktop"
      ref={videoRef}
      controls
      className="mx-auto rounded-xl shadow-md"
      style={{ width: "90%", height: "60vh",objectFit:'contain' }}
      onTimeUpdate={()=>handleVideoTimeUpdate(lectureId,instructorId,lectureQuiz)}
    >
      <source type="video/mp4" src={lectureVideo} />
      Your browser does not support the video tag.
    </video>
        <div className='flex justify-between mt-8 px-8 '>
          <div>

          </div>
       
       
      
          {
  finalLecture == true?
  <Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>

  <button  onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5  mb-2">Final Exam</button>
  </Link>
  :
  <button onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5  mb-2">Mark as completed</button>

}
        </div>
        <ol class="pt-8  flex items-center justify-center border-none  space-x-2  text-center text-gray-500      ">
                  
                  
                  
                 {
                  isDescription ?
                  <li onClick={openDescription}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  Description
                  
                  </li>
                  :
                  <li onClick={openDescription} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>Description</p>
                 
                 </li>
                 }
                  
                  
              
                  {
                  isAudio ?
                  <li onClick={openAudio}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  Audio
                  
                  </li>
                  :
                  <li onClick={openAudio} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>Audio</p>
                 
                 </li>
                 }
                 {
                  isPdf ?
                  <li onClick={openPdf}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  PDF
                  
                  </li>
                  :
                  <li onClick={openPdf} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>PDF</p>
                 
                 </li>
                 }
                  {
                  isPicture ?
                  <li onClick={openPicture}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  Picture
                  
                  </li>
                  :
                  <li onClick={openPicture} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>Picture</p>
                 
                 </li>
                 }
                  </ol>
<div className='flex flex-col mx-auto my-12' style={{width:'95%'}}>
  {
    isDescription == true ?
    <div style={{width:'90%'}} className='mx-auto'>
{
  lectureDescription ? 
  <div className='border py-5 rounded px-5  shadow-md'>
<p className='fjalla  text-gray-900 ' style={{fontSize:"30px"}}>Lecture Description</p>
<p className='borel  text-gray-500 mt-3'  style={{fontSize:"11px"}}>{lectureDescription}</p>  </div>
:
<div style={{width:'100%',height:'40vh'}}>
<img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided a description</p>

</div>
}

</div>
:
isPdf ?
<div style={{width:'100%'}} lassName='mx-auto'>
{
  lecturePdf && lecturePdf.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md flex flex-col items-start'>
<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"25px"}}>Recommendation</p>

  <a href={lecturePdf} className='py-5' target='_blank' >
<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
  <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
</svg>
</a>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided a PDF</p>
 </div>

}

</div>
:
isAudio ?
<div style={{width:'100%'}} lassName='mx-auto'>
{
 lectureAudio &&lectureAudio.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md mx-auto flex flex-col items-start'>
<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"30px"}}>Recommendation</p>
<audio controls>
      <source src={lectureAudio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided an Audio</p>
 </div>

}

</div>
:
<div style={{width:'100%'}} lassName='mx-auto'>
{
  lectureImg && lectureImg.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md mx-auto flex flex-col items-start'>

<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"25px"}}>Attached Image</p>

  <a href={lectureImg} className='py-5' target='_blank'>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
  <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
</svg>
</a>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided an Image</p>
 </div>

}

</div>

  }



</div>
        
        </div>
        </div>

      )
     })
     :
     <div>
        no lecture selected
     </div>
     :
     <div style={{height:"90vh"}} className='flex flex-col items-center justify-center'>
                <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
    <p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>
   
        <img
            style={{ width: '25%' }}
            src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
            alt='no content'
          />
    <Link to={`/course/${courseId}/lecture/${v4().slice(0,5)}`}>
            <button
              type='button'
              className='text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Create Lecture
            </button>
          </Link>
     </div>
    }
  

</div>
  </div>
  )
})
:
<div>
  no course 
</div>
 }
</div>
}


    </div>
    <div id='tablet'  className='relative'>
    <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' width="18" height="18" style={{objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>


  
        
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>

<div id='mainPage' className='relative'>
{
  course.length !=0 ?
course.map(i=>{
  const {courseTitle,isTest,instructorId} = i
  return(
    <div className='flex  '>
      {
        lectureSelect == true ?
        <div id='menuTablet' className=' shadow-md w-full bg-white ' >
 
    <p id='hideTablet' onClick={hideMenuTablet} style={{fontSize:'15px'}} className='text-gray-800 cursor-pointer fjalla flex items-center hover:text-blue-800 justify-end mr-5 mt-8'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
</svg>Hide menu</p>

      {
       lectureMain && lectureMain.length !== 0 ?
       <div>
        {
              lectureMain.map((lecture,currentIndex) => {
                const { lectureTitle,  isCompleted,lectureVideo,id } = lecture;
                
                // Function to handle click event and move to the previous lecture
                const duration = videoDurations[lectureVideo];
                
                return (
                 <div>
                   
                    <div onClick={()=>resetVideoDesktop(currentIndex)} className={`  ${id === lectureId ? "bg-blue-50 borderBlue" : ''} border-b  cursor-pointer`} >
                   
                      <div className='flex     px-4 '>
                     
         
       
             <div
             // onClick={() => handleLectureClick(lectureTitle)}
             className="cursor-pointer  rounded pb-3  flex flex-col items-start"
             key={lectureTitle}
           >
             {/* Lecture content */}
             <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 `} style={{ fontSize: '15px' }}>
             {
               completedLectureIDs.includes(id) ? 
               <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="text-green-800 mr-2 bi bi-browser-edge" viewBox="0 0 16 16">
               <path d="M9.482 9.341c-.069.062-.17.153-.17.309 0 .162.107.325.3.456.877.613 2.521.54 2.592.538h.002c.667 0 1.32-.18 1.894-.519A3.84 3.84 0 0 0 16 6.819c.018-1.316-.44-2.218-.666-2.664l-.04-.08C13.963 1.487 11.106 0 8 0A8 8 0 0 0 .473 5.29C1.488 4.048 3.183 3.262 5 3.262c2.83 0 5.01 1.885 5.01 4.797h-.004v.002c0 .338-.168.832-.487 1.244l.006-.006z"/>
               <path d="M.01 7.753a8.14 8.14 0 0 0 .753 3.641 8 8 0 0 0 6.495 4.564 5 5 0 0 1-.785-.377h-.01l-.12-.075a5.5 5.5 0 0 1-1.56-1.463A5.543 5.543 0 0 1 6.81 5.8l.01-.004.025-.012c.208-.098.62-.292 1.167-.285q.194.001.384.033a4 4 0 0 0-.993-.698l-.01-.005C6.348 4.282 5.199 4.263 5 4.263c-2.44 0-4.824 1.634-4.99 3.49m10.263 7.912q.133-.04.265-.084-.153.047-.307.086z"/>
               <path d="M10.228 15.667a5 5 0 0 0 .303-.086l.082-.025a8.02 8.02 0 0 0 4.162-3.3.25.25 0 0 0-.331-.35q-.322.168-.663.294a6.4 6.4 0 0 1-2.243.4c-2.957 0-5.532-2.031-5.532-4.644q.003-.203.046-.399a4.54 4.54 0 0 0-.46 5.898l.003.005c.315.441.707.821 1.158 1.121h.003l.144.09c.877.55 1.721 1.078 3.328.996"/>
             </svg>
             :
                     <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
                       <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
                       <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
                     </svg>
       
       
             }
             
       
               {lectureTitle} 
             </h1>
           
             <p className='flex'>
               
               {duration && <span className='pt-3 borel text-gray-500'> {formatVideoDuration(duration)}</span>}
             </p>
           </div>
         
       
                      </div>
                     
                    </div>
                   
                 </div>
       
                );
              })
        }
         {
          isTest == true   &&
               <Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>
                   <div className=" ">
                  <div className='border-t px-4 border-b flex w-full cursor-pointer  rounded  flex flex-col items-start'>
             
             <h1 className={`flex items-center fjalla text-center text-gray-900 my-7 `} style={{ fontSize: '15px' }}>
             <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="mr-3 bi bi-calendar2-range-fill" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5m9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5M10 7a1 1 0 0 0 0 2h5V7zm-4 4a1 1 0 0 0-1-1H1v2h4a1 1 0 0 0 1-1"/>
</svg>
             Final assessment
</h1>

                      
                  </div>
                  </div></Link>
             }
       </div>

   
    
        :
        <div className='flex flex-col justify-center' style={{height:"80vh"}}>
          <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
    <p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>
    
        </div>
  
      }
  
    </div>
    :
    <div id='lectureTablet' className=' border-l flex  flex-col ' >
    
{

lectureMain.length != 0 ?

      studentLecture.length !=0 ?
     studentLecture.map(i=>{
      const{lectureVideo,lectureTitle,lectureDescription,lectureQuiz,lecturePdf,lectureImg,lectureAudio} = i
      return(
        <div>
  <div className='flex justify-between mt-5  w-full '>
  <div className='flex items-center my-3 ml-6'>
  <p style={{fontSize:'14px'}}  id='openTablet'onClick={openMenuTablet} className=' cursor-pointer fjalla flex items-center hover:text-blue-800 text-gray-800 justify-end mr-5 '>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="mr-1 bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>Open Menu</p>
   <Link to={`/dashboard`}>
   <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="hover:text-gray-800 text-blue-800 bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg></Link>
<svg className='hover:text-gray-800 text-blue-800 mx-1' aria-hidden="true" width="15" height="15"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/1500/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>

  <Link to={`/course/${courseId}/`}>
<h1 className=" fjalla hover:text-gray-800  underline hover:text-gray-800 text-blue-800" style={{fontSize:'11px'}}>{courseTitle}</h1></Link>
<svg className='hover:text-gray-800 text-blue-800 mx-1' aria-hidden="true" width="15" height="15"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
<h1 className=" fjalla    text-blue-800" style={{fontSize:'11px'}} >{lectureTitle}</h1>

          </div>
<div>

</div>
     
  
      

   
  </div>
  <div className='flex justify-between mt-5  w-full '>
<div></div>
  <div className='flex'>
  {
    initialLecture == false &&
<p onClick={handlePreviousLecture} className='mr-8 fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"15px"}}>Previous</p>

  }
{
  finalLecture == false &&
<p onClick={handleNextLecture} className='mr-8 fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"15px"}}>Next</p>

}
        </div>  
  </div>
        <div className='' id='courseVideoDesktop'>
{
  quizError.length != 0 &&

    <div id="alert-border-10" class="flex items-center p-4 mb-4 text-red-800 border border-red-300 bg-red-50 rounded-xl" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli ">
    {quizError}
    </div>
    <button onClick={closeQuizeErrorMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
  </div>
}

    <div className='flex justify-between items-center my-5'>
        <h1 className='anton capitalize  pl-12 py-5 ' style={{fontSize:"30px"}}>{lectureTitle}</h1>
     {
      lectureQuiz == true &&  <Link to={`/course/${courseId}/quiz/${lectureId}`}>
        <button style={{fontSize:'12px'}}  class=" fjalla mt-5 mr-10 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Take Quiz
      </button></Link>
     }
    </div>
    <video
      id="myVideoDesktop"
      ref={videoRef}
      controls
      className="mx-auto rounded-xl shadow-md"
      style={{ width: "90%", height: "60vh",objectFit:'contain' }}
      onTimeUpdate={()=>handleVideoTimeUpdate(lectureId,instructorId,lectureQuiz)}
    >
      <source type="video/mp4" src={lectureVideo} />
      Your browser does not support the video tag.
    </video>
        <div className='flex justify-between mt-8 px-8 '>
          <div>

          </div>
       
       
{
  finalLecture == true?
  <Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>

  <button  onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5  mb-2">Final Exam</button>
  </Link>
  :
  <button onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5  mb-2">Mark as completed</button>

}

        </div>
        <ol class="pt-8  flex items-center justify-center border-none  space-x-2  text-center text-gray-500      ">
                  
                  
                  
                 {
                  isDescription ?
                  <li onClick={openDescription}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  Description
                  
                  </li>
                  :
                  <li onClick={openDescription} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>Description</p>
                 
                 </li>
                 }
                  
                  
              
                  {
                  isAudio ?
                  <li onClick={openAudio}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  Audio
                  
                  </li>
                  :
                  <li onClick={openAudio} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>Audio</p>
                 
                 </li>
                 }
                 {
                  isPdf ?
                  <li onClick={openPdf}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  PDF
                  
                  </li>
                  :
                  <li onClick={openPdf} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>PDF</p>
                 
                 </li>
                 }
                  {
                  isPicture ?
                  <li onClick={openPicture}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  Picture
                  
                  </li>
                  :
                  <li onClick={openPicture} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
                  
                  <p className='block text-gray-900 text-center '>Picture</p>
                 
                 </li>
                 }
                  </ol>
<div className='flex flex-col mx-auto my-12' style={{width:'95%'}}>
  {
    isDescription == true ?
    <div style={{width:'90%'}} className='mx-auto'>
{
  lectureDescription ? 
  <div className='border py-5 rounded px-5  shadow-md'>
<p className='fjalla  text-gray-900 ' style={{fontSize:"30px"}}>Lecture Description</p>
<p className='borel  text-gray-500 mt-3'  style={{fontSize:"11px"}}>{lectureDescription}</p>  </div>
:
<div style={{width:'100%',height:'40vh'}}>
<img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided a description</p>

</div>
}

</div>
:
isPdf ?
<div style={{width:'100%'}} lassName='mx-auto'>
{
  lecturePdf && lecturePdf.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md flex flex-col items-start'>
<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"25px"}}>Recommendation</p>

  <a href={lecturePdf} className='py-5' target='_blank' >
<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
  <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
</svg>
</a>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided a PDF</p>
 </div>

}

</div>
:
isAudio ?
<div style={{width:'100%'}} lassName='mx-auto'>
{
 lectureAudio &&lectureAudio.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md mx-auto flex flex-col items-start'>
<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"30px"}}>Recommendation</p>
<audio controls>
      <source src={lectureAudio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided an Audio</p>
 </div>

}

</div>
:
<div style={{width:'100%'}} lassName='mx-auto'>
{
  lectureImg && lectureImg.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md mx-auto flex flex-col items-start'>

<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"25px"}}>Attached Image</p>

  <a href={lectureImg} className='py-5' target='_blank'>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
  <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
</svg>
</a>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided an Image</p>
 </div>

}

</div>

  }



</div>
        
        </div>
        </div>

      )
     })
     :
     <div>
        no lecture selected
     </div>
     :
     <div style={{height:"90vh"}} className='flex flex-col items-center justify-center'>
                <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
    <p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>
   
        <img
            style={{ width: '25%' }}
            src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
            alt='no content'
          />
    <Link to={`/course/${courseId}/lecture/${v4().slice(0,5)}`}>
            <button
              type='button'
              className='text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Create Lecture
            </button>
          </Link>
     </div>
    }
  

</div>
      }
    

  </div>
  )
})
:
<div>
  no course 
</div>
 }
</div>
    </div>
    <div id='mobile'  className='relative'>
   <DashboardNav/>

<div  className='relative'>
{
  course.length !=0 ?
course.map(i=>{
  const {courseTitle,isTest,instructorId} = i
  return(
    <div className=''>
      {
        lectureSelect == true ?
        <div  className=' shadow-md w-full bg-white ' >
 
    <p id='hideTablet' onClick={hideMenuTablet} style={{fontSize:'3vw'}} className='text-gray-800 cursor-pointer fjalla flex items-center hover:text-blue-800 justify-end mr-5 mt-8'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
</svg>Hide menu</p>

      {
       lectureMain && lectureMain.length !== 0 ?
       <div>
        {
              lectureMain.map((lecture,currentIndex) => {
                const { lectureTitle,  isCompleted,lectureVideo,id } = lecture;
                
                // Function to handle click event and move to the previous lecture
                const duration = videoDurations[lectureVideo];
                
                return (
                 <div>
                   
                    <div onClick={()=>resetVideoDesktop(currentIndex)} className={`  ${id === lectureId ? "bg-blue-50 borderBlue" : ''} border-b  cursor-pointer`} >
                   
                      <div className='flex     px-4 '>
                     
         
       
             <div
             // onClick={() => handleLectureClick(lectureTitle)}
             className="cursor-pointer  rounded pb-3  flex flex-col items-start"
             key={lectureTitle}
           >
             {/* Lecture content */}
             <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 `} style={{ fontSize: '3.5vw' }}>
             {
               completedLectureIDs.includes(id) ? 
               <svg xmlns="http://www.w3.org/2000/svg" style={{width:'6vw'}} fill="currentColor" class="text-green-800 mr-2 bi bi-browser-edge" viewBox="0 0 16 16">
               <path d="M9.482 9.341c-.069.062-.17.153-.17.309 0 .162.107.325.3.456.877.613 2.521.54 2.592.538h.002c.667 0 1.32-.18 1.894-.519A3.84 3.84 0 0 0 16 6.819c.018-1.316-.44-2.218-.666-2.664l-.04-.08C13.963 1.487 11.106 0 8 0A8 8 0 0 0 .473 5.29C1.488 4.048 3.183 3.262 5 3.262c2.83 0 5.01 1.885 5.01 4.797h-.004v.002c0 .338-.168.832-.487 1.244l.006-.006z"/>
               <path d="M.01 7.753a8.14 8.14 0 0 0 .753 3.641 8 8 0 0 0 6.495 4.564 5 5 0 0 1-.785-.377h-.01l-.12-.075a5.5 5.5 0 0 1-1.56-1.463A5.543 5.543 0 0 1 6.81 5.8l.01-.004.025-.012c.208-.098.62-.292 1.167-.285q.194.001.384.033a4 4 0 0 0-.993-.698l-.01-.005C6.348 4.282 5.199 4.263 5 4.263c-2.44 0-4.824 1.634-4.99 3.49m10.263 7.912q.133-.04.265-.084-.153.047-.307.086z"/>
               <path d="M10.228 15.667a5 5 0 0 0 .303-.086l.082-.025a8.02 8.02 0 0 0 4.162-3.3.25.25 0 0 0-.331-.35q-.322.168-.663.294a6.4 6.4 0 0 1-2.243.4c-2.957 0-5.532-2.031-5.532-4.644q.003-.203.046-.399a4.54 4.54 0 0 0-.46 5.898l.003.005c.315.441.707.821 1.158 1.121h.003l.144.09c.877.55 1.721 1.078 3.328.996"/>
             </svg>
             :
                     <svg xmlns="http://www.w3.org/2000/svg" style={{width:'6vw'}} fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
                       <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
                       <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
                     </svg>
       
       
             }
             
       
               {lectureTitle} 
             </h1>
           
             <p className='flex'>
               
               {duration && <span className='pt-3 borel text-gray-500' style={{fontSize:'3vw'}}> {formatVideoDuration(duration)}</span>}
             </p>
           </div>
         
       
                      </div>
                     
                    </div>
                   
                 </div>
       
                );
              })
        }
         {
          isTest == true   &&
               <Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>
                   <div className=" ">
                  <div className='border-t px-4 border-b flex w-full cursor-pointer  rounded  flex flex-col items-start'>
             
             <h1 className={`flex items-center fjalla text-center text-gray-900 my-7 `} style={{ fontSize: '3.5vw' }}>
             <svg xmlns="http://www.w3.org/2000/svg" style={{width:'6vw'}} fill="currentColor" class="mr-3 bi bi-calendar2-range-fill" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5m9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5M10 7a1 1 0 0 0 0 2h5V7zm-4 4a1 1 0 0 0-1-1H1v2h4a1 1 0 0 0 1-1"/>
</svg>
             Final assessment
</h1>

                      
                  </div>
                  </div></Link>
             }
       </div>

   
    
        :
        <div className='flex flex-col justify-center' style={{height:"80vh"}}>
          <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
    <p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>
    
        </div>
  
      }
  
    </div>
    :
    <div  className='  flex  flex-col ' >
    
{

lectureMain.length != 0 ?

      studentLecture.length !=0 ?
     studentLecture.map(i=>{
      const{lectureVideo,lectureTitle,lectureDescription,lectureQuiz,lecturePdf,lectureImg,lectureAudio} = i
      return(
        <div>
  <div className='  w-full '>
   <p style={{fontSize:'2.8vw',marginBottom:'5vw',marginLeft:'4vw'}}  id='openTablet'onClick={openMenuTablet} className=' cursor-pointer fjalla flex items-center hover:text-blue-800 text-gray-800   '>
  <svg xmlns="http://www.w3.org/2000/svg"  style={{width:'5vw',marginRight:'1vw'}}fill="currentColor" class=" bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>Open Menu</p>
  <div className='flex items-center my-3 '>
 
   <Link to={`/dashboard`}>
   <svg xmlns="http://www.w3.org/2000/svg" style={{width:'4.5vw',marginRight:'1vw',marginLeft:'3vw'}} fill="currentColor" class="hover:text-gray-800 text-blue-800 bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg></Link>
<svg className='hover:text-gray-800 text-blue-800 ' aria-hidden="true" style={{width:'4.5vw',marginRight:'1vw'}}  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/1500/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>

  <Link to={`/course/${courseId}/`}>
<h1 className=" fjalla hover:text-gray-800  underline hover:text-gray-800 text-blue-800" style={{fontSize:'2.5vw',marginRight:'1vw'}}>{courseTitle}</h1></Link>
<svg className='hover:text-gray-800 text-blue-800 ' aria-hidden="true" style={{width:'4.5vw',marginRight:'1vw'}}  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
<h1 className=" fjalla    text-blue-800" style={{fontSize:'2.5vw'}} >{lectureTitle}</h1>

          </div>
<div>

</div>
     
  
      

   
  </div>
  <div className='flex justify-between mt-5  w-full '>
<div></div>
  <div className='flex'>
  {
    initialLecture == false &&
<p onClick={handlePreviousLecture}  className=' fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"3.2vw",marginRight:'5vw'}}>Previous</p>

  }
{
  finalLecture == false &&
<p onClick={handleNextLecture} className=' fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"3.2vw",marginRight:'5vw'}}>Next</p>

}
        </div>  
  </div>
        <div className='' id='courseVideoDesktop'>
{
  quizError.length != 0 &&
 <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
   <div className='flex justify-center items-center'>
 <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div className='Mooli' style={{fontSize:'3vw'}}>
 {quizError}

 </div>
 </div>
 <svg  onClick={closeQuizeErrorMessage} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </div> 
  
}

    <div className='flex justify-between items-center my-5'>
        <h1 className='anton capitalize  pl-12 py-5 ' style={{fontSize:"6vw",paddingLeft:'8vw',paddingTop:'5vw'}}>{lectureTitle}</h1>
     {
      lectureQuiz == true &&  <Link to={`/course/${courseId}/quiz/${lectureId}`}>
        <button style={{fontSize:'3vw',padding:'0 4vw',height:'11vw'}}  class="flex items-center justify-center fjalla mt-5 mr-10 inline-flex  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Take Quiz
      </button></Link>
     }
    </div>
    <video
      id="myVideoDesktop"
      ref={videoRef}
      controls
      className="mx-auto rounded-xl shadow-md"
      style={{ width: "90%", height: "60vh",objectFit:'contain' }}
      onTimeUpdate={()=>handleVideoTimeUpdate(lectureId,instructorId,lectureQuiz)}
    >
      <source type="video/mp4" src={lectureVideo} />
      Your browser does not support the video tag.
    </video>
        <div className='flex justify-between mt-8 px-8 '>
          <div>

          </div>
       
       
{
    finalLecture == true?
    <Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>
  <button onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm   "style={{fontSize:'3vw',height:'12vw',padding:'0vw 3vw'}}>Final Exam</button>
  
    </Link>
    :
  <button onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm   "style={{fontSize:'3vw',height:'12vw',padding:'0vw 3vw'}}>Mark as completed</button>

}

        </div>
        <ol style={{marginTop:'8vw'}} class="  flex items-center justify-center border-none  space-x-2  text-center text-gray-500      ">
                  
                  
                  
                 {
                  isDescription ?
                  <li onClick={openDescription}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800  rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  Description
                  
                  </li>
                  :
                  <li onClick={openDescription} class="border flex items-center justify-center  text-gray-900 text-center border-l  cursor-pointer  hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  <p className='block text-gray-900 text-center '>Description</p>
                 
                 </li>
                 }
                  
                  
              
                  {
                  isAudio ?
                  <li onClick={openAudio}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800  rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  Audio
                  
                  </li>
                  :
                  <li onClick={openAudio} class="border flex items-center justify-center  text-gray-900 text-center border-l  cursor-pointer  hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  <p className='block text-gray-900 text-center '>Audio</p>
                 
                 </li>
                 }
                 {
                  isPdf ?
                  <li onClick={openPdf}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800  rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  PDF
                  
                  </li>
                  :
                  <li onClick={openPdf} class="border flex items-center justify-center  text-gray-900 text-center border-l  cursor-pointer  hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  <p className='block text-gray-900 text-center '>PDF</p>
                 
                 </li>
                 }
                  {
                  isPicture ?
                  <li onClick={openPicture}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800  rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  Picture
                  
                  </li>
                  :
                  <li onClick={openPicture} class="border flex items-center justify-center  text-gray-900 text-center border-l  cursor-pointer  hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'3vw',height:'12vw'}}>
                  
                  <p className='block text-gray-900 text-center '>Picture</p>
                 
                 </li>
                 }
                  </ol>
<div className='flex flex-col mx-auto my-12' style={{width:'95%'}}>
  {
    isDescription == true ?
    <div style={{width:'90%'}} className='mx-auto'>
{
  lectureDescription ? 
  <div className='border rounded   shadow-md' style={{padding:'10vw 6vw'}}>
<p className='fjalla  text-gray-900 ' style={{fontSize:"5vw"}}>Lecture Description</p>
<p className='borel  text-gray-500 mt-3'  style={{fontSize:"11px"}}>{lectureDescription}</p>  </div>
:
<div style={{width:'100%',height:'40vh'}}>
<img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'5vw'}}> The Instructor has not provided a description</p>

</div>
}

</div>
:
isPdf ?
<div style={{width:'100%'}} className='mx-auto'>
{
  lecturePdf && lecturePdf.length != 0 ?
  <div className='border rounded   shadow-md' style={{padding:'10vw 6vw'}}>

<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"5vw"}}>Recommendation</p>

  <a href={lecturePdf} className='py-5' target='_blank' >
<svg xmlns="http://www.w3.org/2000/svg" style={{width:'8vw'}} fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
  <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
</svg>
</a>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'5vw'}}> The Instructor has not provided a PDF</p>
 </div>

}

</div>
:
isAudio ?
<div style={{width:'100%'}} className='mx-auto'>
{
 lectureAudio &&lectureAudio.length != 0 ?
  <div className='border rounded   shadow-md' style={{padding:'10vw 6vw'}}>

<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"5vw"}}>Recommendation</p>
<audio controls>
      <source src={lectureAudio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'5vw'}}> The Instructor has not provided an Audio</p>
 </div>

}

</div>
:
<div style={{width:'100%'}} className='mx-auto'>
{
  lectureImg && lectureImg.length != 0 ?
  <div className='border rounded   shadow-md' style={{padding:'10vw 6vw'}}>

<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"5vw"}}>Attached Image</p>

  <a href={lectureImg} className='py-5' target='_blank'>
  <svg xmlns="http://www.w3.org/2000/svg" style={{width:'8vw'}} fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
  <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
</svg>
</a>  

 </div>
 :
<div style={{width:'100%',height:'40vh'}}>

 <img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

 <p className='fjalla text-center' style={{fontSize:'5vw'}}> The Instructor has not provided an Image</p>
 </div>

}

</div>

  }



</div>
        
        </div>
        </div>

      )
     })
     :
     <div>
        no lecture selected
     </div>
     :
     <div style={{height:"90vh"}} className='flex flex-col items-center justify-center'>
                <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
    <p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>
   
        <img
            style={{ width: '25%' }}
            src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
            alt='no content'
          />
    <Link to={`/course/${courseId}/lecture/${v4().slice(0,5)}`}>
            <button
              type='button'
              className='text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Create Lecture
            </button>
          </Link>
     </div>
    }
  

</div>
      }
    

  </div>
  )
})
:
<div>
  no course 
</div>
 }
</div>
    </div>
    </div>
    )
   })
  )
}
